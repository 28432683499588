import { Pipe, PipeTransform } from '@angular/core';

function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

@Pipe({
  name: 'textColor'
})
export class TextColorPipe implements PipeTransform {
  transform(colorHex: string, ...args: unknown[]): unknown {
    if (colorHex.includes('#')) colorHex = colorHex.slice(1);

    const rbg = hexToRgb(colorHex);
    if (rbg == null) return '#fff';
    // https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
    return rbg.r * 0.299 + rbg.g * 0.587 + rbg.b * 0.114 > 186 ? '#000' : '#fff';
  }
}
