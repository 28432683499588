import { Component, Input, OnInit } from '@angular/core';
import { Order, Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map } from 'rxjs';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { BaseSWGSlotComponent } from '../base/base-slot.component';

@Component({
  selector: 'app-swg-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.scss']
})
export class SwgLockComponent extends BaseSWGSlotComponent {
  @Input()
  item!: ProductLineItem;

  @Input()
  order!: Order;

  lockSlot = 15;
  lockProducts$: Observable<Product[]> | undefined;
  lockOptions$: Observable<Option[]> | undefined;
  selectedLock: Option | undefined = undefined;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  override initItem(): void {}

  override updateItem(firstChange?: boolean | undefined): void {
    // Gets the door products from api
    this.lockProducts$ = this.productService
      .getSlot(this.lockSlot, ProductTypeEnum.Swg)
      .pipe(map((p) => p.filter((p) => p.isAddon)));
    this.lockOptions$ = this.lockProducts$.pipe(
      this.productToOptionsPipe(this.translate.instant('Configuration.SWG.Lock.None'), undefined, this.lockSlot),
      map((res) => {
        this.selectedLock = res.selected;

        if (this.item.height! < 1200) {
          return res.res.filter((option) => !option.meta?.productNumber?.includes('swsh'));
        } else if (this.item.height! >= 1200) {
          return res.res.filter(
            (option) =>
              !option.meta?.productNumber?.includes('5') &&
              (option.meta?.productNumber?.includes('swsh') || option.value == '')
          );
        }

        if (!this.selectedLock) this.selectedLock = res.res[0];

        return res.res;
      })
    );
  }

  async updateLock(option: Option) {
    if (option.value === '') {
      this.removeAddon.emit(this.lockSlot);
    } else {
      this.addAddon.emit({ slot: this.lockSlot, create: { productId: option.value } });
    }
  }
}
