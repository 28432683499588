import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoorComponent } from './slots/door/door.component';
import { AvkComponent } from './avk.component';
import { TranslatedNamePipe } from '../../../pipes/translated-name.pipe';
import { TranslatedDescriptionPipe } from '../../../pipes/translated-description.pipe';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { MyMatPaginatorIntl } from '../../table/paginator.intl';
import { LanguageModule } from '../../../language.module';
import { AtomsModule } from '../../../atoms/atoms.module';
import { DraftSelectComponent } from '../../draft-select/draft-select.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DraftBoxComponent } from '../../draft-box/draft-box.component';
import { BaseComponent } from './slots/base/base.component';
import { CeilingTypeComponent } from './slots/ceiling-type/ceiling-type.component';
import { FrontVerticalComponent } from './slots/front-vertical/front-vertical.component';
import { FrontHorizontalComponent } from './slots/front-horizontal/front-horizontal.component';
import { SidewallComponent } from './slots/sidewall/sidewall.component';
import { BackwallComponent } from './slots/backwall/backwall.component';
import { IndoorComponent } from './slots/indoor/indoor.component';
import { FloorComponent } from './slots/floor/floor.component';
import { SurfaceComponent } from './slots/surface/surface.component';
import { SocketComponent } from './slots/socket/socket.component';
import { RadioComponent } from '../../../atoms/radio/radio.component';
import { TextColorPipe } from '../../../text-color.pipe';
import { FilterComponent } from './slots/filter/filter.component';
import { AttachmentsComponent } from './slots/attachments/attachments.component';
import { ConcatDescriptionPipe } from '../../../concat-description.pipe';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [
    AvkComponent,
    DoorComponent,
    BaseComponent,
    DraftSelectComponent,
    DraftBoxComponent,
    CeilingTypeComponent,
    FrontVerticalComponent,
    FrontHorizontalComponent,
    SidewallComponent,
    BackwallComponent,
    IndoorComponent,
    FloorComponent,
    SurfaceComponent,
    SocketComponent,
    FilterComponent,
    AttachmentsComponent
  ],
  imports: [CommonModule, LanguageModule, AtomsModule, MatTooltipModule, PipesModule],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: (translateService: TranslateService) => new MyMatPaginatorIntl(translateService)
    }
  ],
  exports: [AvkComponent, DraftSelectComponent, DraftBoxComponent, AttachmentsComponent]
})
export class AvkModule {}
