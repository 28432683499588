<app-modal (onClose)="onClose.emit()">
    
    <form class="relative flex flex-col gap-4 bg-white" *ngIf="this.user != undefined">
        <span class="text-center block w-full">
            {{'UserModal.Edit'|translate}}
        </span>
        <app-input 
            [label]="'UserModal.Firstname'|translate" 
            [type]="'text'" 
            [(value)]="this.user.firstName"
        ></app-input>
        <app-input 
            [label]="'UserModal.Lastname'|translate" 
            [type]="'text'" 
            [(value)]="this.user.lastName"
        ></app-input>
        <app-input 
            [label]="'UserModal.Email'|translate" 
            [type]="'text'" 
            [description]="'UserModal.EmailDescription'|translate"
            [(value)]="this.user.email"
        ></app-input>
        <app-button 
            [isDisabled]="isReadonly" 
            (onClick)="onSubmitUser.emit(user)" class="mt-4" 
            [label]="user ? ('UserModal.Save'|translate) : ('UserModal.Add'|translate)" 
            [type]="'secondary'" 
            [icon]="'done'" 
            [fullWidth]="true" 
            (onClick)="save()"></app-button>
        <app-button 
            [isDisabled]="isReadonly" *ngIf="isRemovable && user" 
            [label]="'UserModal.Remove'|translate" 
            [type]="'primary'" 
            [fullWidth]="true" 
            [icon]="'delete'" 
            (onClick)="onRemove.emit()"></app-button>
    </form>
</app-modal>