import { Component, Input } from '@angular/core';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { BaseALSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map } from 'rxjs';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';

@Component({
  selector: 'app-al-mounting',
  templateUrl: './mounting.component.html',
  styleUrls: ['./mounting.component.scss']
})
export class MountingComponent extends BaseALSlotComponent {
  @Input()
  item!: ProductLineItem;

  mountingPlateSlot = 23;
  mountingPlateProducts$: Observable<Product[]> | undefined;
  mountingPlateOptions$: Observable<Option[]> | undefined;
  selectedMountingPlate: Option | undefined = undefined;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  override initItem(): void {}
  override updateItem(firstChange?: boolean | undefined): void {
    this.mountingPlateProducts$ = this.productService.getSlot(this.mountingPlateSlot, ProductTypeEnum.Al);
    this.mountingPlateOptions$ = this.mountingPlateProducts$!.pipe(
      this.productToOptionsPipe(this.mountingPlateSlot, this.translate.instant('Configuration.AVK.Dropdowns.None')),
      map((res) => {
        this.selectedMountingPlate = res.selected;
        return res.res;
      })
    );
  }
}
