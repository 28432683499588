<div
  *ngIf="orderService.order$ | async as order"
  class="relative flex flex-col p-8 bg-white border-l-4 border-monza-500"
  [ngClass]="{ 'h-full overflow-visible': isExpanded, 'h-28 overflow-hidden': !isExpanded }"
>
  <div class="flex items-center gap-4">
    <div>
      <span class="flex items-center font-medium z-10">
        <span class="px-2 py-1 mr-2 text-xs leading-none text-white bg-monza-500" *ngIf="item.amount > 1">
          {{ item.amount }}×
        </span>
        {{ item | translatedName : translate.currentLang }}
      </span>
      <span class="text-sm font-medium leading-none text-monza-500">
        {{ item.isWall ? ('Configuration.Fields.Wall' | translate) : '' }}
        {{ item.isSocket ? ('Configuration.Fields.Socket' | translate) : '' }}
        {{ item.isField ? ('Configuration.Fields.Field' | translate) : '' }}
        {{ item.isDividerWall ? ('Configuration.Fields.Divider' | translate) : '' }}
      </span>
      <span class="text-sm"> — {{ item | translatedDescription : translate.currentLang }}</span>
    </div>
    <div class="flex flex-col items-center gap-2 ml-auto md:flex-row">
      <div>
        <app-badge [type]="'price'" class="ml-auto mr-4 z-10" [label]="item.price"></app-badge>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-8 mt-8">
    <div class="grid grid-cols-6">
      <div class="item-row flex flex-col gap-6 col-span-6">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.BaseData' | translate"
        ></app-order-label>
        <app-avk-base [item]="item"></app-avk-base>
      </div>

      <div class="item-row flex flex-col gap-6 mt-8 col-span-6">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.CeilingType' | translate"
        ></app-order-label>
        <app-avk-ceiling-type
          [item]="item"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-ceiling-type>
      </div>

      <div class="item-row flex flex-col gap-6 mt-8 col-span-6">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.Main' | translate"
        ></app-order-label>
        <app-avk-door
          doorInstallationArea="compartment-left"
          [item]="item"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-door>
        <app-avk-filter
          [item]="item"
          [slot]="26"
          size="full"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-filter>
        <app-order-label
          class="col-span-6"
          [subHeader]="true"
          [label]="'Configuration.Fields.OpenField.FrontHorizontalDoor' | translate"
        ></app-order-label>
        <app-avk-front-horizontal
          [side]="otherSide"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
          [item]="item"
        ></app-avk-front-horizontal>
      </div>

      <div class="item-row flex flex-col gap-6 mt-8 col-span-6 transition-all duration-300">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.Compartment' | translate"
        ></app-order-label>
        <app-avk-front-vertical
          side="right"
          [order]="order"
          [item]="item"
          [(otherSide)]="otherSide"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-front-vertical>
      </div>

      <div
        class="item-row flex flex-col gap-6 mt-8 pr-8 col-span-6"
        [ngClass]="{
          'lg:col-span-3': !sideWallLeftAsDoor && !sideWallRightAsDoor,
          'lg:col-span-6': sideWallLeftAsDoor || sideWallRightAsDoor
        }"
      >
        <app-order-label [label]="'Configuration.Fields.OpenField.SideWallLeft' | translate"></app-order-label>
        <app-avk-sidewall
          [item]="item"
          (sideWallAsDoorChange)="sideWallLeftAsDoor = $event"
          [side]="'Left'"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-sidewall>
        <app-avk-door
          [item]="item"
          doorInstallationArea="side-left"
          [show]="sideWallLeftAsDoor"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-door>
        <app-avk-filter
          [item]="item"
          [slot]="106"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-filter>
      </div>

      <div
        class="item-row flex flex-col gap-6 mt-8 col-span-6"
        [ngClass]="{
          'lg:col-span-3': !sideWallLeftAsDoor && !sideWallRightAsDoor,
          'lg:col-span-6': sideWallLeftAsDoor || sideWallRightAsDoor
        }"
      >
        <app-order-label [label]="'Configuration.Fields.OpenField.SideWallRight' | translate"></app-order-label>
        <app-avk-sidewall
          [item]="item"
          (sideWallAsDoorChange)="sideWallRightAsDoor = $event"
          [side]="'Right'"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-sidewall>
        <app-avk-door
          [item]="item"
          doorInstallationArea="side-right"
          [show]="sideWallRightAsDoor"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-door>
        <app-avk-filter
          [item]="item"
          [slot]="126"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-filter>
      </div>

      <div class="item-row flex flex-col gap-6 mt-8 col-span-6">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.BackWall' | translate"
        ></app-order-label>
        <app-avk-backwall
          [item]="item"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-backwall>
        <app-avk-filter
          [item]="item"
          [slot]="146"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-filter>
      </div>

      <div class="item-row flex flex-col gap-6 mt-8 col-span-6">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.Indoor' | translate"
        ></app-order-label>
        <app-avk-indoor
          [item]="item"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-indoor>
      </div>

      <div class="item-row flex flex-col gap-4 mt-8 col-span-6">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.Floor' | translate"
        ></app-order-label>
        <app-avk-floor
          [item]="item"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-floor>
      </div>

      <div class="item-row flex flex-col gap-4 mt-8 col-span-6">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.Socket' | translate"
        ></app-order-label>
        <app-avk-socket
          [item]="item"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-socket>
      </div>

      <div class="item-row flex flex-col gap-6 mt-8 col-span-6">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.Surface' | translate"
        ></app-order-label>
        <app-avk-surface
          [item]="item"
          (addSlot)="addSlot($event)"
          (updateSlot)="updateSlot($event)"
          (removeSlot)="removeSlot($event)"
        ></app-avk-surface>
      </div>
    </div>
  </div>
</div>
