import { Component, Input } from '@angular/core';
import { Order, OrderStatusEnum, Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { BaseALSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map } from 'rxjs';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';

@Component({
  selector: 'app-al-holes',
  templateUrl: './holes.component.html',
  styleUrls: ['./holes.component.scss']
})
export class HolesComponent extends BaseALSlotComponent {
  @Input()
  item!: ProductLineItem;

  @Input()
  order!: Order;

  slot = 9;
  OrderStatusEnum = OrderStatusEnum;

  doorHoleProduct$: Observable<Option> = this.productService.getSlot(9, ProductTypeEnum.Al).pipe(
    this.productToOptionsPipe(this.slot, null),
    map((res) => res.res[0])
  );
  doorHoleSelected?: ProductLineItem = undefined;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  override initItem(): void {}
  override updateItem(firstChange?: boolean | undefined): void {
    this.doorHoleSelected = this.item.set.find((s) => s.slot === this.slot);
  }
}
