import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CreateProductLineItemDto,
  Order,
  ProductLineItem,
  ProductTypeEnum,
  UpdateProductLineItemDto
} from '../../../../api-client';
import { OrderService } from '../../../../services/order.service';
import { ProductService } from '../../../../services/product.service';
import { map, tap } from 'rxjs';
import { BaseSWGSlotComponent } from '../slots/base/base-slot.component';
import { Option } from '../../../../atoms/custom-select/custom-select.component';

@Component({
  selector: 'app-swg-addon-modal',
  templateUrl: './addon-modal.component.html',
  styleUrls: ['./addon-modal.component.scss']
})
export class SwgAddonModalComponent extends BaseSWGSlotComponent implements OnInit {
  @Input()
  order!: Order;

  @Input()
  item!: ProductLineItem;

  @Input()
  isModalOpen = false;

  @Output()
  isModalOpenChange = new EventEmitter<boolean>();

  @Output()
  submitAddAddon = new EventEmitter<{ id: string; amount: number }>();

  selectedAddon?: Option;
  addonProducts$ = this.productService.getSlots(['9'], ProductTypeEnum.Swg).pipe(
    map((products) => products.filter((product) => product.isAddon)),
    this.productToOptionsPipe(),
    map((res) => res.res)
  );
  quantity = 1;

  constructor(
    public readonly orderService: OrderService,
    public readonly translate: TranslateService,
    public readonly productService: ProductService
  ) {
    super(translate);
  }

  toggleSwgAddonModal() {
    this.isModalOpen = !this.isModalOpen;
    this.isModalOpenChange.emit(this.isModalOpen);
  }

  submitSwgAddonModal() {
    if (!this.selectedAddon) return;

    this.isModalOpen = false;
    this.isModalOpenChange.emit(this.isModalOpen);

    this.submitAddAddon.emit({ id: this.selectedAddon.value, amount: this.quantity });
  }

  override initItem(): void {}

  override updateItem(firstChange?: boolean | undefined): void {}
}
