import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent {

    token: string = "";
    email: string = "";
    isTokenValid = false;
    
    goToCompanyRegister() {
      this.router.navigate(['/register'], { queryParams: { type: 'company' } });
    }

    goToInviteRegister() {
      this.router.navigate(['/register'], { queryParams: { type: 'invite', token: this.token, email: this.email } });
    }

    async checkInviteToken() {
      if(this.email && this.token) {
        this.isTokenValid = await this.companyService.checkInviteToken(this.email, this.token);
      }
    }

    constructor(
        private router: Router,
        private companyService: CompanyService,
    ) { }

}
