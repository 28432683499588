

<div
    *ngIf="orderService.order$ | async as order"
    class="relative flex flex-col p-8 bg-white border-l-4"
    [ngClass]="[
        item.isField ? 'border-monza-500' : '',
        item.isSocket ? 'border-yellow-400' : '',
        item.isWall ? 'border-amber-500' : '',
        item.isDividerWall ? 'border-gray-500' : ''
    ]"
    [@addedElement]="addedState"
>
    <app-modal
        *ngIf="addModalOpen"
        (onClose)="addModalOpen = false"
        class="text-center"
    >
        <app-button
            [fullWidth]="true"
            [label]="'Configuration.Fields.AddFieldModal.AddDefault'|translate" 
            class="mt-8"
            (onClick)="addField(); addModalOpen = false"
        ></app-button>

        <app-button
            [fullWidth]="true"
            [label]="'Configuration.Fields.AddFieldModal.AddCopied'|translate" 
            class="mt-2"
            (onClick)="pasteField(); addModalOpen = false"
        ></app-button>
        
        <!-- <div class="flex overflow-x-auto" style="max-width: 640px;" *ngIf="templateService.lineItemTemplates.length > 0">
            <app-draft-box 
                (selectTemplate)="orderService.addTemplateLineItem($event, item.sorting+5); addModalOpen = false" 
                (deleteTemplate)="templateService.delete($event)"
                *ngFor="let template of templateService.lineItemTemplates" 
                class="col-span-3 md:col-span-1" 
                [template]="template"
            ></app-draft-box>
        </div> -->
        
        <app-draft-select [sorting]="item.sorting+5" [grid]="4" type="FIELD" (selected)="addModalOpen = false"></app-draft-select>

        <ng-container *ngIf="dividerWallIndices$ | async as dividerWallIndices">
            <ng-container *ngIf="dividerWallIndices.includes(index)" >
                <app-button
                    *ngIf="order.status !== 'Ordered'"
                    icon="add"
                    [label]="'Configuration.Fields.AddDivider'|translate" 
                    class="text-center fill-white"
                    type="wall"
                    style="margin-bottom: 0.5rem;"
                    class="mt-2"
                    (onClick)="orderService.addDefaultDividerWall(item.sorting+5); addModalOpen = false"
                    [fullWidth]="true"
                ></app-button>
            </ng-container>
        </ng-container>
    </app-modal>

    <div class="flex items-center gap-4">
        <div>
            <span class="flex items-center font-medium z-10">
                <span
                    class="px-2 py-1 mr-2 text-xs leading-none text-white bg-monza-500"
                    *ngIf="item.amount > 1"
                >
                    {{ item.amount }}×
                </span>
               {{ item | translatedName : translate.currentLang }}

                <span
                    class="px-2 py-1 mr-2 text-xs leading-none ml-4 font-bold"
                    [ngStyle]="{ 'font-family': this.font.label + ', sans-serif', 'background': selectedColor.rgb_hex, 'color': color.value|labelColor }"
                    *ngIf="labelProduct?.custom"
                >
                    {{labelProduct?.custom}}
                </span>
            </span>
            <span class="text-sm font-medium leading-none text-monza-500">
                {{ item.isWall ? ('Configuration.Fields.Wall'|translate) : "" }}
                {{ item.isSocket ? ('Configuration.Fields.Socket'|translate) : "" }}
                {{ item.isField ? ('Configuration.Fields.Field'|translate) : "" }}
                {{ item.isDividerWall ? ('Configuration.Fields.Divider'|translate) : "" }}
            </span>
            <span class="text-sm"> — {{ item |translatedDescription: translate.currentLang }}</span>
        </div>
        <div class="flex flex-col items-center gap-2 ml-auto md:flex-row">
            <div>
                <app-badge
                    [type]="'price'"
                    class="ml-auto mr-4 z-10"
                    [label]="item.price"
                ></app-badge>
            </div>  
            <div class="flex self-end gap-2 items-center">
                <ng-container *ngIf="item.isField">
                    <app-modal
                      *ngIf="createTemplateModal"
                      (onClose)="createTemplateModal = false"
                      (onSubmit)="templateService.createLineItemTemplate(item.id, {name: templateName}); createTemplateModal = false; templateName = ''"
                      class="text-center"
                      [cancelLabel]="'DraftModal.Cancel'|translate"
                      [submitLabel]="'DraftModal.Submit'|translate"
                    >
                      <app-input placeholder="Name der Vorlage"[(value)]="templateName" ></app-input>
                    </app-modal>
                    <app-button
                        [matTooltip]="'Configuration.Fields.CopyTooltip'|translate"
                        matTooltipPosition="above"
                        matTooltipShowDelay="400"
                        icon="copy"
                        [onlyIcon]="true"
                        [type]="'light'"
                        size="xs"
                        class="my-2 text-center fill-white z-10"
                        (onClick)="copyLineItemIdToClipboard(item.id)"
                    ></app-button>
                    <app-button
                        matTooltip="Als Vorlage hinzufügen"
                        [matTooltip]="'Configuration.Fields.SaveAsDraftTooltip'|translate"
                        matTooltipPosition="above"
                        matTooltipShowDelay="400"
                        icon="bookmark-add"
                        [onlyIcon]="true"
                        [type]="'light'"
                        size="xs"
                        class="my-2 text-center fill-white z-10"
                        (onClick)="createTemplateModal = true"
                    ></app-button>
                </ng-container>
                <ng-container *ngIf="fieldAmount$ | async as fieldAmount">
                    <app-button
                        [icon]="'trash'"
                        [onlyIcon]="true"
                        class="my-2 text-center fill-white z-10"
                        [type]="'light'"
                        [size]="'xs'"
                        (onClick)="removeField()"
                        [isDisabled]="order.status == OrderStatusEnum.Ordered"
                        *ngIf="(item.isField && fieldAmount > 1) || item.isSocket || item.isWall || item.isDividerWall"
                    ></app-button>
                </ng-container>
                <app-button
                    [icon]="'keyboard-arrow-left'"
                    [onlyIcon]="true"
                    class="transition-all duration-200 z-10"
                    [ngClass]="{'-rotate-90': isExpanded}"
                    [size]="'xs'"
                    [type]="'light'"
                    (onClick)="toggleIsExpanded()"
                ></app-button>
            </div>
        </div>
    </div>
    <div class="trigger absolute left-0 top-0 right-0 h-28 cursor-pointer" (click)="toggleIsExpanded()">

    </div>
    <div
        class="grid grid-cols-1 gap-8 mt-8"
        *ngIf="isExpanded"
    >
        <div class="grid grid-cols-6 gap-8">

            <ng-container *ngIf="item.isField">
                <div class="flex items-center col-span-6">
                    <div class="flex-shrink-0 block pr-1 text-xs text-gray-300 uppercase">{{'Configuration.Fields.OpenField.Label'|translate}}</div>
                    <div class="w-full h-px bg-gray-200"></div>
                </div>
                <div class="grid grid-cols-6 items-start justify-start sm:flex-nowrap col-span-6 gap-4">
                    <div class="flex col-span-6">
                        <app-checkbox 
                            [isReadonly]="order.status == OrderStatusEnum.Ordered"
                            [label]="labelActive?'':('Configuration.Label.Add' | translate)"
                            class="relative self-end bottom-2.5 mr-4"
                            [(checked)]="labelActive"
                            (checkedChange)="updateLabelActive()"
                        ></app-checkbox>
                        <app-input
                            *ngIf="labelActive"
                            class="w-full"
                            [limit]="40"
                            [label]="'Configuration.Label.Labels.Text'|translate"
                            [value]="labelProduct?.custom"
                            [isReadonly]="order.status == OrderStatusEnum.Ordered"
                            (valueChange)="updateLabel($event, this.font.value, this.fontSize.value, this.labelPosition.value, this.labelPosition2.value, this.color.value)"
                        ></app-input>
                    </div>
                    <app-custom-select
                        *ngIf="labelActive"
                        class="w-full col-span-4 lg:col-span-2"
                        [placeholder]="false"
                        [label]="'Configuration.Label.Labels.Font'|translate"
                        [selectedValue]="font"
                        [options]="fontOptions"
                        [labelStyle]="{ 'font-family': this.font.label + ', sans-serif' }"
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        (cchange)="updateLabel(this.labelProduct?.custom, $event.value, this.fontSize.value, this.labelPosition.value, this.labelPosition2.value, this.color.value)"
                    ></app-custom-select>
                    <ng-template #tooltipTpl class="bg-white">
                        <div class="text-center w-full py-2">Schriftgröße in Versalhöhe</div>
                        <img src="assets/schriftgroesse.png" height="240" width="527">
                    </ng-template>
                    <app-custom-select
                        *ngIf="labelActive"
                        [labelTooltip]="tooltipTpl"
                        class="w-full col-span-2 lg:col-span-1"
                        [label]="'Configuration.Label.Labels.Size'|translate"
                        [unit]="'mm'"
                        [options]="fontSizeOptions"
                        [selectedValue]="fontSize"
                        [placeholder]="false"
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        (cchange)="updateLabel(this.labelProduct?.custom, this.font.value, $event.value, this.labelPosition.value, this.labelPosition2.value, this.color.value)"
                    ></app-custom-select>
                    <app-custom-select
                        *ngIf="labelActive"
                    class="w-full col-span-2 lg:col-span-1"
                        [label]="'Configuration.Label.Labels.Color'|translate"
                        [options]="colorOptions"
                        [selectedValue]="color"
                        [placeholder]="false"
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        (cchange)="updateLabel(this.labelProduct?.custom, this.font.value, this.fontSize.value, this.labelPosition.value, this.labelPosition2.value, $event.value)"
                    ></app-custom-select>
                    <app-custom-select
                        *ngIf="labelActive"
                        [placeholder]="false"
                        class="w-full col-span-2 lg:col-span-1"
                        [label]="'Configuration.Label.Labels.Position'|translate"
                        [options]="labelPositionOptions"
                        [selectedValue]="labelPosition"
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        (cchange)="updateLabel(this.labelProduct?.custom, this.font.value, this.fontSize.value, $event.value, this.labelPosition2.value, this.color.value)"
                    ></app-custom-select>
                    <app-custom-select
                        *ngIf="labelActive"
                        [placeholder]="false"
                        class="w-full col-span-2 lg:col-span-1"
                        [label]="'&nbsp;'"
                        [options]="labelPosition2Options"
                        [selectedValue]="labelPosition2"
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        (cchange)="updateLabel(this.labelProduct?.custom, this.font.value, this.fontSize.value, this.labelPosition.value, $event.value, this.color.value)"
                    ></app-custom-select>

                </div>
            </ng-container>

            <!-- BASE -->
            <div class="flex items-center col-span-6">
                <div class="flex-shrink-0 block pr-1 text-xs text-gray-300 uppercase">{{'Configuration.Fields.OpenField.BaseData'|translate}}</div>
                <div class="w-full h-px bg-gray-200"></div>
            </div>
            <div class="flex items-start justify-start flex-wrap sm:flex-nowrap col-span-6 gap-4 lg:col-span-3">
                <app-checkbox 
                    [matTooltip]="'Configuration.Fields.OpenField.ManualOverride'|translate"
                    matTooltipPosition="above"
                    [highlight]="highlightOverride"
                    [isReadonly]="order.status == OrderStatusEnum.Ordered"
                    [label]="'Configuration.Fields.OpenField.ManualOverride'|translate"
                    [isMobileLabel]="true"
                    class="relative self-end"
                    style="bottom: 10px;"
                    [(checked)]="item.override"
                    (checkedChange)="updateOverride($event)"
                    (mouseenter)="highlightLocks = true"
                    (mouseleave)="highlightLocks = false"
                ></app-checkbox>
                <app-input
                    class="w-full"
                    *ngIf="!item.isDividerWall && !item.isWall"
                    [showLock]="(item.isField ? false : true) || order.status == OrderStatusEnum.Ordered"
                    [isLocked]="!item.override"
                    [highlightLock]="highlightLocks"
                    [label]="'Configuration.Fields.OpenField.Width'|translate"
                    [type]="'number'"
                    [unit]="'mm'"
                    [(value)]="item.width"
                    [isReadonly]="(item.isField || item.override ? false : true) || order.status == OrderStatusEnum.Ordered"
                    (onChange)="updateWidth($event)"
                    (lockMouseEnter)="highlightOverride = true"
                    (lockMouseLeave)="highlightOverride = false"
                ></app-input>
                <app-input
                    class="w-full"
                    *ngIf="!item.isSocket"
                    [showLock]="(item.isSocket ? false : true) || order.status == OrderStatusEnum.Ordered"
                    [isLocked]="!item.override"
                    [highlightLock]="highlightLocks"
                    [label]="'Configuration.Fields.OpenField.Height'|translate"
                    [type]="'number'"
                    [unit]="'mm'"
                    [(value)]="item.height"
                    [isReadonly]="(item.isSocket || item.override ? false : true) || order.status == OrderStatusEnum.Ordered"
                    (onChange)="updateHeight($event)"
                    (lockMouseEnter)="highlightOverride = true"
                    (lockMouseLeave)="highlightOverride = false"
                ></app-input>
                <app-custom-select 
                    class="w-full"
                    [isReadonly]="order.status == OrderStatusEnum.Ordered"
                    *ngIf="item.isSocket" 
                    [selectedValue]="selectedSocketHeightOption" 
                    [options]="socketHeightOptions" 
                    [label]="'Configuration.Fields.OpenField.Height'|translate"
                    (cchange)="updateHeight($event.value)">
                </app-custom-select>
                <app-input
                    class="w-full"
                    [showLock]="order.status != OrderStatusEnum.Ordered"
                    [isLocked]="!item.override"
                    [highlightLock]="highlightLocks"
                    [label]="'Configuration.Fields.OpenField.Depth'|translate"
                    [type]="'number'"
                    [unit]="'mm'"
                    [(value)]="item.depth"
                    (onChange)="updateDepth($event)"
                    (lockMouseEnter)="highlightOverride = true"
                    (lockMouseLeave)="highlightOverride = false"
                    [isReadonly]="(item.override ? false : true) || order.status == OrderStatusEnum.Ordered"
                ></app-input>
            </div>

            <ng-container *ngIf="item.isWall">
                <app-custom-select 
                    class="col-span-3 md:col-span-2"
                    [isReadonly]="order.status == OrderStatusEnum.Ordered" 
                    [options]="wallAmountOptions" 
                    [selectedValue]="wallAmountSelected" 
                    [label]="'Configuration.Fields.OpenField.Amount'|translate"
                    (cchange)="wallAmountSelected = $event;updateAmount($event.value)">
                </app-custom-select>

                <app-checkbox
                    class="col-span-3 md:col-span-1 flex items-end pb-2"
                    style="display: flex;"
                    [isReadonly]="order.status == OrderStatusEnum.Ordered"
                    [label]="'Configuration.Fields.OpenField.WallFlat'|translate"
                    [(checked)]="wallFlat"
                    (checkedChange)="updateWallCustom()"
                    
                ></app-checkbox>

                <app-custom-select
                    class="col-span-6 md:col-span-3"
                    [isReadonly]="order.status == OrderStatusEnum.Ordered"
                    *ngIf="+wallAmountSelected.value == 1"
                    [label]="'Configuration.Fields.OpenField.Side'|translate"
                    [selectedValue]="wallSelectedSide"
                    [placeholder]="false"
                    [options]="sideOptions"
                    (cchange)="
                    wallSelectedSide = $event;
                    updateWallCustom()"
                ></app-custom-select>
            </ng-container>


            <ng-container *ngIf="item.isSocket">
                <div class="flex items-center col-span-6">
                    <div class="flex-shrink-0 block pr-1 text-xs text-gray-300 uppercase">{{'Configuration.Fields.OpenField.SocketDivision'|translate}}</div>
                    <div class="w-full h-px bg-gray-200"></div>
                </div>
                <div class="col-span-6 text-xs text-gray-500">
                    {{'Configuration.Fields.OpenField.AutoSocketDivisionText'|translate}}
                </div>

                <app-button
                    [label]="'Configuration.Fields.OpenField.AddDivions'|translate"
                    class="col-span-2" 
                    icon="add" 
                    type="primary" 
                    (onClick)="customSocketDivision.unshift(0)"
                    *ngIf="order.status != OrderStatusEnum.Ordered"
                ></app-button>
                <app-button
                    [label]="'Configuration.Fields.OpenField.AddFieldDivions'|translate"
                    class="col-span-2" 
                    type="primary" 
                    *ngIf="order.status != OrderStatusEnum.Ordered"
                    (onClick)="setDefaultSocketDivision(order)"
                ></app-button>

                <div
                    *ngFor="let division of customSocketDivision; let index = index"
                    class="col-span-6 flex items-center"
                >
                    <app-input 
                        [keyupEvent]="false" 
                        [(value)]="customSocketDivision[index]"
                        [type]="'number'"
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        (valueChange)="updateSocketDivision()"
                    >
                    </app-input>
                    <app-button
                        *ngIf="order.status != OrderStatusEnum.Ordered"
                        class="relative top-px ml-2"
                        [onlyIcon]="true"
                        icon="trash" 
                        type="primary" 
                        (onClick)="customSocketDivision.splice(index, 1); updateSocketDivision()"
                    ></app-button>
                </div>
                
                <div class="col-span-6 text-xs text-orange-500" *ngIf="customSocketDivisionWarning">
                    {{customSocketDivisionWarning}}
                </div>
            </ng-container>

            <ng-container *ngIf="item.isField">
                <div class="col-span-2 md:col-span-1" *ngIf="item.isField">
                    <app-custom-select
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        [label]="'Configuration.Fields.OpenField.IPProtection'|translate"
                        [selectedValue]="selectedProtection"
                        [options]="protectionLevelOptions"
                        (cchange)="selectedProtection = $event; updateProtectionLevel($event.value)"
                    ></app-custom-select>
                </div>
                <div class="col-span-4 md:col-span-2">
                    <app-custom-select
                        [label]="'Configuration.Fields.OpenField.Without'|translate"
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        *ngIf="slotOptions[4] | async as options"
                        [selectedValue]="selectedSlots[4]"
                        [options]="options"
                        (cchange)="selectedSlots[4] = $event; updateSlot('4', $event)"
                    ></app-custom-select>
                </div>






            

                <!-- DOOR -->
                <ng-container 
                    *ngIf="!(selectedSlots[4] && (selectedSlots[4].meta == 'ot' || selectedSlots[4].meta == 'otorw'))"
                >
                    <div class="col-span-6 md:col-span-3">
                        <app-custom-select
                            [label]="'Configuration.Fields.OpenField.Door'|translate"
                            [isReadonly]="order.status == OrderStatusEnum.Ordered"
                            *ngIf="slotOptions[5] | async as options"
                            [selectedValue]="selectedSlots[5]"
                            [options]="options"
                            (cchange)="selectedSlots[5] = $event; updateSlot('5', $event)"
                        >
                        </app-custom-select>
                    </div>
                    <div
                        class="col-span-6 md:col-span-3"
                    >
                        <app-custom-select
                            class="w-full"
                            [isReadonly]="order.status == OrderStatusEnum.Ordered"
                            *ngIf="slotOptions[6] | async as options"
                            [label]="'Configuration.Fields.OpenField.Lock'|translate"
                            [selectedValue]="selectedSlots[6]"
                            [options]="options"
                            (cchange)="selectedSlots[6] = $event; updateSlot('6', $event)"
                        >
                        </app-custom-select>
                    </div>



                    
                

                    <!-- Türaustanzungen-->
                    <div class="flex items-center col-span-6">
                        <div class="flex-shrink-0 block pr-1 text-xs text-gray-300 uppercase">{{'Configuration.Fields.OpenField.DoorHoles'|translate}}</div>
                        <div class="w-full h-px bg-gray-200"></div>
                    </div>
                    <ng-container *ngIf="order.file">
                        <ng-container  *ngIf="slotOptions[9] | async as options">
                            <div class="col-span-6 md:col-span-2">
                                <app-input
                                [isReadonly]="order.status == OrderStatusEnum.Ordered"
                                label="< Ø100 mm / 100 x 100 mm"
                                unit="×"
                                [(value)]="slot9amount"
                                (onChange)="updateSlot(
                                    '9',
                                    options[0],
                                    undefined,
                                    undefined,
                                    undefined,
                                    +$event,
                                    slot9amount2,
                                    slot9amount3
                                    )"
                                    ></app-input>
                            </div>
                            <div class="col-span-6 md:col-span-2">
                                <app-input
                                [isReadonly]="order.status == OrderStatusEnum.Ordered"
                                label="> Ø101 mm / 101 x 101"
                                unit="×"
                                [(value)]="slot9amount2"
                                (onChange)="updateSlot(
                                    '9',
                                    options[0],
                                    undefined,
                                    undefined,
                                    undefined,
                                    slot9amount,
                                    +$event,
                                    slot9amount3
                                    )"
                                    ></app-input>
                            </div>
                            <div class="col-span-6 md:col-span-2">
                                <app-input
                                [isReadonly]="order.status == OrderStatusEnum.Ordered"
                                label="> Ø301 mm / 301 x 301"
                                unit="×"
                                [(value)]="slot9amount3"
                                (onChange)="updateSlot(
                                    '9',
                                    options[0],
                                    undefined,
                                    undefined,
                                    undefined,
                                    slot9amount,
                                    slot9amount2,
                                    +$event
                                    )"
                                    ></app-input>
                            </div>
                        </ng-container>
                    </ng-container>




                    <ng-container *ngIf="!order.file">
                        <div class="col-span-6 text-xs text-gray-500">
                            {{'Configuration.Fields.OpenField.DoorHolesMessage'|translate}}
                        </div>
                    </ng-container>
                </ng-container>

                <div class="flex items-center col-span-6">
                    <div class="flex-shrink-0 block pr-1 text-xs text-gray-300 uppercase">{{'Configuration.Fields.OpenField.MoreAddons'|translate}}</div>
                    <div class="w-full h-px bg-gray-200"></div>
                </div>

                <ng-container 
                    *ngIf="!(selectedSlots[4] && (selectedSlots[4].meta == 'ot' || selectedSlots[4].meta == 'otorw'))"
                >
                    <div
                        *ngIf="slotOptions[8] | async as options"
                        class="col-span-3"
                    >
                            <app-checkbox
                                [isReadonly]="order.status == OrderStatusEnum.Ordered || selectedProtection.value == '54'"
                                [matTooltip]="selectedProtection.value == '54' ? 'Ist Bestandteil von IP54' : ''"
                                matTooltipPosition="left"
                                [label]="'Configuration.Fields.OpenField.RubberSeal'|translate"
                                [(checked)]="slot8active"
                                (checkedChange)="updateSlot('8', options[0], $event)"
                            >
                            </app-checkbox>
                    </div>
            
                    <div *ngIf="slotOptions[10] | async as options" class="grid grid-cols-2 col-span-3 gap-8">
                        <app-checkbox
                            class="col-span-2 md:col-span-1"
                            [isReadonly]="order.status == OrderStatusEnum.Ordered"
                            [label]="'Configuration.Fields.OpenField.Klapptablar'|translate"
                            [(checked)]="slot10active"
                            (checkedChange)="updateSlot('10', options[0], $event, slot10SelectedSide.value)"
                        >
                        </app-checkbox>
                        <app-custom-select
                            class="col-span-2 md:col-span-1"
                            [isReadonly]="order.status == OrderStatusEnum.Ordered"
                            *ngIf="slot10active && doubleDoorSelected"
                            [label]="'Configuration.Fields.OpenField.Side'|translate"
                            [selectedValue]="slot10SelectedSide"
                            [placeholder]="false"
                            [options]="sideOptions"
                            (cchange)="
                            slot10SelectedSide = $event;
                            updateSlot(
                                '10',
                                options[0],
                                slot10active,
                                slot10SelectedSide.value
                            )"
                        >
                        </app-custom-select>
                    </div>
                </ng-container>



                <!-- PATRONEN-->
                <!-- <div class="flex items-center col-span-6">
                    <div class="flex-shrink-0 block pr-1 text-xs text-gray-300 uppercase">Patronen</div>
                    <div class="w-full h-px bg-gray-200"></div>
                </div> -->
                <ng-container *ngIf="!(selectedSlots[4] && (selectedSlots[4].meta == 'ot' || selectedSlots[4].meta == 'otorw'))">
                <ng-container
                    *ngIf="slotOptions[11] | async as options"
                >
                    <app-custom-select
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        class="col-span-6 md:col-span-3"
                        [label]="'Configuration.Fields.OpenField.CartridgeHolder'|translate"
                        [selectedValue]="selectedSlots[11]"
                        [options]="options"
                        (cchange)="
                        selectedSlots[11] = $event;
                        updateSlot(
                            '11',
                            $event,
                            true,
                            slot11SelectedSide.value,
                            undefined,
                            slot11amount
                        )"
                    ></app-custom-select>
                    <app-input
                        *ngIf="selectedSlots[11] && selectedSlots[11].value != ''"
                        class="col-span-2 md:col-span-1"
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        [(value)]="slot11amount"
                        [label]="'Configuration.Fields.OpenField.Amount'|translate"
                        unit="×"
                        (onChange)="
                        updateSlot(
                            '11',
                            selectedSlots[11],
                            true,
                            slot11SelectedSide.value,
                            undefined,
                            slot11amount
                        )"
                    ></app-input>
                    <app-custom-select
                    [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        *ngIf="doubleDoorSelected"
                        class="col-span-4 md:col-span-2"
                        [label]="'Configuration.Fields.OpenField.Side'|translate"
                        [selectedValue]="slot11SelectedSide"
                        [placeholder]="false"
                        [options]="sideOptions"
                        (cchange)="
                        slot11SelectedSide = $event;
                        updateSlot(
                            '11',
                            selectedSlots[11],
                            true,
                            slot11SelectedSide.value,
                            undefined,
                            slot11amount
                        )"
                    ></app-custom-select>
                </ng-container>







                <!-- SCHEMAFACH-->
                <!-- <div class="flex items-center col-span-6">
                    <div class="flex-shrink-0 block pr-1 text-xs text-gray-300 uppercase">Schemafach</div>
                    <div class="w-full h-px bg-gray-200"></div>
                </div> -->
                <div
                    *ngIf="slotOptions[12] | async as options"
                    class="grid grid-cols-6 col-span-6 gap-8"
                >
                    <app-custom-select
                        class="col-span-6 md:col-span-3"
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        label="Schemafach"
                        [label]="'Configuration.Fields.OpenField.SchemaCompartment'|translate"
                        [selectedValue]="selectedSlots[12]"
                        [options]="options"
                        (cchange)="
                        selectedSlots[12] = $event;
                        updateSlot('12', $event, true, doubleDoorSelected ? slot12SelectedSide.value : undefined)
                        "
                    ></app-custom-select>
                    <app-custom-select
                        class="col-span-4 md:col-span-2"
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        *ngIf="doubleDoorSelected"
                        [label]="'Configuration.Fields.OpenField.Side'|translate"
                        [selectedValue]="slot12SelectedSide"
                        [placeholder]="false"
                        [options]="sideOptions"
                        (cchange)="
                        slot12SelectedSide = $event;
                        updateSlot(
                            '12',
                            selectedSlots[12],
                            true,
                            doubleDoorSelected ? slot12SelectedSide.value : undefined
                        )"
                    ></app-custom-select>


                    <div 
                        *ngIf="slotOptions[13] | async as options"
                        class="col-span-2 md:col-span-1"
                    >
                        <app-custom-select 
                            [isReadonly]="order.status == OrderStatusEnum.Ordered" 
                            [options]="slot13Options" 
                            [selectedValue]="slot13SelectedOption" 
                            [label]="'Configuration.Fields.OpenField.ThreadedBolt'|translate"
                            (cchange)="updateSlot(
                                '13',
                                options[0],
                                undefined,
                                undefined,
                                undefined,
                                +$event.value
                            )">
                        </app-custom-select>
                        <!-- <app-input
                            [isReadonly]="order.status == OrderStatusEnum.Ordered"
                            label="Gewindebolzen"
                            unit="×"
                            [(value)]="slot13amount"
                            (valueChange)="updateSlot(
                                '13',
                                options[0],
                                undefined,
                                undefined,
                                undefined,
                                $event
                            )"
                        ></app-input> -->
                    </div>            

                </div>
            </ng-container>


                <!-- Leuchten-->
                <!-- <div class="flex items-center col-span-6">
                    <div class="flex-shrink-0 block pr-1 text-xs text-gray-300 uppercase">Leuchten</div>
                    <div class="w-full h-px bg-gray-200"></div>
                </div> -->
                <div class="col-span-6">
                    <app-custom-select
                    [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        *ngIf="slotOptions[14] | async as options"
                        label="Leuchten"
                        [label]="'Configuration.Fields.OpenField.Lamps'|translate"
                        [selectedValue]="selectedSlots[14]"
                        [options]="options"
                        (cchange)="selectedSlots[14] = $event; updateSlot('14', $event)"
                    >
                    </app-custom-select>
                </div>





                <!-- EINFÜHRUNGSSCHLICHTS-->
                <!-- <div class="flex items-center col-span-6">
                    <div class="flex-shrink-0 block pr-1 text-xs text-gray-300 uppercase">Einführungsschlitz</div>
                    <div class="w-full h-px bg-gray-200"></div>
                </div> -->
                <div *ngIf="slotOptions[15] | async as options" class="grid items-center grid-cols-3 col-span-6">
                    <app-custom-select
                        class="col-span-3 md:col-span-2"
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        class="col-span-2"
                        [label]="'Configuration.Fields.OpenField.CableEntryTop'|translate"
                        [selectedValue]="slot15SelectedCustom"
                        [placeholder]="false"
                        [options]="materialOptions"
                        (cchange)="
                        slot15SelectedCustom = $event;
                        updateSlot(
                            '15',
                            options[($event.value == '-1') ? 0 : 1],
                            true,
                            undefined,
                            $event.value
                        )"
                    ></app-custom-select>
                </div>




                <!-- BODEN -->
                <!-- <div class="flex items-center col-span-6">
                  <div class="flex-shrink-0 block pr-1 text-xs text-gray-300 uppercase">Boden</div>
                  <div class="w-full h-px bg-gray-200"></div>
                </div> -->
                <div *ngIf="slotOptions[20] | async as options" class="grid items-center grid-cols-3 col-span-6">
                    <app-custom-select
                    [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        *ngIf="slot20active"
                        class="col-span-3 mb-4 md:mb-0 md:pr-4 md:col-span-1"
                        [label]="'Configuration.Fields.OpenField.Floor'|translate"
                        [selectedValue]="selectedSlots[20]"
                        [placeholder]="false"
                        [options]="options"
                        (cchange)="
                        selectedSlots[20] = $event;
                        updateSlot(
                            '20',
                            $event,
                            true,
                            undefined,
                            slot20SelectedCustom.value
                        )"
                    ></app-custom-select>
                    <app-custom-select
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        *ngIf="selectedSlots[20] && selectedSlots[20].meta != 'uo'"
                        class="col-span-3 md:col-span-2"
                        [label]="'Configuration.Fields.OpenField.With'|translate"
                        [selectedValue]="slot20SelectedCustom"
                        [placeholder]="false"
                        [options]="materialOptionsFloor"
                        (cchange)="
                        slot20SelectedCustom = $event;
                        updateSlot(
                            '20',
                            selectedSlots[20],
                            true,
                            undefined,
                            $event.value
                        )"
                    ></app-custom-select>
                </div>


                <!-- EXTRAS-->
                <!-- <div class="flex items-center col-span-6">
                    <div class="flex-shrink-0 block pr-1 text-xs text-gray-300 uppercase">Extras</div>
                    <div class="w-full h-px bg-gray-200"></div>
                </div> -->
                <div
                    class="col-span-6 md:col-span-2"
                    *ngIf="slotOptions[22] | async as options"
                >
                    <app-checkbox
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        [label]="'2x '+('Configuration.Fields.OpenField.GratingAttachmentProfile'|translate)"
                        [(checked)]="slot22active"
                        (checkedChange)="updateSlot('22', options[0], $event, undefined, undefined, 2)"
                    ></app-checkbox>
                </div>

                <div    
                    class="col-span-6 md:col-span-1"
                    *ngIf="slotOptions[23] | async as options"
                >
                    <app-checkbox
                        [isReadonly]="order.status == OrderStatusEnum.Ordered"
                        [label]="'Configuration.Fields.OpenField.MountingPlate'|translate"
                        [(checked)]="slot23active"
                        (checkedChange)="updateSlot('23', options[0], $event)"
                    ></app-checkbox>
                </div>




                <!-- Farbe -->
                <div class="flex items-center col-span-6">
                    <div class="flex-shrink-0 block pr-1 text-xs text-gray-300 uppercase">{{'Configuration.Fields.OpenField.Color'|translate}}</div>
                    <div class="w-full h-px bg-gray-200"></div>
                </div>

                <ng-container *ngIf="ralColor | async as _ralColor">
                    <ng-container *ngIf="ral7035 | async as _ral7035">
                        <div class="relative flex flex-row items-center col-span-6">
                            <div
                                #elem
                                class="w-12 h-12 mr-8 transition duration-100 ease-in-out rounded-full shadow-xl ring-2 ring-offset-2 ring-offset-white ring-gray-200 hover:ring-gray-400" 
                                [ngStyle]="{'background': selectedColor.rgb_hex, 'cursor-default': order.status == 'Ordered', 'cursor-pointer': order.status != 'Ordered'}" 
                                (click)="order.status == 'Ordered' ? undefined : colorPickerOpen = !colorPickerOpen"
                            ></div>
                            <app-input 
                              [isReadonly]="order.status == OrderStatusEnum.Ordered"
                              class="w-32"
                              [label]="'Configuration.Fields.OpenField.RALColor'|translate"
                              [error]="ralError"
                              [value]="selectedColor.code" 
                              (valueChange)="updateRalColor($event, $event == '7035' ? _ral7035 : _ralColor)"
                            ></app-input>
                            <div #elem2 class="absolute w-1/2 max-w-xs transform -translate-y-4 bg-white shadow-xl bottom-full ring-1 ring-gray-100" *ngIf="colorPickerOpen">
                                <app-button 
                                    [label]="'Configuration.Fields.OpenField.Close'|translate"
                                    [icon]="'close'" 
                                    [onlyIcon]="true" 
                                    [type]="'ghost'" 
                                    [size]="'xs'" 
                                    class="absolute top-0 transform translate-x-2 shadow-xl left-full ring-1 ring-gray-100"
                                    (onClick)="colorPickerOpen = false"
                                ></app-button>
                                <div class="grid w-full h-full grid-cols-5 gap-4 p-4 overflow-y-scroll max-h-64 scrollbar">
                                    <div
                                        class="flex w-full transition duration-100 ease-in-out rounded-full shadow-xl cursor-pointer aspect-square ring-2 ring-offset-2 ring-offset-white ring-gray-200 hover:ring-monza-500" 
                                        [ngStyle]="{'background': color.rgb_hex}" 
                                        *ngFor="let color of RAL_COLORS"
                                        (click)="
                                            selectedColor = color; 
                                            colorPickerOpen = false;
                                            updateSlot(
                                                '30',
                                                color.code == '7035' ? _ral7035 : _ralColor,
                                                true,
                                                undefined,
                                                color.code
                                            )
                                        "
                                    >
                                        <span class="relative self-center w-full font-mono text-xs text-center text-white top-px" [ngStyle]="{'color': color.rgb_hex | textColor}">{{color.code}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

            </ng-container>
        </div>
    </div>
</div>

<ng-container *ngIf="orderService.order$ | async as order">
    <app-button
        icon="add"
        [label]="'Configuration.Fields.OpenField.Add'|translate"
        class="mt-4 text-center fill-white"
        (onClick)="addModalOpen = true"
        *ngIf="item.isField && order.status != OrderStatusEnum.Ordered"
        [fullWidth]="true"
    ></app-button>
</ng-container>
