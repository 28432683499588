import { Component, Input } from '@angular/core';
import { ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { BaseALSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { OrderService } from '../../../../../services/order.service';
import { firstValueFrom, map } from 'rxjs';

@Component({
  selector: 'app-al-socket',
  templateUrl: './socket.component.html',
  styleUrls: ['./socket.component.scss']
})
export class SocketComponent extends BaseALSlotComponent {
  @Input()
  item!: ProductLineItem;

  highlightLocks = false;
  highlightOverride = false;

  socketProduct$ = this.productService.getSlot(40, ProductTypeEnum.Al).pipe(
    map((res) => res.filter((p) => p.productNumber === 'also')),
    this.productToOptionsPipe(40, null),
    map((res) => res.res[0])
  );

  selectedHeight!: Option;
  heightOptions: Option[] = [50, 100, 200].map((d) => ({
    value: d.toString(),
    description: '',
    label: d.toString()
  }));

  withoutSocket = false;
  selectedSocket: ProductLineItem | undefined;

  showModal = false;

  constructor(
    private readonly translate: TranslateService,
    private readonly productService: ProductService,
    private readonly orderService: OrderService
  ) {
    super(translate);
  }

  override initItem(): void {}

  override updateItem(firstChange?: boolean | undefined): void {
    const socket = this.item.set.find((set) => set.slot === 40);
    if (socket) {
      this.withoutSocket = false;
      this.selectedSocket = socket;
      this.selectedHeight = this.heightOptions.find((o) => o.value == socket.height?.toString())!;
    } else {
      this.withoutSocket = true;
    }
  }

  updateOverride(event: boolean) {
    this.orderService.updateLineItem(this.item.id, { override: event });
  }

  async updateWithoutSocket(checked: boolean, socket: Option) {
    this.withoutSocket = checked;
    if (!checked) {
      this.addSlotItem(40, socket, {
        depth: this.item.depth,
        height: 100,
        width: this.item.width
      });

      const ceiling = this.item.set.find((set) => set.slot === 20);
      if (ceiling && ceiling.productNumber != 'uo') {
        const ceilingProduct = await firstValueFrom(
          this.productService.getByProductnumber('uo', 20, ProductTypeEnum.Al)
        );
        if (ceilingProduct) {
          this.addSlot.emit({ slot: 20, create: { productId: ceilingProduct.id } });
          this.showModal = true;
        }
      }
    } else {
      this.removeSlot.emit(40);
    }
  }
}
