<app-modal
  [submitLabel]="'Configuration.Fields.SaveAddons' | translate"
  *ngIf="isAttachmentModalOpen"
  (onClose)="toggleAttachmentModal()"
  (onSubmit)="submitAttachmentModal()"
>
  <div class="text-lg font-medium">{{ 'Configuration.Fields.ChooseAddons' | translate }}</div>
  <div class="divide-y divide-neutral-200 w-96 max-w-full">
    <div
      class="flex justify-start items-center py-4"
      *ngFor="let a of attachments$ | async"
      [matTooltip]="a.isReadyonly ? ('Configuration.Fields.AddonsDisabledTooltip' | translate) : ''"
      matTooltipPosition="above"
    >
      <div class="flex items-center mr-4">
        <app-input
          [isReadonly]="a.isReadyonly"
          [(value)]="selectedAddons[a.productNumber].amount"
          class="w-16 mr-4"
          unit="×"
        >
        </app-input>
      </div>
      <div class="cursor-pointer">
        <span class="self-center font-weight-500 block leading-none">{{ a.name }}</span>
        <span class="text-sm font-medium leading-none text-monza-500 font-mono"
          >CHF {{ a.price | currency : ' ' : 'symbol' : '1.2-2' }}</span
        >
      </div>
    </div>
  </div>
</app-modal>

<ng-template #baseLoading>
  <div role="status" class="w-full animate-pulse">
    <div class="h-40 bg-gray-200 dark:bg-gray-700 w-full mb-4"></div>
  </div>
</ng-template>

<ng-template #smallLoading>
  <div role="status" class="w-full animate-pulse">
    <div class="h-10 bg-gray-200 dark:bg-gray-700 w-full mb-4"></div>
  </div>
</ng-template>

<div class="space-y-4">
  <ng-container *ngIf="orderService.order$ | async as order; else baseLoading">
    <div class="p-8 mb-4 bg-white border-l-4 border-gray-200" *ngIf="order.productType == orderService.ProductTypeEnum.Ars">
      <span class="block mb-4 font-medium">{{ 'Configuration.Fields.OpenField.BaseData' | translate }}</span>
      <div class="grid grid-cols-2 gap-4">
        <app-input
          class="col-span-2 md:col-span-1"
          [label]="'Configuration.Fields.Height' | translate"
          [type]="'number'"
          [unit]="'mm'"
          [isReadonly]="order.status == OrderStatusEnum.Ordered"
          [value]="order.height ? order.height + '' : '0'"
          (onChange)="updateOrder(order, 'height', $event)"
        ></app-input>
        <app-input
          class="col-span-2 md:col-span-1"
          [label]="'Configuration.Fields.Depth' | translate"
          [type]="'number'"
          [unit]="'mm'"
          [isReadonly]="order.status == OrderStatusEnum.Ordered"
          [value]="order.depth ? order.depth + '' : '0'"
          (onChange)="updateOrder(order, 'depth', $event)"
        ></app-input>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="orderService.order$ | async as order; else smallLoading">
    <ng-container *ngIf="order.productType == orderService.ProductTypeEnum.Ars">
      <ng-container *ngIf="hasNoWall$ | async as hasNoWall">
        <app-button
          icon="add"
          [label]="'Configuration.Fields.AddWall' | translate"
          class="text-center fill-white"
          type="wall"
          style="margin-top: 0; margin-bottom: 0.5rem"
          *ngIf="hasNoWall && order.status !== 'Ordered'"
          (onClick)="addWall()"
          [fullWidth]="true"
        ></app-button>
      </ng-container> 
    </ng-container>
  </ng-container>

  <ng-container *ngIf="orderService.order$ | async as order; else baseLoading">
    <ng-container *ngFor="let item of sortedFields$ | async; trackBy: trackById; let index = index">
      <ng-container *ngIf="order.productType == orderService.ProductTypeEnum.Al">
        <app-al *ngIf="item.isField" [item]="item" [index]="index"></app-al>
      </ng-container>
      <ng-container *ngIf="order.productType == orderService.ProductTypeEnum.Avk">
        <app-avk *ngIf="item.isField" [item]="item" [index]="index"></app-avk>
      </ng-container>
      <ng-container *ngIf="order.productType == orderService.ProductTypeEnum.Swg">
        <app-swg class="block" *ngIf="item.isField" [item]="item" [index]="index"></app-swg>
      </ng-container>
      <ng-container *ngIf="order.productType == orderService.ProductTypeEnum.Ars">
        <app-order-field
          *ngIf="item.isField || item.isSocket || item.isWall || item.isDividerWall"
          [item]="item"
          [index]="index"
          [dividerWallIndices$]="dividerWallIndices$"
        ></app-order-field>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="orderService.order$ | async as order; else smallLoading">
    <ng-container *ngIf="order.productType == orderService.ProductTypeEnum.Ars">
      <ng-container *ngIf="hasNoSocket$ | async">
        <app-button
          *ngIf="order.status !== 'Ordered'"
          icon="add"
          [label]="'Configuration.Fields.AddSocket' | translate"
          type="socket"
          (onClick)="addSocket()"
          [fullWidth]="true"
        ></app-button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="order.productType === orderService.ProductTypeEnum.Swg && order.status !== 'Ordered'">
      <app-button
        icon="add"
        [label]="'Configuration.Fields.AddSwg' | translate"
        type="primary"
        (onClick)="addSwg(order)"
        [fullWidth]="true"
      ></app-button>
      <app-button
        icon="add"
        [label]="'Configuration.Fields.AddAddon' | translate"
        type="primary"
        (onClick)="toggleSwgAddonModal()"
        [fullWidth]="true"
      ></app-button>
      
    </ng-container>
    <ng-container *ngFor="let item of sortedFields$ | async; trackBy: trackById; let index = index">
      <ng-container *ngIf="order.productType == orderService.ProductTypeEnum.Swg">
        <app-swg-addon [item]="item" *ngIf="item.isAddon && !item.addedFrom">
        </app-swg-addon>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="orderService.order$ | async as order; else smallLoading">
    <ng-container *ngIf="order.productType == 'ARS'">
      <app-button
        *ngIf="order.status !== 'Ordered'"
        icon="variables"
        [label]="'Configuration.Fields.ChooseAddons' | translate"
        type="light"
        (onClick)="toggleAttachmentModal()"
        [fullWidth]="true"
      ></app-button>
    </ng-container>
    <app-avk-attachments *ngIf="order.productType === 'AVK'" [order]="order"> </app-avk-attachments>
    <app-al-attachments *ngIf="order.productType === 'AL'" [order]="order"> </app-al-attachments>
  </ng-container>

  <ng-container *ngIf="orderService.order$ | async as order">
    <app-swg-addon-modal [order]="order" [(isModalOpen)]="swgAddAddonModalOpen" (submitAddAddon)="addSwgAddon($event.id, $event.amount)">

    </app-swg-addon-modal>
  </ng-container>

  <ng-container *ngIf="orderService.order$ | async as order">
    <app-swg-aggregation *ngIf="order.productType === 'SWG'" [order]="order"> </app-swg-aggregation>
  </ng-container>
</div>
