import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from 'src/app/services/order.service';
import { ProductLineItem, ProductTypeEnum } from '../../api-client';
import { CompanyService } from '../../services/company.service';
import { filter, firstValueFrom, map } from 'rxjs';

@Component({
  selector: 'app-overview-order',
  templateUrl: './overview-order.component.html',
  styleUrls: ['./overview-order.component.scss']
})
export class OverviewOrderComponent implements OnInit {
  discount: number = 1;

  openFieldId: string = '';

  dividerProducts = ['aval', 'avar'];

  lineItems$ = this.orderService.order$.pipe(
    map((order) => {
      if (!order) return [];
      if (order.productType === 'SWG') {
        const items: ProductLineItem[] = [];
        for (const item of order.lineItems) {
          if (item.isAddon) {
            if (items.some((l) => l.productNumber === item.productNumber)) {
              const found = items.find((l) => l.productNumber === item.productNumber);
              found!.amount += item.amount;
              found!.price += item.price;
            } else {
              items.push({ ...item });
            }
          } else {
            items.push({ ...item });
          }
        }
        return items;
      }

      return order.lineItems;
    })
  );

  constructor(
    public orderService: OrderService,
    public companyService: CompanyService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initAVK();
  }

  async initAVK() {
    const order = await firstValueFrom(this.orderService.order$.pipe(filter((o) => o !== undefined)));

    if (order?.productType === ProductTypeEnum.Avk || order?.productType === ProductTypeEnum.Al) {
      this.openFieldId = order.lineItems[0].id;
    }
  }

  sortSet(items: ProductLineItem[]) {
    return items.sort((a, b) => a.slot - b.slot);
  }

  toggleField(id: string) {
    if (this.openFieldId == id) {
      this.openFieldId = '';
    } else {
      this.openFieldId = id;
    }
  }
}
