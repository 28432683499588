import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatedNamePipe } from './translated-name.pipe';
import { TranslatedDescriptionPipe } from './translated-description.pipe';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { TextColorPipe } from '../text-color.pipe';
import { ConcatDescriptionPipe } from '../concat-description.pipe';
import { MyMatPaginatorIntl } from '../components/table/paginator.intl';
import { ContainsProductPipe } from './contains-product.pipe';
import { LabelColorPipe } from './label-color.pipe';

@NgModule({
  declarations: [
    TranslatedDescriptionPipe,
    TranslatedNamePipe,
    TextColorPipe,
    ConcatDescriptionPipe,
    ContainsProductPipe,
    LabelColorPipe
  ],
  imports: [CommonModule],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: (translateService: TranslateService) => new MyMatPaginatorIntl(translateService)
    }
  ],
  exports: [
    ConcatDescriptionPipe,
    TranslatedDescriptionPipe,
    TranslatedNamePipe,
    TextColorPipe,
    ContainsProductPipe,
    LabelColorPipe
  ]
})
export class PipesModule {}
