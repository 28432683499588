import { Component, EventEmitter, input, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input()
  type: 'text' | 'email' | 'password' | 'url' | 'number' | 'file' | 'textarea' = 'text';

  @Input()
  accept: string | undefined = undefined;

  @Input()
  loading = false;

  @Input()
  size: 'default' | 'xs' = 'default';

  @Input()
  value: string | number | undefined = '';

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter();

  @Input()
  label: string = '';

  @Input()
  isLabelHidden: boolean = false;

  @Input()
  description: string = '';

  @Input()
  placeholder: string = '';

  @Input()
  optional: boolean = false;

  /** LOCK */
  @Input()
  showLock: boolean = false;

  @Input()
  isLocked: boolean = false;

  @Input()
  keyupEvent = true;

  @Input()
  highlightLock: boolean = false;
  /** LOCK */

  @Input()
  unit: string = '';

  @Input()
  isReadonly: boolean = false;

  @Input()
  isGhost: boolean = false;

  @Input()
  error: string = '';

  @Input()
  hasError: boolean = false;

  @Input()
  limit: number | undefined = undefined;

  @Output()
  onChange: EventEmitter<string> = new EventEmitter();

  @Output()
  lockMouseEnter: EventEmitter<MouseEvent> = new EventEmitter();

  @Output()
  lockMouseLeave: EventEmitter<MouseEvent> = new EventEmitter();

  keyupTimeout!: any;

  @Input()
  fileName: string = '';

  change(event: any) {
    if (this.type == 'file') {
      this.value = event;
      this.valueChange.emit(event);
      this.onChange.emit(event);
    } else {
      this.value = event.target.value;
      this.valueChange.emit(event.target.value);
      this.onChange.emit(event.target.value);
    }
    this.error = '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fileName'] && !changes['fileName'].firstChange && changes['fileName'].currentValue == '') {
      this.value = '';
    }
  }

  keyup(event: any) {
    this.value = event.target.value;
  }

  constructor() {}

  ngOnInit(): void {}
}
