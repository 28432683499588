import { Pipe, PipeTransform } from '@angular/core';
import { Product, ProductLineItem } from '../api-client';

@Pipe({
  name: 'translatedDescription'
})
export class TranslatedDescriptionPipe implements PipeTransform {
  transform(item: ProductLineItem | Product, langKey: string): string {
    if (langKey === undefined || langKey === 'de') {
      return item.description;
    } else {
      return item.descriptionFr;
    }
  }
}
