import { Component } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { ProductService } from 'src/app/services/product.service';
import { AuthService } from '../../../services/auth.service';
import { CompanyService } from '../../../services/company.service';
import { TemplateService } from '../../../services/template.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  arsActive: boolean = true;
  type1Active: boolean = false;
  type2Active: boolean = false;

  constructor(
    public orderService: OrderService,
    public authService: AuthService,
    public companyService: CompanyService,
    public productService: ProductService,
    public templateService: TemplateService
  ) {}

  ngOnInit() {
    this.companyService.loadCompany();
  }

  createNewOrder(productID: string) {
    this.orderService.createNewOrder(productID);
  }
}
