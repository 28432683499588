<div class="relative h-full flex flex-col p-6 bg-gray-100 w-56 text-left" *ngIf="template && template.serializedLineItem">
    <span class="text-xs text-gray-500">{{'Drafts.Header'|translate}}</span>
    <span 
        class="font-medium mt-2 mb-4 overflow-hidden whitespace-nowrap text-ellipsis" 
        [title]="template.name"
    >{{template.name}}</span>
    <span class="mb-2 text-sm text-gray-500 mt-auto">{{'Drafts.TypeText'|translate}} - <span class="text-monza-500">{{'Drafts.TypeField'|translate}}-{{template.productType}}</span></span>
    <app-badge [label]="template.serializedLineItem.price" [type]="'price'"></app-badge>
    <hr class="my-4">
    <app-button (onClick)="selectTemplate.emit(template.id)" [label]="'Drafts.CTA'|translate" [icon]="'bookmark-added'" [fullWidth]="true" [type]="'secondary'"></app-button>
    <div class="absolute top-1 right-1">
        <app-button (onClick)="deleteTemplate.emit(template.id)" [label]="'Drafts.Delete'|translate" [onlyIcon]="true" [icon]="'delete'"></app-button>
    </div>
</div>
<div class="relative h-full flex flex-col p-6 bg-white w-56" *ngIf="template && template.serializedOrder">
    <span class="text-xs text-gray-500">{{'Drafts.Header'|translate}}</span>
    <span 
        class="font-medium mt-2 mb-4 overflow-hidden whitespace-nowrap text-ellipsis"
        [title]="template.name"
    >{{template.name}}</span>
    <span class="mb-2 text-sm text-gray-500 mt-auto">{{'Drafts.TypeText'|translate}} - <span class="text-monza-500">{{'Drafts.TypeConfiguration'|translate}}-{{template.productType}}</span></span>
    <app-badge [label]="template.serializedOrder.nettoPrice" [type]="'price'"></app-badge>
    <hr class="my-4">
    <app-button (onClick)="selectTemplate.emit(template.id)" [label]="'Drafts.CTA'|translate" [icon]="'bookmark-added'" [fullWidth]="true" [type]="'secondary'"></app-button>
    <div class="absolute top-1 right-1">
        <app-button (onClick)="deleteTemplate.emit(template.id)" [label]="'Drafts.Delete'|translate" [onlyIcon]="true" [icon]="'delete'"></app-button>
    </div>
</div>