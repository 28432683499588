import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AxiosError } from 'axios';
import { CreateAddressDto, RegisterPayload, RegisterWithTokenPayload } from 'src/app/api-client';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private companyService: CompanyService
  ) {}

  async ngOnInit() {
    let params = this.route.snapshot.queryParams;
    this.isCompany = params['type'] === 'invite' ? false : true;
    this.email = params['email'];
    this.isEmailReadOnly = params['email'] !== '' ? true : false;
    this.token = params['token'];
    if (!this.isCompany) {
      let check = await this.companyService.checkInviteToken(this.email, this.token);
      if (!check) {
        this.router.navigate(['/branch']);
      }
      this.inviteCompanyName = await this.companyService.getNameByToken(this.email, this.token);
      this.labelUser =
        `Ich bin mir bewusst darüber, dass dieser Account ein Unteraccount des Unternehmens <span class="text-amber-500">` +
        this.inviteCompanyName +
        `</span> wird.`;
    }
  }

  // inputs
  email: string = '';
  firstname: string = '';
  lastname: string = '';
  password: string = '';
  passwordConfirmation: string = '';

  companyName: string = '';
  phone: string = '';
  street: string = '';
  postal: string = '';
  city: string = '';
  country: string = '';

  privacyChecked = false;
  warningChecked = false;

  // vars
  steps: string[] = ['Auth.Register.Steps.Personal', 'Auth.Register.Steps.Company'];
  active: number = 0;
  error: string = '';

  isCompany: boolean = true;
  isEmailReadOnly: boolean = false;
  token: string = '';
  inviteCompanyName!: string | undefined;

  // labels
  labelUser: string = '';

  nextStep() {
    if (
      this.firstname != '' &&
      this.lastname != '' &&
      this.email != '' &&
      this.password != '' &&
      this.passwordConfirmation != '' &&
      this.privacyChecked &&
      this.warningChecked
    ) {
      if (this.password === this.passwordConfirmation) {
        this.error = '';
        this.active = 1;
      } else {
        this.error = 'Die Passwörter stimmen nicht überein.';
      }
    } else {
      this.error = 'Bitte füllen Sie alle Felder aus.';
    }
  }

  async registerCompany() {
    if (
      this.companyName != '' &&
      this.street != '' &&
      this.postal != '' &&
      this.city != '' &&
      this.country != '' &&
      this.phone != ''
    ) {
      this.error = '';
      const address: CreateAddressDto = {
        firstname: this.firstname,
        lastname: this.lastname,
        street: this.street,
        postal: this.postal,
        city: this.city,
        country: this.country,
        phone: this.phone
      };
      const payload: RegisterPayload = {
        email: this.email,
        firstName: this.firstname,
        lastName: this.lastname,
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        companyName: this.companyName,
        companyAddress: address
      };
      try {
        await this.auth.register(payload);
        this.router.navigate(['login']);
      } catch (error) {
        if (error instanceof AxiosError && error.response) {
          if (Array.isArray(error.response.data.message)) {
            this.error = error.response.data.message.join(', ');
            this.active = 0;
          } else {
            this.error = this.translate.instant(error.response.data.message);
            this.active = 0;
          }
        } else {
          this.error =
            'Es ist ein Fehler aufgetreten versuchen Sie es später erneut. Eventuell ist der Server nicht erreichbar überprüfen sie ihre Internetverbindung und Firewall/VPN Einstellungen.';
        }
      }
    } else {
      this.error = 'Bitte füllen Sie alle Felder aus.';
    }
  }

  async registerToken() {
    if (
      this.firstname != '' &&
      this.lastname != '' &&
      this.email != '' &&
      this.passwordConfirmation != '' &&
      this.privacyChecked &&
      this.warningChecked
    ) {
      if (this.password === this.passwordConfirmation) {
        this.error = '';
        const payload: RegisterWithTokenPayload = {
          email: this.email,
          firstName: this.firstname,
          lastName: this.lastname,
          password: this.password,
          passwordConfirmation: this.passwordConfirmation
        };
        try {
          const res = await this.auth.registerWithToken(this.token, payload);
          this.router.navigate(['']);
        } catch (error: any) {
          this.error = error?.response?.data?.message;
        }
      } else {
        this.error = 'Die Passwörter stimmen nicht überein.';
      }
    } else {
      this.error = 'Bitte füllen Sie alle Felder aus.';
    }
  }
}
