<div class="grid grid-cols-6 items-start justify-start col-span-6 gap-4" *ngIf="order$ | async as order" >
    <app-input
        class="col-span-3 lg:col-span-1"
        *ngIf="!item.isDividerWall && !item.isWall"
        [label]="'Configuration.Fields.OpenField.Width'|translate"
        [type]="'number'"
        [unit]="'mm'"
        [(value)]="item.width"
        [isReadonly]="order.status == OrderStatusEnum.Ordered"
        (onChange)="updateWidth($event)"
    ></app-input>
    <app-input
        class="col-span-3 lg:col-span-1"
        *ngIf="!item.isSocket"
        [label]="'Configuration.Fields.OpenField.Height'|translate"
        [type]="'number'"
        [unit]="'mm'"
        [(value)]="item.height"
        [isReadonly]="order.status == OrderStatusEnum.Ordered"
        (onChange)="updateHeight($event)"
    ></app-input>
    <app-custom-select
        class="col-span-3 lg:col-span-1"
        [label]="'Configuration.Fields.OpenField.Depth'|translate"
        [options]="depthOptions"
        [selectedValue]="selectedDepth"
        (cchange)="updateDepth($event.value)"
        [isReadonly]="order.status == OrderStatusEnum.Ordered"
        [placeholder]="false"
        unit="mm"
    ></app-custom-select>
    
    <div class="col-span-3 lg:col-span-1" *ngIf="item.isField">
        <app-custom-select
            [isReadonly]="order.status == OrderStatusEnum.Ordered"
            [label]="'Configuration.Fields.OpenField.IPProtection'|translate"
            [selectedValue]="selectedProtection"
            [options]="protectionLevelOptions"
            (cchange)="selectedProtection = $event; updateProtectionLevel($event.value)"
            [placeholder]="false"
        ></app-custom-select>
    </div>
    <div class="col-span-6 lg:col-span-2">
        <app-custom-select
            *ngIf="withoutOptions$ | async as withoutOptions"
            [label]="'Configuration.Fields.OpenField.Without'|translate"
            [isReadonly]="order.status == OrderStatusEnum.Ordered"
            [options]="withoutOptions"
            [selectedValue]="selectedWithout"
            (cchange)="addSlotItem(withoutSlot,$event)"
        ></app-custom-select>
    </div>
</div>