<app-modal
  [submitLabel]="'Configuration.Fields.SaveAddons' | translate"
  *ngIf="modalOpen"
  (onClose)="toggleAttachmentModal()"
  (onSubmit)="submitAttachmentModal()"
>
  <div class="text-lg font-medium">{{ 'Configuration.Fields.ChooseAddons' | translate }}</div>
  <div class="divide-y divide-neutral-200 w-96 max-w-full">
    <div class="flex flex-col">
      <div class="flex justify-between gap-2 items-center mb-2">
        <div>{{'Configuration.AVK.Attachments.Heating' | translate}}</div>
        <app-button
          [icon]="'keyboard-arrow-left'"
          [onlyIcon]="true"
          class="transition-all duration-200 z-10"
          [ngClass]="{ '-rotate-90': isHeatingExpanded }"
          [size]="'xs'"
          [type]="'light'"
          (onClick)="isHeatingExpanded = !isHeatingExpanded"
        ></app-button>
      </div>

      <div
        class="flex flex-col divide-y divide-neutral-200 transition-all duration-300 overflow-hidden"
        [ngClass]="{ 'max-h-0': !isHeatingExpanded, 'max-h-80': isHeatingExpanded }"
      >
        <div
          class="flex justify-start items-center py-4"
          matTooltipPosition="above"
          *ngFor="let heatingOption of heatingOptions$ | async; let index = index"
        >
          <div class="flex items-center mr-4" *ngIf="heatingAmounts$ | async as heatingAmounts; else mdLoading">
            <app-input
              [value]="heatingAmounts[index].amount"
              (valueChange)="amounts[heatingOption.id] = +$event"
              class="w-16 mr-4"
              unit="×"
            >
            </app-input>
          </div>
          <div class="cursor-pointer">
            <span class="self-center font-weight-500 block leading-none">{{
              heatingOption | concatDescription : translate.currentLang
            }}</span>
            <span class="text-sm font-medium leading-none text-monza-500 font-mono"
              >CHF {{ heatingOption.basePrice | currency : ' ' : 'symbol' : '1.2-2' }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="flex justify-between gap-2 items-center my-2">
        <div>{{'Configuration.AVK.Attachments.Thermostat' | translate}}</div>
        <app-button
          [icon]="'keyboard-arrow-left'"
          [onlyIcon]="true"
          class="transition-all duration-200 z-10"
          [ngClass]="{ '-rotate-90': isThermostatExpanded }"
          [size]="'xs'"
          [type]="'light'"
          (onClick)="isThermostatExpanded = !isThermostatExpanded"
        ></app-button>
      </div>

      <div
        class="flex flex-col divide-y divide-neutral-200 transition-all duration-300 overflow-hidden"
        [ngClass]="{ 'max-h-0': !isThermostatExpanded, 'max-h-80': isThermostatExpanded }"
      >
        <div
          class="flex justify-start items-center py-4"
          matTooltipPosition="above"
          *ngFor="let thermostatOption of thermostatOptions$ | async; let index = index"
        >
          <div class="flex items-center mr-4" *ngIf="thermostatAmounts$ | async as thermostatAmounts; else mdLoading">
            <app-input
              [value]="thermostatAmounts[index].amount"
              (valueChange)="amounts[thermostatOption.id] = +$event"
              class="w-16 mr-4"
              unit="×"
            >
            </app-input>
          </div>
          <div class="cursor-pointer">
            <span class="self-center font-weight-500 block leading-none">{{
              thermostatOption | concatDescription : translate.currentLang
            }}</span>
            <span class="text-sm font-medium leading-none text-monza-500 font-mono"
              >CHF {{ thermostatOption.basePrice | currency : ' ' : 'symbol' : '1.2-2' }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex justify-start items-center py-4"
      matTooltipPosition="above"
      *ngIf="hygrostat$ | async as hygrostat; else mdLoading"
    >
      <div class="flex items-center mr-4" *ngIf="hygrostatAmount$ | async as hygrostatAmount; else mdLoading">
        <app-input
          [value]="hygrostatAmount.amount"
          (valueChange)="amounts[hygrostat.id] = +$event"
          class="w-16 mr-4"
          unit="×"
        >
        </app-input>
      </div>
      <div class="cursor-pointer">
        <span class="self-center font-weight-500 block leading-none">{{
          hygrostat | concatDescription : translate.currentLang
        }}</span>
        <span class="text-sm font-medium leading-none text-monza-500 font-mono"
          >CHF {{ hygrostat.basePrice | currency : ' ' : 'symbol' : '1.2-2' }}</span
        >
      </div>
    </div>

    <div
      class="flex justify-start items-center py-4"
      matTooltipPosition="above"
      *ngIf="floorClamp$ | async as floorClamp; else mdLoading"
    >
      <div class="flex items-center mr-4" *ngIf="floorClampAmount$ | async as floorClampAmount; else mdLoading">
        <app-input
          [value]="floorClampAmount.amount"
          (valueChange)="amounts[floorClamp.id] = +$event"
          class="w-16 mr-4"
          unit="×"
        >
        </app-input>
      </div>
      <div class="cursor-pointer">
        <span class="self-center font-weight-500 block leading-none">{{
          floorClamp | concatDescription : translate.currentLang
        }}</span>
        <span class="text-sm font-medium leading-none text-monza-500 font-mono"
          >CHF {{ floorClamp.basePrice | currency : ' ' : 'symbol' : '1.2-2' }}</span
        >
      </div>
    </div>
  </div>
</app-modal>

<app-button
  *ngIf="order.status !== 'Ordered'"
  icon="variables"
  [label]="'Configuration.Fields.ChooseAddons' | translate"
  type="light"
  (onClick)="toggleAttachmentModal()"
  [fullWidth]="true"
></app-button>


<ng-template #lgLoading>
  <app-skeleton size="lg"></app-skeleton>
</ng-template>
<ng-template #mdLoading>
  <app-skeleton size="md" class="col-span-3"></app-skeleton>
</ng-template>
<ng-template #xsLoading>
  <app-skeleton size="xs"></app-skeleton>
</ng-template>
