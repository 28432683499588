<div>
    <img class="absolute inset-0 object-cover w-full h-full" src="assets/background.jpeg" alt="">
    <div *ngIf="cookie.hasConsented" class="absolute flex flex-col h-auto w-full max-w-xs gap-2 right-4 sm:right-6 lg:right-12 bottom-12 top-12 overflow-y-auto pr-2 scrollbar">
        <div class="w-full px-6 py-4 font-medium bg-white">
            {{'Auth.News'|translate}}
        </div>
        <div class="flex flex-col w-full px-6 py-4 bg-white bg-opacity-75">
            <span class="font-medium">ASS Online-Konfigurator</span>
            <span class="text-sm text-gray-500">24.11.2022</span>
            <p class="mt-2 text-sm"><iframe class="w-full h-auto" src="https://www.youtube.com/embed/o6REpF8q3ro" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
        </div>
        <div class="flex flex-col w-full px-6 py-4 bg-white bg-opacity-75">
            <span class="font-medium">Wie läuft das? #1</span>
            <span class="text-sm text-gray-500">24.11.2022</span>
            <p class="mt-2 text-sm"><iframe class="w-full h-auto" src="https://www.youtube.com/embed/N5j_tFd3Big" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
        </div>
        <div class="flex flex-col w-full px-6 py-4 bg-white bg-opacity-75">
            <span class="font-medium">Wie läuft das? #2</span>
            <span class="text-sm text-gray-500">24.11.2022</span>
            <p class="mt-2 text-sm"><iframe class="w-full h-auto" src="https://www.youtube.com/embed/nxMh_lYXMxo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
        </div>
        <div class="flex flex-col w-full px-6 py-4 bg-white bg-opacity-75">
            <span class="font-medium">Wie läuft das? #3</span>
            <span class="text-sm text-gray-500">24.11.2022</span>
            <p class="mt-2 text-sm"><iframe class="w-full h-auto" src="https://www.youtube.com/embed/ZHmNE1uZ3iE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
        </div>
    </div>
</div>
