import { Component, Input } from '@angular/core';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { BaseALSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map } from 'rxjs';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';

@Component({
  selector: 'app-al-lamp',
  templateUrl: './lamp.component.html',
  styleUrls: ['./lamp.component.scss']
})
export class LampComponent extends BaseALSlotComponent {
  @Input()
  item!: ProductLineItem;

  lampSlot = 14;
  lampProducts$: Observable<Product[]> | undefined;
  lampOptions$: Observable<Option[]> | undefined;
  selectedLamp: Option | undefined = undefined;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  override initItem(): void {}
  override updateItem(firstChange?: boolean | undefined): void {
    this.lampProducts$ = this.productService.getSlot(this.lampSlot, ProductTypeEnum.Al);
    this.lampOptions$ = this.lampProducts$!.pipe(
      this.productToOptionsPipe(this.lampSlot, this.translate.instant('Configuration.AVK.Dropdowns.None')),
      map((res) => {
        this.selectedLamp = res.selected;
        return res.res;
      })
    );
  }
}
