import { Component, OnInit } from '@angular/core';
import { subMinutes } from 'date-fns';
import { map, Observable } from 'rxjs';
import { CompanyService } from 'src/app/services/company.service';
import { OrderService } from 'src/app/services/order.service';
import { Address, Order } from '../../api-client';

@Component({
  selector: 'app-overview-address',
  templateUrl: './overview-address.component.html',
  styleUrls: ['./overview-address.component.scss']
})
export class OverviewAddressComponent implements OnInit {
  isDeliveryAddressModalOpen = false;
  isBillingAddressModalOpen = false;
  activeAddress$: Observable<Address | undefined> = this.companyService.company$.pipe(
    map((c) => c.addresses.find((a) => a.isActive))
  );

  deliveryAddress$ = this.orderService.order$.pipe(map((order) => order?.deliveryAddress));
  billingAddress$ = this.orderService.order$.pipe(map((order) => order?.billingAddress));

  today: Date = new Date();

  minDate: Date = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 7);

  submittingOrder = false;

  constructor(public companyService: CompanyService, public orderService: OrderService) {}

  ngOnInit(): void {}

  updateDeliveryDate(order: Order, date: Date | null) {
    if (date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      const normalizedDate = subMinutes(date, date?.getTimezoneOffset());

      this.orderService.updateOrder(order.id, { name: order.name, deliveryDate: normalizedDate.toISOString() });
    } else {
      console.error('Date is not defined');
    }
  }

  async finishOrder() {
    if (this.submittingOrder) this.submittingOrder = false;
    this.submittingOrder = true;
    try {
      await this.orderService.finishOrder();
    } catch (e) {
      this.submittingOrder = false;
    }
    this.submittingOrder = false;
  }

  async updateOrderNote(order: Order, note: string) {
    this.orderService.updateOrder(order.id, { name: order.name, note });
  }

  async updateOrderReference(order: Order, reference: string) {
    this.orderService.updateOrder(order.id, { name: order.name, reference });
  }

  async updateDeliveryAddress(address: Address) {
    this.orderService.setDeliveryAddress(address.id);
  }

  async updateBillingAddress(address: Address) {
    this.orderService.setBillingAddress(address.id);
  }

  async removeDeliveryAddress() {
    this.orderService.removeDeliveryAddress();
  }

  async removeBillingAddress() {
    this.orderService.removeBillingAddress();
  }
}
