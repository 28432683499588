import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CreateProductLineItemDto, ProductLineItem, UpdateProductLineItemDto } from '../../../api-client';
import { OrderService } from '../../../services/order.service';
import { TemplateService } from '../../../services/template.service';
import { ToastService } from '../../../services/toast.service';
import { ProductService } from '../../../services/product.service';
import { Side } from './slots/door/door.component';

export interface AddAVKSlotDTO {
  slot: number;
  create: CreateProductLineItemDto;
}

export interface UpdateAVKSlotDTO {
  slot: number;
  update: UpdateProductLineItemDto;
}

@Component({
  selector: 'app-avk',
  templateUrl: './avk.component.html',
  styleUrls: ['./avk.component.scss']
})
export class AvkComponent implements OnInit {
  @Input()
  item!: ProductLineItem;

  @Input()
  index!: number;

  isExpanded = true;

  addModalOpen = false;

  otherSide: Side = 'left';

  sideWallLeftAsDoor = false;
  sideWallRightAsDoor = false;

  templateName = '';
  createTemplateModal = false;

  constructor(
    public readonly orderService: OrderService,
    public readonly translate: TranslateService,
    public readonly templateService: TemplateService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {}

  toggleIsExpanded() {
    // this.isExpanded = !this.isExpanded;
  }

  async copyLineItemIdToClipboard(id: string) {
    // try {
    //   this.orderService.clipboard$.next(id);
    //   await navigator.clipboard.writeText(id);
    //   this.toastService.displayToast({ message: 'Feld in Zwischenablage kopiert', type: 'success', time: 5000 });
    // } catch (e) {
    //   console.error(e);
    //   this.toastService.displayToast({ message: 'Konnte Feld nicht kopieren', type: 'danger', time: 15000 });
    // }
  }

  addField() {
    this.orderService.addLineItem({
      productId: this.item.productId,
      sorting: this.item.sorting + 1
    });
    this.orderService.addedSorting = this.item.sorting + 10;
  }

  addSlot(dto: AddAVKSlotDTO) {
    if (this.item.set.some((prod) => prod.id == dto.create.productId)) {
      this.toastService.displayToast({
        message: this.translate.instant('Configuration.AVK.Errors.AlreadyAdded'),
        time: 5000,
        type: 'danger'
      });
      return;
    }
    this.orderService.addLineItemSlot(this.item.id, dto.slot.toString(), dto.create);
  }

  updateSlot(dto: UpdateAVKSlotDTO) {
    this.orderService.updateLineItemSlot(this.item.id, dto.slot.toString(), dto.update);
  }

  removeSlot(slot: number) {
    if (!this.item.set.some((prod) => prod.slot == slot)) {
      this.toastService.displayToast({
        message: this.translate.instant('Configuration.AVK.Errors.AlreadyRemoved'),
        time: 5000,
        type: 'danger'
      });
      return;
    }
    this.orderService.removeLineItemSlot(this.item.id, slot.toString());
  }

  async pasteField() {
    // try {
    //   const id = this.orderService.clipboard$.value;
    //   if (id == undefined || id.length !== 36) {
    //     this.toastService.displayToast({
    //       message: await firstValueFrom(this.translate.get('Configuration.Fields.NoFieldCopied')),
    //       type: 'danger',
    //       time: 15000
    //     });
    //     return;
    //   }
    //   this.orderService.pasteLineItem(id, (this.item.sorting + 1).toString());
    //   this.orderService.addedSorting = this.item.sorting + 10;
    // } catch (e) {
    //   console.error(e);
    //   this.toastService.displayToast({
    //     message: await firstValueFrom(this.translate.get('Configuration.Fields.FieldPasteError')),
    //     type: 'danger',
    //     time: 15000
    //   });
    // }
  }
}
