<div>
    <div class="flex items-center justify-between mb-1" *ngIf="!isLabelHidden">
        <label *ngIf="type != 'file'" [for]="label.toLowerCase()" class="block text-sm font-medium text-gray-600">{{label}}</label>
        <ng-container *ngIf="showLock">
            <div [ngClass]="optional ? 'pr-2' : ''" (mouseenter)="lockMouseEnter.emit($event)" (mouseleave)="lockMouseLeave.emit($event)">
                <app-icon *ngIf="isLocked" [icon]="'lock-closed'" class="w-4 h-4 transition duration-200" [ngClass]="{'text-monza-500': highlightLock, 'text-gray-500': !highlightLock}"></app-icon>
                <app-icon *ngIf="!isLocked" [icon]="'lock-open'" class="w-4 h-4 transition duration-200" [ngClass]="{'text-monza-500': highlightLock, 'text-gray-500': !highlightLock}"></app-icon>
            </div>
        </ng-container>
        <span *ngIf="optional" class="text-sm text-gray-500" [id]="label.toLowerCase() + '-optional'">{{'Input.Optional'|translate}}</span>
    </div>
    <div class="relative group">
        <input
            *ngIf="type != 'textarea' && type != 'file'"
            (keyup)="keyup($event)"
            (change)="change($event)"
            [readOnly]="isReadonly"
            [accept]="accept"
            [type]="type"
            [name]="label.toLowerCase()"
            [id]="label.toLowerCase()"
            [value]="value"
            [attr.maxlength]="limit"
            [multiple]="false"
            [placeholder]="placeholder"
            class="block w-full focus:ring-monza-400 focus:border-monza-500 sm:text-sm placeholder:text-gray-400"
            [ngClass]="[
                size == 'xs' ? 'p-1' : '',
                isReadonly ? 'bg-gray-100 focus:border-gray-300 focus:ring-transparent' : '',
                isGhost ? 'bg-transparent border-transparent hover:text-gray-500 font-medium' : 'border-gray-300',
                hasError ? 'border-red-500' : ''
            ]"
            [tabIndex]="isReadonly ? '-1' : ''"
        >
        <input
            *ngIf="type == 'file'"
            (keyup)="keyup($event)"
            (change)="change($event)"
            [readOnly]="isReadonly"
            [disabled]="isReadonly && type =='file'"
            [accept]="accept"
            [type]="type"
            [name]="label.toLowerCase()"
            [id]="label.toLowerCase()"
            [multiple]="false"
            [placeholder]="placeholder"
            class="block w-full focus:ring-monza-400 focus:border-monza-500 sm:text-sm placeholder:text-gray-400"
            [ngClass]="[
                size == 'xs' ? 'p-1' : '',
                isReadonly ? 'bg-gray-100 focus:border-gray-300 focus:ring-transparent' : '',
                isGhost ? 'bg-transparent border-transparent hover:text-gray-500 font-medium' : 'border-gray-300',
                hasError ? 'border-red-500' : ''
            ]"
            [tabIndex]="isReadonly ? '-1' : ''"
        >
        <textarea
            rows="5"
            class="block w-full focus:ring-monza-400 focus:border-monza-500 sm:text-sm placeholder:text-gray-400"
            [ngClass]="[
                isReadonly ? 'bg-gray-100 focus:border-gray-300 focus:ring-transparent' : '',
                isGhost ? 'bg-transparent border-transparent hover:text-gray-500 font-medium' : 'border-gray-300',
                hasError ? 'border-red-500' : ''
            ]"
            *ngIf="type == 'textarea'"
            [placeholder]="placeholder"
            (keyup)="keyup($event)"
            (change)="change($event)"
            [name]="label.toLowerCase()"
            [id]="label.toLowerCase()"
            [readOnly]="isReadonly"
            [disabled]="isReadonly"
        >{{value}}</textarea>
        <label *ngIf="type == 'file'" [for]="label.toLowerCase()" [ngClass]="{'bg-green-600 active': fileName, 'bg-gray-500': !fileName}">
            <ng-container *ngIf="loading">
                <svg class="h-6 m-auto animate-spin fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><defs><style>.fa-secondary{opacity:.4}</style></defs><path class="fa-primary" d="M256 32C256 14.33 270.3 0 288 0C429.4 0 544 114.6 544 256C544 302.6 531.5 346.4 509.7 384C500.9 399.3 481.3 404.6 465.1 395.7C450.7 386.9 445.5 367.3 454.3 351.1C470.6 323.8 480 291 480 255.1C480 149.1 394 63.1 288 63.1C270.3 63.1 256 49.67 256 31.1V32z"/><path class="fa-secondary" d="M287.1 64C181.1 64 95.1 149.1 95.1 256C95.1 362 181.1 448 287.1 448C358.1 448 419.3 410.5 452.9 354.4L453 354.5C446.1 369.4 451.5 387.3 465.1 395.7C481.3 404.6 500.9 399.3 509.7 384C509.9 383.7 510.1 383.4 510.2 383.1C466.1 460.1 383.1 512 288 512C146.6 512 32 397.4 32 256C32 114.6 146.6 0 288 0C270.3 0 256 14.33 256 32C256 49.67 270.3 64 288 64H287.1z"/></svg>
            </ng-container>
            <ng-container *ngIf="!loading">
                {{label}}
                <hr class="my-2" *ngIf="fileName">
                <div *ngIf="fileName" class="text-sm">
                    {{fileName}}
                </div>
            </ng-container>
            <ng-content></ng-content>
        </label>
        <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none " *ngIf="isGhost">
            <app-icon [icon]="'edit'" class="w-4 h-4 text-gray-500 bg-white"></app-icon>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none" *ngIf="unit">
            <span class="text-gray-500 sm:text-sm" id="price-currency"> {{unit}} </span>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none" *ngIf="limit">
            <span class="text-gray-500 sm:text-sm" id="price-currency" *ngIf="value"> {{value ? ($any(value).length ?? 0) : 0}}/{{limit}} </span>
        </div>
    </div>
    <p *ngIf="description" class="mt-2 text-sm text-gray-500" [id]="label.toLowerCase() + '-description'">{{description}}</p>
    <span class="p-4 text-sm text-monza-500" *ngIf="error">{{error}}</span>
</div>