import { NgStyle } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { distance } from 'fastest-levenshtein';
import { Observable } from 'rxjs';

export enum ReadonlyReason {
  'AlreadyAdded' = 'AlreadyAdded',
  'NotAvailable' = 'NotAvailable',
  'NotCompatible' = 'NotCompatible',
  'NeedsSlot13' = 'NeedsSlot13'
}

export interface Option<T = string> {
  value: T;
  label: string;
  description: string;
  readonly?: boolean;
  readonlyReason?: ReadonlyReason;
  translated?: Observable<string>;
  meta?: any;
}

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomSelectComponent implements OnInit {
  isOpen = false;
  itemStyles = 'text-gray-900';
  descStyles = 'text-gray-500';
  checkStyles = 'text-blue-500';
  highlightIndex!: number;

  @Input()
  labelTooltip: TemplateRef<any> | null = null;

  @Input()
  selectedValue!: Option<any> | null;

  @Input()
  placeholder = true;

  @Input()
  multiLine = false;

  @Input()
  tooltip: string = '';

  @Input()
  name = '';

  @Input()
  label = '';

  @Input()
  unit: string = '';

  @Input()
  isReadonly = false;

  @Input()
  options!: Array<Option<any>>;

  /** LOCK */
  @Input()
  showLock: boolean = false;

  @Input()
  isLocked: boolean = false;

  @Input()
  highlightLock: boolean = false;

  @Input()
  size: 'sm' | 'md' | 'lg' = 'md';

  @Input()
  labelStyle?:
    | {
        [klass: string]: any;
      }
    | null
    | undefined;

  @Output()
  cchange = new EventEmitter<Option<any>>();

  @Output()
  lockMouseEnter: EventEmitter<MouseEvent> = new EventEmitter();

  @Output()
  lockMouseLeave: EventEmitter<MouseEvent> = new EventEmitter();

  originalOptions!: Array<Option>;

  isSearchVisible = false;
  searchValue = '';

  @ViewChild('search')
  search!: ElementRef;

  @ViewChild('elem')
  elem!: ElementRef;
  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    /**
     * This events get called by all clicks on the page
     */
    this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (!this.elem.nativeElement.contains(e.target)) {
        this.close();
        this.cdr.detectChanges();
      }
    });

    this.renderer.listen('window', 'keydown', (e: KeyboardEvent) => {
      if (!this.isSearchVisible && this.isOpen) {
        this.isSearchVisible = true;
        this.cdr.detectChanges();
        if (this.search && this.search.nativeElement) this.search.nativeElement.focus();
        this.searchValue = e.key;
      }
    });
  }

  ngOnInit(): void {
    if (!this.selectedValue) {
      this.selectedValue = [...this.options][0];
    }
    this.originalOptions = [...this.options];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this.options = [...changes['options'].currentValue];
      this.originalOptions = [...this.options];
      this.cdr.detectChanges();
    }
  }

  onSearch(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;

    this.updateOptionList();
  }

  updateOptionList() {
    if (!this.originalOptions) return;
    this.options = this.originalOptions.filter((option) => {
      return !this.searchValue
        .split(' ')
        .map((search) => {
          return (
            option.label.toLowerCase().includes(search.toLowerCase()) ||
            option.label
              .split(' ')
              .filter((l) => l.length > 3)
              .some((l) => {
                return distance(search.toLowerCase(), l.toLowerCase()) <= l.length / 5;
              })
          );
        })
        .some((v) => !v);
    });

    this.cdr.detectChanges();
  }

  select(option: Option) {
    if (option.readonly) return;
    this.selectedValue = option;
    this.cchange.emit(option);
    this.close();
  }

  close() {
    this.isOpen = false;
    this.isSearchVisible = false;
    this.searchValue = '';
    this.updateOptionList();
  }

  highlightItem(event: MouseEvent, index: number) {
    this.highlightIndex = index;
    this.itemStyles = event.type == 'mouseenter' ? 'text-white bg-monza-500' : 'text-gray-900';
    this.descStyles = event.type == 'mouseenter' ? 'text-blue-100' : 'text-gray-500';
  }
}
