<ng-container *ngIf="!order.activePromotion">
<div class="flex mt-2" >
  <app-input class="w-full" [placeholder]="'Promotion.PromotionInput' | translate" [(value)]="promoCode"></app-input>
  <app-button class="mt-1" [icon]="'arrow-right-alt'" (onClick)="addPromo()"></app-button>
</div>

<span class="text-xs text-red-500 pl-4" *ngIf="error">{{error}}</span>
</ng-container>

<div class="flex mt-2" *ngIf="order.activePromotion">
  <span class="inline-flex items-center px-2.5 py-1 text-sm font-medium badge text-blue-800 bg-blue-100">{{order.activePromotion.code}} 
    <div class="pl-2 ml-2 border-l border-l-blue-600">
      <app-icon icon="close" class="h-4 w-4 cursor-pointer" (click)="removePromo()"></app-icon>
    </div>
  </span>
</div>
