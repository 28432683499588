<div class="grid grid-cols-6 items-center justify-start col-span-6 gap-4">
  <app-custom-select
    *ngIf="schemaOptions$ | async as schemaOptions"
    class="col-span-6 md:col-span-3"
    label="Schemafach"
    [label]="'Configuration.Fields.OpenField.SchemaCompartment' | translate"
    [options]="schemaOptions"
    [selectedValue]="selectedSchema!"
    (cchange)="addSlotItem(schemaSlot, $event)"
  ></app-custom-select>
  <app-custom-select 
    *ngIf="selectedSchema?.value != '' && isDoubleDoor"
    class="col-span-4 md:col-span-2"
    [label]="'Configuration.Fields.OpenField.Side' | translate"
    [placeholder]="false"
    [options]="sideOptions"
    [selectedValue]="selectedSide!"
    (cchange)="setSide($event.value)"
  ></app-custom-select>
</div>
