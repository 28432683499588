import { Component, Input } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { OrderService } from '../../../../../services/order.service';
import { ProductService } from '../../../../../services/product.service';
import { BaseAVKSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../../services/toast.service';
import { AVK_CONFIG } from '../../config/avk.config';

export type AVKDoorInstallationArea =
  | 'compartment-left'
  | 'compartment-right'
  | 'side-left'
  | 'side-right'
  | 'back'
  | 'front-horizontal-left'
  | 'front-horizontal-right';

export type Side = 'left' | 'right';
export type DoorType = 'single' | 'double' | 'middle' | 'same';

@Component({
  selector: 'app-avk-door',
  templateUrl: './door.component.html',
  styleUrls: ['./door.component.scss']
})
export class DoorComponent extends BaseAVKSlotComponent {
  @Input()
  item!: ProductLineItem;

  @Input()
  show = true;

  @Input()
  additionalCompartment = false;

  @Input()
  doorInstallationArea: AVKDoorInstallationArea = 'compartment-left';
  groupIdentifier = '';

  selectedType: DoorType = AVK_CONFIG.DOOR.DEFAULTS.TYPE;

  doorSlots: Map<AVKDoorInstallationArea, number> = new Map([
    ['compartment-left', AVK_CONFIG.DOOR.COMPARTMENT_LEFT.SLOT_DOOR],
    ['front-horizontal-left', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_DOOR],
    ['compartment-right', AVK_CONFIG.DOOR.COMPARTMENT_RIGHT.SLOT_DOOR],
    ['front-horizontal-right', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_DOOR],
    ['side-left', AVK_CONFIG.DOOR.SIDE_LEFT.SLOT_DOOR],
    ['side-right', AVK_CONFIG.DOOR.SIDE_RIGHT.SLOT_DOOR],
    ['back', AVK_CONFIG.DOOR.BACK.SLOT_DOOR]
  ]);
  doorProducts$: Observable<Product[]> | undefined;
  selectedDoorSide: Side = AVK_CONFIG.DOOR.DEFAULTS.SIDE;

  doorMap = AVK_CONFIG.DOOR.DOOR_MAP;
  reverseDoorMap: { [key: string]: [DoorType, Side] } = AVK_CONFIG.DOOR.DOOR_REVERSE_MAP;

  //#region Dropdown Options
  sideOptions: Option[] = [
    {
      description: '',
      label: this.translate.instant('Configuration.AVK.Sides.Left'),
      value: 'left'
    },
    {
      description: '',
      label: this.translate.instant('Configuration.AVK.Sides.Right'),
      value: 'right'
    }
  ];
  //#endregion

  //#region Klapptablar
  klapptablarSlots: Map<AVKDoorInstallationArea, number> = new Map([
    ['compartment-left', AVK_CONFIG.DOOR.COMPARTMENT_LEFT.SLOT_KLAPPTABLAR],
    ['front-horizontal-left', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_KLAPPTABLAR],
    ['compartment-right', AVK_CONFIG.DOOR.COMPARTMENT_RIGHT.SLOT_KLAPPTABLAR],
    ['front-horizontal-right', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_KLAPPTABLAR],
    ['side-left', AVK_CONFIG.DOOR.SIDE_LEFT.SLOT_KLAPPTABLAR],
    ['side-right', AVK_CONFIG.DOOR.SIDE_RIGHT.SLOT_KLAPPTABLAR],
    ['back', AVK_CONFIG.DOOR.BACK.SLOT_KLAPPTABLAR]
  ]);
  klapptablarProduct$: Observable<Product | undefined> | undefined = undefined;
  klapptablarProductLeft$: Observable<Product | undefined> | undefined = undefined;
  klapptablarProductRight$: Observable<Product | undefined> | undefined = undefined;
  hasKlapptablar = false;
  selectedSideOption = this.sideOptions[0];
  //#endregion

  //#region groundWire
  groundWireSlots: Map<AVKDoorInstallationArea, number> = new Map([
    ['compartment-left', AVK_CONFIG.DOOR.COMPARTMENT_LEFT.SLOT_GROUND_WIRE],
    ['front-horizontal-left', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_GROUND_WIRE],
    ['compartment-right', AVK_CONFIG.DOOR.COMPARTMENT_RIGHT.SLOT_GROUND_WIRE],
    ['front-horizontal-right', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_GROUND_WIRE],
    ['side-left', AVK_CONFIG.DOOR.SIDE_LEFT.SLOT_GROUND_WIRE],
    ['side-right', AVK_CONFIG.DOOR.SIDE_RIGHT.SLOT_GROUND_WIRE],
    ['back', AVK_CONFIG.DOOR.BACK.SLOT_GROUND_WIRE]
  ]);
  hasGroundWire = false;
  groundWireProduct$: Observable<Product> | undefined = undefined;
  //#endregion

  //#region Lockable
  lockableSlots: Map<AVKDoorInstallationArea, number> = new Map([
    ['compartment-left', AVK_CONFIG.DOOR.COMPARTMENT_LEFT.SLOT_LOCKABLE],
    ['front-horizontal-left', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_LOCKABLE],
    ['compartment-right', AVK_CONFIG.DOOR.COMPARTMENT_RIGHT.SLOT_LOCKABLE],
    ['front-horizontal-right', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_LOCKABLE],
    ['side-left', AVK_CONFIG.DOOR.SIDE_LEFT.SLOT_LOCKABLE],
    ['side-right', AVK_CONFIG.DOOR.SIDE_RIGHT.SLOT_LOCKABLE],
    ['back', AVK_CONFIG.DOOR.BACK.SLOT_LOCKABLE]
  ]);
  lockableProduct$: Observable<Product> | undefined = undefined;
  hasLockable = false;
  //#endregion

  //#region Handle
  handleSlots: Map<AVKDoorInstallationArea, number> = new Map([
    ['compartment-left', AVK_CONFIG.DOOR.COMPARTMENT_LEFT.SLOT_HANDLE],
    ['front-horizontal-left', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_HANDLE],
    ['compartment-right', AVK_CONFIG.DOOR.COMPARTMENT_RIGHT.SLOT_HANDLE],
    ['front-horizontal-right', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_HANDLE],
    ['side-left', AVK_CONFIG.DOOR.SIDE_LEFT.SLOT_HANDLE],
    ['side-right', AVK_CONFIG.DOOR.SIDE_RIGHT.SLOT_HANDLE],
    ['back', AVK_CONFIG.DOOR.BACK.SLOT_HANDLE]
  ]);
  handleProducts$: Observable<Product[]> | undefined;

  private readonly LOCK_MAP: { [key: string]: { [key: string]: string } } = {
    '1': {
      '0': 'avshgvr',
      '1': 'avshgr5000'
    },
    '2': {
      '0': 'avshgvp',
      '1': 'avshgp'
    },
    '3': {
      '0': 'avshgrwkvr',
      '1': 'avshgrwk5000'
    },
    '4': {
      '0': 'avshgrwkvp',
      '1': 'avshgrwkp'
    },
    '5': {
      '0': 'avshgrwkvpd',
      '1': 'avshgrwkpd'
    },
    '6': {
      '0': 'avshgrdzvr',
      '1': 'avshgrdzr'
    },
    '7': {
      '0': 'avshgrdzvp',
      '1': 'avshgrdzp'
    }
  };
  get currentLockProduct() {
    if (this.selectedCylinder.value && this.selectedHandle.value) {
      return this.LOCK_MAP[this.selectedHandle.value][this.selectedCylinder.value];
    } else if (this.selectedHandle.value) {
      return this.LOCK_MAP[this.selectedHandle.value][0];
    } else {
      return '';
    }
  }
  private readonly REVERSE_LOCK_MAP: { [key: string]: [string, string] } = {
    avshgvr: ['1', '0'],
    avshgr5000: ['1', '1'],
    avshgvp: ['2', '0'],
    avshgp: ['2', '1'],
    avshgrwkvr: ['3', '0'],
    avshgrwk5000: ['3', '1'],
    avshgrwkvp: ['4', '0'],
    avshgrwkp: ['4', '1'],
    avshgrwkvpd: ['5', '0'],
    avshgrwkpd: ['5', '1'],
    avshgrdzvr: ['6', '0'],
    avshgrdzr: ['6', '1'],
    avshgrdzvp: ['7', '0'],
    avshgrdzp: ['7', '1']
  };

  handleOptions: Option[] = [
    {
      label: this.translate.instant('Configuration.AVK.Dropdowns.None'),
      translated: this.translate.getStreamOnTranslationChange('Configuration.AVK.Dropdowns.None'),
      value: '0',
      description: ''
    },
    {
      label: this.translate.instant('Configuration.AVK.Door.Handles.SHG_RH'),
      translated: this.translate.getStreamOnTranslationChange('Configuration.AVK.Door.Handles.SHG_RH'),
      value: '1',
      description: ''
    },
    {
      label: this.translate.instant('Configuration.AVK.Door.Handles.SHG_PH'),
      translated: this.translate.getStreamOnTranslationChange('Configuration.AVK.Door.Handles.SHG_PH'),
      value: '2',
      description: ''
    },
    {
      label: this.translate.instant('Configuration.AVK.Door.Handles.WK2_SHG_RH'),
      translated: this.translate.getStreamOnTranslationChange('Configuration.AVK.Door.Handles.WK2_SHG_RH'),
      value: '3',
      description: ''
    },
    {
      label: this.translate.instant('Configuration.AVK.Door.Handles.WK2_SHG_PH'),
      translated: this.translate.getStreamOnTranslationChange('Configuration.AVK.Door.Handles.WK2_SHG_PH'),
      value: '4',
      description: ''
    },
    {
      label: this.translate.instant('Configuration.AVK.Door.Handles.WK2_SHG_DPH'),
      translated: this.translate.getStreamOnTranslationChange('Configuration.AVK.Door.Handles.WK2_SHG_DPH'),
      value: '5',
      description: ''
    },
    {
      label: this.translate.instant('Configuration.AVK.Door.Handles.DZ_SG_RH'),
      translated: this.translate.getStreamOnTranslationChange('Configuration.AVK.Door.Handles.DZ_SG_RH'),
      value: '6',
      description: ''
    },
    {
      label: this.translate.instant('Configuration.AVK.Door.Handles.DZ_SG_PH'),
      translated: this.translate.getStreamOnTranslationChange('Configuration.AVK.Door.Handles.DZ_SG_PH'),
      value: '7',
      description: ''
    }
  ];
  cylinderOptions: Option[] = [];
  cylinderOptionsSmall: Option[] = [
    {
      label: this.translate.instant('Configuration.AVK.Dropdowns.None'),
      translated: this.translate.getStreamOnTranslationChange('Configuration.AVK.Dropdowns.None'),
      value: '0',
      description: ''
    },
    {
      label: this.translate.instant('Configuration.AVK.Door.Cylinders.Kaba_Profil_17'),
      translated: this.translate.getStreamOnTranslationChange('Configuration.AVK.Door.Cylinders.Kaba_Profil_17'),
      value: '1',
      description: ''
    }
  ];
  cylinderOptionsLarge: Option[] = [
    {
      label: this.translate.instant('Configuration.AVK.Dropdowns.None'),
      translated: this.translate.getStreamOnTranslationChange('Configuration.AVK.Dropdowns.None'),
      value: '0',
      description: ''
    },
    {
      label: this.translate.instant('Configuration.AVK.Door.Cylinders.Kaba_8_22'),
      translated: this.translate.getStreamOnTranslationChange('Configuration.AVK.Door.Cylinders.Kaba_8_22'),
      value: '1',
      description: ''
    }
  ];
  selectedCylinder: Option = this.cylinderOptionsSmall[0];
  selectedHandle: Option = this.handleOptions[0];
  //#endregion

  //#region Schema
  schemaSlots: Map<AVKDoorInstallationArea, number> = new Map([
    ['compartment-left', AVK_CONFIG.DOOR.COMPARTMENT_LEFT.SLOT_SCHEMA],
    ['front-horizontal-left', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_SCHEMA],
    ['compartment-right', AVK_CONFIG.DOOR.COMPARTMENT_RIGHT.SLOT_SCHEMA],
    ['front-horizontal-right', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_SCHEMA],
    ['side-left', AVK_CONFIG.DOOR.SIDE_LEFT.SLOT_SCHEMA],
    ['side-right', AVK_CONFIG.DOOR.SIDE_RIGHT.SLOT_SCHEMA],
    ['back', AVK_CONFIG.DOOR.BACK.SLOT_SCHEMA]
  ]);
  schemaProducts$: Observable<Product[]> | undefined;
  selectedSchema: Option | undefined = undefined;
  selectedSchemaSideOption: Option = this.sideOptions[0];
  schemaOptions$: Observable<Option[]> | undefined;
  //#endregion

  //#region Opening
  openingSlots: Map<AVKDoorInstallationArea, number> = new Map([
    ['compartment-left', AVK_CONFIG.DOOR.COMPARTMENT_LEFT.SLOT_OPENING],
    ['front-horizontal-left', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_OPENING],
    ['compartment-right', AVK_CONFIG.DOOR.COMPARTMENT_RIGHT.SLOT_OPENING],
    ['front-horizontal-right', AVK_CONFIG.DOOR.FRONT_HORIZONTAL_LEFT.SLOT_OPENING],
    ['side-left', AVK_CONFIG.DOOR.SIDE_LEFT.SLOT_OPENING],
    ['side-right', AVK_CONFIG.DOOR.SIDE_RIGHT.SLOT_OPENING],
    ['back', AVK_CONFIG.DOOR.BACK.SLOT_OPENING]
  ]);
  openingProducts$: Observable<Product[]> | undefined;
  selectedOpening: Option | undefined = undefined;
  openingOptions$: Observable<Option[]> | undefined;
  //#endregion

  constructor(
    private readonly orderService: OrderService,
    private readonly productService: ProductService,
    private readonly translate: TranslateService,
    private toastService: ToastService
  ) {
    super(translate);
  }

  override initItem(): void {
    this.doorProducts$ = this.productService.getSlot(
      this.doorSlots.get(this.doorInstallationArea)!,
      ProductTypeEnum.Avk
    );
    this.klapptablarProduct$ = this.productService
      .getSlot(this.klapptablarSlots.get(this.doorInstallationArea)!, ProductTypeEnum.Avk)
      .pipe(map((prods) => prods.find((prod) => prod.productNumber == AVK_CONFIG.DOOR.KLAPPTABLAR.NORMAL)));
    this.klapptablarProductLeft$ = this.productService
      .getSlot(this.klapptablarSlots.get(this.doorInstallationArea)!, ProductTypeEnum.Avk)
      .pipe(map((prods) => prods.find((prod) => prod.productNumber == AVK_CONFIG.DOOR.KLAPPTABLAR.LEFT)));
    this.klapptablarProductRight$ = this.productService
      .getSlot(this.klapptablarSlots.get(this.doorInstallationArea)!, ProductTypeEnum.Avk)
      .pipe(map((prods) => prods.find((prod) => prod.productNumber == AVK_CONFIG.DOOR.KLAPPTABLAR.RIGHT)));
    this.groundWireProduct$ = this.productService
      .getSlot(this.groundWireSlots.get(this.doorInstallationArea)!, ProductTypeEnum.Avk)
      .pipe(map((prods) => prods[0]));
    this.lockableProduct$ = this.productService
      .getSlot(this.lockableSlots.get(this.doorInstallationArea)!, ProductTypeEnum.Avk)
      .pipe(map((prods) => prods[0]));
    this.handleProducts$ = this.productService.getSlot(
      this.handleSlots.get(this.doorInstallationArea)!,
      ProductTypeEnum.Avk
    );
    this.schemaProducts$ = this.productService.getSlot(
      this.schemaSlots.get(this.doorInstallationArea)!,
      ProductTypeEnum.Avk
    );
    this.schemaOptions$ = this.schemaProducts$.pipe(
      map((products) => products.filter((p) => AVK_CONFIG.DOOR.SCHEMA.PRODUCTS.includes(p.productNumber))),
      tap((prods) => {
        const schemaItem = this.item.set.find((s) => s.slot == this.schemaSlots.get(this.doorInstallationArea)!);
        const selected = prods.find((prod) => {
          return prod.productNumber != '' && schemaItem?.productNumber.includes(prod.productNumber);
        });
        this.selectedSchema = selected
          ? this.productSlotToOption(selected)
          : {
              value: '',
              description: '',
              label: this.translate.instant('Configuration.AVK.Dropdowns.None')
            };
      }),
      this.productToOptionsPipe(
        this.schemaSlots.get(this.doorInstallationArea)!,
        this.translate.instant('Configuration.AVK.Dropdowns.None')
      ),
      map((res) => {
        return res.res;
      })
    );
    this.openingProducts$ = this.productService.getSlot(
      this.openingSlots.get(this.doorInstallationArea)!,
      ProductTypeEnum.Avk
    );
    this.openingOptions$ = this.openingProducts$.pipe(
      this.productToOptionsPipe(
        this.openingSlots.get(this.doorInstallationArea)!,
        this.translate.instant('Configuration.AVK.Dropdowns.None')
      ),
      map((res) => {
        this.selectedOpening = res.selected;
        return res.res;
      })
    );

    if (this.doorInstallationArea == 'compartment-left' || this.doorInstallationArea == 'compartment-right') {
      if (this.additionalCompartment) {
        this.groupIdentifier = 'additional-compartment';
      } else {
        this.groupIdentifier = 'compartment';
      }
    } else if (
      this.doorInstallationArea == 'front-horizontal-left' ||
      this.doorInstallationArea == 'front-horizontal-right'
    ) {
      if (this.additionalCompartment) {
        this.groupIdentifier = 'additional-front-horizontal';
      } else {
        this.groupIdentifier = 'front-horizontal';
      }
    } else {
      this.groupIdentifier = this.doorInstallationArea;
    }
  }

  /**
   * Gets automaticly called on this.item update
   */
  override updateItem(firstChange: boolean): void {
    this.updateInstallation();
    this.updateKlapptablar();
    this.updateGroundWire();
    this.updateLockable();
    this.updateSchema();
    this.updateHandle();
    this.updateDoorType();
    if (!firstChange) this.initItem();
    //TODO: OTHER Update Funcs
  }

  updateInstallation() {
    if (this.doorInstallationArea == 'compartment-left' || this.doorInstallationArea == 'compartment-right') {
      const compartmentProd = this.item.set.find((p) => p.slot == AVK_CONFIG.DOOR.META.LEFT);
      const compartmentProd2 = this.item.set.find((p) => p.slot == AVK_CONFIG.DOOR.META.RIGHT);
      if (
        compartmentProd?.custom == 'additional' ||
        (compartmentProd2 && compartmentProd2.custom != 'additional') ||
        (this.doorInstallationArea == 'compartment-right' && !compartmentProd2)
      ) {
        this.doorInstallationArea = this.additionalCompartment ? 'compartment-left' : 'compartment-right';
      } else {
        this.doorInstallationArea = this.additionalCompartment ? 'compartment-right' : 'compartment-left';
      }
    }
  }

  async setHandle(opt: Option) {
    if (opt.value !== '0') {
      if (['1', '3', '6'].includes(opt.value) && !['2', '4', '5', '7'].includes(this.selectedHandle.value)) {
        this.cylinderOptions = this.cylinderOptionsLarge;
        this.selectedCylinder = this.cylinderOptions[0];
      }
      if (['2', '4', '5', '7'].includes(opt.value) && !['1', '3', '6'].includes(this.selectedHandle.value)) {
        this.cylinderOptions = this.cylinderOptionsSmall;
        this.selectedCylinder = this.cylinderOptions[0];
      }
    }

    this.selectedHandle = opt;
    this.setLock();
  }

  async setCylinder(opt: Option) {
    this.selectedCylinder = opt;
    this.setLock();
  }

  async setLock() {
    const locks = await this.productService.getSlotAsync(
      this.handleSlots.get(this.doorInstallationArea)!,
      ProductTypeEnum.Avk
    );
    const lock = locks.find((lock) => lock.productNumber === this.currentLockProduct);
    if (!lock) {
      this.toastService.displayDefaultError();
      return;
    }

    this.addSlot.emit({
      create: { productId: lock.id },
      slot: this.handleSlots.get(this.doorInstallationArea)!
    });
  }

  updateHandle() {
    const lock = this.item.set.find((s) => s.slot == this.handleSlots.get(this.doorInstallationArea)!);
    if (!lock) return;

    const lockOptions = this.REVERSE_LOCK_MAP[lock.productNumber];

    if (['1', '3', '6'].includes(lockOptions[0])) {
      this.cylinderOptions = this.cylinderOptionsLarge;
    } else {
      this.cylinderOptions = this.cylinderOptionsSmall;
    }

    this.selectedHandle = this.handleOptions.find((opt) => opt.value === lockOptions[0]) ?? this.selectedHandle;
    this.selectedCylinder = this.cylinderOptions.find((opt) => opt.value === lockOptions[1]) ?? this.selectedCylinder;
  }

  setSlot(productId: string, slot: number) {
    if (productId == '') {
      if (this.item.set.some((s) => s.slot == slot)) {
        this.removeSlot.emit(slot);
      }
    } else {
      this.addSlot.emit({
        create: { productId },
        slot
      });
    }
  }

  toggleSlot(prod: Product, selected: boolean, slot: number) {
    if (selected) {
      this.addSlot.emit({
        create: { productId: prod.id },
        slot
      });
    } else {
      this.removeSlot.emit(slot);
    }
  }

  //#region Door Type
  updateDoorType() {
    const doorProd = this.item.set.find((p) => p.slot === this.doorSlots.get(this.doorInstallationArea));
    if (!doorProd) {
      return;
    }

    const settings = this.reverseDoorMap[doorProd.productNumber];
    if (!settings) {
      return;
    }

    if (this.selectedType != settings[0]) {
      this.selectedType = settings[0];
    }
    if (this.selectedDoorSide != settings[1]) {
      this.selectedDoorSide = settings[1];
    }
  }

  getDoorProductNumber(side: Side, type: DoorType) {
    if (type == 'single') {
      return this.doorMap.single[side];
    } else {
      return this.doorMap[type];
    }
  }

  setDoorType(doorProducts: Product[], side: Side, type: DoorType) {
    const prod = doorProducts.find((p) => p.productNumber == this.getDoorProductNumber(side, type));
    if (!prod) {
      console.error('Door not found');
      return;
    }
    this.setSlot(prod.id, this.doorSlots.get(this.doorInstallationArea)!);
  }
  //#endregion

  //#region Schema
  setSchema(schemaProducts: Product[], side: Side, selectedProductId: string) {
    const slot = this.schemaSlots.get(this.doorInstallationArea)!;
    if (this.selectedType === 'single' || selectedProductId == '') {
      this.setSlot(selectedProductId, slot);
      return;
    }

    const selectedProduct = schemaProducts.find((p) => p.id === selectedProductId);
    if (!selectedProduct) {
      this.toastService.displayDefaultError();
      return;
    }

    const sideProduct = schemaProducts.find(
      (p) =>
        p.productNumber.startsWith(selectedProduct.productNumber) &&
        p.productNumber.endsWith(side === 'left' ? 'l' : 'r')
    );
    if (!sideProduct) {
      this.toastService.displayDefaultError();
      return;
    }

    this.setSlot(sideProduct.id, slot);
  }

  updateSchema() {
    const prod = this.item.set.find((p) => p.slot === this.schemaSlots.get(this.doorInstallationArea));
    if (!prod) {
      return;
    }

    if (prod.productNumber.endsWith('l'))
      this.selectedSchemaSideOption = this.sideOptions.find((o) => o.value == 'left')!;
    if (prod.productNumber.endsWith('r'))
      this.selectedSchemaSideOption = this.sideOptions.find((o) => o.value == 'right')!;
  }
  //#endregion

  //#region Lockable
  toggleLockable(prod: Product, selected: boolean) {
    this.toggleSlot(prod, selected, this.lockableSlots.get(this.doorInstallationArea)!);
  }

  updateLockable() {
    this.hasLockable = this.item.set.some(
      (s) => s.slot === this.lockableSlots.get(this.doorInstallationArea) && s.productNumber == 'avtfa'
    );
  }
  //#endregion

  //#region Erungskabel
  toggleGroundWire(prod: Product, selected: boolean) {
    this.toggleSlot(prod, selected, this.groundWireSlots.get(this.doorInstallationArea)!);
  }

  updateGroundWire() {
    this.hasGroundWire = this.item.set.some(
      (s) => s.slot === this.groundWireSlots.get(this.doorInstallationArea) && s.productNumber == 'avek'
    );
  }
  //#endregion

  //#region Klapptablar Funcs
  toggleKlapptablar(prod: Product, selected: boolean) {
    this.toggleSlot(prod, selected, this.klapptablarSlots.get(this.doorInstallationArea)!);
  }

  setKlapptablarSide(prod: Product, opt: Option) {
    this.addSlot.emit({
      create: { productId: prod.id, custom: opt.value },
      slot: this.klapptablarSlots.get(this.doorInstallationArea)!
    });
  }

  updateKlapptablar() {
    this.hasKlapptablar = this.item.set.some(
      (s) =>
        s.productNumber === AVK_CONFIG.DOOR.KLAPPTABLAR.NORMAL &&
        s.slot === this.klapptablarSlots.get(this.doorInstallationArea)!
    );

    if (
      this.item.set.some(
        (s) =>
          s.productNumber === AVK_CONFIG.DOOR.KLAPPTABLAR.LEFT &&
          s.slot === this.klapptablarSlots.get(this.doorInstallationArea)!
      )
    ) {
      this.hasKlapptablar = true;
      this.selectedSideOption = this.sideOptions[0];
    } else if (
      this.item.set.some(
        (s) =>
          s.productNumber === AVK_CONFIG.DOOR.KLAPPTABLAR.RIGHT &&
          s.slot === this.klapptablarSlots.get(this.doorInstallationArea)!
      )
    ) {
      this.hasKlapptablar = true;
      this.selectedSideOption = this.sideOptions[1];
    }
  }
  //#endregion

  widthForInstallationArea$ = this.orderService.order$.pipe(
    map((order) => {
      const lineItem = order?.lineItems.find((item) => item.isField);
      if (!lineItem) return 0;

      const compartmentMeta = lineItem.set.find(
        (item) => (item.slot == 18 || item.slot == 58) && item.custom == 'additional'
      );
      let compartmentWidth = 0;
      if (compartmentMeta) {
        compartmentWidth = lineItem.set.find((item) => item.slot == compartmentMeta.slot + 1)?.width ?? 0;
      }

      if (this.additionalCompartment) {
        return compartmentWidth;
      }

      switch (this.doorInstallationArea) {
        case 'back':
          return lineItem.width;
        case 'compartment-right':
          return (lineItem.width ?? 0) - compartmentWidth;
        case 'front-horizontal-left':
          return (lineItem.width ?? 0) - compartmentWidth;
        case 'front-horizontal-right':
          return (lineItem.width ?? 0) - compartmentWidth;
        case 'side-left':
          return lineItem.depth;
        case 'side-right':
          return lineItem.depth;
        default:
          return (lineItem.width ?? 0) - compartmentWidth;
      }
    })
  );
}
