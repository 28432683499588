import { Component, Input } from '@angular/core';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { BaseALSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { Observable, map } from 'rxjs';
import { ToastService } from '../../../../../services/toast.service';

@Component({
  selector: 'app-al-entry-top',
  templateUrl: './entry-top.component.html',
  styleUrls: ['./entry-top.component.scss']
})
export class EntryTopComponent extends BaseALSlotComponent {
  @Input()
  item!: ProductLineItem;

  readonly entryTopSlot = 15;
  entryTopProducts: Promise<Product[]> = this.productService.getSlotAsync(this.entryTopSlot, ProductTypeEnum.Al);
  entryTopOptions: Option[] = [];
  selectedEntryTop: Option = this.entryTopOptions[0];
  selectedMaterial!: Option;

  constructor(
    private readonly translate: TranslateService,
    private readonly productService: ProductService,
    private readonly notificationService: ToastService
  ) {
    super(translate);
  }

  override initItem(): void {
    this.initMaterials();
    this.selectedMaterial = this.materialOptions![0];
  }

  override updateItem(firstChange?: boolean | undefined): void {
    const entryTop = this.item.set.find((x) => x.slot === this.entryTopSlot);
    if (entryTop) {
      if (entryTop.custom && entryTop.custom !== '-1') {
        const [entryType, material] = entryTop.custom.split('.');
        this.initMaterials();
        this.selectedEntryTop = this.entryTopOptions.find((x) => x.value === entryType)!;
        this.selectedMaterial = this.materialOptions!.find((x) => x.value === material)!;
      } else {
        this.initMaterials();
        this.selectedEntryTop = this.entryTopOptions[0];
        this.selectedMaterial = this.materialOptions![0];
      }
    }
  }

  initMaterials() {
    this.entryTopOptions = [
      {
        value: 'og',
        label: this.translate.instant('Configuration.AL.Ceiling.Closed'),
        description: ''
      },
      {
        value: 'oes',
        label: this.translate.instant('Configuration.AL.Ceiling.Opening'),
        description: ''
      }
    ];

    this.materialOptions = [
      {
        value: '0',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.None')
      },
      {
        value: 'MGK40',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.MGKP_IP40'),
        meta: true
      },
      {
        value: 'KDP54',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.KDFP_IP54'),
        meta: true
      },
      {
        value: 'KP41',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.KSP_IP41')
      },
      {
        value: 'KP54',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.KSP_IP54')
      },
      {
        value: 'AP41',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.AP_IP41')
      },
      {
        value: 'AP54',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.AP_IP54')
      },
      {
        value: 'B',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.Brushes')
      }
    ];

    if (this.item?.depth == 150) {
      this.materialOptions = this.materialOptions.filter((m) => !m.meta);
    }
  }

  updateEntryType(option: Option) {
    this.selectedEntryTop = option;
    this.initMaterials();

    if (
      (this.selectedEntryTop?.value == 'C' || this.selectedEntryTop?.value == 'D') &&
      this.selectedMaterial.meta === true
    ) {
      this.selectedMaterial = this.materialOptions[0];
    }
    this.updateEntry();
  }

  updateMaterial(option: Option) {
    this.selectedMaterial = option;
    this.updateEntry();
  }

  async updateEntry() {
    if (this.selectedEntryTop.value == 'og') {
      const prod = (await this.entryTopProducts).find((prod) => prod.productNumber == 'og');
      if (!prod) {
        this.notificationService.displayDefaultError();
        return;
      }
      this.addSlot.emit({
        slot: this.entryTopSlot,
        create: {
          productId: prod!.id,
          custom: '-1'
        }
      });
    } else {
      const prod = (await this.entryTopProducts).find((prod) => prod.productNumber == 'oes');
      if (!prod) {
        this.notificationService.displayDefaultError();
        return;
      }
      this.addSlot.emit({
        slot: this.entryTopSlot,
        create: {
          productId: prod!.id,
          custom: `${this.selectedEntryTop.value}.${this.selectedMaterial.value}`
        }
      });
    }
  }
}
