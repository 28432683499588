import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge, [badge]',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {
  @Input()
  label: string | number = '';

  @Input()
  type: 'success' | 'info' | 'danger' | 'price' | 'percent-price' = 'info';

  @Input()
  hasPromo = false;

  constructor() {}

  ngOnInit(): void {}
}
