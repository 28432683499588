import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  class: string = '';
  iconClass: string = '';

  @Input()
  label: string = '';

  @Input()
  size: 'xs' | 'base' | 'xl' = 'base';

  @Input()
  icon: string = '';

  @Input()
  isIconRotated: boolean = false;

  @Input()
  onlyIcon: boolean = false;

  @Input()
  type:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'light'
    | 'ghost'
    | 'socket'
    | 'wall'
    | 'outline-primary'
    | 'outline-secondary' = 'primary';

  @Input()
  btnType: 'button' | 'submit' = 'button';

  @Input()
  fullWidth: boolean = false;

  @Input()
  isDisabled: boolean = false;

  @Input()
  customContent: boolean = false;

  @Input()
  loading: boolean = false;

  @Output()
  onClick: EventEmitter<boolean> = new EventEmitter();

  click() {
    if (!this.isDisabled) {
      this.onClick.emit(true);
    }
  }

  constructor() {}
}
