import { Pipe, PipeTransform } from '@angular/core';
import { ProductLineItem } from '../api-client';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(array: Array<ProductLineItem>): Array<ProductLineItem> {
    return array.sort((a, b) => {
      return a.sorting - b.sorting;
    });
  }

}
