<nav class="flex items-center justify-between" aria-label="Progress">
    <p class="text-sm font-medium">
        {{ steps[active]|translate }}
        <span class="block text-xs text-gray-500">{{'Auth.Register.Steps.Step'|translate:({step: active+1, steps: steps.length})}}</span>
    </p>

    <ol role="list" class="flex items-center ml-8 space-x-5">

        <li *ngFor="let step of steps;let i = index;">
            <!-- done -->
            <button href="#" class="block w-2.5 h-2.5 bg-monza-500 rounded-full hover:bg-monza-600" *ngIf="active > i" (click)="stepEvent(i)">
                <span class="sr-only">Schritt {{ i }} - {{ step }}</span>
            </button>
            <!-- active -->
            <div class="relative flex items-center justify-center" aria-current="step" *ngIf="active === i">
                <span class="absolute flex w-5 h-5 p-px" aria-hidden="true">
                    <span class="w-full h-full rounded-full bg-monza-500 bg-opacity-20"></span>
                  </span>
                  <span class="relative block w-2.5 h-2.5 bg-monza-500 rounded-full" aria-hidden="true"></span>
                  <span class="sr-only">Schritt {{ i }} - {{ step }}</span>
                </div>
            <!-- future -->
            <div class="block w-2.5 h-2.5 bg-gray-200 rounded-full" *ngIf="active < i"></div>
        </li>
  
    </ol>
</nav>