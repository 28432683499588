import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Order, Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { Side } from '../door/door.component';
import { BaseAVKSlotComponent } from '../base/base-slot.component';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map, tap } from 'rxjs';
import { OrderService } from '../../../../../services/order.service';
import { ToastService } from '../../../../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-avk-front-vertical',
  templateUrl: './front-vertical.component.html',
  styleUrls: ['./front-vertical.component.scss']
})
export class FrontVerticalComponent extends BaseAVKSlotComponent {
  @Input()
  item!: ProductLineItem;

  @Input()
  order!: Order;

  @Input()
  show = false;

  @Input()
  otherSide: Side = 'right';

  @Output()
  otherSideChange = new EventEmitter<Side>();

  compartmentMetaSlots: Map<Side, number> = new Map([
    ['left', 18],
    ['right', 58]
  ]);
  get currentMetaSlot() {
    return this.compartmentMetaSlots.get(this.selectedSide.value as Side)!;
  }
  get currentCompartmentSlot() {
    return this.currentMetaSlot == 18 ? 19 : 59;
  }
  get currentDoorSlot() {
    return this.currentMetaSlot == 18 ? 20 : 60;
  }
  get currentDividerSlot() {
    return this.currentMetaSlot == 18 ? 35 : 75;
  }

  metaProducts$: Observable<Product[]> | undefined = undefined;
  compartmentProduct: ProductLineItem | undefined = undefined;

  dividerProducts$: Observable<Product[]> | undefined = undefined;
  dividerOptions$: Observable<Option[]> | undefined = undefined;
  selectedDivider: Option | undefined = undefined;

  // DUMMY DATA
  hasDoor = false;

  sideOptions: Option[] = [
    {
      description: '',
      label: this.translate.instant('Configuration.AVK.Sides.Left'),
      value: 'left'
    },
    {
      description: '',
      label: this.translate.instant('Configuration.AVK.Sides.Right'),
      value: 'right'
    }
  ];
  selectedSide = this.sideOptions[1];

  constructor(
    private readonly productService: ProductService,
    private readonly orderService: OrderService,
    private readonly translate: TranslateService,
    private readonly toastService: ToastService
  ) {
    super(translate);
  }

  override initItem() {
    this.metaProducts$ = this.productService.getSlot(this.currentMetaSlot, ProductTypeEnum.Avk);
    this.dividerProducts$ = this.productService.getSlot(this.currentDividerSlot, ProductTypeEnum.Avk).pipe(
      map((p) => (p.length ? [p[0]] : [])),
      map((p) =>
        p.map((p) => {
          p.additionalDescription = '';
          p.additionalDescriptionFr = '';
          return p;
        })
      )
    );
    this.dividerOptions$ = this.dividerProducts$.pipe(
      this.productToOptionsPipe(this.currentDividerSlot, this.translate.instant('Configuration.AVK.Dropdowns.None')),
      map((res) => {
        this.selectedDivider = res.selected;
        return res.res;
      })
    );
  }

  override updateItem(firstChange?: boolean): void {
    this.updateHasCompartment();
    this.updateHasDoor();
    if (!firstChange) this.initItem();
  }

  setDivider(productId: string) {
    if (productId == '') {
      this.removeSlot.emit(this.currentDividerSlot);
    } else {
      this.addSlot.emit({ create: { productId }, slot: this.currentDividerSlot });
    }
  }

  updateWidth(width: number) {
    this.updateSlot.emit({ update: { width }, slot: this.currentCompartmentSlot });
  }

  changeSide(side: Option) {
    if (side.value !== this.selectedSide.value) {
      this.switchCompartments();
    }

    this.selectedSide = side;
    this.otherSide = side.value == 'left' ? 'right' : 'left';
    this.otherSideChange.emit(this.otherSide);
  }

  switchCompartments() {
    this.orderService.switchCompartments(this.order.id);
  }

  updateHasDoor() {
    const doorSlot = this.currentDoorSlot;
    this.hasDoor = this.item.set.some((p) => p.slot === doorSlot);
  }

  async addDoor() {
    const slot = this.currentDoorSlot;
    const prods = await this.productService.getSlotAsync(slot, ProductTypeEnum.Avk);
    if (!prods) {
      this.toastService.displayDefaultError();
      return;
    }
    const defaultDoor = prods.find((p) => p.productNumber == 'avtl');
    if (!defaultDoor) {
      this.toastService.displayDefaultError();
      return;
    }

    this.addSlot.emit({
      create: { productId: defaultDoor.id },
      slot
    });
  }

  async removeDoor() {
    const slot = this.currentDoorSlot;
    this.removeSlot.emit(slot);
  }

  toggleCompartment(compartmentProducts: Product[]) {
    if (this.compartmentProduct) {
      this.removeSlot.emit(this.currentMetaSlot);
    } else {
      const currentProduct = compartmentProducts.find((p) => p.slots[0].slot == this.currentMetaSlot);
      if (!currentProduct) return;

      this.addSlot.emit({
        create: { productId: currentProduct.id, custom: 'additional' },
        slot: this.currentMetaSlot
      });
    }
  }

  updateHasCompartment() {
    this.compartmentProduct = this.item.set.find((p) => p.slot === 19 || p.slot === 59);
    if (this.compartmentProduct) {
      const compartmentProd = this.item.set.find((p) => p.custom === 'additional');
      if (!compartmentProd) return;

      this.selectedSide = {
        description: '',
        label: compartmentProd.slot == 18 ? 'Links' : 'Rechts',
        value: compartmentProd.slot == 18 ? 'left' : 'right'
      };
      this.otherSide = this.selectedSide.value == 'left' ? 'right' : 'left';
      this.otherSideChange.emit(this.otherSide);
    }
  }
}
