<div class="sm:grid flex grid-cols-4 items-start justify-start col-span-6 gap-4">
  <div class="flex flex-col gap-4" *ngIf="assemblyPlate$ | async as assemblyPlate; else mdLoading">
    <ng-container *ngIf="assemblyPlateMovable$ | async as assemblyPlateMovable; else mdLoading">
      <app-checkbox
        [label]="'Configuration.AVK.Indoor.AssemblyPlate' | translate"
        [checked]="hasAssemblyPlate"
        (checkedChange)="
          hasAssemblyPlate = $event; toggleAssembyPlate(isMoveable ? assemblyPlateMovable : assemblyPlate, $event)
        "
      ></app-checkbox>
      <app-checkbox
        *ngIf="hasAssemblyPlate"
        [label]="'Configuration.AVK.Indoor.Movable' | translate"
        [checked]="isMoveable"
        (checkedChange)="
          isMoveable = $event; toggleAssembyPlate($event ? assemblyPlateMovable : assemblyPlate, hasAssemblyPlate)
        "
      ></app-checkbox
    ></ng-container>
  </div>
  <div class="flex flex-col gap-4">
    <app-checkbox
      *ngIf="tablarProduct$ | async as tablarProduct; else mdLoading"
      [label]="'Configuration.AVK.Indoor.Tablar' | translate"
      [checked]="hasTablar"
      (checkedChange)="hasTablar = $event; toggleTablar(tablarProduct, $event)"
    ></app-checkbox>
    <app-input
      *ngIf="hasTablar"
      unit="mm"
      [label]="'Abstand von unten'"
      [value]="tablarDistance"
      (valueChange)="setTablarDistance($event)"
    ></app-input>
  </div>
</div>

<ng-template #lgLoading>
  <app-skeleton size="lg"></app-skeleton>
</ng-template>
<ng-template #mdLoading>
  <app-skeleton size="md" class="col-span-3"></app-skeleton>
</ng-template>
<ng-template #xsLoading>
  <app-skeleton size="xs"></app-skeleton>
</ng-template>