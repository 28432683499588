<app-modal
  [submitLabel]="'Configuration.Fields.SaveAddons' | translate"
  *ngIf="modalOpen"
  (onClose)="toggleAttachmentModal()"
  (onSubmit)="submitAttachmentModal()"
>
<div class="text-lg font-medium">{{ 'Configuration.Fields.ChooseAddons' | translate }}</div>
<div class="divide-y divide-neutral-200 w-96 max-w-full">
  <div
    class="flex justify-start items-center py-4"
    *ngFor="let a of attachmentProducts$ | async"
    matTooltipPosition="above"
  >
    <div class="flex items-center mr-4">
      <app-input
        [(value)]="amounts[a.id]"
        class="w-16 mr-4"
        unit="×"
      >
      </app-input>
    </div>
    <div class="cursor-pointer">
      <span class="self-center font-weight-500 block leading-none">{{ a | concatDescription : translate.currentLang }}</span>
      <span class="text-sm font-medium leading-none text-monza-500 font-mono">
        CHF {{ a.basePrice | currency : ' ' : 'symbol' : '1.2-2' }}
      </span>
    </div>
  </div>
</div>
</app-modal>

<app-button
  *ngIf="order.status !== 'Ordered'"
  icon="variables"
  [label]="'Configuration.Fields.ChooseAddons' | translate"
  type="light"
  (onClick)="toggleAttachmentModal()"
  [fullWidth]="true"
></app-button>
