import { ChangeDetectorRef, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { Order, OrderStatusEnum, Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { BaseALSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map } from 'rxjs';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { colors } from '../../../colors';

@Component({
  selector: 'app-al-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.scss']
})
export class ColorComponent extends BaseALSlotComponent {
  @Input()
  item!: ProductLineItem;

  @Input()
  order!: Order;

  ralError = '';

  productRalSlot = 35;

  OrderStatusEnum = OrderStatusEnum;
  colorPickerOpen = false;

  withBack = false;

  withBackProduct$!: Observable<Product>;
  ral7035$!: Observable<Product>;

  readonly RAL_COLORS = colors;
  selectedColor: {
    code: string;
    rgb_approx: string;
    rgb_hex: string;
    name: string;
  } = this.RAL_COLORS.find((c) => c.code == '7035')!;

  @ViewChild('elem') elem!: ElementRef;
  @ViewChild('elem2') elem2!: ElementRef;
  constructor(
    private readonly translate: TranslateService,
    private readonly productService: ProductService,
    private readonly cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    super(translate);
    /**
     * This events get called by all clicks on the page
     */
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.elem || !this.elem2) return;
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (!this.elem.nativeElement.contains(e.target) && !this.elem2.nativeElement.contains(e.target)) {
        this.colorPickerOpen = false;
        this.cdr.detectChanges();
      }
    });
  }

  override initItem(): void {
    const options = this.productService.getSlot(this.productRalSlot, ProductTypeEnum.Al);

    this.ral7035$ = options.pipe(map((s) => s.filter((slot) => slot.productNumber == '7035')[0]));
    this.withBackProduct$ = options.pipe(map((s) => s.filter((slot) => slot.productNumber == '7035irw')[0]));
  }

  override updateItem(firstChange?: boolean | undefined): void {
    const slot = this.item.set.find((s) => s.slot == this.productRalSlot);
    if (slot) {
      if (slot.custom) {
        const color = this.RAL_COLORS.find((c) => c.code == slot.custom);
        if (color) {
          this.selectedColor = color;
        }
      }
      if (slot.productNumber === '7035irw') {
        this.withBack = true;
      }
    }
  }

  updateColor(code: string): void {
    const color = this.RAL_COLORS.find((c) => c.code == code);

    if (color) {
      this.selectedColor = color;
      this.updateSlotItem(this.productRalSlot, { custom: color.code });
    } else {
      this.ralError = `RAL-${code} konnte nicht gefunden werden`;
    }
  }

  toggleWithBack(withBack: boolean, withBackProduct: Product, ralColor: Product): void {
    if (withBack) {
      this.addSlot.emit({
        slot: this.productRalSlot,
        create: {
          productId: withBackProduct.id,
          custom: this.selectedColor.code
        }
      });
    } else {
      this.addSlot.emit({
        slot: this.productRalSlot,
        create: {
          productId: ralColor.id,
          custom: this.selectedColor.code
        }
      });
    }
  }
}
