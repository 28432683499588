import { Injectable } from '@angular/core';
import {
  NgcCookieConsentService,
  NgcInitializationErrorEvent,
  NgcInitializingEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import * as Sentry from '@sentry/browser';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  hasConsented = false;

  constructor(private ccService: NgcCookieConsentService, private authService: AuthService) {
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
      // console.log(`popupOpen`);
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
      // console.log(`popupClose`);
    });

    this.initializingSubscription = this.ccService.initializing$.subscribe((event: NgcInitializingEvent) => {
      // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
      // console.log(`initializing: ${JSON.stringify(event)}`);
    });

    this.initializedSubscription = this.ccService.initialized$.subscribe(() => {
      // the cookieconsent has been successfully initialized.
      // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
      // console.log(`initialized: ${JSON.stringify(event)}`);
    });

    this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
      (event: NgcInitializationErrorEvent) => {
        // the cookieconsent has failed to initialize...
        // console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
      // you can use this.ccService.getConfig() to do stuff...
      // console.log(`statusChange: ${JSON.stringify(event)}`);
      this.hasConsented = event.status === 'allow';

      this.setSentryUser();
    });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
      this.hasConsented = false;
    });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
      // you can use this.ccService.getConfig() to do stuff...
      // console.log(`noCookieLaw`, event);
      this.hasConsented = true;
      this.setSentryUser();
    });

    this.ccService.hasConsented();
    this.setSentryUser();
    this.authService.user$.subscribe(() => {
      this.setSentryUser();
    });
  }

  setSentryUser() {
    if (this.hasConsented && this.authService.currentUser) {
      Sentry.setUser({ email: this.authService.currentUser.email });
    }
  }
}
