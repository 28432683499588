import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AddALSlotDTO, UpdateALSlotDTO } from '../../al.component';
import { Product, ProductLineItem, ProductLineItemSideEnum, UpdateProductLineItemDto } from '../../../../../api-client';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({ template: '' })
export abstract class BaseALSlotComponent implements OnChanges, OnInit, OnDestroy {
  abstract item: ProductLineItem;

  @Output()
  addSlot = new EventEmitter<AddALSlotDTO>();

  @Output()
  updateSlot = new EventEmitter<UpdateALSlotDTO>();

  @Output()
  removeSlot = new EventEmitter<number>();

  doubleDoorProductIds = ['tgrv', 'tglv', 'dtasvtl', 'dtasvtr', 'dtv', 'dtasv'];
  singleDoorProductIds = ['trv', 'tlv', 'tlrv', 'taslv', 'tasrv'];
  isDoubleDoor = false;
  materialOptions!: Option[];

  sideOptions: Option<ProductLineItemSideEnum>[] = [
    {
      value: 'Left',
      description: '',
      meta: '',
      label: this.translateService.instant('Configuration.Fields.Sides.Left')
    },
    {
      value: 'Right',
      description: '',
      meta: '',
      label: this.translateService.instant('Configuration.Fields.Sides.Right')
    }
  ];

  langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
    this.initItem();
    this.updateItem();
  });

  constructor(protected readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.initItem();
  }

  ngOnDestroy(): void {
    this.langChangeSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['item']) {
      if (this.item && this.item.width) {
        this.isDoubleDoor =
          this.item.width > 1000 || this.item.set.some((s) => this.doubleDoorProductIds.includes(s.productNumber));
      }
      this.updateItem(changes['item'].firstChange);
    }
  }

  addSlotItem(slot: number, option: Option, update?: UpdateProductLineItemDto) {
    if (option.value == '') {
      this.removeSlot.emit(slot);
    } else {
      this.addSlot.emit({ slot: slot, create: { productId: option.value, ...update } });
    }
  }

  toggleSlotItem(slot: number, productId: string, checked: boolean) {
    if (!checked) {
      this.removeSlot.emit(slot);
    } else {
      this.addSlot.emit({ slot: slot, create: { productId } });
    }
  }

  updateSlotItem(slot: number, updateDto: UpdateProductLineItemDto) {
    this.updateSlot.emit({
      slot,
      update: updateDto
    });
  }

  abstract initItem(): void;

  abstract updateItem(firstChange?: boolean): void;

  productToOptionsPipe(slotNr: number, startingElem: string | null = '-') {
    let selected: Option | undefined = undefined;
    return map((s: Product[]) => {
      s.sort((a, b) => a.sorting - b.sorting);
      const res = s.map((slot) => {
        const opt = this.productSlotToOption(slot);
        if (slot.id == this.item.set.find((s) => s.slot == slotNr)?.productId) selected = opt;
        return opt;
      });
      if (startingElem != null)
        res.unshift({
          value: '',
          description: '',
          label: startingElem
        });
      if (!selected) selected = res[0];
      return { selected, res };
    });
  }

  productSlotToOption(prod: Product): Option {
    const description =
      this.translateService.currentLang === undefined || this.translateService.currentLang !== 'fr'
        ? prod.description
        : prod.descriptionFr;
    const additionalDescription =
      this.translateService.currentLang === undefined || this.translateService.currentLang !== 'fr'
        ? prod.additionalDescription
        : prod.additionalDescriptionFr;

    let description1 = description.trim().replace(/-$/g, '');
    const description2 = additionalDescription;

    if (
      !description.endsWith(' ') &&
      !description.endsWith('-') &&
      !description.endsWith('- ') &&
      !description2.startsWith(' ')
    ) {
      description1 = description1 + ' ';
    }

    const translated = this.translateService.getStreamOnTranslationChange('none').pipe(
      map((v) => {
        const description =
          this.translateService.currentLang === undefined || this.translateService.currentLang !== 'fr'
            ? prod.description
            : prod.descriptionFr;
        const additionalDescription =
          this.translateService.currentLang === undefined || this.translateService.currentLang !== 'fr'
            ? prod.additionalDescription
            : prod.additionalDescriptionFr;

        let description1 = description.trim().replace(/-$/g, '');
        const description2 = additionalDescription;

        if (
          !description.endsWith(' ') &&
          !description.endsWith('-') &&
          !description.endsWith('- ') &&
          !description2.startsWith(' ')
        ) {
          description1 = description1 + ' ';
        }

        return `${description1}${description2}`;
      })
    );

    return {
      value: prod.id,
      description: '',
      label: `${description1}${description2}`,
      translated,
      meta: prod.productNumber
    };
  }
}
