import { Component, Input } from '@angular/core';
import { Order, Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map } from 'rxjs';
import { BaseSWGSlotComponent } from '../base/base-slot.component';

@Component({
  selector: 'app-swg-accesories',
  templateUrl: './accesories.component.html',
  styleUrls: ['./accesories.component.scss']
})
export class SwgAccesoriesComponent extends BaseSWGSlotComponent {
  @Input()
  item!: ProductLineItem;

  @Input()
  order!: Order;

  assemblyPlateSlot = 30;
  assemblyPlateProduct$: Observable<Product> = this.productService
    .getSlot(this.assemblyPlateSlot, ProductTypeEnum.Swg)
    .pipe(map((prod) => prod[0]));
  selectedAssemblyPlate: boolean = false;

  entrySlot = 7;
  entryProduct$: Observable<Product> = this.productService
    .getSlot(this.entrySlot, ProductTypeEnum.Swg)
    .pipe(map((prod) => prod[0]));
  selectedEntry = false;

  wallAttachSlot = 10;
  wallAttachProduct$: Observable<Product> = this.productService
    .getSlot(this.wallAttachSlot, ProductTypeEnum.Swg)
    .pipe(map((prod) => prod[0]));
  selectedWallAttach = false;

  grateFasteningSlot = 13;
  grateFasteningProduct$: Observable<Product> = this.productService
    .getSlot(this.grateFasteningSlot, ProductTypeEnum.Swg)
    .pipe(map((prod) => prod[0]));
  selectedGrateFastening = false;

  // only for frontend views
  isEntryValid = true;
  validEntryDimensions: any = {
    300: [210],
    400: [210, 260, 300],
    500: [210, 260, 300],
    600: [210, 260, 300, 400],
    800: [210, 260, 300, 400],
    1000: [300, 400],
    1200: [300, 400]
  };

  isWallAttachValid = true;

  isGrateFasteningValid = true;
  validGrateFasteningDepths: number[] = [210, 260, 300];

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  override initItem(): void {}

  override updateItem(firstChange?: boolean | undefined): void {
    // Gets the door products from api
    const addons = this.order.lineItems.filter((l) => l.addedFrom?.includes(this.item.id));

    if (this.item.set.some((s) => s.slot === this.assemblyPlateSlot && s.productNumber !== 'swmpo')) {
      this.selectedAssemblyPlate = true;
    }

    if (addons.some((a) => a.slot === this.entrySlot)) {
      this.selectedEntry = true;
    } else {
      this.selectedEntry = false;
    }

    if (addons.some((a) => a.slot === this.wallAttachSlot)) {
      this.selectedWallAttach = true;
    } else {
      this.selectedWallAttach = false;
    }

    if (addons.some((a) => a.slot === this.grateFasteningSlot)) {
      this.selectedGrateFastening = true;
    } else {
      this.selectedGrateFastening = false;
    }

    if (this.order.lineItems.some((li) => li.addedFrom?.includes(this.item.id) && li.slot === 9)) {
      this.isWallAttachValid = false;
    }

    // Check if the selected entry is valid
    if (!this.item.width || !this.item.depth) return;
    this.isEntryValid = this.validEntryDimensions[this.item.width]?.includes(this.item.depth);
    this.isGrateFasteningValid = this.validGrateFasteningDepths.includes(this.item.depth);
  }

  toggleEntry(id: string) {
    if (this.selectedEntry) {
      this.addAddon.emit({
        slot: this.entrySlot,
        create: { productId: id }
      });
    } else {
      this.removeAddon.emit(this.entrySlot);
    }
  }

  toggleWallAttach(id: string) {
    if (this.selectedWallAttach) {
      this.addAddon.emit({
        slot: this.wallAttachSlot,
        create: { productId: id }
      });
    } else {
      this.removeAddon.emit(this.wallAttachSlot);
    }
  }

  toggleGrateFastening(id: string) {
    if (this.selectedGrateFastening) {
      this.addAddon.emit({
        slot: this.grateFasteningSlot,
        create: { productId: id }
      });
    } else {
      this.removeAddon.emit(this.grateFasteningSlot);
    }
  }

  toggleAssemblyPlate(id: string): void {
    if (this.selectedAssemblyPlate) {
      this.addSlot.emit({
        slot: this.assemblyPlateSlot,
        create: { productId: id }
      });
    } else {
      this.removeSlot.emit(this.assemblyPlateSlot);
    }
  }
}
