<div class="grid grid-cols-6 items-center justify-start col-span-6 gap-4">
  <app-custom-select
    *ngIf="mountingPlateOptions$ | async as mountingPlateOptions"
    class="col-span-6"
    label="Leuchten"
    [label]="'Configuration.Fields.OpenField.MountingPlate'|translate"
    [options]="mountingPlateOptions"
    [selectedValue]="selectedMountingPlate!"
    (cchange)="addSlotItem(mountingPlateSlot, $event)"
  ></app-custom-select>
</div>
