import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'labelColor'
})
export class LabelColorPipe implements PipeTransform {
  transform(colorString?: string): string {
    if (!colorString) return '#fff';
    switch (colorString) {
      case 'Black':
        return '#000';
      case 'White':
        return '#fff';
      case 'Red':
        return '#dd002e';
      case 'Blue':
        return '#3b82f6';
      case 'Yellow':
        return '#eab308';
      default:
        return '#fff';
    }
  }
}
