import { Component, Input, OnInit } from '@angular/core';
import { Order, OrderStatusEnum, Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map, tap } from 'rxjs';
import { Option, ReadonlyReason } from '../../../../../atoms/custom-select/custom-select.component';
import { BaseSWGSlotComponent } from '../base/base-slot.component';
import { OrderService } from '../../../../../services/order.service';

function onlyUniqueSlots(value: Product, index: any, array: Product[]) {
  return array.findIndex((v) => v.slots[0].slot === value.slots[0].slot) === index;
}

@Component({
  selector: 'app-swg-additional',
  templateUrl: './additional.component.html',
  styleUrls: ['./additional.component.scss']
})
export class SwgAdditionalComponent extends BaseSWGSlotComponent {
  @Input()
  item!: ProductLineItem;

  @Input()
  order!: Order;

  selectedAddon1: Option | undefined = undefined;
  selectedAddon2: Option | undefined = undefined;

  additionalSlots = [8, 9, 11, 12, 14, 22, 16, 17];

  validWidthsSlot11: number[] = [300, 400, 500, 600];
  validHeightsSlot12: number[] = [700, 800, 1000, 1200];
  validHeightsSlot14: number[] = [400, 500, 600, 800, 1000, 1200];
  validWidthsSlot17: number[] = [400, 500, 600, 800, 1000, 1200];

  additionalProducts$!: Observable<Option[]>;

  constructor(
    private readonly translate: TranslateService,
    private readonly productService: ProductService,
    private readonly orderService: OrderService
  ) {
    super(translate);
  }

  override initItem(): void {}

  override updateItem(firstChange?: boolean | undefined): void {
    // Gets the door products from api
    console.log('Getting additional products');

    this.additionalProducts$ = this.productService
      .getSlots(
        this.additionalSlots.map((n) => n.toString()),
        ProductTypeEnum.Swg
      )
      .pipe(
        map((prod) => prod.filter((p) => this.additionalSlots.includes(p.slots[0].slot))),
        map((prod) =>
          prod.filter((p) => {
            console.log(p.slots[0].slot, p.name, p.productNumber);

            switch (p.slots[0].slot) {
              case 11:
                return p.productNumber.includes(this.item.width!.toString());
              case 12:
                return p.productNumber.includes(this.item.height!.toString());
              case 14:
                return p.productNumber.includes(this.item.height!.toString());
              case 17:
                return p.productNumber.includes((this.item.width! / 10).toString());
              case 22:
                return p.productNumber.includes(this.item.depth!.toString());

              default:
                console.log(p.slots[0].slot, p.name, p.productNumber);
                return true;
            }
          })
        ),
        this.productToOptionsPipe(undefined, true),
        map((res) => res.res.sort((a, b) => a.meta.slot - b.meta.slot)),
        map((res) => {
          const addons = this.order.lineItems.filter(
            (li) => this.additionalSlots.includes(li.slot) && li.addedFrom?.includes(this.item.id)
          );
          console.log(res);

          const addon1 = addons.find((o) => o.custom === '1');
          const addon2 = addons.find((o) => o.custom === '2');

          if (addon1) {
            this.selectedAddon1 = res.find((o) => o.meta?.slot === addon1.slot);
          } else {
            this.selectedAddon1 = res[0];
          }

          if (addon2) {
            this.selectedAddon2 = res.find((o) => o.meta?.slot === addon2.slot);
          } else {
            this.selectedAddon2 = res[0];
          }

          res = res.map((option) => {
            if (
              (option.value == this.selectedAddon1?.value && this.selectedAddon1.value != '') ||
              (option.value == this.selectedAddon2?.value && this.selectedAddon2.value != '')
            ) {
              option.readonly = true;
              option.readonlyReason = ReadonlyReason.AlreadyAdded;
            } else {
              option.readonly = false;
              option.readonlyReason = undefined;
            }
            if (option.meta?.slot === 11 && !this.validWidthsSlot11.includes(this.item.width!)) {
              option.readonly = true;
              option.readonlyReason = ReadonlyReason.NotCompatible;
            }
            if (option.meta?.slot === 12 && !this.validHeightsSlot12.includes(this.item.height!)) {
              option.readonly = true;
              option.readonlyReason = ReadonlyReason.NotCompatible;
            }
            if (option.meta?.slot === 14 && !this.validHeightsSlot14.includes(this.item.height!)) {
              option.readonly = true;
              option.readonlyReason = ReadonlyReason.NotCompatible;
            }
            if (option.meta?.slot === 17 && !this.validWidthsSlot17.includes(this.item.width!)) {
              option.readonly = true;
              option.readonlyReason = ReadonlyReason.NotCompatible;
            }
            if (option.meta?.slot === 12 && !this.order.lineItems.some((li) => li.slot === 13)) {
              option.readonly = true;
              option.readonlyReason = ReadonlyReason.NeedsSlot13;
            }
            return option;
          });

          return res;
        })
      );
  }

  async onAddAddon(option: Option, additionalSlot: number, previousSlot: number | undefined) {
    if (previousSlot) this.orderService.removeAddonProduct(this.item.id, previousSlot);
    if (!option.meta) return;
    this.orderService.addAddonProduct(this.item.id, { productId: option.value, custom: additionalSlot.toString() });
  }

  onRemoveAddon(slot?: number): void {
    if (!slot) return;
    this.removeAddon.emit(slot);
  }
}
