export const colors = [
  {
    "code": "1000",
    "rgb_approx": "214-199-148",
    "rgb_hex": "#BEBD7F",
    "name": "Green beige"
  },
  {
    "code": "1001",
    "rgb_approx": "217-186-140",
    "rgb_hex": "#C2B078",
    "name": "Beige"
  },
  {
    "code": "1002",
    "rgb_approx": "198-166-100",
    "rgb_hex": "#C6A664",
    "name": "Sand yellow"
  },
  {
    "code": "1003",
    "rgb_approx": "229-190-001",
    "rgb_hex": "#E5BE01",
    "name": "Signal yellow"
  },
  {
    "code": "1004",
    "rgb_approx": "205-164-052",
    "rgb_hex": "#CDA434",
    "name": "Golden yellow"
  },
  {
    "code": "1005",
    "rgb_approx": "169-131-007",
    "rgb_hex": "#A98307",
    "name": "Honey yellow"
  },
  {
    "code": "1006",
    "rgb_approx": "228-160-016",
    "rgb_hex": "#E4A010",
    "name": "Maize yellow"
  },
  {
    "code": "1007",
    "rgb_approx": "220-156-000",
    "rgb_hex": "#DC9D00",
    "name": "Daffodil yellow"
  },
  {
    "code": "1011",
    "rgb_approx": "138-102-066",
    "rgb_hex": "#8A6642",
    "name": "Brown beige"
  },
  {
    "code": "1012",
    "rgb_approx": "199-180-070",
    "rgb_hex": "#C7B446",
    "name": "Lemon yellow"
  },
  {
    "code": "1013",
    "rgb_approx": "234-230-202",
    "rgb_hex": "#EAE6CA",
    "name": "Oyster white"
  },
  {
    "code": "1014",
    "rgb_approx": "225-204-079",
    "rgb_hex": "#E1CC4F",
    "name": "Ivory"
  },
  {
    "code": "1015",
    "rgb_approx": "230-214-144",
    "rgb_hex": "#E6D690",
    "name": "Light ivory"
  },
  {
    "code": "1016",
    "rgb_approx": "237-255-033",
    "rgb_hex": "#EDFF21",
    "name": "Sulfur yellow"
  },
  {
    "code": "1017",
    "rgb_approx": "245-208-051",
    "rgb_hex": "#F5D033",
    "name": "Saffron yellow"
  },
  {
    "code": "1018",
    "rgb_approx": "248-243-053",
    "rgb_hex": "#F8F32B",
    "name": "Zinc yellow"
  },
  {
    "code": "1019",
    "rgb_approx": "158-151-100",
    "rgb_hex": "#9E9764",
    "name": "Grey beige"
  },
  {
    "code": "1020",
    "rgb_approx": "153-153-080",
    "rgb_hex": "#999950",
    "name": "Olive yellow"
  },
  {
    "code": "1021",
    "rgb_approx": "243-218-011",
    "rgb_hex": "#F3DA0B",
    "name": "Rape yellow"
  },
  {
    "code": "1023",
    "rgb_approx": "250-210-001",
    "rgb_hex": "#FAD201",
    "name": "Traffic yellow"
  },
  {
    "code": "1024",
    "rgb_approx": "174-160-075",
    "rgb_hex": "#AEA04B",
    "name": "Ochre yellow"
  },
  {
    "code": "1026",
    "rgb_approx": "255-255-000",
    "rgb_hex": "#FFFF00",
    "name": "Luminous yellow"
  },
  {
    "code": "1027",
    "rgb_approx": "157-145-001",
    "rgb_hex": "#9D9101",
    "name": "Curry"
  },
  {
    "code": "1028",
    "rgb_approx": "244-169-000",
    "rgb_hex": "#F4A900",
    "name": "Melon yellow"
  },
  {
    "code": "1032",
    "rgb_approx": "214-174-001",
    "rgb_hex": "#D6AE01",
    "name": "Broom yellow"
  },
  {
    "code": "1033",
    "rgb_approx": "243-165-005",
    "rgb_hex": "#F3A505",
    "name": "Dahlia yellow"
  },
  {
    "code": "1034",
    "rgb_approx": "239-169-074",
    "rgb_hex": "#EFA94A",
    "name": "Pastel yellow"
  },
  {
    "code": "1035",
    "rgb_approx": "106-093-077",
    "rgb_hex": "#6A5D4D",
    "name": "Pearl beige"
  },
  {
    "code": "1036",
    "rgb_approx": "112-083-053",
    "rgb_hex": "#705335",
    "name": "Pearl gold"
  },
  {
    "code": "1037",
    "rgb_approx": "243-159-024",
    "rgb_hex": "#F39F18",
    "name": "Sun yellow"
  },
  {
    "code": "2000",
    "rgb_approx": "237-118-014",
    "rgb_hex": "#ED760E",
    "name": "Yellow orange"
  },
  {
    "code": "2001",
    "rgb_approx": "201-060-032",
    "rgb_hex": "#C93C20",
    "name": "Red orange"
  },
  {
    "code": "2002",
    "rgb_approx": "203-040-033",
    "rgb_hex": "#CB2821",
    "name": "Vermilion"
  },
  {
    "code": "2003",
    "rgb_approx": "255-117-020",
    "rgb_hex": "#FF7514",
    "name": "Pastel orange"
  },
  {
    "code": "2004",
    "rgb_approx": "244-070-017",
    "rgb_hex": "#F44611",
    "name": "Pure orange"
  },
  {
    "code": "2005",
    "rgb_approx": "255-035-001",
    "rgb_hex": "#FF2301",
    "name": "Luminous orange"
  },
  {
    "code": "2007",
    "rgb_approx": "255-164-032",
    "rgb_hex": "#FFA420",
    "name": "Luminous bright orange"
  },
  {
    "code": "2008",
    "rgb_approx": "247-094-037",
    "rgb_hex": "#F75E25",
    "name": "Bright red orange"
  },
  {
    "code": "2009",
    "rgb_approx": "245-064-033",
    "rgb_hex": "#F54021",
    "name": "Traffic orange"
  },
  {
    "code": "2010",
    "rgb_approx": "216-075-032",
    "rgb_hex": "#D84B20",
    "name": "Signal orange"
  },
  {
    "code": "2011",
    "rgb_approx": "236-124-038",
    "rgb_hex": "#EC7C26",
    "name": "Deep orange"
  },
  {
    "code": "2012",
    "rgb_approx": "235-106-014",
    "rgb_hex": "#E55137",
    "name": "Salmon range"
  },
  {
    "code": "2013",
    "rgb_approx": "195-088-049",
    "rgb_hex": "#C35831",
    "name": "Pearl orange"
  },
  {
    "code": "3000",
    "rgb_approx": "175-043-030",
    "rgb_hex": "#AF2B1E",
    "name": "Flame red"
  },
  {
    "code": "3001",
    "rgb_approx": "165-032-025",
    "rgb_hex": "#A52019",
    "name": "Signal red"
  },
  {
    "code": "3002",
    "rgb_approx": "162-035-029",
    "rgb_hex": "#A2231D",
    "name": "Carmine red"
  },
  {
    "code": "3003",
    "rgb_approx": "155-017-030",
    "rgb_hex": "#9B111E",
    "name": "Ruby red"
  },
  {
    "code": "3004",
    "rgb_approx": "117-021-030",
    "rgb_hex": "#75151E",
    "name": "Purple red"
  },
  {
    "code": "3005",
    "rgb_approx": "094-033-041",
    "rgb_hex": "#5E2129",
    "name": "Wine red"
  },
  {
    "code": "3007",
    "rgb_approx": "065-034-039",
    "rgb_hex": "#412227",
    "name": "Black red"
  },
  {
    "code": "3009",
    "rgb_approx": "100-036-036",
    "rgb_hex": "#642424",
    "name": "Oxide red"
  },
  {
    "code": "3011",
    "rgb_approx": "120-031-025",
    "rgb_hex": "#781F19",
    "name": "Brown red"
  },
  {
    "code": "3012",
    "rgb_approx": "193-135-107",
    "rgb_hex": "#C1876B",
    "name": "Beige red"
  },
  {
    "code": "3013",
    "rgb_approx": "161-035-018",
    "rgb_hex": "#A12312",
    "name": "Tomato red"
  },
  {
    "code": "3014",
    "rgb_approx": "211-110-112",
    "rgb_hex": "#D36E70",
    "name": "Antique pink"
  },
  {
    "code": "3015",
    "rgb_approx": "234-137-154",
    "rgb_hex": "#EA899A",
    "name": "Light pink"
  },
  {
    "code": "3016",
    "rgb_approx": "179-040-033",
    "rgb_hex": "#B32821",
    "name": "Coral red"
  },
  {
    "code": "3017",
    "rgb_approx": "230-050-068",
    "rgb_hex": "#E63244",
    "name": "Rose"
  },
  {
    "code": "3018",
    "rgb_approx": "213-048-050",
    "rgb_hex": "#D53032",
    "name": "Strawberry red"
  },
  {
    "code": "3020",
    "rgb_approx": "204-006-005",
    "rgb_hex": "#CC0605",
    "name": "Traffic red"
  },
  {
    "code": "3022",
    "rgb_approx": "217-080-048",
    "rgb_hex": "#D95030",
    "name": "Salmon pink"
  },
  {
    "code": "3024",
    "rgb_approx": "248-000-000",
    "rgb_hex": "#F80000",
    "name": "Luminous red"
  },
  {
    "code": "3026",
    "rgb_approx": "254-000-000",
    "rgb_hex": "#FE0000",
    "name": "Luminous bright red"
  },
  {
    "code": "3027",
    "rgb_approx": "197-029-052",
    "rgb_hex": "#C51D34",
    "name": "Raspberry red"
  },
  {
    "code": "3028",
    "rgb_approx": "203-050-052",
    "rgb_hex": "#CB3234",
    "name": "Pure  red"
  },
  {
    "code": "3031",
    "rgb_approx": "179-036-040",
    "rgb_hex": "#B32428",
    "name": "Orient red"
  },
  {
    "code": "3032",
    "rgb_approx": "114-020-034",
    "rgb_hex": "#721422",
    "name": "Pearl ruby red"
  },
  {
    "code": "3033",
    "rgb_approx": "180-076-067",
    "rgb_hex": "#B44C43",
    "name": "Pearl pink"
  },
  {
    "code": "4001",
    "rgb_approx": "109-063-091",
    "rgb_hex": "#6D3F5B",
    "name": "Red lilac"
  },
  {
    "code": "4002",
    "rgb_approx": "146-043-062",
    "rgb_hex": "#922B3E",
    "name": "Red violet"
  },
  {
    "code": "4003",
    "rgb_approx": "222-076-138",
    "rgb_hex": "#DE4C8A",
    "name": "Heather violet"
  },
  {
    "code": "4004",
    "rgb_approx": "110-028-052",
    "rgb_hex": "#641C34",
    "name": "Claret violet"
  },
  {
    "code": "4005",
    "rgb_approx": "108-070-117",
    "rgb_hex": "#6C4675",
    "name": "Blue lilac"
  },
  {
    "code": "4006",
    "rgb_approx": "160-052-114",
    "rgb_hex": "#A03472",
    "name": "Traffic purple"
  },
  {
    "code": "4007",
    "rgb_approx": "074-025-044",
    "rgb_hex": "#4A192C",
    "name": "Purple violet"
  },
  {
    "code": "4008",
    "rgb_approx": "146-078-125",
    "rgb_hex": "#924E7D",
    "name": "Signal violet"
  },
  {
    "code": "4009",
    "rgb_approx": "164-125-144",
    "rgb_hex": "#A18594",
    "name": "Pastel violet"
  },
  {
    "code": "4010",
    "rgb_approx": "215-045-109",
    "rgb_hex": "#CF3476",
    "name": "Telemagenta"
  },
  {
    "code": "4011",
    "rgb_approx": "134-115-161",
    "rgb_hex": "#8673A1",
    "name": "Pearl violet"
  },
  {
    "code": "4012",
    "rgb_approx": "108-104-129",
    "rgb_hex": "#6C6874",
    "name": "Pearl black berry"
  },
  {
    "code": "5000",
    "rgb_approx": "042-046-075",
    "rgb_hex": "#354D73",
    "name": "Violet blue"
  },
  {
    "code": "5001",
    "rgb_approx": "031-052-056",
    "rgb_hex": "#1F3438",
    "name": "Green blue"
  },
  {
    "code": "5002",
    "rgb_approx": "032-033-079",
    "rgb_hex": "#20214F",
    "name": "Ultramarine blue"
  },
  {
    "code": "5003",
    "rgb_approx": "029-030-051",
    "rgb_hex": "#1D1E33",
    "name": "Saphire blue"
  },
  {
    "code": "5004",
    "rgb_approx": "032-033-079",
    "rgb_hex": "#18171C",
    "name": "Black blue"
  },
  {
    "code": "5005",
    "rgb_approx": "030-045-110",
    "rgb_hex": "#1E2460",
    "name": "Signal blue"
  },
  {
    "code": "5007",
    "rgb_approx": "062-095-138",
    "rgb_hex": "#3E5F8A",
    "name": "Brillant blue"
  },
  {
    "code": "5008",
    "rgb_approx": "038-037-045",
    "rgb_hex": "#26252D",
    "name": "Grey blue"
  },
  {
    "code": "5009",
    "rgb_approx": "002-086-105",
    "rgb_hex": "#025669",
    "name": "Azure blue"
  },
  {
    "code": "5010",
    "rgb_approx": "014-041-075",
    "rgb_hex": "#0E294B",
    "name": "Gentian blue"
  },
  {
    "code": "5011",
    "rgb_approx": "035-026-036",
    "rgb_hex": "#231A24",
    "name": "Steel blue"
  },
  {
    "code": "5012",
    "rgb_approx": "059-131-189",
    "rgb_hex": "#3B83BD",
    "name": "Light blue"
  },
  {
    "code": "5013",
    "rgb_approx": "037-041-074",
    "rgb_hex": "#1E213D",
    "name": "Cobalt blue"
  },
  {
    "code": "5014",
    "rgb_approx": "096-111-140",
    "rgb_hex": "#606E8C",
    "name": "Pigeon blue"
  },
  {
    "code": "5015",
    "rgb_approx": "034-113-179",
    "rgb_hex": "#2271B3",
    "name": "Sky blue"
  },
  {
    "code": "5017",
    "rgb_approx": "006-057-113",
    "rgb_hex": "#063971",
    "name": "Traffic blue"
  },
  {
    "code": "5018",
    "rgb_approx": "063-136-143",
    "rgb_hex": "#3F888F",
    "name": "Turquoise blue"
  },
  {
    "code": "5019",
    "rgb_approx": "027-085-131",
    "rgb_hex": "#1B5583",
    "name": "Capri blue"
  },
  {
    "code": "5020",
    "rgb_approx": "029-051-074",
    "rgb_hex": "#1D334A",
    "name": "Ocean blue"
  },
  {
    "code": "5021",
    "rgb_approx": "037-109-123",
    "rgb_hex": "#256D7B",
    "name": "Water blue"
  },
  {
    "code": "5022",
    "rgb_approx": "037-040-080",
    "rgb_hex": "#252850",
    "name": "Night blue"
  },
  {
    "code": "5023",
    "rgb_approx": "073-103-141",
    "rgb_hex": "#49678D",
    "name": "Distant blue"
  },
  {
    "code": "5024",
    "rgb_approx": "093-155-155",
    "rgb_hex": "#5D9B9B",
    "name": "Pastel blue"
  },
  {
    "code": "5025",
    "rgb_approx": "042-100-120",
    "rgb_hex": "#2A6478",
    "name": "Pearl gentian blue"
  },
  {
    "code": "5026",
    "rgb_approx": "016-044-084",
    "rgb_hex": "#102C54",
    "name": "Pearl night blue"
  },
  {
    "code": "6000",
    "rgb_approx": "049-102-080",
    "rgb_hex": "#316650",
    "name": "Patina green"
  },
  {
    "code": "6001",
    "rgb_approx": "040-114-051",
    "rgb_hex": "#287233",
    "name": "Emerald green"
  },
  {
    "code": "6002",
    "rgb_approx": "045-087-044",
    "rgb_hex": "#2D572C",
    "name": "Leaf green"
  },
  {
    "code": "6003",
    "rgb_approx": "066-070-050",
    "rgb_hex": "#424632",
    "name": "Olive green"
  },
  {
    "code": "6004",
    "rgb_approx": "031-058-061",
    "rgb_hex": "#1F3A3D",
    "name": "Blue green"
  },
  {
    "code": "6005",
    "rgb_approx": "047-069-056",
    "rgb_hex": "#2F4538",
    "name": "Moss green"
  },
  {
    "code": "6006",
    "rgb_approx": "062-059-050",
    "rgb_hex": "#3E3B32",
    "name": "Grey olive"
  },
  {
    "code": "6007",
    "rgb_approx": "052-059-041",
    "rgb_hex": "#343B29",
    "name": "Bottle green"
  },
  {
    "code": "6008",
    "rgb_approx": "057-053-042",
    "rgb_hex": "#39352A",
    "name": "Brown green"
  },
  {
    "code": "6009",
    "rgb_approx": "049-055-043",
    "rgb_hex": "#31372B",
    "name": "Fir green"
  },
  {
    "code": "6010",
    "rgb_approx": "053-104-045",
    "rgb_hex": "#35682D",
    "name": "Grass green"
  },
  {
    "code": "6011",
    "rgb_approx": "088-114-070",
    "rgb_hex": "#587246",
    "name": "Reseda green"
  },
  {
    "code": "6012",
    "rgb_approx": "052-062-064",
    "rgb_hex": "#343E40",
    "name": "Black green"
  },
  {
    "code": "6013",
    "rgb_approx": "108-113-086",
    "rgb_hex": "#6C7156",
    "name": "Reed green"
  },
  {
    "code": "6014",
    "rgb_approx": "071-064-046",
    "rgb_hex": "#47402E",
    "name": "Yellow olive"
  },
  {
    "code": "6015",
    "rgb_approx": "059-060-054",
    "rgb_hex": "#3B3C36",
    "name": "Black olive"
  },
  {
    "code": "6016",
    "rgb_approx": "030-089-069",
    "rgb_hex": "#1E5945",
    "name": "Turquoise green"
  },
  {
    "code": "6017",
    "rgb_approx": "076-145-065",
    "rgb_hex": "#4C9141",
    "name": "May green"
  },
  {
    "code": "6018",
    "rgb_approx": "087-166-057",
    "rgb_hex": "#57A639",
    "name": "Yellow green"
  },
  {
    "code": "6019",
    "rgb_approx": "189-236-182",
    "rgb_hex": "#BDECB6",
    "name": "Pastel green"
  },
  {
    "code": "6020",
    "rgb_approx": "046-058-035",
    "rgb_hex": "#2E3A23",
    "name": "Chrome green"
  },
  {
    "code": "6021",
    "rgb_approx": "137-172-118",
    "rgb_hex": "#89AC76",
    "name": "Pale green"
  },
  {
    "code": "6022",
    "rgb_approx": "037-034-027",
    "rgb_hex": "#25221B",
    "name": "Olive drab"
  },
  {
    "code": "6024",
    "rgb_approx": "048-132-070",
    "rgb_hex": "#308446",
    "name": "Traffic green"
  },
  {
    "code": "6025",
    "rgb_approx": "061-100-045",
    "rgb_hex": "#3D642D",
    "name": "Fern green"
  },
  {
    "code": "6026",
    "rgb_approx": "001-093-082",
    "rgb_hex": "#015D52",
    "name": "Opal green"
  },
  {
    "code": "6027",
    "rgb_approx": "132-195-190",
    "rgb_hex": "#84C3BE",
    "name": "Light green"
  },
  {
    "code": "6028",
    "rgb_approx": "044-085-069",
    "rgb_hex": "#2C5545",
    "name": "Pine green"
  },
  {
    "code": "6029",
    "rgb_approx": "032-096-061",
    "rgb_hex": "#20603D",
    "name": "Mint green"
  },
  {
    "code": "6032",
    "rgb_approx": "049-127-067",
    "rgb_hex": "#317F43",
    "name": "Signal green"
  },
  {
    "code": "6033",
    "rgb_approx": "073-126-118",
    "rgb_hex": "#497E76",
    "name": "Mint turquoise"
  },
  {
    "code": "6034",
    "rgb_approx": "127-181-181",
    "rgb_hex": "#7FB5B5",
    "name": "Pastel turquoise"
  },
  {
    "code": "6035",
    "rgb_approx": "028-084-045",
    "rgb_hex": "#1C542D",
    "name": "Pearl green"
  },
  {
    "code": "6036",
    "rgb_approx": "022-053-055",
    "rgb_hex": "#193737",
    "name": "Pearl opal green"
  },
  {
    "code": "6037",
    "rgb_approx": "000-143-057",
    "rgb_hex": "#008F39",
    "name": "Pure green"
  },
  {
    "code": "6038",
    "rgb_approx": "000-187-045",
    "rgb_hex": "#00BB2D",
    "name": "Luminous green"
  },
  {
    "code": "7000",
    "rgb_approx": "120-133-139",
    "rgb_hex": "#78858B",
    "name": "Squirrel grey"
  },
  {
    "code": "7001",
    "rgb_approx": "138-149-151",
    "rgb_hex": "#8A9597",
    "name": "Silver grey"
  },
  {
    "code": "7002",
    "rgb_approx": "126-123-082",
    "rgb_hex": "#7E7B52",
    "name": "Olive grey"
  },
  {
    "code": "7003",
    "rgb_approx": "108-112-089",
    "rgb_hex": "#6C7059",
    "name": "Moss grey"
  },
  {
    "code": "7004",
    "rgb_approx": "150-153-146",
    "rgb_hex": "#969992",
    "name": "Signal grey"
  },
  {
    "code": "7005",
    "rgb_approx": "100-107-099",
    "rgb_hex": "#646B63",
    "name": "Mouse grey"
  },
  {
    "code": "7006",
    "rgb_approx": "109-101-082",
    "rgb_hex": "#6D6552",
    "name": "Beige grey"
  },
  {
    "code": "7008",
    "rgb_approx": "106-095-049",
    "rgb_hex": "#6A5F31",
    "name": "Khaki grey"
  },
  {
    "code": "7009",
    "rgb_approx": "077-086-069",
    "rgb_hex": "#4D5645",
    "name": "Green grey"
  },
  {
    "code": "7010",
    "rgb_approx": "076-081-074",
    "rgb_hex": "#4C514A",
    "name": "Tarpaulin grey"
  },
  {
    "code": "7011",
    "rgb_approx": "067-075-077",
    "rgb_hex": "#434B4D",
    "name": "Iron grey"
  },
  {
    "code": "7012",
    "rgb_approx": "078-087-084",
    "rgb_hex": "#4E5754",
    "name": "Basalt grey"
  },
  {
    "code": "7013",
    "rgb_approx": "070-069-049",
    "rgb_hex": "#464531",
    "name": "Brown grey"
  },
  {
    "code": "7015",
    "rgb_approx": "067-071-080",
    "rgb_hex": "#434750",
    "name": "Slate grey"
  },
  {
    "code": "7016",
    "rgb_approx": "041-049-051",
    "rgb_hex": "#293133",
    "name": "Anthracite grey"
  },
  {
    "code": "7021",
    "rgb_approx": "035-040-043",
    "rgb_hex": "#23282B",
    "name": "Black grey"
  },
  {
    "code": "7022",
    "rgb_approx": "051-047-044",
    "rgb_hex": "#332F2C",
    "name": "Umbra grey"
  },
  {
    "code": "7023",
    "rgb_approx": "104-108-094",
    "rgb_hex": "#686C5E",
    "name": "Concrete grey"
  },
  {
    "code": "7024",
    "rgb_approx": "071-074-081",
    "rgb_hex": "#474A51",
    "name": "Graphite grey"
  },
  {
    "code": "7026",
    "rgb_approx": "047-053-059",
    "rgb_hex": "#2F353B",
    "name": "Granite grey"
  },
  {
    "code": "7030",
    "rgb_approx": "139-140-122",
    "rgb_hex": "#8B8C7A",
    "name": "Stone grey"
  },
  {
    "code": "7031",
    "rgb_approx": "071-075-078",
    "rgb_hex": "#474B4E",
    "name": "Blue grey"
  },
  {
    "code": "7032",
    "rgb_approx": "184-183-153",
    "rgb_hex": "#B8B799",
    "name": "Pebble grey"
  },
  {
    "code": "7033",
    "rgb_approx": "125-132-113",
    "rgb_hex": "#7D8471",
    "name": "Cement grey"
  },
  {
    "code": "7034",
    "rgb_approx": "143-139-102",
    "rgb_hex": "#8F8B66",
    "name": "Yellow grey"
  },
  {
    "code": "7035",
    "rgb_approx": "215-215-215",
    "rgb_hex": "#D7D7D7",
    "name": "Light grey"
  },
  {
    "code": "7036",
    "rgb_approx": "127-118-121",
    "rgb_hex": "#7F7679",
    "name": "Platinum grey"
  },
  {
    "code": "7037",
    "rgb_approx": "125-127-120",
    "rgb_hex": "#7D7F7D",
    "name": "Dusty grey"
  },
  {
    "code": "7038",
    "rgb_approx": "195-195-195",
    "rgb_hex": "#B5B8B1",
    "name": "Agate grey"
  },
  {
    "code": "7039",
    "rgb_approx": "108-105-096",
    "rgb_hex": "#6C6960",
    "name": "Quartz grey"
  },
  {
    "code": "7040",
    "rgb_approx": "157-161-170",
    "rgb_hex": "#9DA1AA",
    "name": "Window grey"
  },
  {
    "code": "7042",
    "rgb_approx": "141-148-141",
    "rgb_hex": "#8D948D",
    "name": "Traffic grey A"
  },
  {
    "code": "7043",
    "rgb_approx": "078-084-082",
    "rgb_hex": "#4E5452",
    "name": "Traffic grey B"
  },
  {
    "code": "7044",
    "rgb_approx": "202-196-176",
    "rgb_hex": "#CAC4B0",
    "name": "Silk grey"
  },
  {
    "code": "7045",
    "rgb_approx": "144-144-144",
    "rgb_hex": "#909090",
    "name": "Telegrey 1"
  },
  {
    "code": "7046",
    "rgb_approx": "130-137-143",
    "rgb_hex": "#82898F",
    "name": "Telegrey 2"
  },
  {
    "code": "7047",
    "rgb_approx": "208-208-208",
    "rgb_hex": "#D0D0D0",
    "name": "Telegrey 4"
  },
  {
    "code": "7048",
    "rgb_approx": "137-129-118",
    "rgb_hex": "#898176",
    "name": "Pearl mouse grey"
  },
  {
    "code": "8000",
    "rgb_approx": "130-108-052",
    "rgb_hex": "#826C34",
    "name": "Green brown"
  },
  {
    "code": "8001",
    "rgb_approx": "149-095-032",
    "rgb_hex": "#955F20",
    "name": "Ochre brown"
  },
  {
    "code": "8002",
    "rgb_approx": "108-059-042",
    "rgb_hex": "#6C3B2A",
    "name": "Signal brown"
  },
  {
    "code": "8003",
    "rgb_approx": "115-066-034",
    "rgb_hex": "#734222",
    "name": "Clay brown"
  },
  {
    "code": "8004",
    "rgb_approx": "142-064-042",
    "rgb_hex": "#8E402A",
    "name": "Copper brown"
  },
  {
    "code": "8007",
    "rgb_approx": "089-053-031",
    "rgb_hex": "#59351F",
    "name": "Fawn brown"
  },
  {
    "code": "8008",
    "rgb_approx": "111-079-040",
    "rgb_hex": "#6F4F28",
    "name": "Olive brown"
  },
  {
    "code": "8011",
    "rgb_approx": "091-058-041",
    "rgb_hex": "#5B3A29",
    "name": "Nut brown"
  },
  {
    "code": "8012",
    "rgb_approx": "089-035-033",
    "rgb_hex": "#592321",
    "name": "Red brown"
  },
  {
    "code": "8014",
    "rgb_approx": "056-044-030",
    "rgb_hex": "#382C1E",
    "name": "Sepia brown"
  },
  {
    "code": "8015",
    "rgb_approx": "099-058-052",
    "rgb_hex": "#633A34",
    "name": "Chestnut brown"
  },
  {
    "code": "8016",
    "rgb_approx": "076-047-039",
    "rgb_hex": "#4C2F27",
    "name": "Mahogany brown"
  },
  {
    "code": "8017",
    "rgb_approx": "069-050-046",
    "rgb_hex": "#45322E",
    "name": "Chocolate brown"
  },
  {
    "code": "8019",
    "rgb_approx": "064-058-058",
    "rgb_hex": "#403A3A",
    "name": "Grey brown"
  },
  {
    "code": "8022",
    "rgb_approx": "033-033-033",
    "rgb_hex": "#212121",
    "name": "Black brown"
  },
  {
    "code": "8023",
    "rgb_approx": "166-094-046",
    "rgb_hex": "#A65E2E",
    "name": "Orange brown"
  },
  {
    "code": "8024",
    "rgb_approx": "121-085-061",
    "rgb_hex": "#79553D",
    "name": "Beige brown"
  },
  {
    "code": "8025",
    "rgb_approx": "117-092-072",
    "rgb_hex": "#755C48",
    "name": "Pale brown"
  },
  {
    "code": "8028",
    "rgb_approx": "078-059-049",
    "rgb_hex": "#4E3B31",
    "name": "Terra brown"
  },
  {
    "code": "8029",
    "rgb_approx": "118-060-040",
    "rgb_hex": "#763C28",
    "name": "Pearl copper"
  },
  {
    "code": "9001",
    "rgb_approx": "250-244-227",
    "rgb_hex": "#FDF4E3",
    "name": "Cream"
  },
  {
    "code": "9002",
    "rgb_approx": "231-235-218",
    "rgb_hex": "#E7EBDA",
    "name": "Grey white"
  },
  {
    "code": "9003",
    "rgb_approx": "244-244-244",
    "rgb_hex": "#F4F4F4",
    "name": "Signal white"
  },
  {
    "code": "9004",
    "rgb_approx": "040-040-040",
    "rgb_hex": "#282828",
    "name": "Signal black"
  },
  {
    "code": "9005",
    "rgb_approx": "010-010-010",
    "rgb_hex": "#0A0A0A",
    "name": "Jet black"
  },
  {
    "code": "9006",
    "rgb_approx": "165-165-165",
    "rgb_hex": "#A5A5A5",
    "name": "White aluminium"
  },
  {
    "code": "9007",
    "rgb_approx": "143-143-143",
    "rgb_hex": "#8F8F8F",
    "name": "Grey aluminium"
  },
  {
    "code": "9010",
    "rgb_approx": "255-255-255",
    "rgb_hex": "#FFFFFF",
    "name": "Pure white"
  },
  {
    "code": "9011",
    "rgb_approx": "028-028-028",
    "rgb_hex": "#1C1C1C",
    "name": "Graphite black"
  },
  {
    "code": "9016",
    "rgb_approx": "246-246-246",
    "rgb_hex": "#F6F6F6",
    "name": "Traffic white"
  },
  {
    "code": "9017",
    "rgb_approx": "030-030-030",
    "rgb_hex": "#1E1E1E",
    "name": "Traffic black"
  },
  {
    "code": "9018",
    "rgb_approx": "215-215-215",
    "rgb_hex": "#D7D7D7",
    "name": "Papyrus white"
  },
  {
    "code": "9022",
    "rgb_approx": "156-156-156",
    "rgb_hex": "#9C9C9C",
    "name": "Pearl light grey"
  },
  {
    "code": "9023",
    "rgb_approx": "130-130-130",
    "rgb_hex": "#828282",
    "name": "Pearl dark grey"
  }
]