<div class="flex flex-col">
  <div class="flex items-center justify-between">
    <label class="block text-sm font-medium text-gray-600">
      {{ label }}
      <app-helper *ngIf="tooltip" matTooltipPosition="above" [matTooltip]="tooltip"></app-helper>
      <app-helper *ngIf="labelTooltip" mtxTooltipPosition="above" [mtxTooltip]="labelTooltip"></app-helper>
    </label>

    <ng-container *ngIf="showLock">
      <div (mouseenter)="lockMouseEnter.emit($event)" (mouseleave)="lockMouseLeave.emit($event)">
        <app-icon
          *ngIf="isLocked"
          [icon]="'lock-closed'"
          class="w-4 h-4 transition duration-200"
          [ngClass]="{ 'text-monza-500': highlightLock, 'text-gray-500': !highlightLock }"
        ></app-icon>
        <app-icon
          *ngIf="!isLocked"
          [icon]="'lock-open'"
          class="w-4 h-4 transition duration-200"
          [ngClass]="{ 'text-monza-500': highlightLock, 'text-gray-500': !highlightLock }"
        ></app-icon>
      </div>
    </ng-container>
  </div>
  <div class="relative w-full" [ngClass]="{ 'mt-1': label }" #elem>
    <button
      [disabled]="isReadonly"
      (click)="isOpen = !isOpen"
      type="button"
      class="relative w-full pl-3 pr-10 text-left bg-white border border-gray-300 cursor-default sm:text-sm input"
      [ngClass]="{ 'bg-gray-100': isReadonly, 'py-2': size === 'md', 'py-1': size === 'sm'}"
      aria-haspopup="listbox"
      aria-expanded="true"
      aria-labelledby="listbox-label"
    >
      <span
        class="inline-flex w-full truncate"
        [ngStyle]="labelStyle"
        [ngClass]="{ 'text-gray-500': placeholder && selectedValue?.value == '' }"
      >
        <span class="truncate">
          <ng-container *ngIf="selectedValue?.translated | async as translated; else SelectedLabel">
            {{ translated }}
          </ng-container>
          <ng-template #SelectedLabel>
            {{ selectedValue?.label }}
          </ng-template>
        </span>
        <span class="ml-1 text-gray-500 truncate"> {{ selectedValue?.description }} </span>
        <div
          [ngClass]="{ 'right-0': isReadonly, 'right-6': !isReadonly }"
          class="absolute inset-y-0 flex items-center pr-3 pointer-events-none"
          *ngIf="unit"
        >
          <span class="text-gray-500 sm:text-sm" id="price-currency"> {{ unit }} </span>
        </div>
      </span>
      <span *ngIf="!isReadonly" class="absolute inset-y-0 right-0 flex items-center h-full pr-2 pointer-events-none">
        <app-icon class="w-5 h-5 text-gray-400" [icon]="'keyboard-arrow-down'"></app-icon>
      </span>
    </button>

    <ul
      *ngIf="isOpen && !isReadonly"
      class="absolute z-10 mt-1 min-w-full w-fit overflow-auto text-base bg-white max-h-60 ring-1 ring-gray-300 focus:outline-none sm:text-sm"
      tabindex="-1"
      role="listbox"
      aria-labelledby="listbox-label"
      aria-activedescendant="listbox-option-3"
    >
      <div class="w-full" *ngIf="isSearchVisible">
        <input class="search-input" [value]="searchValue" #search (keyup)="onSearch($event)"> 
        <app-icon icon="magnifying-glass" class="fill-slate-400 absolute right-2 top-2"></app-icon>
      </div>
      <li
        [matTooltip]="option.readonlyReason ? ('Input.'+option.readonlyReason.toString() | translate):undefined"
        matTooltipPosition="above"
        matTooltipShowDelay="500"
        (click)="select(option)"
        *ngFor="let option of options; index as i"
        class="relative pl-3 py-2 pr-8 cursor-pointer select-none hover:text-white hover:bg-monza-500"
        [ngClass]="[
          placeholder && i == 0 && searchValue == '' && !(selectedValue?.value === option.value) ? 'text-gray-500' : '',
          selectedValue?.value === option.value ? 'text-white bg-monza-500' : '',
          option.readonly && !(selectedValue?.value === option.value) ? 'cursor-default text-gray-400 bg-gray-100' : '',
        ]"
        [id]="'listbox-option-' + i"
        role="option"
      >
        <div class="flex" [ngClass]="{ 'flex-col': multiLine }">
          <span class="font-normal truncate">
            <ng-container *ngIf="option.translated | async as translated; else OptionLabel">
              {{ translated }}
            </ng-container>
            <ng-template #OptionLabel>
              {{ option.label }}
            </ng-template>
          </span>
          <span
            class="text-gray-500 truncate hover:text-blue-100"
            [ngClass]="[multiLine ? '' : 'ml-1', selectedValue?.value === option.value ? 'text-blue-100' : '']"
          >
            {{ option.description }}
          </span>
          <div class="flex items-center pointer-events-none" *ngIf="unit">
            <span class="sm:text-sm" id="price-currency"> {{ unit }} </span>
          </div>
        </div>
        <span
          *ngIf="selectedValue?.value === option.value"
          class="text-white absolute inset-y-0 right-0 flex items-center pr-4"
        >
          <app-icon class="w-5 h-5 text-current" [icon]="'done'"></app-icon>
        </span>
      </li>
    </ul>
  </div>
</div>
