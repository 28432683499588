import { Component, Input, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DiscountTypeEnum, Order } from '../../api-client';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss']
})
export class DiscountComponent implements OnInit {
  discount$ = this.orderService.order$.pipe(
    map((order) => {
      if (order == undefined) return;
      if (order.activeDiscount == null && order.activePromotion == null) return;

      let bestDiscount = this.getBestDiscountPercent(order);

      return Math.round(order.priceSum * (bestDiscount / 100) * 20) / 20;
    })
  );

  discountSeries$ = this.orderService.order$.pipe(
    map((order) => {
      if (order == undefined) return;
      if (order.activeDiscount == null && order.activePromotion == null) return;
      if (order.activeDiscount.type === DiscountTypeEnum.Series) {
        const bestSeries = order.activeDiscount.series.reduce((prev, curr) => {
          return order.priceSum * (curr.discountAmount / 100) >= order.priceSum * (prev.discountAmount / 100) &&
            order.lineItems.reduce((sum, curr) => (sum += curr.isField ? curr.amount : 0), 0) >= curr.minFields
            ? curr
            : prev;
        });
        return bestSeries;
      } else {
        return undefined;
      }
    })
  );

  discountPercent$ = this.orderService.order$.pipe(
    map((order) => {
      if (order == undefined) return;
      if (order.activeDiscount == null && order.activePromotion == null) return;

      let bestDiscount = this.getBestDiscountPercent(order);
      return bestDiscount;
    })
  );

  promotion$ = this.orderService.order$.pipe(
    map((order) => {
      if (order == undefined) return;
      if (order.activePromotion == null) return;

      let bestDiscount = this.getBestDiscountPercent(order);

      const diff = order.activePromotion.maxPercent - bestDiscount;

      let percent = order.activePromotion.percent;
      if (diff <= order.activePromotion.percent) {
        percent = diff;
      }

      return { percent, value: Math.round(order.priceSum * (percent / 100) * 20) / 20 };
    })
  );

  constructor(public orderService: OrderService) {}

  ngOnInit(): void {}

  getBestDiscountPercent(order: Order) {
    if (!order.activeDiscount) return 0;
    switch (order.activeDiscount.type) {
      case DiscountTypeEnum.Percent:
        return order.activeDiscount.percent;

      case DiscountTypeEnum.Series:
        const bestSeries = order.activeDiscount.series.reduce((prev, curr) => {
          return order.priceSum * (curr.discountAmount / 100) >= order.priceSum * (prev.discountAmount / 100) &&
            order.lineItems.reduce((sum, curr) => (sum += curr.isField ? curr.amount : 0), 0) >= curr.minFields
            ? curr
            : prev;
        });
        if (
          bestSeries &&
          order.lineItems.reduce((sum, curr) => (sum += curr.isField ? curr.amount : 0), 0) >= bestSeries.minFields
        ) {
          return bestSeries.discountAmount;
        }
        return 0;

      default:
        return 0;
    }
  }
}
