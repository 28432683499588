import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimatedNumberComponent } from './animated-number/animated-number.component';
import { BadgeComponent } from './badge/badge.component';
import { ButtonComponent } from './button/button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { IconComponent } from './icon/icon.component';
import { InputComponent } from './input/input.component';
import { ModalComponent } from './modal/modal.component';
import { StepsComponent } from './steps/steps.component';
import { ToastComponent } from './toast/toast.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '../language.module';
import { OrderLabelComponent } from './order-label/order-label.component';
import { HelperComponent } from './helper/helper.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RadioComponent } from './radio/radio.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';

@NgModule({
  declarations: [
    AnimatedNumberComponent,
    BadgeComponent,
    ButtonComponent,
    CheckboxComponent,
    CustomSelectComponent,
    IconComponent,
    InputComponent,
    ModalComponent,
    StepsComponent,
    ToastComponent,
    HelperComponent,
    DatepickerComponent,
    OrderLabelComponent,
    RadioComponent,
    SkeletonComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatIconModule,
    MtxTooltipModule,
    LanguageModule
  ],
  exports: [
    AnimatedNumberComponent,
    BadgeComponent,
    ButtonComponent,
    CheckboxComponent,
    CustomSelectComponent,
    IconComponent,
    InputComponent,
    ModalComponent,
    StepsComponent,
    HelperComponent,
    ToastComponent,
    DatepickerComponent,
    OrderLabelComponent,
    RadioComponent,
    SkeletonComponent
  ]
})
export class AtomsModule {}
