import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UpdateUserDto, UserApi } from '../api-client';
import { ToastService } from './toast.service';
import { ASS_SERVICE_CONF } from './conf';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  api: UserApi;

  constructor(
    public jwtHelper: JwtHelperService,
    private authService: AuthService,
    private toastService: ToastService
  ) {
    this.api = new UserApi(this.authService.apiConfig, ASS_SERVICE_CONF.BASE_URL);
    this.authService.apiConfig$.subscribe((apiConfig) => {
      this.api = new UserApi(apiConfig, ASS_SERVICE_CONF.BASE_URL);
    });
  }

  updateUser(updateDto: UpdateUserDto) {
    this.api
      .userControllerUpdateUser(updateDto)
      .then((res) => {
        this.toastService.displayToast({
          type: 'success',
          message: 'Profildaten erfolgreich gespeichert',
          time: 4000
        });
        this.authService.currentUser = res.data;
      })
      .catch(this.catch.bind(this));
  }

  catch(error: any) {
    let message = '';
    if ((error as any).response?.data) {
      message = (error as any).response?.data.message;
    } else {
      message = error.message;
    }
    console.error(error);
    this.toastService.displayToast({
      type: 'danger',
      message,
      time: 4000
    });
  }
}
