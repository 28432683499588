import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { firstValueFrom, lastValueFrom, map, Observable, switchMap, tap, zip } from 'rxjs';
import {
  Address,
  AdminUpdateUserDto,
  Company,
  DiscountTypeEnum,
  OrderByUser,
  SearchUser,
  UpdateCompanyDto,
  User
} from 'src/app/api-client';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { Option } from '../../atoms/custom-select/custom-select.component';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent {
  addressToEdit: Address | undefined;
  inviteEmail: string = '';
  isInviteEmployeeModalOpen = false;
  isAddressModalOpen = false;

  slot12Ids?: {
    id: string;
    number: string;
    size: string;
  }[];

  selectedSchemaSize!: Option;
  schemaSizes$!: Observable<Option[]>;

  discountCountFix$ = this.companyService.company$.pipe(
    map((c) => c.discounts.filter((d) => d.type === DiscountTypeEnum.Flat).length)
  );
  discountCountPercent$ = this.companyService.company$.pipe(
    map((c) => c.discounts.filter((d) => d.type === DiscountTypeEnum.Percent).length)
  );
  discountCountSeries$ = this.companyService.company$.pipe(
    map((c) => c.discounts.filter((d) => d.type === DiscountTypeEnum.Series).length)
  );

  isAdmin$!: Observable<boolean>;

  offset = 0;
  limit = 10;
  orderBy: OrderByUser = {};
  search: SearchUser = {};

  confirmDeleteModal = false;

  confirmDeleteUserModal = false;
  selectedDeleteUser?: User;

  userEditModalOpen = false;
  selectedUser?: User;

  constructor(
    public companyService: CompanyService,
    public authService: AuthService,
    public productService: ProductService
  ) {
    this.companyService.loadUsers(this.search, this.orderBy, this.limit, this.offset);
    const combinedObs = zip(this.companyService.company$, this.authService.user$);
    this.isAdmin$ = combinedObs.pipe(map(([comp, user]) => (comp.admin.id == user.id) as boolean));

    this.schemaSizes$ = productService.getSlot(12).pipe(
      map((slot) =>
        slot
          .filter((p) => !p.productNumber.includes('tl') && !p.productNumber.includes('tr'))
          .map((p) => ({
            value: p.id,
            meta: p.productNumber,
            label: p.productNumber.replace('sf', ''),
            description: 'mm'
          }))
          .sort((a, b) => +a.label - +b.label)
      )
    );

    zip(this.schemaSizes$, this.companyService.company$)
      .pipe(map((val) => val[0].find((opt) => opt.value == val[1].defaultSlot12) ?? val[0][0]))
      .subscribe((d) => (this.selectedSchemaSize = d));
  }

  updateUser(user: User) {
    const dto: AdminUpdateUserDto = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email
    };
    this.companyService.api
      .companyControllerAdminUpdateCompanyUser(user.id, dto)
      .then(() => this.companyService.loadUsers(this.search, this.orderBy, this.limit, this.offset));
  }

  deleteUser(user: User) {
    this.companyService.api
      .companyControllerAdminDeleteUser(user.id)
      .then(() => this.companyService.loadUsers(this.search, this.orderBy, this.limit, this.offset));
  }

  updateCompanyDefaults(id: string, dto: UpdateCompanyDto) {
    this.companyService.udpateCompany(id, dto);
  }

  setDefaultProduct(companyId: string, productNumber: string, quantity: number) {
    this.companyService.setDefaultProduct(companyId, productNumber, quantity);
  }

  async setDefaultProductALAddonWall(companyId: string, productNumber: string, quantity: number) {
    if (productNumber === 'wblal200-400mrw') {
      await this.companyService.deleteDefaultProduct(companyId, 'wblal200-400v');
    } else {
      await this.companyService.deleteDefaultProduct(companyId, 'wblal200-400mrw');
    }
    this.companyService.setDefaultProduct(companyId, productNumber, quantity);
  }

  deleteDefaultProduct(companyId: string, productNumber: string) {
    this.companyService.deleteDefaultProduct(companyId, productNumber);
  }

  deleteDefaultALAddonWall(company: Company) {
    if (company.defaultProducts?.some((p) => p.productNumber === 'wblal200-400mrw')) {
      this.companyService.deleteDefaultProduct(company.id, 'wblal200-400mrw');
    }
    if (company.defaultProducts?.some((p) => p.productNumber === 'wblal200-400v')) {
      this.companyService.deleteDefaultProduct(company.id, 'wblal200-400v');
    }
  }

  displayedColumns: string[] = ['name', 'email', 'created', 'actions'];

  sortEmployees(event: Sort) {
    let colName = event.active;

    if (colName == undefined) return console.error('Colname not found when sorting');
    if (event.direction == undefined || event.direction == '') {
      delete this.orderBy[colName as keyof OrderByUser];
    } else {
      this.orderBy = {};
      if (colName == 'name') {
        this.orderBy['firstName'] = event.direction.toUpperCase() as any;
        this.orderBy['lastName'] = event.direction.toUpperCase() as any;
      } else {
        this.orderBy[colName as keyof OrderByUser] = event.direction.toUpperCase() as any;
      }
    }

    this.companyService.loadUsers(this.search, this.orderBy, this.limit, this.offset);
  }

  paginateUsers(event: PageEvent) {
    this.offset = event.pageSize * event.pageIndex;
    this.limit = event.pageSize;
    this.companyService.loadUsers(this.search, this.orderBy, this.limit, this.offset);
  }

  toggleInviteEmployeeModal() {
    this.isInviteEmployeeModalOpen = !this.isInviteEmployeeModalOpen;
  }

  inviteEmpoyee() {
    this.isInviteEmployeeModalOpen = false;
    this.companyService.inviteEmployee(this.inviteEmail);
    this.inviteEmail = '';
  }

  editAddress(address: Address) {
    this.addressToEdit = address;
    this.isAddressModalOpen = true;
  }

  createAddress() {
    this.addressToEdit = undefined;
    this.isAddressModalOpen = true;
  }

  setAddressActive(address: Address) {
    this.companyService.setActiveAddress(address.id);
  }
}
