
<div class="w-full" *ngIf="orderService.order$ | async as order">
  <app-address-modal
    *ngIf="isDeliveryAddressModalOpen"
    (onClose)="this.isDeliveryAddressModalOpen = false"
    (onRemove)="removeDeliveryAddress(); this.isDeliveryAddressModalOpen = false"
    (onSubmitAddress)="updateDeliveryAddress($event)"
    [showExistingAddresses]="true"
    [isDeletable]="false"
    [isRemovable]="true"
    [isReadonly]="order.status == 'Ordered'"
    [address]="deliveryAddress$ | async"
  ></app-address-modal>
  <app-address-modal
    *ngIf="isBillingAddressModalOpen"
    (onClose)="this.isBillingAddressModalOpen = false"
    (onRemove)="removeBillingAddress(); this.isBillingAddressModalOpen = false"
    (onSubmitAddress)="updateBillingAddress($event)"
    [isDeletable]="false"
    [isRemovable]="true"
    [showExistingAddresses]="true"
    [isReadonly]="order.status == 'Ordered'"
    [address]="billingAddress$ | async"
  ></app-address-modal>
  <div class="w-full px-8 py-6 text-white bg-monza-500">
    <span class="block text-gray-200">{{'OrderOverview.Sidebar.Header'|translate}}</span>
    <span class="block text-xl font-medium" *ngIf="orderService.order$ | async as order">{{ order.name }}</span>
    <span class="block w-1/2 bg-gray-200 h-7 animate-pulse" *ngIf="!(orderService.order$ | async)"></span>
  </div>
  <div class="flex flex-col gap-4" *ngIf="companyService.company$ | async as company">
    <div class="p-8 space-y-4 bg-white">

      <div *ngIf="activeAddress$ | async as activeAdress">
        <span class="block mb-2 font-medium">{{ activeAdress.companyName ? activeAdress.companyName : company.name }}</span>
        <span class="block">{{ activeAdress.firstname }} {{ activeAdress.lastname }}</span>
        <span class="block mb-2">{{ activeAdress.phone }} </span>
        <span class="block">{{ activeAdress.street }}</span>
        <span class="block">{{ activeAdress.city }} {{ activeAdress.postal }}</span>
        <span class="block">{{ activeAdress.country }}</span>
      </div>
      <app-button 
        [customContent]="(deliveryAddress$ | async) !== null" 
        [type]="'light'" 
        [label]="'OrderOverview.Sidebar.DifferingShippingAddress'|translate" 
        [fullWidth]="true" 
        [icon]="'add'" 
        (onClick)="isDeliveryAddressModalOpen = true">
        <ng-container *ngIf="deliveryAddress$ | async as deliveryAddress">
        </ng-container>
        <div class="w-full flex flex-col" *ngIf="deliveryAddress$ | async as deliveryAddress">
          <span *ngIf="order.status == 'Ordered'">Lieferadresse</span>
          <span *ngIf="order.status != 'Ordered'">Abweichende Lieferadresse</span>
          <hr class="my-1">
          <span>
            {{ deliveryAddress.companyName ? deliveryAddress.companyName : company.name }}<br>
            {{ deliveryAddress.street }}<br>
            {{ deliveryAddress.city }} {{ deliveryAddress.postal }}<br>
          </span>
        </div>
      </app-button>
      <app-button 
        [customContent]="(billingAddress$ | async) !== null "
        [type]="'light'" 
        [label]="'OrderOverview.Sidebar.DifferingBillingAddress'|translate" 
        [fullWidth]="true" 
        [icon]="'add'" 
        [isDisabled]="true"
        (onClick)="isBillingAddressModalOpen = true"
      >
        <div class="w-full flex flex-col" *ngIf="billingAddress$ | async as billingAddress">
          <span *ngIf="order.status == 'Ordered'">Rechnungsadresse</span>
          <span *ngIf="order.status != 'Ordered'">Abweichende Rechnungsadresse</span>
          <hr class="my-1">
          <span>
            {{ billingAddress.companyName ? billingAddress.companyName : company.name }}<br>
            {{ billingAddress.street }}<br>
            {{ billingAddress.city }} {{ billingAddress.postal }}<br>
          </span>
        </div>
      </app-button>
      <hr />
      <app-input 
        [type]="'text'" 
        [label]="'OrderOverview.Sidebar.Reference'|translate"
        [placeholder]="'OrderOverview.Sidebar.Reference'|translate" 
        [isLabelHidden]="true" 
        [(value)]="order.reference"
        (valueChange)="updateOrderReference(order,$event)"
        [isReadonly]="order.status == 'Ordered'"
      ></app-input>
      <app-input 
        [type]="'textarea'" 
        [label]="'OrderOverview.Sidebar.Note'|translate" 
        [placeholder]="'OrderOverview.Sidebar.Note'|translate" 
        [isLabelHidden]="true" 
        [(value)]="order.note"
        (valueChange)="updateOrderNote(order,$event)"
        [isReadonly]="order.status == 'Ordered'"
      ></app-input>
      <app-datepicker 
        class="mt-4 block"
        [isReadonly]="order.status == 'Ordered'" 
        [date]="order.deliveryDate" 
        (onChange)="updateDeliveryDate(order, $event.value)" 
        [placeholder]="'OrderOverview.Sidebar.DeliveryDate'|translate" 
        [minDate]="minDate"
      ></app-datepicker>
      <app-button 
        (onClick)="finishOrder()" 
        [fullWidth]="true" 
        [loading]="submittingOrder"
        [isDisabled]="submittingOrder || order.status == 'Ordered'"
        [label]="order.status == 'Ordered' ? ('OrderOverview.Sidebar.OrderedCTA'|translate) : ('OrderOverview.Sidebar.OrderCTA'|translate)" 
        [icon]="'done'" 
        [type]="'secondary'"
      ></app-button>
    </div>
  </div>
</div>