import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  id = uuidv4();

  @Input()
  checked: boolean | undefined = false;

  @Output()
  checkedChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  label: string = '';

  @Input()
  isMobileLabel = false;

  @Input()
  isReadonly = false;

  @Input()
  description: string = '';

  @Input()
  tooltip: string = '';

  @Input()
  highlight: boolean = false;

  @Output()
  onToggle: EventEmitter<boolean> = new EventEmitter();

  change() {
    this.onToggle.emit(this.checked);
    this.checkedChange.emit(this.checked);
  }

  constructor() {}

  ngOnInit(): void {}
}
