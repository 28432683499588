<div
  *ngIf="orderService.order$ | async as order"
  class="relative flex flex-col p-8 bg-white border-l-4 border-monza-500"
  [ngClass]="{ 'h-full overflow-visible': isExpanded, 'h-28 overflow-hidden': !isExpanded }"
>
  <div class="flex items-center gap-4">
    <div>
      <span class="flex items-center font-medium z-10">
        <span class="px-2 py-1 mr-2 text-xs leading-none text-white bg-monza-500" *ngIf="item.amount > 1">
          {{ item.amount }}×
        </span>
        {{ item | translatedName : translate.currentLang }}

        <span
          class="px-2 py-1 mr-2 text-xs leading-none ml-4 font-bold bg-gray-700 text-gray-100"
          *ngIf="labelProduct?.custom"
        >
          {{ labelProduct?.custom }}
        </span>
      </span>

      <span class="text-sm font-medium leading-none text-monza-500">
        {{ item.isWall ? ('Configuration.Fields.Wall' | translate) : '' }}
        {{ item.isSocket ? ('Configuration.Fields.Socket' | translate) : '' }}
        {{ item.isField ? ('Configuration.Fields.Field' | translate) : '' }}
        {{ item.isDividerWall ? ('Configuration.Fields.Divider' | translate) : '' }}
      </span>
      <span class="text-sm"> — {{ item | translatedDescription : translate.currentLang }}</span>
    </div>
    <div class="flex flex-col items-center gap-2 ml-auto md:flex-row">
      <div>
        <app-badge [type]="'price'" class="ml-auto mr-4 z-10" [label]="item.price"></app-badge>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-8 mt-8">
    <div class="grid grid-cols-6">
      <div class="item-row flex flex-col gap-6 col-span-6 pb-4">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.Label' | translate"
        ></app-order-label>
        <app-al-label [item]="item" [order]="order"></app-al-label>
      </div>

      <div class="item-row flex flex-col gap-6 col-span-6">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.BaseData' | translate"
        ></app-order-label>
        <app-al-base
          [item]="item"
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (updateSlot)="updateSlot($event)"
        ></app-al-base>
      </div>
      <div
        class="item-row flex flex-col gap-6 col-span-6 mt-8"
        *ngIf="!((item | containsProduct : 'ot') || (item | containsProduct : 'otorw'))"
      >
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.Door' | translate"
        ></app-order-label>
        <app-al-door
          [item]="item"
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (updateSlot)="updateSlot($event)"
        ></app-al-door>
      </div>
      <div
        class="item-row flex flex-col gap-6 col-span-6 mt-8"
        *ngIf="!((item | containsProduct : 'ot') || (item | containsProduct : 'otorw'))"
      >
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.DoorHoles' | translate"
        ></app-order-label>
        <app-al-holes
          [item]="item"
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (updateSlot)="updateSlot($event)"
          [order]="order"
        ></app-al-holes>
      </div>
      <div class="item-row flex flex-col gap-6 col-span-6 mt-8">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.MoreAddons' | translate"
        ></app-order-label>
        <app-al-klapptablar
          *ngIf="!((item | containsProduct : 'ot') || (item | containsProduct : 'otorw'))"
          [item]="item"
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (updateSlot)="updateSlot($event)"
        ></app-al-klapptablar>
        <app-al-cartridge
          *ngIf="!((item | containsProduct : 'ot') || (item | containsProduct : 'otorw'))"
          [item]="item"
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (updateSlot)="updateSlot($event)"
        ></app-al-cartridge>
        <app-al-schema
          *ngIf="!((item | containsProduct : 'ot') || (item | containsProduct : 'otorw'))"
          [item]="item"
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (updateSlot)="updateSlot($event)"
        ></app-al-schema>
        <app-al-lamp
          [item]="item"
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (updateSlot)="updateSlot($event)"
        ></app-al-lamp>
        <app-al-entry-top
          [item]="item"
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (updateSlot)="updateSlot($event)"
        ></app-al-entry-top>
        <app-al-entry-bot
          [item]="item"
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (updateSlot)="updateSlot($event)"
        ></app-al-entry-bot>
        <app-al-mounting
          [item]="item"
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (updateSlot)="updateSlot($event)"
        ></app-al-mounting>
      </div>
      <div class="item-row flex flex-col gap-6 col-span-6 mt-8">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.CeilingType' | translate"
        ></app-order-label>
        <app-al-roof
          [item]="item"
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (updateSlot)="updateSlot($event)"
        ></app-al-roof>
      </div>
      <div class="item-row flex flex-col gap-6 col-span-6 mt-8">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.Color' | translate"
        ></app-order-label>
        <app-al-color
          [order]="order"
          [item]="item"
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (updateSlot)="updateSlot($event)"
        ></app-al-color>
      </div>
      <div class="item-row flex flex-col gap-6 col-span-6 mt-8">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.Socket' | translate"
        ></app-order-label>
        <app-al-socket
          [item]="item"
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (updateSlot)="updateSlot($event)"
        ></app-al-socket>
      </div>
    </div>
  </div>
</div>
