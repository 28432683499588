import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  filter,
  first,
  firstValueFrom,
  from,
  lastValueFrom,
  map,
  Observable,
  skip,
  Subscription,
  switchMap,
  tap
} from 'rxjs';
import {
  Order,
  OrderStatusEnum,
  Product,
  ProductLineItem,
  ProductTypeEnum,
  SetAddonProductDTO,
  SetAddonProductsDTO,
  UpdateOrderDto
} from 'src/app/api-client';
import { OrderService } from 'src/app/services/order.service';
import { ProductService } from '../../services/product.service';
import { ConcatDescription } from '../utils/concat-description';

export interface Attachment {
  id: string;
  productNumber: string;
  name: string;
  price: number;
  isReadyonly: boolean;
}

@Component({
  selector: 'app-order-fields',
  templateUrl: './order-fields.component.html',
  styleUrls: ['./order-fields.component.scss']
})
export class OrderFieldsComponent {
  sortedFields$!: Observable<ProductLineItem[] | undefined>;
  hasNoSocket$!: Observable<Boolean>;
  hasNoWall$!: Observable<Boolean>;
  dividerWallIndices$!: Observable<number[]>;

  isAttachmentModalOpen: boolean = false;
  swgAddAddonModalOpen: boolean = false;

  OrderStatusEnum = OrderStatusEnum;

  constructor(
    public orderService: OrderService,
    public productService: ProductService,
    public translate: TranslateService
  ) {}

  attachments$!: Observable<Attachment[]>;

  selectedAddons: {
    [key: string]: { attachment: Attachment; amount: number };
  } = {};

  // Only for frontend, Prices WILL be calculated in API
  attachmentPrices: { [key: string]: number } = {
    arswbw: 3,
    arswbwl: 12,
    trs: 6,
    bb: 2
  };
  updateOrder(currentOrder: Order, field: 'depth' | 'height', value: string) {
    let v: number = parseInt(value);
    const payload: UpdateOrderDto = {
      name: currentOrder.name,
      depth: field === 'depth' ? v : currentOrder.depth,
      height: field === 'height' ? v : currentOrder.height
    };
    this.orderService.updateOrder(currentOrder.id, payload);
  }

  private avkSubcription: Subscription | undefined;
  private alSubcription: Subscription | undefined;
  private swgSubcription: Subscription | undefined;

  ngOnDestroy() {
    if (this.avkSubcription && !this.avkSubcription.closed) this.avkSubcription.unsubscribe();
    if (this.alSubcription && !this.alSubcription.closed) this.alSubcription.unsubscribe();
    if (this.swgSubcription && !this.swgSubcription.closed) this.swgSubcription.unsubscribe();
  }

  async ngOnInit() {
    this.avkSubcription = this.orderService.order$.subscribe((order) => {
      // Prefetch all slots at once to reduce calls
      if (order?.productType === ProductTypeEnum.Avk) {
        this.productService.getSlots(
          new Array(220).fill('').map((value, index) => index.toString()), // Load sets 0-220
          ProductTypeEnum.Avk
        );
      }
    });

    this.alSubcription = this.orderService.order$.subscribe((order) => {
      // Prefetch all slots at once to reduce calls
      if (order?.productType === ProductTypeEnum.Al) {
        this.productService.getSlots(
          new Array(220).fill('').map((value, index) => index.toString()), // Load sets 0-220
          ProductTypeEnum.Al
        );
      }
    });

    this.swgSubcription = this.orderService.order$.subscribe((order) => {
      // Prefetch all slots at once to reduce calls
      if (order?.productType === ProductTypeEnum.Swg) {
        this.productService.getSlots(
          new Array(220).fill('').map((value, index) => index.toString()), // Load sets 0-220
          ProductTypeEnum.Swg
        );
      }
    });

    // Zubehör
    this.attachments$ = from(
      this.productService.api.productControllerFindAllAddonProductsForProduct(ProductTypeEnum.Ars).then((d) => d.data)
    ).pipe(
      switchMap((prods) =>
        this.orderService.order$.pipe(
          filter((order) => order !== undefined),
          map((order) =>
            prods.map((prod, index) => {
              // Amount is in the name xxx_2
              const attachment: Attachment = {
                id: prod.id,
                productNumber: prod.productNumber,
                isReadyonly:
                  order?.lineItems.find((l) => l.isField && l.set.find((s) => s.slot == 13) != undefined) ==
                    undefined && prod.productNumber != 'bb',
                name: ConcatDescription(prod, this.translate.currentLang),
                price: this.attachmentPrices[prod.productNumber]
              };

              const foundAttachment = order?.lineItems.find((l) => l.productId == prod.id);
              const selected = foundAttachment !== undefined && !attachment.isReadyonly;
              const selectedAmount = selected ? foundAttachment.amount : 0;

              this.selectedAddons[prod.productNumber] = {
                attachment,
                amount: selectedAmount
              };
              return attachment;
            })
          )
        )
      )
    );

    this.sortedFields$ = this.orderService.order$.pipe(map((o) => o?.lineItems.sort((a, b) => a.sorting - b.sorting)));
    this.hasNoSocket$ = this.sortedFields$.pipe(map((fields) => fields?.find((f) => f.isSocket) === undefined));
    this.hasNoWall$ = this.sortedFields$.pipe(map((fields) => fields?.find((f) => f.isWall) === undefined));
    this.dividerWallIndices$ = this.sortedFields$.pipe(
      map(
        (fields) =>
          fields
            ?.map((field, index) => {
              if (field.isField) {
                return index;
              } else {
                return undefined;
              }
            })
            .filter((f) => f !== undefined) as number[]
      )
    );
  }

  trackById(index: number, ob: any) {
    return ob.id;
  }

  addSocket() {
    this.orderService.addDefaultSocket();
  }

  async addSwg(order: Order) {
    let sorting =
      order.lineItems.filter((li) => li.isField)[order.lineItems.filter((li) => li.isField).length - 1].sorting + 1;
    this.orderService.addSWG(sorting);
  }

  async addSwgAddon(id: string, amount: number) {
    this.orderService.addAddonProduct(undefined, { productId: id, amount });
  }

  addWall() {
    this.orderService.addDefaultWall();
  }

  toggleSwgAddonModal() {
    this.swgAddAddonModalOpen = !this.swgAddAddonModalOpen;
  }

  submitSwgAddonModal() {
    this.swgAddAddonModalOpen = false;
  }

  toggleAttachmentModal() {
    this.isAttachmentModalOpen = !this.isAttachmentModalOpen;
  }

  submitAttachmentModal() {
    this.isAttachmentModalOpen = false;

    const dto: SetAddonProductsDTO = {
      addons: Object.values(this.selectedAddons)
        .filter((s) => s.amount > 0)
        .map(
          (val) =>
            ({
              id: val.attachment.id,
              amount: val.amount
            } as SetAddonProductDTO)
        )
    };

    this.orderService.setAddonItems(dto);
  }
}
