import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { filter, first, last, map, Observable, takeLast, tap } from 'rxjs';
import { CompanyService } from 'src/app/services/company.service';
import { OrderByOrder, OrderByOrderUser, SearchOrder } from '../../api-client';
import { AuthService } from '../../services/auth.service';
import { OrderService } from '../../services/order.service';
import { TableColumn } from '../table/models/table-column.model';
import { TableEvent } from '../table/models/table-event.model';
import { TableCell, TableRow } from '../table/models/table-row.model';
import { SortAction } from '../table/models/table-sort.model';
import { PaginationEvent } from '../table/table-pagination/table-pagination.component';

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss']
})
export class OrdersTableComponent implements OnInit {
  offset = 0;
  limit = 20;
  orderBy: OrderByOrder = { created: 'DESC' };
  search: SearchOrder = {
    onlyMe: false,
    onlyOrdered: false
  };

  private _selectAll = false;
  set selectAll(selectAll: boolean) {
    this.rowsSelected.forEach((s) => (s.selected = selectAll && s.selectable));
    this._selectAll = selectAll;
    this.somethingSelected = selectAll;
    this.selectedRowsCount = this.rowsSelected.filter((r) => r.selected).length;
  }

  get selectAll() {
    return this._selectAll;
  }

  confirmModalOpen = false;

  somethingSelected = false;
  rowsSelected: { id: string; selectable: boolean; selected: boolean }[] = [];
  selectedRowsCount = 0;
  displayedColumns = ['select', 'name', 'price', 'user', 'created', 'updated', 'type', 'status', 'actions'];

  isConfigurationsFilterOpen = false;

  constructor(
    public companyService: CompanyService,
    public orderService: OrderService,
    public authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.companyService.orders$.subscribe((s) => {
      let index = 0;
      for (const order of s) {
        this.rowsSelected[index] = { id: order.id, selectable: order.status !== 'Ordered', selected: false };
        index++;
      }

      this.selectedRowsCount = this.rowsSelected.filter((r) => r.selected).length;
    });
    this.companyService.loadOrders(this.search, this.orderBy, this.limit, this.offset);
  }

  goToConfigurator(row: TableRow) {
    this.orderService.order$.next(undefined);
    this.router.navigate(['/c', row.id]);
  }

  toggleSelected(index: number) {
    this.rowsSelected[index].selected = !this.rowsSelected[index].selected;
    this.somethingSelected = this.rowsSelected.find((row) => row.selected == true) !== undefined;
    this.selectedRowsCount = this.rowsSelected.filter((r) => r.selected).length;
  }

  async restoreOrders() {
    if (!this.companyService.deletedOrders) return;
    await this.companyService.restoreOrders(this.companyService.deletedOrders);
    this.companyService.deletedOrders = undefined;
    this.companyService.loadOrders(this.search, this.orderBy, this.limit, this.offset);
  }

  async deleteOrders() {
    await this.companyService.deleteOrders(this.rowsSelected.filter((row) => row.selected).map((row) => row.id));
    this.companyService.loadOrders(this.search, this.orderBy, this.limit, this.offset);
    this.selectAll = false;
    this.somethingSelected = false;
  }

  searchOrdersChange() {
    this.companyService.loadOrders(this.search, this.orderBy, this.limit, this.offset);
  }

  searchOrders(event: string) {
    if (event == '') {
      delete this.search.name;
      delete this.search.user;
      this.companyService.loadOrders(this.search, this.orderBy, this.limit, this.offset);
    } else {
      this.search.user = { firstName: event, lastName: event };
      this.search.name = event;
      this.companyService.loadOrders(this.search, this.orderBy, this.limit, this.offset);
    }
  }

  sortOrders(event: Sort) {
    // this.sortedRows = this.rows;

    let colName = event.active;

    if (colName == undefined) return console.error('Colname not found when sorting');
    if (event.direction == undefined || event.direction == '') {
      delete this.orderBy[colName as keyof OrderByOrder];
    } else {
      this.orderBy = {};
      if (colName == 'user') {
        this.orderBy[colName as keyof OrderByOrder] = {
          firstName: event.direction.toUpperCase(),
          lastName: event.direction.toUpperCase()
        } as any;
      } else {
        this.orderBy[colName as keyof OrderByOrder] = event.direction.toUpperCase() as any;
      }
    }
    this.companyService.loadOrders(this.search, this.orderBy, this.limit, this.offset);
  }

  paginateOrders(event: PageEvent) {
    this.offset = event.pageSize * event.pageIndex;
    this.limit = event.pageSize;
    this.companyService.loadOrders(this.search, this.orderBy, this.limit, this.offset);
  }
}
