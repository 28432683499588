import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.scss']
})
export class ConfiguratorComponent implements OnInit {
  constructor(public orderService: OrderService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.initOrder();
  }

  initOrder() {
    this.orderService.loadOrder(this.route.snapshot.params['id']);
  }
}
