import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomCellRenderer } from '../base-renderer/custom-renderer.base';

@Component({
  selector: 'app-date-renderer',
  templateUrl: './date-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRendererComponent extends CustomCellRenderer {
  public get date() {
    const date = new Date(this.data.value);
    const string = date.toLocaleDateString('de', { month: '2-digit', year: 'numeric', day: '2-digit' });
    return string;
  }
}
