import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent {
  @Input()
  name: string = '';

  @Input()
  short: string = '';

  @Input()
  image: string = '';

  @Input()
  id: string = '';

  @Output()
  onClick: EventEmitter<void> = new EventEmitter();
}
