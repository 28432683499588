<div class="w-full bg-white">
    <div class="container flex items-center justify-between h-16 px-4 mx-auto lg:px-8">
        <a [routerLink]="['/']" class="py-4">
            <img src="/assets/svg/logo.svg" class="w-auto h-8" />
        </a>
        <div class="relative flex items-center gap-2 py-4 group" *ngIf="auth.currentUser">
            <div class="relative flex items-center justify-center flex-none w-8 h-8 overflow-hidden rounded-full bg-monza-500">
                <app-icon [icon]="'person'" class="w-5 h-5 text-white"></app-icon>
            </div>
            <div class="flex flex-col">
                <span class="mb-1 font-medium leading-none">{{ auth.currentUser.firstName }} {{ auth.currentUser.lastName }}</span>
                <span class="text-xs leading-none text-gray-500">{{ auth.currentUser.email }}</span>
            </div>
            <div class="absolute right-0 z-10 flex-col hidden w-full gap-2 p-2 text-white border border-white sm:w-56 top-full bg-monza-500 group-hover:flex">
                <a [routerLink]="['/profile']" class="flex items-center gap-2 px-3 py-1.5 hover:bg-monza-600">
                    <app-icon [icon]="'person'" class="w-5 h-5"></app-icon>
                    <span class="font-medium">{{'Nav.Profile'|translate}}</span>
                </a>
                <a href="https://ass-backend.hypetrain.cloud/" target="_blank" class="flex items-center gap-2 px-3 py-1.5 hover:bg-monza-600" *ngIf="auth.currentUser.isSuperAdmin">
                    <app-icon [icon]="'account-tree'" class="w-5 h-5"></app-icon>
                    <span class="font-medium">{{'Nav.AdminPanel'|translate}}</span>
                </a>
                <hr class="border-white">
                <button (click)="auth.logout()" class="flex items-center gap-2 px-3 py-1.5 hover:bg-monza-600">
                    <app-icon [icon]="'logout'" class="w-5 h-5"></app-icon>
                    <span class="font-medium">{{'Nav.Logout'|translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>