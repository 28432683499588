import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface ToastPayload {
  message: string;
  type: 'danger' | 'success';
  time: number;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private message = new Subject<ToastPayload>();

  displayDefaultError() {
    this.message.next({
      message: 'Something went wrong',
      time: 5000,
      type: 'danger'
    });
  }

  displayToast(payload: ToastPayload) {
    this.message.next(payload);
  }

  getToast(): Observable<ToastPayload> {
    return this.message.asObservable();
  }

  constructor() {}
}
