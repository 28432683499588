<div class="grid grid-cols-6 items-start justify-start col-span-6 gap-4" *ngIf="order$ | async as order">
  <app-custom-select
    *ngIf="widths.length > 0"
    [isReadonly]="order.status == OrderStatusEnum.Ordered"
    [label]="'Configuration.Fields.OpenField.Width' | translate"
    [selectedValue]="selectedWidth"
    [options]="widths"
    (cchange)="updateWidth($event)"
    [placeholder]="false"
    class="col-span-2 xl:col-span-1"
  ></app-custom-select>
  <app-custom-select
    *ngIf="heights.length > 0"
    [isReadonly]="order.status == OrderStatusEnum.Ordered"
    [label]="'Configuration.Fields.OpenField.Height' | translate"
    [selectedValue]="selectedHeight"
    [options]="heights"
    (cchange)="updateHeight($event)"
    [placeholder]="false"
    class="col-span-2 xl:col-span-1"
  ></app-custom-select>
  <app-custom-select
    *ngIf="depths.length > 0"
    [isReadonly]="order.status == OrderStatusEnum.Ordered"
    [label]="'Configuration.Fields.OpenField.Depth' | translate"
    [selectedValue]="selectedDepth"
    [options]="depths"
    (cchange)="updateDepth($event)"
    [placeholder]="false"
    class="col-span-2 xl:col-span-1"
  ></app-custom-select>
  <div class="hidden xl:block xl:col-span-2"></div>
  <app-custom-select
    [isReadonly]="order.status == OrderStatusEnum.Ordered"
    unit="×"
    [label]="'Configuration.Fields.OpenField.Amount' | translate"
    [selectedValue]="selectedAmount"
    [options]="amountOptions"
    (cchange)="updateAmount($event.value)"
    [placeholder]="false"
    class="col-span-2 xl:col-span-1"
  ></app-custom-select>
</div>

<ng-container *ngIf="order$ | async as order">
  <div
    class="flex items-start justify-start col-span-6 gap-4 flex-wrap"
    *ngIf="order.status != OrderStatusEnum.Ordered"
  >
    <app-button
      class="btn whitespace-nowrap"
      *ngFor="let prod of filteredProducts$ | async"
      [label]="prod.product.name"
      (onClick)="updateProduct(prod.product, prod.matrix)"
    >
    </app-button>
  </div>
</ng-container>
