import { Component, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFilterFn, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { format, isWeekend } from 'date-fns';
import { de } from 'date-fns/locale';

export const PICK_FORMATS = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: 'input',
    monthYearLabel: 'monthYear',
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};

@Injectable()
class PickDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return format(date, 'dd. MMMM yyyy', { locale: de });
    } else if (displayFormat === 'monthYear') {
      return format(date, 'MMM yyyy', { locale: de });
    } else {
      return date.toDateString();
    }
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }
}

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' }
  ]
})
export class DatepickerComponent {
  dateForm = new UntypedFormControl();

  @Input()
  date!: string;

  @Input()
  minDate: Date | undefined;

  @Input()
  placeholder: string = '';

  @Input()
  isReadonly = false;

  @Output()
  onChange: EventEmitter<MatDatepickerInputEvent<Date, Date | null>> = new EventEmitter();

  ngOnInit() {
    this.dateForm.disable();
  }

  ngOnChanges() {
    if (this.date) {
      this.dateForm.setValue(new Date(this.date), { emitEvent: false });
    }
  }

  filter: DateFilterFn<Date | null> = (date: Date | null) => date != null && !isWeekend(date);
}
