import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwgComponent } from './swg.component';
import { LanguageModule } from '../../../language.module';
import { AtomsModule } from '../../../atoms/atoms.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '../../../pipes/pipes.module';
import { SwgLockComponent } from './slots/lock/lock.component';
import { BaseComponent } from './slots/base/base.component';
import { SwgAddonComponent } from './addon/swg-addon.component';
import { SwgAddonModalComponent } from './addon-modal/addon-modal.component';
import { SwgAccesoriesComponent } from './slots/accesories/accesories.component';
import { SwgAdditionalComponent } from './slots/additional/additional.component';
import { SwgAggregationComponent } from './aggregation/saggregation.component';
import { SWGLabelComponent } from './slots/label/label.component';

@NgModule({
  declarations: [
    SwgComponent,
    SwgLockComponent,
    BaseComponent,
    SwgAddonComponent,
    SwgAddonModalComponent,
    SwgAccesoriesComponent,
    SwgAdditionalComponent,
    SwgAggregationComponent,
    SWGLabelComponent
  ],
  imports: [CommonModule, LanguageModule, AtomsModule, MatTooltipModule, PipesModule],
  exports: [SwgComponent, SwgAddonComponent, SwgAddonModalComponent, SwgAggregationComponent]
})
export class SwgModule {}
