import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CreateProductLineItemDto,
  OrderStatusEnum,
  ProductLineItem,
  UpdateProductLineItemDto
} from '../../../../api-client';
import { OrderService } from '../../../../services/order.service';
import { Option } from '../../../../atoms/custom-select/custom-select.component';

export interface AddSWGSlotDTO {
  slot: number;
  create: CreateProductLineItemDto;
}

export interface UpdateSWGSlotDTO {
  slot: number;
  update: UpdateProductLineItemDto;
}

@Component({
  selector: 'app-swg-addon',
  templateUrl: './swg-addon.component.html',
  styleUrls: ['./swg-addon.component.scss']
})
export class SwgAddonComponent implements OnInit {
  @Input()
  item!: ProductLineItem;

  @Input()
  index!: number;

  isExpanded = false;

  addModalOpen = false;

  OrderStatusEnum = OrderStatusEnum;

  templateName = '';
  createTemplateModal = false;

  selectedAmount!: Option<number>;
  amountOptions: Option<number>[] = new Array(11)
    .fill('')
    .map((_, i) => ({ label: i.toString(), value: i, description: '' }));

  constructor(public readonly orderService: OrderService, public readonly translate: TranslateService) {}

  ngOnInit(): void {}

  removeAddon() {
    this.orderService.removeLineItem(this.item.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item']) {
      this.selectedAmount = this.amountOptions.find((o) => o.value === this.item.amount)!;
    }
  }

  updateAmount(amount: number) {
    if (amount === 0) {
      this.removeAddon();
    } else {
      this.orderService.updateLineItem(this.item.id, { amount });
    }
  }
}
