import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth-guard.service';

import { BranchComponent } from './routes/auth/branch/branch.component';
import { LoginComponent } from './routes/auth/login/login.component';
import { RegisterComponent } from './routes/auth/register/register.component';
import { ForgotComponent } from './routes/auth/forgot/forgot.component';
import { WrapperComponent } from './routes/wrapper/wrapper.component';
import { DashboardComponent } from './routes/wrapper/dashboard/dashboard.component';
import { ProfileComponent } from './routes/wrapper/profile/profile.component';
import { ConfiguratorComponent } from './routes/wrapper/configurator/configurator.component';
import { ResetComponent } from './routes/auth/reset/reset.component';
import { OverviewComponent } from './routes/wrapper/overview/overview.component';

const routes: Routes = [
  {
    path: '',
    component: WrapperComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'c/:id',
        component: ConfiguratorComponent
      },
      {
        path: 'overview/:id',
        component: OverviewComponent
      }
    ]
  },
  // auth
  { path: 'login', component: LoginComponent },
  { path: 'branch', component: BranchComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'reset', component: ResetComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
