import { DoorType, Side } from '../slots/door/door.component';

export const AVK_CONFIG = {
  CEILING: {
    SLOT: 10,
    PRODUCTS: {
      Default: 'avdf',
      Design: 'avdd',
      Gabled: 'avdg',
      Pent: 'avdp'
    }
  },
  BACKWALL: {
    SLOT: 139,
    DOOR_SLOT: 140,
    DEFAULT: 'avrw',
    DEFAULT_DOOR: 'avtl'
  },
  FRONT_H: {
    COMPARTMENT_SLOT_LEFT: 39,
    COMPARTMENT_SLOT_RIGHT: 79,
    DOOR_SLOT_LEFT: 40,
    DOOR_SLOT_RIGHT: 80,
    DEFAULT_DOOR: 'avtl'
  },
  DOOR: {
    DEFAULTS: {
      SIDE: 'left' as Side,
      TYPE: 'single' as DoorType
    },
    META: {
      LEFT: 18,
      RIGHT: 58
    },
    PRODUCT_NUMBERS: ['avtr', 'avtl', 'avt2', 'avtdf'],
    DOOR_MAP: {
      single: {
        left: 'avtl',
        right: 'avtr'
      },
      double: 'avtdf',
      middle: 'avt2',
      same: 'avt2'
    },
    DOOR_REVERSE_MAP: {
      avtl: ['single', 'left'] as [DoorType, Side],
      avtr: ['single', 'right'] as [DoorType, Side],
      avtdf: ['double', 'left'] as [DoorType, Side],
      avt2: ['middle', 'left'] as [DoorType, Side]
    },
    KLAPPTABLAR: {
      NORMAL: 'avkt',
      LEFT: 'avktl',
      RIGHT: 'avktr'
    },
    SCHEMA: {
      PRODUCTS: ['avsf30', 'avsf50']
    },
    COMPARTMENT_LEFT: {
      SLOT_DOOR: 20,
      SLOT_LOCKABLE: 21,
      SLOT_HANDLE: 22,
      SLOT_KLAPPTABLAR: 23,
      SLOT_SCHEMA: 24,
      SLOT_OPENING: 25,
      SLOT_FILTER_TOP: 26,
      SLOT_FILTER_BOTTOM: 27,
      SLOT_GROUND_WIRE: 34
    },
    FRONT_HORIZONTAL_LEFT: {
      SLOT_DOOR: 40,
      SLOT_LOCKABLE: 41,
      SLOT_HANDLE: 42,
      SLOT_KLAPPTABLAR: 43,
      SLOT_SCHEMA: 44,
      SLOT_OPENING: 45,
      SLOT_FILTER_TOP: 46,
      SLOT_FILTER_BOTTOM: 47,
      SLOT_GROUND_WIRE: 54
    },
    COMPARTMENT_RIGHT: {
      SLOT_DOOR: 60,
      SLOT_LOCKABLE: 61,
      SLOT_HANDLE: 62,
      SLOT_KLAPPTABLAR: 63,
      SLOT_SCHEMA: 64,
      SLOT_OPENING: 65,
      SLOT_FILTER_TOP: 66,
      SLOT_FILTER_BOTTOM: 67,
      SLOT_GROUND_WIRE: 74
    },
    FRONT_HORIZONTAL_RIGHT: {
      SLOT_DOOR: 80,
      SLOT_LOCKABLE: 81,
      SLOT_HANDLE: 82,
      SLOT_KLAPPTABLAR: 83,
      SLOT_SCHEMA: 84,
      SLOT_OPENING: 85,
      SLOT_FILTER_TOP: 86,
      SLOT_FILTER_BOTTOM: 87,
      SLOT_GROUND_WIRE: 94
    },
    SIDE_LEFT: {
      SLOT_DOOR: 100,
      SLOT_LOCKABLE: 101,
      SLOT_HANDLE: 102,
      SLOT_KLAPPTABLAR: 103,
      SLOT_SCHEMA: 104,
      SLOT_OPENING: 105,
      SLOT_FILTER_TOP: 106,
      SLOT_FILTER_BOTTOM: 107,
      SLOT_GROUND_WIRE: 114
    },
    SIDE_RIGHT: {
      SLOT_DOOR: 120,
      SLOT_LOCKABLE: 121,
      SLOT_HANDLE: 122,
      SLOT_KLAPPTABLAR: 123,
      SLOT_SCHEMA: 124,
      SLOT_OPENING: 125,
      SLOT_FILTER_TOP: 126,
      SLOT_FILTER_BOTTOM: 127,
      SLOT_GROUND_WIRE: 134
    },
    BACK: {
      SLOT_DOOR: 140,
      SLOT_LOCKABLE: 141,
      SLOT_HANDLE: 142,
      SLOT_KLAPPTABLAR: 143,
      SLOT_SCHEMA: 144,
      SLOT_OPENING: 145,
      SLOT_FILTER_TOP: 146,
      SLOT_FILTER_BOTTOM: 147,
      SLOT_GROUND_WIRE: 154
    }
  },
  SOCKET: {
    SOCKET_SLOT: 200,
    ENTRY_SLOT: 202,
    LIFTING_SLOT: 204,
    NO_SOCKET_PROD: 'avos5040',
    SOCKET_PROD_PREFIX: 'avso'
  }
};
