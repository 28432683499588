<div class="flex min-h-screen">
    <div class="flex flex-col justify-center flex-1 w-full max-w-xl px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="w-full">
            <img class="w-auto h-12 mx-auto" src="assets/svg/logo.svg" alt="Logo ASS">
        </div>
        <div class="w-full mx-auto my-auto">
            <div>
                <h2 class="mt-6 text-3xl font-extrabold text-gray-800">{{'Auth.Register.Register'|translate}}</h2>
                <p class="mt-2 text-sm text-gray-500">
                    {{'Auth.Register.AlreadyRegistered'|translate}}
                    <a [routerLink]="['/login']" class="font-medium text-monza-500 hover:text-monza-600">{{'Auth.Register.Login'|translate}}</a>
                </p>
            </div>
    
            <div class="mt-8">

                <div class="relative flex items-stretch mb-6 text-sm bg-gray-100" *ngIf="!isCompany">
                    <div class="flex items-center justify-center flex-none w-20 text-gray-500 bg-gray-200">
                        <app-icon [icon]="'factory'" class="w-6 h-6">
                            
                        </app-icon>
                    </div>
                    <span class="block p-4">
                        {{'Auth.Register.CompanyRegisterNotice'|translate}} <span class="font-medium text-amber-500">{{ inviteCompanyName }}</span>
                    </span>
                </div>

                <div *ngIf="isCompany" class="w-full mb-6">
                    <app-steps 
                        [steps]="steps" 
                        [active]="active" 
                        (onStepEvent)="this.active = $event">
                    </app-steps>
                </div>

                <!-- no super user -->
                <div class="space-y-6" *ngIf="!isCompany">

                    <app-input 
                        [label]="'Auth.Register.InviteCode'|translate" 
                        [type]="'text'" 
                        [value]="this.token" 
                        [isReadonly]="true">
                    </app-input>

                    <div class="grid grid-cols-2 gap-4">
                        <app-input 
                            [label]="'Auth.Register.Firstname'|translate" 
                            [type]="'text'" 
                            (onChange)="this.firstname = $event">
                        </app-input>
                        <app-input 
                            [label]="'Auth.Register.Lastname'|translate" 
                            [type]="'text'" 
                            (onChange)="this.lastname = $event">
                        </app-input>
                    </div>

                    <app-input 
                        [label]="'Auth.Register.Email'|translate" 
                        [type]="'email'" 
                        [placeholder]="'mail@ass-ag.ch'" 
                        [value]="this.email" 
                        [isReadonly]="isEmailReadOnly" 
                        (onChange)="this.email = $event">
                    </app-input>

                    <app-input 
                        [label]="'Auth.Register.Password'|translate" 
                        [type]="'password'" 
                        (onChange)="this.password = $event">
                    </app-input>
                    <app-input 
                        [label]="'Auth.Register.PasswordConfirm'|translate" 
                        [type]="'password'" 
                        (onChange)="this.passwordConfirmation = $event">
                    </app-input>
    
                    <div class="flex items-center justify-between">
                        <app-checkbox 
                            (onToggle)="this.privacyChecked = $event">
                            <a href="https://www.ass-ag.ch/datenschutzerklaerung/" target="_blank" class="font-medium text-monza-500 hover:text-monza-600">
                               {{'Auth.Register.Privacy'|translate}}
                            </a>
                            {{'Auth.Register.PrivacyText'|translate}}
                        </app-checkbox>
                    </div>
                    
                    <div class="flex items-center justify-between" *ngIf="!isCompany">
                        <app-checkbox 
                            [label]="'Auth.Register.SubAccountLabel'|translate: {companyName:inviteCompanyName}"  
                            (onToggle)="this.warningChecked = $event">
                        </app-checkbox>
                    </div>

                    <div *ngIf="error" class="flex items-center gap-2 text-monza-500">
                        <app-icon class="flex-none w-5 h-5" 
                            [icon]="'error'">
                        </app-icon>
                        <span class="text-sm font-medium">{{ error }}</span>
                    </div>

                    <app-button 
                        [label]="'Registrieren'" 
                        [size]="'xl'" 
                        [fullWidth]="true" 
                        (onClick)="registerToken()">
                    </app-button>
                </div>

                <!-- step 1 -->
                <div class="space-y-6" *ngIf="active === 0 && isCompany">
                    
                    <div class="grid grid-cols-2 gap-4">
                        <app-input 
                            [label]="'Auth.Register.Firstname'|translate" 
                            [type]="'text'" 
                            (onChange)="this.firstname = $event">
                        </app-input>
                        <app-input 
                            [label]="'Auth.Register.Lastname'|translate" 
                            [type]="'text'" 
                            (onChange)="this.lastname = $event">
                        </app-input>
                    </div>

                    <app-input 
                        [label]="'Auth.Register.Email'|translate" 
                        [type]="'email'" 
                        [placeholder]="'mail@ass-ag.ch'" 
                        (onChange)="this.email = $event">
                    </app-input>

                    <app-input 
                        [label]="'Auth.Register.Password'|translate" 
                        [type]="'password'" 
                        (onChange)="this.password = $event">
                    </app-input>
                    <app-input 
                        [label]="'Auth.Register.PasswordConfirm'|translate" 
                        [type]="'password'" 
                        (onChange)="this.passwordConfirmation = $event">
                    </app-input>
    
                    <div class="flex items-center justify-between">
                        <app-checkbox 
                            label="" 
                            (onToggle)="this.privacyChecked = $event">
                            <a href="https://www.ass-ag.ch/datenschutzerklaerung/" target="_blank" class="font-medium text-monza-500 hover:text-monza-600">
                                {{'Auth.Register.Privacy'|translate}}
                             </a>
                             {{'Auth.Register.PrivacyText'|translate}}
                        </app-checkbox>
                    </div>

                    <div class="flex items-center justify-between cursor-help">
                        <app-checkbox 
                            [label]="'Auth.Register.AdminLabel'|translate" 
                            (onToggle)="this.warningChecked = $event">
                        </app-checkbox>
                    </div>

                    <div *ngIf="error" class="flex items-center gap-2 text-monza-500">
                        <app-icon class="flex-none w-5 h-5" 
                            [icon]="'error'">
                        </app-icon>
                        <span class="text-sm font-medium">{{ error }}</span>
                    </div>
    
                    <app-button 
                        [label]="'Auth.Register.Continue'|translate" 
                        [size]="'xl'" 
                        [fullWidth]="true" 
                        (onClick)="nextStep()">
                    </app-button>
                </div>

                <!-- step 2 -->
                <div class="space-y-6" *ngIf="active === 1 && isCompany">
                    <app-input 
                        [label]="'Auth.Register.CompanyName'|translate" 
                        [type]="'text'" 
                        [placeholder]="'ASS Alu-Schaltschränke AG'" 
                        (onChange)="this.companyName = $event">
                    </app-input>
                    <app-input 
                        [label]="'Auth.Register.Phone'|translate" 
                        [type]="'text'" 
                        [placeholder]="'+49 123 456 789'" 
                        (onChange)="this.phone = $event">
                    </app-input>
                    <div class="grid grid-cols-2 gap-4">
                        <app-input 
                            [label]="'Auth.Register.Street'|translate" 
                            [type]="'text'" 
                            [placeholder]="'Haupstraße 22'" 
                            (onChange)="this.street = $event">
                        </app-input>
                        <app-input 
                            [label]="'Auth.Register.Land'|translate" 
                            [type]="'text'" 
                            [placeholder]="'Schweiz'" 
                            (onChange)="this.country = $event">
                        </app-input>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <app-input 
                            [label]="'Auth.Register.Postal'|translate" 
                            [type]="'text'" 
                            [placeholder]="'8564'" 
                            (onChange)="this.postal = $event">
                        </app-input>
                        <app-input 
                            [label]="'Auth.Register.City'|translate" 
                            [type]="'text'" 
                            [placeholder]="'Wäldi'" 
                            (onChange)="this.city = $event">
                        </app-input>
                    </div>
                    <div *ngIf="error" class="flex items-center gap-2 text-monza-500">
                        <app-icon class="flex-none w-5 h-5" 
                            [icon]="'error'">
                        </app-icon>
                        <span class="text-sm font-medium">{{ error }}</span>
                    </div>
    
                    <app-button 
                        [label]="'Auth.Register.Register'|translate"
                        [size]="'xl'" 
                        [fullWidth]="true" 
                        (onClick)="registerCompany()">
                    </app-button>
                </div>

            </div>
        </div>
    </div>
    <!-- image -->
    <app-preview class="relative flex-1 hidden w-0 lg:block">
        
    </app-preview>
  </div>