import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from './services/cookie.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {
  param = { value: 'world' };

  constructor(translate: TranslateService, private cookie: CookieService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(localStorage.getItem('ass_language') ?? 'de');
  }
}
