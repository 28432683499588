<div class="flex flex-col w-full gap-4" *ngIf="orderService.order$ | async as order">
  <div class="grid grid-cols-2 lg:grid-cols-4 p-8 bg-white">
    <div>{{'OrderOverview.CustomerNumber'|translate}}</div>
    <div *ngIf="companyService">{{ (companyService.company$ | async)?.abacusId }}</div>
    <div>{{'OrderOverview.Commission'|translate}}</div>
    <div>{{ order.name }}</div>
    <div>{{'OrderOverview.Reference'|translate}}</div>
    <div>{{order.reference ? order.reference : "-"}}</div>
    <div>{{'OrderOverview.DeliveryDate'|translate}}</div>
    <div>{{order.deliveryDate ? (order.deliveryDate | date: "dd.MM.yyyy") : "-"}}</div>
  </div>
  <div class="overflow-auto bg-white">
    <div class="p-8 space-y-4 bg-white min-w-max lg:min-w-fit">
      <div class="grid grid-cols-12 gap-2 text-sm font-medium text-gray-500">
        <div>{{'OrderOverview.Position'|translate}}</div>
        <div class="col-span-3">
          {{'OrderOverview.Description'|translate}}
        </div>
        <div>{{'OrderOverview.ProtectionType'|translate}}</div>
        <div class="text-right">{{'OrderOverview.Amount'|translate}}</div>
        <div>{{'OrderOverview.Unit'|translate}}</div>
        <div class="col-span-2 text-right">{{'OrderOverview.SinglePrice'|translate}}</div>
        <div class="col-span-2 text-right">{{'OrderOverview.Price'|translate}}</div>
        <div class="flex justify-end"><app-icon [icon]="'keyboard-arrow-left'" class="w-5 h-5 mr-2"></app-icon></div>
      </div>
      <hr />
      <ng-container *ngIf="lineItems$ | async as lineItems">
        <div *ngFor="let item of lineItems | sort" class="grid items-center grid-cols-12 gap-2 text-sm font-semibold">
          <div>{{ item.sorting }}</div>
          <div class="col-span-3 py-1" *ngIf="item.isWall || item.isField || item.isSocket">
            {{ item |translatedName: translate.currentLang }}
            <ng-container *ngIf="item.isSocket && item.custom">
              <br>
              <span class="font-normal">{{item.custom.split(',').length}}-teilig {{item.custom}}</span>
            </ng-container>
          </div>
          <div class="col-span-3 py-1" *ngIf="!item.isWall && !item.isField && !item.isSocket">
            {{ item|translatedDescription: translate.currentLang }}
            <ng-container *ngIf="item.isLabel">
              <br>
              <span class="font-normal">{{item.custom}}</span>
            </ng-container>
          </div>
          <div class="col-span-1">
            <app-badge *ngIf="item.isField" [label]="'IP' + item.ipProtectionType" [type]="'danger'"></app-badge>
          </div>
          <div class="text-right">{{ item.amount }}</div>
          <div>{{'OrderOverview.SingleUnit'|translate}}</div>
          <div class="col-span-2 text-right">CHF {{ item.price / item.amount| currency:' ':'symbol':'1.2-2' }}</div>
          <div class="col-span-2 text-right">CHF {{ item.price | currency:' ':'symbol':'1.2-2' }}</div>
          <div class="flex justify-end col-span-1">
            <app-button 
              *ngIf="item.isField" 
              (onClick)="toggleField(item.id)" 
              [icon]="openFieldId == item.id ? 'keyboard-arrow-down' : 'keyboard-arrow-left'" 
              [onlyIcon]="true" 
              [size]="'xs'" 
              [type]="'light'"
            ></app-button>
          </div>
          <div class="grid overflow-hidden items-center grid-cols-12 gap-2 text-sm font-semibold col-span-12" *ngIf="item.isField && openFieldId == item.id">
            <ng-container *ngFor="let set of sortSet(item.set)">
              <div></div>
              <div class="col-span-4 font-normal">
                <span>{{ set | concatDescription: translate.currentLang }}</span>  
                <div class="border-b border-gray-500 w-full" *ngIf="dividerProducts.includes(set.productNumber)"></div>
              </div>
              <div class="font-normal text-right">{{ set.amount + set.amount2 + set.amount3 }}</div>
              <div class="font-normal">{{'OrderOverview.SingleUnit'|translate}}</div>
              <div class="col-span-2"></div>
              <div class="col-span-2"></div>
              <div class="col-span-1"></div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <hr>
    <div class="p-8 space-y-2 text-sm bg-white">
      <div class="flex items-center justify-between">
        <span>{{'OrderOverview.SubTotal'|translate}}</span>
        <span class="font-mono">CHF {{ order.priceSum | currency:' ':'symbol':'1.2-2' }}</span>
      </div>
      <app-discount class="block"></app-discount>
      <div class="flex items-center justify-between" *ngIf="order.productType !== 'AVK'">
        <span>{{'OrderOverview.ShippingCost'|translate}}</span>
        <span class="font-mono">CHF {{ order.deliveryFee | currency:' ':'symbol':'1.2-2' }}</span>
      </div>
      <div class="flex items-center justify-between">
        <span>{{ 'OrderOverview.Mwst'|translate:({price:order.nettoPrice | currency:' ':'symbol':'1.2-2'}) }}</span>
        <span class="font-mono">CHF {{ order.nettoPrice / 100 * 8.1 | round  | currency:' ':'symbol':'1.2-2' }}</span>
      </div>
      <hr />
      <div class="flex items-center justify-between">
        <span *ngIf="order.productType !== 'AVK'" class="font-semibold">{{'OrderOverview.Total'|translate}}</span>
        <span *ngIf="order.productType === 'AVK'" class="font-semibold">{{'OrderOverview.TotalAVK'|translate}}</span>
        <span class="font-semibold font-mono">CHF {{ order.nettoPrice / 100 * 108.1 | round | currency:' ':'symbol':'1.2-2' }}</span>
      </div>
    </div>
  </div>
</div>