<div class="relative flex items-start">
    <div class="flex items-center h-5">
        <input 
            [disabled]="isReadonly" 
            (change)="change()" 
            [(ngModel)]="checked" 
            [id]="id" [attr.aria-describedby]="id + '-description'" 
            [name]="id" 
            type="checkbox" 
            class="w-4 h-4 border-gray-300 cursor-pointer text-monza-500 focus:ring-monza-400 transition-all duration-200" 
            [ngClass]="{'ring-monza-400 ring-2 ring-offset-2': highlight}"
        >
    </div>
    <div class="ml-3 text-sm">
        <label [for]="id" class="font-medium text-gray-600" [ngClass]="{'block sm:hidden': isMobileLabel}">
            <span *ngIf="label" [innerHTML]="label"></span>
            <ng-content *ngIf="!label"></ng-content>
        </label>
        <p *ngIf="description" id="offers-description" class="text-gray-400">{{description}}</p>
    </div>    
    <app-helper *ngIf="tooltip" matTooltipPosition="above" [matTooltip]="tooltip"></app-helper>
</div>