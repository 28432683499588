<div
  class="grid grid-cols-4 items-center justify-start col-span-6 gap-4"
  *ngIf="additionalProducts$ | async as additionalProducts"
>
  <app-custom-select
    [isReadonly]="order.status == OrderStatusEnum.Ordered"
    [label]="('Configuration.Fields.Addon' | translate) + ' 1'"
    [placeholder]="false"
    [selectedValue]="selectedAddon1!"
    [options]="additionalProducts"
    (cchange)="onAddAddon($event,1, selectedAddon1?.meta?.slot)"
    class="col-span-2 xl:col-span-1"
  ></app-custom-select>
  <app-custom-select
    [isReadonly]="order.status == OrderStatusEnum.Ordered"
    [label]="('Configuration.Fields.Addon' | translate) + ' 2'"
    [placeholder]="false"
    [selectedValue]="selectedAddon2!"
    [options]="additionalProducts"
    (cchange)="onAddAddon($event,2,selectedAddon2?.meta?.slot)"
    class="col-span-2 xl:col-span-1"
  ></app-custom-select>
</div>