<div class="grid grid-cols-6 items-start justify-start sm:flex-nowrap col-span-6 gap-4">
  <div class="flex col-span-6">
    <app-checkbox
      [isReadonly]="order.status == OrderStatusEnum.Ordered"
      [label]="labelActive ? '' : ('Configuration.Label.Add' | translate)"
      class="relative self-end bottom-2.5 mr-4"
      [(checked)]="labelActive"
      (checkedChange)="updateLabelActive()"
    ></app-checkbox>
    <app-input
      *ngIf="labelActive"
      class="w-full"
      [limit]="40"
      [label]="'Configuration.Label.Labels.Text' | translate"
      [value]="labelProduct?.custom"
      [isReadonly]="order.status == OrderStatusEnum.Ordered"
      (valueChange)="
        updateLabel($event, this.font.value, this.fontSize.value, this.labelPosition.value, this.color.value)
      "
    ></app-input>
  </div>
  <app-custom-select
    *ngIf="labelActive"
    class="w-full col-span-4 lg:col-span-2"
    [placeholder]="false"
    [label]="'Configuration.Label.Labels.Font' | translate"
    [selectedValue]="font"
    [options]="fontOptions"
    [labelStyle]="{ 'font-family': this.font.label + ', sans-serif' }"
    [isReadonly]="order.status == OrderStatusEnum.Ordered"
    (cchange)="
      updateLabel(
        this.labelProduct?.custom,
        $event.value,
        this.fontSize.value,
        this.labelPosition.value,
        this.color.value
      )
    "
  ></app-custom-select>
  <ng-template #tooltipTpl class="bg-white">
    <div class="text-center w-full py-2">Schriftgröße in Versalhöhe</div>
    <img src="assets/schriftgroesse.png" height="240" width="527" />
  </ng-template>
  <app-custom-select
    *ngIf="labelActive"
    [labelTooltip]="tooltipTpl"
    class="w-full col-span-2 lg:col-span-1"
    [label]="'Configuration.Label.Labels.Size' | translate"
    [unit]="'mm'"
    [options]="fontSizeOptions"
    [selectedValue]="fontSize"
    [placeholder]="false"
    [isReadonly]="order.status == OrderStatusEnum.Ordered"
    (cchange)="
      updateLabel(this.labelProduct?.custom, this.font.value, $event.value, this.labelPosition.value, this.color.value)
    "
  ></app-custom-select>
  <app-custom-select
    *ngIf="labelActive"
    class="w-full col-span-2 lg:col-span-1"
    [label]="'Configuration.Label.Labels.Color' | translate"
    [options]="colorOptions"
    [selectedValue]="color"
    [placeholder]="false"
    [isReadonly]="order.status == OrderStatusEnum.Ordered"
    (cchange)="
      updateLabel(
        this.labelProduct?.custom,
        this.font.value,
        this.fontSize.value,
        this.labelPosition.value,
        $event.value
      )
    "
  ></app-custom-select>
  <app-custom-select
    *ngIf="labelActive"
    [placeholder]="false"
    class="w-full col-span-2 lg:col-span-2"
    [label]="'Configuration.Label.Labels.Position' | translate"
    [options]="labelPositionOptions"
    [selectedValue]="labelPosition"
    [isReadonly]="order.status == OrderStatusEnum.Ordered"
    (cchange)="
      updateLabel(this.labelProduct?.custom, this.font.value, this.fontSize.value, $event.value, this.color.value)
    "
  ></app-custom-select>
</div>
