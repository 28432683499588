import { Pipe, PipeTransform } from '@angular/core';
import { Product, ProductLineItem } from './api-client';

@Pipe({
  name: 'concatDescription'
})
export class ConcatDescriptionPipe implements PipeTransform {
  transform(item: ProductLineItem | Product, langKey: string): string {
    const description = langKey === undefined || langKey !== 'fr' ? item.description : item.descriptionFr;
    const additionalDescription =
      langKey === undefined || langKey !== 'fr' ? item.additionalDescription : item.additionalDescriptionFr;

    let description1 = description.trim().replace(/-$/g, '');
    const description2 = additionalDescription;

    if (
      !description.endsWith(' ') &&
      !description.endsWith('-') &&
      !description.endsWith('- ') &&
      !description2.startsWith(' ')
    ) {
      description1 = description1 + ' ';
    }
    return description1 + description2;
  }
}
