<div class="container px-4 py-12 lg:py-20 mx-auto space-y-16 lg:px-8">
    <div class="flex flex-col">
        <span class="text-3xl font-bold uppercase">{{'Profile.Header'|translate}}</span>
        <span class="text-gray-500">{{'Profile.SubHeader'|translate}}</span>
    </div>
    <div class="w-full p-8 bg-white">
        <div class="grid grid-cols-1 gap-y-4 xl:gap-8 xl:grid-cols-12" *ngIf="auth.currentUser">
            <div class="space-y-4 xl:col-span-3">
                <app-input [label]="'Profile.UserdataForm.Firstname'|translate" [(value)]="auth.currentUser.firstName"></app-input>
                <app-input [label]="'Profile.UserdataForm.Lastname'|translate" [(value)]="auth.currentUser.lastName"></app-input>
            </div>
            <div class="space-y-4 xl:col-span-3">
                <app-input [label]="'Profile.UserdataForm.Email'|translate" [type]="'email'" [(value)]="auth.currentUser.email"></app-input>
                <app-input [label]="'Profile.UserdataForm.OldPassword'|translate" [type]="'password'" [(value)]="oldPassword"></app-input>
            </div>
            <div class="space-y-4 xl:col-span-3">
                <app-input [label]="'Profile.UserdataForm.NewPassword'|translate" [type]="'password'" [placeholder]="'Profile.UserdataForm.PasswordPlaceholder'|translate" [optional]="true" [(value)]="password"></app-input>
                <app-input [label]="'Profile.UserdataForm.NewPasswordConfirm'|translate" [type]="'password'" [optional]="true" [(value)]="passwordConfirm"></app-input>
            </div>
            <!-- admin/company panel -->
            <div class="h-full mt-4 border-gray-200 xl:mt-0 xl:col-span-3 xl:pl-8 xl:border-l">
                <div  class="flex flex-col justify-between h-full p-4 text-white bg-monza-500">
                    <div>
                        <span class="block text-sm font-medium text-gray-200">{{'Profile.UserdataForm.YourCompany'|translate}}</span>
                        <span class="block font-semibold">{{ ( this.companyService.company$ | async )?.name }}</span>
                    </div>                
                    <div class="mt-2">
                        <span class="block text-sm font-medium text-gray-200">{{'Profile.UserdataForm.Admin'|translate}}</span>
                        <span class="block">{{ ( this.companyService.company$ | async )?.admin?.firstName }} {{ ( this.companyService.company$ | async )?.admin?.lastName }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- footer -->
        <div class="p-8 mt-12 -mx-8 -mb-8 lg:mt-8 bg-gray-50">
            <div class="flex justify-end">
                <app-button [label]="'Profile.UserdataForm.Submit'|translate" [icon]="'done'" [type]="'secondary'" (onClick)="saveToast()"></app-button>
            </div>
        </div>
    </div>

    <!-- <app-orders-table></app-orders-table> -->

    <app-company></app-company>

</div>