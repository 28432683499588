import { Component, Input, OnInit } from '@angular/core';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { BaseAVKSlotComponent } from '../base/base-slot.component';
import { ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, tap } from 'rxjs';
import { ProductService } from '../../../../../services/product.service';

@Component({
  selector: 'app-avk-floor',
  templateUrl: './floor.component.html',
  styleUrls: ['./floor.component.scss']
})
export class FloorComponent extends BaseAVKSlotComponent {
  @Input()
  item!: ProductLineItem;

  floorOptions$: Observable<Option[]> = this.productService.getSlot(190, ProductTypeEnum.Avk).pipe(
    map((prods) =>
      prods
        .filter((prod) => ['avbodk', 'avbod', 'avbgb06040'].includes(prod.productNumber))
        .map((prod) => {
          if (prod.productNumber == 'avbgb06040') {
            prod.additionalDescription = '';
            prod.additionalDescriptionFr = '';
          }
          return prod;
        })
        .sort((a, b) => a.sorting - b.sorting)
    ),
    this.productToOptionsPipe(190, null),
    tap((res) => (this.selectedFloorOption = res.selected)),
    map((res) => res.res)
  );
  selectedFloorOption?: Option = undefined;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  override initItem(): void {}
  override updateItem(firstChange?: boolean | undefined): void {}

  changeFloor(productId: string) {
    this.addSlot.emit({ create: { productId }, slot: 190 });
  }
}
