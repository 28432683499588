<div class="grid grid-cols-6 items-center justify-start col-span-6 gap-4">
  <app-custom-select
    *ngIf="lampOptions$ | async as lampOptions"
    class="col-span-6"
    label="Leuchten"
    [label]="'Configuration.Fields.OpenField.Lamps' | translate"
    [options]="lampOptions"
    [selectedValue]="selectedLamp!"
    (cchange)="addSlotItem(lampSlot, $event)"
  >
  </app-custom-select>
</div>
