import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { AddAVKSlotDTO } from '../../avk.component';
import { BaseAVKSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { Side } from '../door/door.component';
import { Observable, map } from 'rxjs';
import { ProductService } from '../../../../../services/product.service';
import { ToastService } from '../../../../../services/toast.service';
import { AVK_CONFIG } from '../../config/avk.config';

export type HorizontalType = 'fix' | 'removable' | 'door';

@Component({
  selector: 'app-avk-front-horizontal',
  templateUrl: './front-horizontal.component.html',
  styleUrls: ['./front-horizontal.component.scss']
})
export class FrontHorizontalComponent extends BaseAVKSlotComponent implements OnInit {
  @Input()
  item!: ProductLineItem;

  @Input()
  side: Side = 'left';

  @Input()
  additionalCompartment = false;

  get currentCompartmentSlot() {
    return this.side == 'left' ? AVK_CONFIG.FRONT_H.COMPARTMENT_SLOT_LEFT : AVK_CONFIG.FRONT_H.COMPARTMENT_SLOT_RIGHT;
  }

  get currentDoorSlot() {
    return this.side == 'left' ? AVK_CONFIG.FRONT_H.DOOR_SLOT_LEFT : AVK_CONFIG.FRONT_H.DOOR_SLOT_RIGHT;
  }

  compartmentProduct$: Observable<Product> | undefined = undefined;
  compartmentLineItem: ProductLineItem | undefined = undefined;

  constructionTypes: Option[] = [
    {
      description: '',
      label: this.translate.instant('Configuration.AVK.FrontHorizontal.TypeFix'),
      value: 'fix'
    },
    {
      description: '',
      label: this.translate.instant('Configuration.AVK.FrontHorizontal.TypeRemovable'),
      value: 'removable'
    },
    {
      description: '',
      label: this.translate.instant('Configuration.AVK.FrontHorizontal.TypeDoor'),
      value: 'door'
    }
  ];
  selectedConstructionType = this.constructionTypes[0];

  constructor(
    private readonly translate: TranslateService,
    private readonly productService: ProductService,
    private readonly toastService: ToastService
  ) {
    super(translate);
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if (changes['item'] || changes['side']) {
      this.updateItem();
    }
  }

  override initItem(): void {}

  override updateItem(firstChange?: boolean | undefined): void {
    this.compartmentProduct$ = this.productService
      .getSlot(this.currentCompartmentSlot, ProductTypeEnum.Avk)
      .pipe(map((p) => p[0]));

    this.compartmentLineItem = this.item.set.find((p) => p.slot == this.currentCompartmentSlot);

    if (this.item.set.some((p) => p.slot == this.currentDoorSlot)) {
      this.selectedConstructionType = this.constructionTypes.find((c) => c.value == 'door')!;
    } else if (this.compartmentLineItem) {
      this.selectedConstructionType = this.constructionTypes.find((c) => c.value == 'fix')!;
    }
  }

  updateHeight(height: number) {
    this.updateSlot.emit({ update: { height }, slot: this.currentCompartmentSlot });
  }

  toggleCompartment(compartmentProduct: Product, checked: boolean) {
    if (checked) {
      this.addSlot.emit({
        create: {
          productId: compartmentProduct.id,
          height: this.item.height ? Math.ceil(this.item.height / 400) * 100 : 300
        },
        slot: this.currentCompartmentSlot
      });
    } else {
      this.removeSlot.emit(this.currentCompartmentSlot);
      this.selectedConstructionType = this.constructionTypes.find((c) => c.value == 'fix')!;
    }
  }

  setType(type: HorizontalType) {
    if (type === 'fix') {
      this.removeSlot.emit(this.currentDoorSlot);
    } else if (type === 'door') {
      this.addDoor();
    } else if (type === 'removable') {
      // Set type
    }
  }

  async addDoor() {
    const slot = this.currentDoorSlot;
    const prods = await this.productService.getSlotAsync(slot, ProductTypeEnum.Avk);
    if (!prods) {
      this.toastService.displayDefaultError();
      return;
    }
    const defaultDoor = prods.find((p) => p.productNumber == AVK_CONFIG.FRONT_H.DEFAULT_DOOR);
    if (!defaultDoor) {
      this.toastService.displayDefaultError();
      return;
    }

    this.addSlot.emit({
      create: { productId: defaultDoor.id },
      slot
    });
  }
}
