import { Component, Input, OnInit } from '@angular/core';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { BaseAVKSlotComponent } from '../base/base-slot.component';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map, tap } from 'rxjs';
import { ToastService } from '../../../../../services/toast.service';
import { AVK_CONFIG } from '../../config/avk.config';

@Component({
  selector: 'app-avk-socket',
  templateUrl: './socket.component.html',
  styleUrls: ['./socket.component.scss']
})
export class SocketComponent extends BaseAVKSlotComponent {
  @Input()
  item!: ProductLineItem;

  socketOptions$: Observable<Option[]> = this.productService
    .getSlot(AVK_CONFIG.SOCKET.SOCKET_SLOT, ProductTypeEnum.Avk)
    .pipe(
      map((sockets) =>
        sockets
          .filter(
            (socket) =>
              socket.productNumber == AVK_CONFIG.SOCKET.NO_SOCKET_PROD ||
              socket.productNumber.includes(AVK_CONFIG.SOCKET.SOCKET_PROD_PREFIX)
          )
          .sort((a, b) => a.sorting - b.sorting)
      ),
      this.productToOptionsPipe(200, null),
      tap((res) => (this.selectedSocketOption = res.selected)),
      map((res) => {
        res.res[0].meta = false;
        return res.res;
      })
    );
  selectedSocketOption: Option | undefined;

  liftingEyesProduct$ = this.productService
    .getSlot(AVK_CONFIG.SOCKET.LIFTING_SLOT, ProductTypeEnum.Avk)
    .pipe(map((p) => p[0]));
  hasLiftingEyes = false;

  entryProducts$ = this.productService.getSlot(AVK_CONFIG.SOCKET.ENTRY_SLOT, ProductTypeEnum.Avk);

  hasEntry = false;

  sideOptions: Option[] = [
    {
      description: '',
      label: this.translate.instant('Configuration.AVK.Sides.Left'),
      value: 'l'
    },
    {
      description: '',
      label: this.translate.instant('Configuration.AVK.Sides.Right'),
      value: 'r'
    },
    {
      description: '',
      label: this.translate.instant('Configuration.AVK.Sides.Both'),
      value: 'b'
    }
  ];
  selectedSide = this.sideOptions[0];

  sizeOptions: Option[] = [
    {
      description: '',
      label: this.translate.instant('Configuration.AVK.Socket.Sizes.TypA'),
      value: 'k'
    },
    {
      description: '',
      label: this.translate.instant('Configuration.AVK.Socket.Sizes.TypB'),
      value: 'g'
    }
  ];
  selectedSize = this.sizeOptions[0];

  constructor(
    private readonly translate: TranslateService,
    private readonly productService: ProductService,
    private readonly toastService: ToastService
  ) {
    super(translate);
  }

  override initItem(): void {}
  override updateItem(firstChange?: boolean | undefined): void {
    this.hasLiftingEyes = this.item.set.some((s) => s.slot == AVK_CONFIG.SOCKET.LIFTING_SLOT);
    this.hasEntry = this.item.set.some((s) => s.slot === AVK_CONFIG.SOCKET.ENTRY_SLOT);

    const entry = this.item.set.find((s) => s.slot === AVK_CONFIG.SOCKET.ENTRY_SLOT);
    this.selectedSide =
      this.sideOptions.find((opt) => opt.value == entry?.productNumber.slice(-1)) ?? this.sideOptions[0];
    this.selectedSize =
      this.sizeOptions.find((opt) => opt.value == entry?.productNumber.slice(-2, -1)) ?? this.sizeOptions[0];
  }

  selectEntry(checked: boolean, products: Product[], side: string, size: string) {
    if (!checked) {
      this.removeSlot.emit(AVK_CONFIG.SOCKET.ENTRY_SLOT);
      return;
    }

    const prod = products.find((product) => product.productNumber.endsWith(`${size}${side}`));

    if (!prod) {
      this.toastService.displayDefaultError();
      return;
    }

    this.addSlot.emit({ create: { productId: prod.id }, slot: AVK_CONFIG.SOCKET.ENTRY_SLOT });
  }

  selectHeight(opt: Option) {
    if (opt.meta) {
      this.addSlot.emit({ create: { productId: opt.value }, slot: AVK_CONFIG.SOCKET.SOCKET_SLOT });
    } else {
      this.addSlot.emit({ create: { productId: opt.value }, slot: AVK_CONFIG.SOCKET.SOCKET_SLOT });
    }
  }

  toggleLiftingEyes(product: Product, checked: boolean) {
    if (checked) {
      this.addSlot.emit({ create: { productId: product.id }, slot: AVK_CONFIG.SOCKET.LIFTING_SLOT });
    } else {
      this.removeSlot.emit(AVK_CONFIG.SOCKET.LIFTING_SLOT);
    }
    this.hasLiftingEyes = checked;
  }
}
