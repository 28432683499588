import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import axios from 'axios';
import { ReplaySubject } from 'rxjs';
import {
  AuthenticationApi,
  Configuration,
  ConfigurationParameters,
  ForgotPassswordPayload,
  LoginPayload,
  RegisterPayload,
  RegisterWithTokenPayload,
  ResetPassswordPayload,
  User
} from '../api-client';
import { ASS_SERVICE_CONF } from './conf';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  api: AuthenticationApi;
  token: string | null;

  currentUser: User | undefined;
  user$: ReplaySubject<User> = new ReplaySubject();

  apiConfig: Configuration | undefined;

  apiConfig$: ReplaySubject<Configuration | undefined> = new ReplaySubject();

  errorMessage = '';

  constructor(public jwtHelper: JwtHelperService, public router: Router, private translate: TranslateService) {
    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response?.data?.message == 'API.Auth.Error.ForcePasswordReset' &&
          location.pathname !== '/login' &&
          location.pathname !== '/reset' &&
          location.pathname !== '/register'
        ) {
          this.router.navigate(['login']);
        }
        throw err;
      }
    );
    axios.interceptors.request.use(
      (req) => {
        if (req.headers) {
          req.headers['ass-lang'] = translate.currentLang;
        }
        return req;
      },
      (err) => err
    );
    this.token = localStorage.getItem('ass_token');
    if (this.token !== null) {
      const configParameters: ConfigurationParameters = {
        accessToken: this.token as string
      };
      this.apiConfig = new Configuration(configParameters);
      this.api = new AuthenticationApi(this.apiConfig, ASS_SERVICE_CONF.BASE_URL);
      this.getUser();
    } else {
      this.api = new AuthenticationApi(undefined, ASS_SERVICE_CONF.BASE_URL);
    }
  }

  async getUser() {
    try {
      const user = (await this.api.authControllerGetLoggedInUser()).data;
      this.user$.next(user);
      this.currentUser = user;
      return user;
    } catch (e) {
      if (location.pathname !== '/login' && location.pathname !== '/reset' && location.pathname !== '/register') {
        this.router.navigate(['login']);
      }
      return undefined;
    }
  }

  async register(payload: RegisterPayload) {
    return await this.api.authControllerRegister(payload);
  }

  registerWithToken(token: string, payload: RegisterWithTokenPayload) {
    return this.api.authControllerRegisterWithToken(token, payload);
  }

  forgot(payload: ForgotPassswordPayload) {
    try {
      this.api.authControllerForgotPassword(payload);
    } catch (error) {
      console.error(error);
    }
  }

  async reset(payload: ResetPassswordPayload) {
    try {
      const data = (await this.api.authControllerResetPasswordWithToken(payload)).data;

      localStorage.setItem('ass_token', data.accessToken);
      this.token = data.accessToken;

      const configParameters: ConfigurationParameters = {
        accessToken: this.token
      };
      this.apiConfig = new Configuration(configParameters);

      this.currentUser = data.user;

      return data;
    } catch (e) {
      const error = e as any;
      console.log(error);
      this.errorMessage = error.response.data.message;

      return undefined;
    }
  }

  async login(payload: LoginPayload) {
    try {
      const data = (await this.api.authControllerLogin(payload)).data;

      const configParameters: ConfigurationParameters = {
        accessToken: data.accessToken
      };
      this.apiConfig = new Configuration(configParameters);
      this.apiConfig$.next(this.apiConfig);
      this.api = new AuthenticationApi(this.apiConfig, ASS_SERVICE_CONF.BASE_URL);

      localStorage.setItem('ass_token', data.accessToken);
      this.token = data.accessToken;

      this.currentUser = data.user;
      this.user$.next(this.currentUser);

      return data;
    } catch (e) {
      const error = e as any;

      const message = this.translate.instant(error.response.data.message, {
        link: `<a class="font-bold" href="/register?type=invite&token=${payload.password}&email=${
          payload.email
        }">${this.translate.instant('API.Auth.Error.Here')}</a>`
      });
      if (typeof message === 'object') {
        this.errorMessage = Object.values(message)[0] as string;
      } else {
        this.errorMessage = message;
      }

      return undefined;
    }
  }

  logout() {
    localStorage.removeItem('ass_token');
    this.token = null;
    this.currentUser = undefined;
    this.router.navigate(['login']);
  }

  public isAuthenticated() {
    const token = this.token ?? (localStorage.getItem('ass_token') as string);

    if (this.jwtHelper.isTokenExpired(token)) {
      localStorage.removeItem('ass_token');
      this.token = null;
    }

    return !this.jwtHelper.isTokenExpired(token);
  }
}
