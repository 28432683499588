<div>
  <div class="flex flex-col items-center justify-center p-8 mt-8 bg-white">
    <img height="256" [src]="image" class="h-64 -mt-16 cursor-pointer" (click)="this.onClick.emit()" />
    <span class="block mt-8">{{ name }}</span>
  </div>
  <app-button
    [fullWidth]="true"
    [label]="'ProductCard.Configure' | translate : { short }"
    [size]="'xl'"
    [icon]="'arrow-right-alt'"
    (onClick)="this.onClick.emit()"
  ></app-button>
</div>
