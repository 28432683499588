<span
    [title]="label"
    class="inline-flex items-center px-2.5 py-1 text-sm font-medium badge"
    [ngClass]="[
        type === 'info' ? 'text-blue-800 bg-blue-100' : '',
        type === 'success' ? 'text-emerald-800 bg-emerald-100' : '',
        type === 'danger' ? 'text-red-800 bg-red-100' : '',
        type === 'price' ? 'text-purple-800 bg-purple-100 price' : '',
        hasPromo ? 'has-promo' : '',
    ]"
>
    <span *ngIf="label == '' || label == undefined">&nbsp;</span>
    <span *ngIf="type !== 'price' && label != ''">{{label}}</span>
    <div class="flex" *ngIf="type == 'price' && label != ''">
        <span class="mr-1">CHF</span>
        {{label | currency:' ':'symbol':'1.2-2'}}
    </div>
</span>