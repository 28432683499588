import { Component, Input, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Order, Product, ProductLineItem, ProductTypeEnum, SetAddonProductsDTO } from '../../../../../api-client';
import { firstValueFrom, from, lastValueFrom, map, switchMap } from 'rxjs';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { OrderService } from '../../../../../services/order.service';

@Component({
  selector: 'app-al-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class ALAttachmentsComponent {
  @Input()
  order!: Order;

  modalOpen = false;

  amounts: { [key: string]: number } = {};

  attachmentProducts$ = from(
    this.productService.api.productControllerFindAllAddonProductsForProduct(ProductTypeEnum.Al)
  ).pipe(map((res) => res.data));
  attachmentAmounts$ = this.attachmentProducts$.pipe(
    switchMap((prods) =>
      this.orderService.order$.pipe(
        map((order) =>
          prods.map((prod) => ({
            amount: order?.lineItems.find((item) => item.productId == prod.id)?.amount ?? 0,
            id: prod.id
          }))
        )
      )
    )
  );

  constructor(
    public readonly translate: TranslateService,
    private readonly productService: ProductService,
    private readonly orderService: OrderService
  ) {}

  ngOnInit() {
    this.init();
  }

  async init() {
    const amounts = await firstValueFrom(this.attachmentAmounts$);

    for (const amount of amounts) {
      if (amount.id) {
        this.amounts[amount.id] = amount.amount;
      }
    }
  }

  toggleAttachmentModal() {
    this.modalOpen = !this.modalOpen;
  }

  async submitAttachmentModal() {
    this.modalOpen = false;

    const dto: SetAddonProductsDTO = {
      addons: Object.entries(this.amounts)
        .filter((obj) => obj[1] > 0)
        .map((obj) => ({ amount: obj[1], id: obj[0] }))
    };

    // console.log(dto);
    // return;

    this.orderService.setAddonItems(dto);
  }
}
