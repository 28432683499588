import { Component, Input } from '@angular/core';
import { Product, ProductLineItem, ProductLineItemSideEnum, ProductTypeEnum } from '../../../../../api-client';
import { BaseALSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map } from 'rxjs';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { Side } from '../../../avk/slots/door/door.component';

@Component({
  selector: 'app-al-schema',
  templateUrl: './schema.component.html',
  styleUrls: ['./schema.component.scss']
})
export class SchemaComponent extends BaseALSlotComponent {
  @Input()
  item!: ProductLineItem;

  schemaSlot = 12;
  schemaProducts$: Observable<Product[]> | undefined;
  schemaOptions$: Observable<Option[]> | undefined;
  selectedSchema: Option | undefined = undefined;
  selectedSide: Option<ProductLineItemSideEnum> | undefined = undefined;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  override initItem(): void {}
  override updateItem(firstChange?: boolean | undefined): void {
    this.schemaProducts$ = this.productService.getSlot(this.schemaSlot, ProductTypeEnum.Al);
    this.schemaOptions$ = this.schemaProducts$!.pipe(
      this.productToOptionsPipe(this.schemaSlot, this.translate.instant('Configuration.AVK.Dropdowns.None')),
      map((res) => {
        this.selectedSchema = res.selected;
        return res.res;
      })
    );
    const side = this.sideOptions.find(
      (side) => side.value == this.item.set.find((s) => s.slot == this.schemaSlot)?.side
    );
    if (side) this.selectedSide = side;
  }

  setSide(side: ProductLineItemSideEnum) {
    this.updateSlotItem(12, { side });
  }
}
