import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input()
  submitLabel: string = '';

  @Input()
  cancelLabel: string = '';

  @Input()
  overflow: boolean = true;

  @Output()
  onClose: EventEmitter<void> = new EventEmitter();

  @Output()
  onSubmit: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
