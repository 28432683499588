<div
  *ngIf="orderService.order$ | async as order"
  class="relative flex flex-col px-8 bg-white border-l-4 border-monza-500"
>
  <div class="flex items-center gap-4">
    <div>
      <span class="flex items-center z-10">
        <span class="px-2 py-1 mr-2 text-xs leading-none text-white bg-monza-500" *ngIf="item.amount > 1">
          {{ item.amount }}×
        </span>
        {{ item | translatedDescription : translate.currentLang}}
      </span>
    </div>
    <div class="flex flex-col items-center gap-2 ml-auto md:flex-row my-2">
      <app-custom-select
        *ngIf="order.status != OrderStatusEnum.Ordered"
        unit="×"
        size="sm"
        [selectedValue]="selectedAmount"
        [options]="amountOptions"
        (cchange)="updateAmount($event.value)"
        [placeholder]="false"
        class="col-span-2 xl:col-span-1"
      ></app-custom-select>
      <div>
        <app-badge [type]="'price'" class="ml-auto mr-4 z-10" [label]="item.price"></app-badge>
      </div>
      <div class="flex self-end gap-2 items-center">
        <app-button
            [icon]="'trash'"
            [onlyIcon]="true"
            class="text-center fill-white z-10"
            [type]="'light'"
            [size]="'xs'"
            (onClick)="removeAddon()"
            *ngIf="order.status != OrderStatusEnum.Ordered"
        ></app-button>
      </div>
    </div>
  </div>
</div>
