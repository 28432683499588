import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { Address, CreateAddressDto, UpdateAddressDto } from 'src/app/api-client';
import { CompanyService } from 'src/app/services/company.service';
import { Option } from '../../atoms/custom-select/custom-select.component';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent implements OnChanges {
  constructor(private companyService: CompanyService) {}

  ngOnInit() {
    this.initEmptyAddress();
  }

  ngOnChanges(): void {
    this.initEmptyAddress();
  }

  initEmptyAddress(force = false) {
    if (this.address == undefined || force) {
      this.address = {
        firstname: '',
        lastname: '',
        phone: '',
        street: '',
        postal: '',
        city: '',
        country: ''
      } as Address;
    }
  }

  @Input()
  isReadonly = false;

  @Input()
  address!: Address | null | undefined;

  @Input()
  showExistingAddresses = false;

  @Input()
  isDeletable = true;

  @Input()
  isRemovable = false;

  @Output()
  onClose: EventEmitter<void> = new EventEmitter();

  @Output()
  onRemove: EventEmitter<void> = new EventEmitter();

  @Output()
  onSubmitAddress: EventEmitter<Address> = new EventEmitter();

  selectedAddress!: Option;
  addressOptions$: Observable<Option[]> = this.companyService.company$.pipe(
    map((c) =>
      c.addresses.map(
        (address) =>
          ({
            description: `${address.street} ${address.city} ${address.postal}`,
            label: `${address.firstname} ${address.lastname}, ${address.companyName ? address.companyName : c.name} ${
              address.isActive ? '- Standardadresse' : ''
            }${address.mainAddress ? '- Firmenadresse' : ''}`,
            value: address.id,
            meta: address
          } as Option)
      )
    ),
    map((opts) => {
      opts.unshift({ description: '', label: '-', value: '' } as Option);
      return opts;
    }),
    tap((opts) => {
      const opt = opts.find((opt) => opt.value === this.address?.id);
      if (opt) {
        this.selectedAddress = opt;
      }
    })
  );

  async save() {
    if (!this.address) return;

    if (!this.address.isActive && !this.address.mainAddress) {
      if (this.address.id) {
        const payload: UpdateAddressDto = {
          firstname: this.address.firstname,
          lastname: this.address.lastname,
          phone: this.address.phone,
          street: this.address.street,
          postal: this.address.postal,
          city: this.address.city,
          country: this.address.country
        };
        await this.companyService.updateAddress(this.address.id, payload);
      } else {
        const payload: CreateAddressDto = {
          firstname: this.address.firstname,
          lastname: this.address.lastname,
          phone: this.address.phone,
          street: this.address.street,
          postal: this.address.postal,
          city: this.address.city,
          country: this.address.country
        };
        await this.companyService.createAddress(payload);
      }
    }
    this.onClose.emit();
  }

  delete() {
    if (this.address) {
      this.companyService.deleteAddress(this.address.id);
    }
    this.onClose.emit();
  }
}
