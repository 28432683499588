<div
  *ngIf="orderService.order$ | async as order"
  class="relative flex flex-col p-8 bg-white border-l-4 border-monza-500"
  [ngClass]="{ 'h-full overflow-visible': isExpanded, 'h-28 overflow-hidden': !isExpanded }"
>
  <div class="flex items-center gap-4">
    <div>
      <span class="flex items-center font-medium z-10">
        <span class="px-2 py-1 mr-2 text-xs leading-none text-white bg-monza-500" *ngIf="item.amount > 1">
          {{ item.amount }}×
        </span>
        {{ item | translatedName : translate.currentLang }}
        
        <span
          class="px-2 py-1 mr-2 text-xs leading-none ml-4 font-bold bg-gray-700 text-gray-100"
          *ngIf="labelProduct?.custom"
        >
          {{ labelProduct?.custom }}
        </span>
      </span>
      <span class="text-sm font-medium leading-none text-monza-500">
        {{ item.isWall ? ('Configuration.Fields.Wall' | translate) : '' }}
        {{ item.isSocket ? ('Configuration.Fields.Socket' | translate) : '' }}
        {{ item.isField ? ('Configuration.Fields.Field' | translate) : '' }}
        {{ item.isDividerWall ? ('Configuration.Fields.Divider' | translate) : '' }}
      </span>
      <span class="text-sm"> — {{ item | translatedDescription : translate.currentLang }}</span>
    </div>
    <div class="flex flex-col items-center gap-2 ml-auto md:flex-row">
      <div>
        <app-badge [type]="'price'" class="ml-auto mr-4 z-10" [label]="item.price"></app-badge>
      </div>
      <div class="flex self-end gap-2 items-center">
        <ng-container *ngIf="fieldAmount$ | async as fieldAmount">
          <app-button
            [icon]="'trash'"
            [onlyIcon]="true"
            class="my-2 text-center fill-white z-10"
            [type]="'light'"
            [size]="'xs'"
            (onClick)="removeField()"
            *ngIf="((item.isField && fieldAmount > 1) || item.isSocket || item.isWall || item.isDividerWall) && order.status != 'Ordered'"
          ></app-button>
        </ng-container>
        <app-button
          [icon]="'keyboard-arrow-left'"
          [isIconRotated]="isExpanded"
          [onlyIcon]="true"
          class="transition-all duration-200 z-10"
          [size]="'xs'"
          [type]="'light'"
          (onClick)="toggleIsExpanded()"
        ></app-button>
      </div>
    </div>
  </div>
  <div class="trigger absolute left-0 top-0 right-0 h-28 cursor-pointer" (click)="toggleIsExpanded()"></div>
  <div class="grid grid-cols-1 gap-8 mt-8" *ngIf="isExpanded">
    <div class="grid grid-cols-6">

      <div class="item-row flex flex-col gap-6 col-span-6 pb-4">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.Label' | translate"
        ></app-order-label>
        <app-swg-label [item]="item" [order]="order"></app-swg-label>
      </div>

      <div class="item-row flex flex-col gap-6 col-span-6">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.OpenField.BaseData' | translate"
        ></app-order-label>
        <app-swg-base [item]="item"></app-swg-base>
      </div>
      <div class="item-row flex flex-col gap-6 col-span-6 mt-8">
        <app-order-label class="col-span-6" [label]="'Configuration.Fields.Addon' | translate"></app-order-label>
        <app-swg-accesories
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (addAddon)="addAddon($event)"
          (removeAddon)="removeAddon($event)"
          [item]="item"
          [order]="order"
        ></app-swg-accesories>
        <app-swg-lock
          (addSlot)="addSlot($event)"
          (removeSlot)="removeSlot($event)"
          (addAddon)="addAddon($event)"
          (removeAddon)="removeAddon($event)"
          [item]="item"
          [order]="order"
        ></app-swg-lock>
      </div>
      <div class="item-row flex flex-col gap-6 col-span-6 mt-8">
        <app-order-label
          class="col-span-6"
          [label]="'Configuration.Fields.MoreAccesories' | translate"
        ></app-order-label>
        <app-swg-additional
          [item]="item"
          [order]="order"
          (addAddon)="addAddon($event)"
          (removeAddon)="removeAddon($event)"
        ></app-swg-additional>
      </div>
    </div>
  </div>
</div>
