import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year: number = 2000;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    let date = new Date();
    this.year = date.getFullYear();
  }
}
