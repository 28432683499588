import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastPayload, ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

    toast: ToastPayload | null = null;
    subscription!: Subscription;
    isActive: boolean = false;
    isAnimation: boolean = false;

    constructor(
        private toastService: ToastService
    ) {
        this.subscription = this.toastService.getToast().subscribe((toast) => {
            this.toast = toast;
            this.displayToast(this.toast.time);
        })
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    displayToast(time: number) {
        this.isActive = true;
        this.isAnimation = true;
        setTimeout(() => {
            this.isActive = false;
        }, time - 150)
        setTimeout(() => {
            this.isAnimation = false;
        }, time)
    }


}
