import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPassswordPayload } from 'src/app/api-client';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  email: string = "";
  password: string = "";
  passwordConfirmation: string = "";

  token: string = "";

  async ngOnInit() {
    let params = this.route.snapshot.queryParams;
    this.email = params['email'];
    this.token = params['token'];
  }

  constructor(
      public auth: AuthService,
      private router: Router,
      private route: ActivatedRoute,
  ) { }

  async reset() {
    const payload: ResetPassswordPayload = {
        email: this.email,
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        token: this.token,
    }

    const res = await this.auth.reset(payload);
    if(res == undefined) return;
    this.router.navigate(['']);
  }

}
