<div class="grid grid-cols-6 items-center justify-start col-span-6 gap-4">
  <app-custom-select
    class="col-span-6 md:col-span-2"
    [label]="'Configuration.AL.EntryTop' | translate"
    [placeholder]="false"
    [options]="entryTopOptions"
    [selectedValue]="selectedEntryTop!"
    (cchange)="updateEntryType($event)"
  ></app-custom-select>
  <app-custom-select
    *ngIf="selectedEntryTop?.value != 'og'"
    class="col-span-6 md:col-span-4"
    [label]="'Configuration.Fields.OpenField.With' | translate"
    [placeholder]="false"
    [options]="materialOptions"
    [selectedValue]="selectedMaterial!"
    (cchange)="updateMaterial($event)"
  ></app-custom-select>
</div>
