<div class="grid grid-cols-6 items-center justify-start col-span-6 gap-4">
  <app-custom-select
      class="col-span-6 md:col-span-2"
      [label]="'Configuration.AL.EntryBottom' | translate"
      [placeholder]="false"
      [options]="entryBotOptions"
      [selectedValue]="selectedEntryBot!"
      (cchange)="selectEntryBotType($event)"
  ></app-custom-select>
  <app-custom-select 
    *ngIf="selectedEntryBot?.value == 'ues'"
    class="col-span-6 md:col-span-4"
    [label]="'Configuration.Fields.OpenField.With'|translate"
    [placeholder]="false"
    [options]="materialOptions"
    [selectedValue]="selectedMaterialOption!"
    (cchange)="selectEntryBotMaterial($event)"
  ></app-custom-select>
</div>
