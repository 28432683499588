import { Injectable } from '@angular/core';
import { Order, ProductTypeEnum } from '../api-client';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root'
})
export class IframeService {
  private iframe?: HTMLIFrameElement;

  private initSubscriptionBound = this.initSubscription.bind(this);

  constructor(private orderService: OrderService) {}

  initIframe() {
    this.iframe = document.getElementById('iframe-view') as HTMLIFrameElement;
    window.addEventListener('message', this.initSubscriptionBound);
  }

  initSubscription(message: MessageEvent) {
    if (message.data.ready) {
      this.orderService.order$.subscribe((order) => this.setSizes(order));
      window.removeEventListener('message', this.initSubscriptionBound);
    }
  }

  setSizes(order: Order | undefined) {
    if (!order) return;
    if (!this.iframe) return;

    const dto: any = {
      resizeField: order.lineItems
        .filter((l) => l.isField)
        .map((l, index) => ({
          id: index,
          scale: {
            x: (l.width as number) / 1000,
            y: (l.height as number) / 1000,
            z: (l.depth as number) / 1000
          },
          doubleDoor:
            l.set
              .find((s) => s.slot == 5)
              ?.description.toLowerCase()
              .includes('doppel') ||
            l.set
              .find((s) => s.slot == 5)
              ?.description.toLowerCase()
              .includes('gleichschliessend'),
          rightDoor:
            l.set
              .find((s) => s.slot == 5)
              ?.description.toLowerCase()
              .includes('rechts') ||
            l.set
              .find((s) => s.slot == 5)
              ?.additionalDescription.toLowerCase()
              .includes('rechts'),
          sameDir: l.set
            .find((s) => s.slot == 5)
            ?.description.toLowerCase()
            .includes('gleich'),
          noDoor:
            l.set.find((s) => s.slot == 4)?.productNumber.includes('ot') ||
            l.set.find((s) => s.slot == 4)?.productNumber.includes('otorw') ||
            !l.set.some((s) => s.slot == 5)
        }))
    };

    if (order.lineItems.find((l) => l.isSocket)) {
      dto.resizeSocket = {
        id: 0,
        scale: order.lineItems
          .filter((l) => l.isSocket)
          .map((l) => ({
            x: (l.width as number) / 1000,
            y: (l.height as number) / 1000,
            z: (l.depth as number) / 1000
          }))[0]
      };
    }

    if (order.productType === ProductTypeEnum.Al) {
      order.lineItems.forEach((l) => {
        const socket = l.set?.find((s) => s.slot === 40);
        if (socket) {
          dto.resizeSocket = {
            id: 0,
            scale: {
              x: (socket.width as number) / 1000,
              y: (socket.height as number) / 1000,
              z: ((socket.depth as number) - 22) / 1000
            }
          };
        }
      });
    }

    this.iframe.contentWindow?.postMessage(dto, '*');
  }

  targetFps(fps: number) {
    this.iframe?.contentWindow?.postMessage({ limitFps: fps }, '*');
  }
}
