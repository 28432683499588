<div class="grid grid-cols-2 items-center justify-start col-span-6 gap-4">
  <app-custom-select
    *ngIf="socketOptions$ | async as socketOptions; else mdLoading"
    [label]="'Configuration.AVK.Socket.Height' | translate"
    [options]="socketOptions"
    [selectedValue]="selectedSocketOption!"
    (cchange)="selectedSocketOption = $event; selectHeight(selectedSocketOption)"
  ></app-custom-select>
  <app-checkbox
    *ngIf="liftingEyesProduct$ | async as liftingEyesProduct; else mdLoading"
    [ngClass]="{
      'opacity-100': selectedSocketOption?.meta,
      'opacity-0 pointer-events-none': !selectedSocketOption?.meta
    }"
    class="mt-5 transition-all duration-300"
    [label]="'Configuration.AVK.Socket.LiftingEyes' | translate"
    [checked]="hasLiftingEyes"
    (checkedChange)="toggleLiftingEyes(liftingEyesProduct, $event)"
  ></app-checkbox>
</div>
<div
  class="grid grid-cols-3 items-center justify-start col-span-6 gap-4 transition-all duration-300"
  [ngClass]="{
    'opacity-100 max-h-40': selectedSocketOption?.meta,
    'opacity-0 pointer-events-none max-h-0': !selectedSocketOption?.meta
  }"
  *ngIf="entryProducts$ | async as entryProducts; else mdLoading"
>
  <app-checkbox
    class="mt-5 transition-all duration-300"
    [label]="'Configuration.AVK.Socket.Entry' | translate"
    [checked]="hasEntry"
    (checkedChange)="hasEntry = $event; selectEntry($event, entryProducts, selectedSide.value, selectedSize.value)"
  ></app-checkbox>
  <app-custom-select
    class="transition-all duration-300"
    [ngClass]="{
      'opacity-100 max-w-xl': hasEntry,
      'opacity-0 pointer-events-none max-w-0': !hasEntry
    }"
    [label]="'Configuration.AVK.Socket.Side' | translate"
    [options]="sideOptions"
    [selectedValue]="selectedSide"
    (cchange)="selectedSide = $event; selectEntry(hasEntry, entryProducts, selectedSide.value, selectedSize.value)"
  ></app-custom-select>
  <app-custom-select
    class="transition-all duration-300"
    [ngClass]="{
      'opacity-100 max-w-xl': hasEntry,
      'opacity-0 pointer-events-none max-w-0': !hasEntry
    }"
    [label]="'Configuration.AVK.Socket.Size' | translate"
    [options]="sizeOptions"
    [selectedValue]="selectedSize"
    (cchange)="selectedSize = $event; selectEntry(hasEntry, entryProducts, selectedSide.value, selectedSize.value)"
  ></app-custom-select>
</div>


<ng-template #lgLoading>
  <app-skeleton size="lg"></app-skeleton>
</ng-template>
<ng-template #mdLoading>
  <app-skeleton size="md" class="col-span-3"></app-skeleton>
</ng-template>
<ng-template #xsLoading>
  <app-skeleton size="xs"></app-skeleton>
</ng-template>