import { Component, Input } from '@angular/core';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { BaseALSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map } from 'rxjs';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';

@Component({
  selector: 'app-al-entry-bot',
  templateUrl: './entry-bot.component.html',
  styleUrls: ['./entry-bot.component.scss']
})
export class EntryBotComponent extends BaseALSlotComponent {
  @Input()
  item!: ProductLineItem;

  readonly entryBotSlot = 20;
  entryBotProducts: Promise<Product[]> = this.productService.getSlotAsync(this.entryBotSlot, ProductTypeEnum.Al);
  entryBotOptions: Option[] = [];
  selectedEntryBot: Option | undefined = undefined;

  selectedMaterialOption: Option | undefined = undefined;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  initMaterials() {
    this.entryBotOptions = [
      {
        value: 'ug',
        label: this.translate.instant('Configuration.AL.Floor.Closed'),
        description: ''
      },
      {
        value: 'ues',
        label: this.translate.instant('Configuration.AL.Floor.Opening'),
        description: ''
      },
      {
        value: 'uo',
        label: this.translate.instant('Configuration.AL.Floor.Open'),
        description: ''
      }
    ];

    this.materialOptions = [
      {
        value: '0',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.None')
      },
      {
        value: 'MGK40',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.MGKP_IP40'),
        meta: true
      },
      {
        value: 'KDP54',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.KDFP_IP54'),
        meta: true
      },
      {
        value: 'KP41',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.KSP_IP41')
      },
      {
        value: 'KP54',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.KSP_IP54')
      },
      {
        value: 'AP41',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.AP_IP41')
      },
      {
        value: 'AP54',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.AP_IP54')
      },
      {
        value: 'B',
        description: '',
        label: this.translateService.instant('Configuration.Fields.Materials.Brushes')
      }
    ];

    if (this.item?.depth == 150) {
      this.materialOptions = this.materialOptions.filter((m) => !m.meta);
    }
  }

  override initItem(): void {
    this.initMaterials();
    this.selectedMaterialOption = this.materialOptions![0];
  }

  override updateItem(firstChange?: boolean | undefined): void {
    const foundEntry = this.item.set.find((set) => set.slot === this.entryBotSlot);

    this.initMaterials();
    if (foundEntry && this.materialOptions && this.entryBotOptions) {
      if (foundEntry.productNumber === 'uo') {
        this.selectedEntryBot = this.entryBotOptions[2];
        this.selectedMaterialOption = this.materialOptions[0];
      } else if (foundEntry.productNumber === 'ug') {
        this.selectedEntryBot = this.entryBotOptions[0];
        this.selectedMaterialOption = this.materialOptions[0];
      } else if (foundEntry.custom) {
        const [entryType, material] = foundEntry.custom?.split('.');
        this.selectedEntryBot = this.entryBotOptions.find((x) => x.value === entryType)!;
        this.selectedMaterialOption = this.materialOptions?.find((x) => x.value === material)!;
      }
    }
  }

  async updateEntryBot() {
    const products = await this.entryBotProducts;

    const selectedProduct = products.find((prod) => {
      if (this.selectedEntryBot?.value === 'uo') {
        return prod.productNumber === 'uo';
      } else if (this.selectedEntryBot?.value === 'ug') {
        return prod.productNumber === 'ug';
      } else {
        return prod.productNumber === 'ues';
      }
    });

    console.log(selectedProduct);

    if (!selectedProduct) return;

    let custom = '-1';
    if (this.selectedEntryBot?.value === 'uo') {
      custom = '0';
    } else if (this.selectedEntryBot?.value === 'ug') {
      custom = '-1';
    } else {
      custom = `${this.selectedEntryBot?.value}.${this.selectedMaterialOption?.value}`;
    }

    this.addSlot.emit({
      slot: this.entryBotSlot,
      create: {
        productId: selectedProduct?.id,
        custom: custom
      }
    });
  }

  async selectEntryBotType(option: Option) {
    this.selectedEntryBot = option;
    this.updateEntryBot();
  }

  async selectEntryBotMaterial(option: Option) {
    this.selectedMaterialOption = option;
    this.updateEntryBot();
  }
}
