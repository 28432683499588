import { Component, Input, OnInit } from '@angular/core';
import { BaseAVKSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { ProductService } from '../../../../../services/product.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-avk-indoor',
  templateUrl: './indoor.component.html',
  styleUrls: ['./indoor.component.scss']
})
export class IndoorComponent extends BaseAVKSlotComponent {
  @Input()
  item!: ProductLineItem;

  assemblyPlateProducts$ = this.productService.getSlot(170, ProductTypeEnum.Avk);
  assemblyPlate$ = this.assemblyPlateProducts$.pipe(
    map((prods) =>
      prods
        .filter((prod) => !prod.productNumber.includes('avmpv'))
        .find((prod) => prod.productNumber.startsWith('avmp'))
    )
  );
  assemblyPlateMovable$ = this.assemblyPlateProducts$.pipe(
    map((prods) =>
      prods
        .filter((prod) => !prod.productNumber.includes('avmpvm'))
        .find((prod) => prod.productNumber.startsWith('avmpv'))
    )
  );
  tablarProduct$ = this.productService.getSlot(173, ProductTypeEnum.Avk).pipe(map((p) => p[0]));
  tablarDistance = 1000;

  hasAssemblyPlate = false;
  isMoveable = false;
  hasTablar = false;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  override initItem(): void {}

  override updateItem(firstChange?: boolean | undefined): void {
    this.hasAssemblyPlate = this.item.set.some((s) => s.slot == 170);
    this.hasTablar = this.item.set.some((s) => s.slot == 173);
    if (this.hasTablar && this.tablarDistance != +this.item.set.find((s) => s.slot == 173)!.custom!) {
      this.tablarDistance = +this.item.set.find((s) => s.slot == 173)!.custom!;
    }
    this.isMoveable = this.item.set.some((s) => s.slot == 170 && s.productNumber.startsWith('avmpv'));
  }

  setTablarDistance(dist: string) {
    this.tablarDistance = +dist;
    this.updateSlot.emit({ slot: 173, update: { custom: dist } });
  }

  toggleTablar(product: Product, checked: boolean) {
    if (checked) {
      this.addSlot.emit({
        create: { productId: product.id, custom: '1000' },
        slot: 173
      });
    } else {
      this.removeSlot.emit(173);
    }
  }

  toggleAssembyPlate(product: Product, checked: boolean) {
    if (checked) {
      this.addSlot.emit({
        create: { productId: product.id },
        slot: 170
      });
    } else {
      this.removeSlot.emit(170);
    }
  }
}
