<app-order-bar [isOverview]="true" (createOffer)="initOrder()"></app-order-bar>

<div class="container grid grid-cols-6 gap-4 px-4 py-4 mx-auto md:py-20 lg:px-8">
  <div class="col-span-6 md:col-span-4">
      <app-overview-order></app-overview-order>
  </div>
  <div class="col-span-6 md:col-span-2">
      <app-overview-address></app-overview-address>
  </div>
</div>
