import { Component, OnInit } from '@angular/core';
import { ForgotPassswordPayload } from 'src/app/api-client';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent {

    email: string = "";
    error: string = "";

    constructor(
      private auth: AuthService,
      private toastService: ToastService,
    ) { }

    async reset() {
        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          this.error = "";
          const payload: ForgotPassswordPayload = {
              email: this.email,
          }

          await this.auth.forgot(payload);
          this.toastService.displayToast({
            message: 'Mail wurde versendet. Prüfen Sie Ihr E-Mail Postfach',
            type: 'success',
            time: 4000
          })
        } else {
          this.error = "Tragen Sie Ihre Mail in das Feld ein.";
        }
    }

}
