import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateParser, TranslateService } from '@ngx-translate/core';

export class MyMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translateService: TranslateService) {
    super();
    this.getTranslations();
  }

  getTranslations() {
    this.translateService
      .stream(['Table.ItemsPerPageLabel', 'Table.NextPageLabel', 'Table.PreviousPageLabel'])
      .subscribe((translation) => {
        this.itemsPerPageLabel = translation['Table.ItemsPerPageLabel'];
        this.nextPageLabel = translation['Table.NextPageLabel'];
        this.previousPageLabel = translation['Table.PreviousPageLabel'];
        this.changes.next();
      });
  }

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return this.translateService.instant('Table.RangeLabelZero', { length });
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return this.translateService.instant('Table.RangeLabel', { start: startIndex + 1, end: endIndex, length });
  };
}
