import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginPayload } from 'src/app/api-client';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  email: string = '';
  password: string = '';

  error: string = '';

  constructor(public auth: AuthService, private router: Router) {}

  async login() {
    const payload: LoginPayload = {
      email: this.email,
      password: this.password
    };

    const res = await this.auth.login(payload);
    if (res == undefined) return;
    this.router.navigate(['']);
  }
}
