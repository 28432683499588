import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProductService } from '../../../../../services/product.service';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { AddAVKSlotDTO } from '../../avk.component';
import { BaseAVKSlotComponent } from '../base/base-slot.component';
import { ToastService } from '../../../../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { AVK_CONFIG } from '../../config/avk.config';

@Component({
  selector: 'app-avk-ceiling-type',
  templateUrl: './ceiling-type.component.html',
  styleUrls: ['./ceiling-type.component.scss']
})
export class CeilingTypeComponent extends BaseAVKSlotComponent implements OnChanges {
  @Input()
  item!: ProductLineItem;

  public readonly CeilingMap = AVK_CONFIG.CEILING.PRODUCTS;

  public products$ = this.productService.getSlot(AVK_CONFIG.CEILING.SLOT, ProductTypeEnum.Avk);
  selectedProduct: ProductLineItem | undefined = undefined;

  constructor(
    private readonly productService: ProductService,
    private toastService: ToastService,
    private readonly translate: TranslateService
  ) {
    super(translate);
  }

  override initItem(): void {}

  updateItem() {
    this.selectedProduct = this.item.set.find((s) => s.slot == AVK_CONFIG.CEILING.SLOT);
  }

  async selectCeilingType(products: Product[], selected: string) {
    const addProduct = products.find((p) => p.productNumber == selected);
    if (!addProduct) {
      this.toastService.displayDefaultError();
      return;
    }
    this.addSlot.emit({
      slot: AVK_CONFIG.CEILING.SLOT,
      create: { productId: addProduct.id }
    });
  }
}
