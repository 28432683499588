import { Component, Input, OnInit } from '@angular/core';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { BaseAVKSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map } from 'rxjs';
import { AVK_CONFIG } from '../../config/avk.config';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';

@Component({
  selector: 'app-avk-backwall',
  templateUrl: './backwall.component.html',
  styleUrls: ['./backwall.component.scss']
})
export class BackwallComponent extends BaseAVKSlotComponent {
  @Input()
  item!: ProductLineItem;

  openingSlot = AVK_CONFIG.DOOR.BACK.SLOT_OPENING;
  openingProducts$: Observable<Product[]> | undefined;
  selectedOpening: Option | undefined = undefined;
  openingOptions$: Observable<Option[]> | undefined;

  backWallProduct$ = this.productService
    .getSlot(AVK_CONFIG.BACKWALL.SLOT, ProductTypeEnum.Avk)
    .pipe(map((p) => p.find((p) => p.productNumber == AVK_CONFIG.BACKWALL.DEFAULT)));
  backWallDoor$ = this.productService
    .getSlot(AVK_CONFIG.BACKWALL.DOOR_SLOT, ProductTypeEnum.Avk)
    .pipe(map((p) => p.find((p) => p.productNumber == AVK_CONFIG.BACKWALL.DEFAULT_DOOR)));

  asDoor = false;
  airIntakesTop = false;
  airIntakesBottom = false;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  override initItem(): void {
    this.openingProducts$ = this.productService.getSlot(this.openingSlot, ProductTypeEnum.Avk);
    this.openingOptions$ = this.openingProducts$.pipe(
      this.productToOptionsPipe(this.openingSlot, this.translate.instant('Configuration.AVK.Dropdowns.None')),
      map((res) => {
        this.selectedOpening = res.selected;
        return res.res;
      })
    );
  }
  override updateItem(firstChange?: boolean | undefined): void {
    this.asDoor = this.item.set.some((s) => s.slot == AVK_CONFIG.BACKWALL.DOOR_SLOT);
  }

  setOpening(productId: string) {
    if (productId == '') {
      if (this.item.set.some((s) => s.slot == AVK_CONFIG.DOOR.BACK.SLOT_OPENING)) {
        this.removeSlot.emit(AVK_CONFIG.DOOR.BACK.SLOT_OPENING);
      }
    } else {
      this.addSlot.emit({
        create: { productId },
        slot: AVK_CONFIG.DOOR.BACK.SLOT_OPENING
      });
    }
  }

  toggleDoor(backwall: Product, door: Product, checked: boolean) {
    this.asDoor = checked;
    if (!checked) {
      this.addSlot.emit({ create: { productId: backwall.id }, slot: 139 });
    } else {
      this.addSlot.emit({ create: { productId: door.id }, slot: 140 });
    }
  }
}
