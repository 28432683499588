import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CreateProductLineItemDto,
  OrderStatusEnum,
  ProductLineItem,
  UpdateProductLineItemDto
} from '../../../api-client';
import { OrderService } from '../../../services/order.service';
import { TemplateService } from '../../../services/template.service';
import { ToastService } from '../../../services/toast.service';
import { Option } from '../../../atoms/custom-select/custom-select.component';
import { ProductService } from '../../../services/product.service';

export interface AddALSlotDTO {
  slot: number;
  create: CreateProductLineItemDto;
}

export interface UpdateALSlotDTO {
  slot: number;
  update: UpdateProductLineItemDto;
}

@Component({
  selector: 'app-al',
  templateUrl: './al.component.html',
  styleUrls: ['./al.component.scss']
})
export class AlComponent implements OnInit {
  @Input()
  item!: ProductLineItem;

  @Input()
  index!: number;

  isExpanded = true;

  addModalOpen = false;

  templateName = '';
  createTemplateModal = false;

  labelProduct?: ProductLineItem;
  font: string = 'Arial';
  color: string = 'Black';

  constructor(
    public readonly orderService: OrderService,
    public readonly translate: TranslateService,
    public readonly templateService: TemplateService,
    public readonly productService: ProductService,
    private readonly toastService: ToastService
  ) {
    this.ngOnChanges();
  }

  ngOnInit(): void {}

  ngOnChanges(changes?: SimpleChanges) {
    this.labelProduct = this.orderService.order$.value?.lineItems?.find(
      (l) => l.addedFrom && l.addedFrom.includes(this.item?.id) && l.isLabel
    );
  }

  addField() {
    this.orderService.addLineItem({
      productId: this.item.productId,
      sorting: this.item.sorting + 1
    });
    this.orderService.addedSorting = this.item.sorting + 10;
  }

  addSlot(dto: AddALSlotDTO) {
    if (this.item.set.some((prod) => prod.id == dto.create.productId)) {
      this.toastService.displayToast({
        message: this.translate.instant('Configuration.AVK.Errors.AlreadyAdded'),
        time: 5000,
        type: 'danger'
      });
      return;
    }
    this.orderService.addLineItemSlot(this.item.id, dto.slot.toString(), dto.create);
  }

  updateSlot(dto: UpdateALSlotDTO) {
    this.orderService.updateLineItemSlot(this.item.id, dto.slot.toString(), dto.update);
  }

  removeSlot(slot: number) {
    if (!this.item.set.some((prod) => prod.slot == slot)) {
      this.toastService.displayToast({
        message: this.translate.instant('Configuration.AVK.Errors.AlreadyRemoved'),
        time: 5000,
        type: 'danger'
      });
      return;
    }
    this.orderService.removeLineItemSlot(this.item.id, slot.toString());
  }
}
