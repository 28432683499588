<div class="grid grid-cols-6 items-center justify-start col-span-6 gap-4 px-4">
  <app-radio
    class="col-span-6 lg:col-span-2"
    name="roof"
    [label]="'Configuration.Fields.OpenField.RoofDefault' | translate"
    [checked]="empty"
    (checkedChange)="removeSlot.emit(32)"
  ></app-radio>
  <app-radio
    *ngIf="roof1$ | async as roof1"
    class="col-span-6 lg:col-span-2"
    name="roof"
    [label]="'Configuration.Fields.OpenField.RoofPult' | translate"
    [checked]="selected1"
    (checkedChange)="addSlotItem(32, roof1)"
  ></app-radio>
  <app-radio
    *ngIf="roof2$ | async as roof2"
    class="col-span-6 lg:col-span-2"
    name="roof"
    [label]="'Configuration.Fields.OpenField.RoofProfile' | translate"
    [checked]="selected2"
    (checkedChange)="addSlotItem(32, roof2)"
  ></app-radio>
</div>
