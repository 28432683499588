<div class="grid grid-cols-6 items-start justify-start col-span-6 gap-4">
  <div class="col-span-6 flex items-start justify-start gap-4">
    <ng-container *ngIf="sideWallProduct$ | async as sideWallProduct; else  mdLoading">
      <ng-container *ngIf="doorProduct$ | async as doorProduct; else  mdLoading">
        <app-radio
          class="col-span-3"
          name="sidewall-{{ side }}-as-door"
          [label]="'Configuration.AVK.Sidewall.Screwed' | translate"
          [checked]="!sideWallAsDoor"
          (checkedChange)="toggleDoor(sideWallProduct, doorProduct, false)"
        ></app-radio>
        <app-radio
          class="col-span-3"
          name="sidewall-{{ side }}-as-door"
          [label]="'Configuration.AVK.Sidewall.AsDoor' | translate"
          [checked]="sideWallAsDoor"
          (checkedChange)="toggleDoor(sideWallProduct, doorProduct, true)"
        ></app-radio>
      </ng-container>
    </ng-container>
  </div>

  <div class="col-span-6" *ngIf="!sideWallAsDoor">
    <app-custom-select
      *ngIf="openingOptions$ | async as openingOptions; else  mdLoading"
      class="col-span-2"
      [placeholder]="true"
      [label]="'Configuration.AVK.Door.Opening' | translate"
      [options]="openingOptions"
      [selectedValue]="selectedOpening!"
      (cchange)="selectedOpening = $event; setOpening($event.value, this.openingSlots.get(this.side)!)"
    ></app-custom-select>
  </div>
</div>

<ng-template #lgLoading>
  <app-skeleton size="lg"></app-skeleton>
</ng-template>
<ng-template #mdLoading>
  <app-skeleton size="md" class="col-span-3"></app-skeleton>
</ng-template>
<ng-template #xsLoading>
  <app-skeleton size="xs"></app-skeleton>
</ng-template>