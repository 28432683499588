import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { from, ReplaySubject, Subject } from 'rxjs';
import { CreateTemplatePayload, SearchTemplatePayload, Template, TemplateApi } from '../api-client';
import { AuthService } from './auth.service';
import { ASS_SERVICE_CONF } from './conf';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  api: TemplateApi;

  orderTemplates$ = new ReplaySubject<Template[]>();
  orderTemplatesCount$ = new ReplaySubject<number>();
  orderTemplateOffset = 0;
  orderTemplateLimit = 6;
  orderTemplateSearchDto = {};

  lineItemTemplates$ = new ReplaySubject<Template[]>();
  lineItemTemplatesCount$ = new ReplaySubject<number>();
  lineItemTemplateOffset = 0;
  lineItemTemplateLimit = 4;
  lineItemTemplateSearchDto = {};

  lineItemTemplates: Template[] = [];

  constructor(public jwtHelper: JwtHelperService, private authService: AuthService) {
    this.api = new TemplateApi(authService.apiConfig, ASS_SERVICE_CONF.BASE_URL);
    this.authService.apiConfig$.subscribe((apiConfig) => {
      this.api = new TemplateApi(apiConfig, ASS_SERVICE_CONF.BASE_URL);
      this.findAllLineItemTemplates();
      this.findAllOrderTemplates();
    });

    if (authService.apiConfig?.accessToken) {
      this.findAllLineItemTemplates();
      this.findAllOrderTemplates();
    }
  }

  async createOrderemplate(id: string, dto: CreateTemplatePayload) {
    this.api
      .templateControllerCreateOrderTemplate(id, dto)
      .then(() => this.findAllOrderTemplates(this.orderTemplateSearchDto));
  }

  async createLineItemTemplate(id: string, dto: CreateTemplatePayload) {
    this.api
      .templateControllerCreateLineItemTemplate(id, dto)
      .then(() => this.findAllLineItemTemplates(this.lineItemTemplateSearchDto));
  }

  findAllLineItemTemplates(dto: SearchTemplatePayload = {}) {
    this.lineItemTemplateSearchDto = dto;
    this.api
      .templateControllerFindAllLineItemTemplates(
        this.lineItemTemplateOffset.toString(),
        this.lineItemTemplateLimit.toString(),
        dto
      )
      .then((d) => {
        this.lineItemTemplates$.next(d.data.data);
        this.lineItemTemplatesCount$.next(d.data.count);
      });
  }

  findAllOrderTemplates(dto: SearchTemplatePayload = {}) {
    this.orderTemplateSearchDto = dto;
    this.api
      .templateControllerFindAllOrderTemplates(
        this.orderTemplateOffset.toString(),
        this.orderTemplateLimit.toString(),
        dto
      )
      .then((d) => {
        this.orderTemplates$.next(d.data.data);
        this.orderTemplatesCount$.next(d.data.count);
      });
  }

  delete(id: string) {
    this.api.templateControllerDeleteTemplate(id).then((d) => {
      this.findAllLineItemTemplates(this.lineItemTemplateSearchDto);
      this.findAllOrderTemplates(this.orderTemplateSearchDto);
    });
  }
}
