import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/services/company.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  constructor(
    public orderService: OrderService,
    private companyService: CompanyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initOrder();
    this.companyService.loadCompany();
  }

  initOrder() {
    this.orderService.sortOrder(this.route.snapshot.params['id']);
  }
}
