<div class="grid grid-cols-6 items-center justify-start col-span-6 gap-4">
  <app-checkbox
    *ngIf="kapptablarItem$ | async as kapptablarItem"
    (checkedChange)="toggleSlotItem(klapptablarSlot, kapptablarItem.id, $event)"
    class="col-span-2 md:col-span-1"
    [checked]="isKlapptablarSelected"
    [label]="'Configuration.Fields.OpenField.Klapptablar' | translate"
  >
  </app-checkbox>
  <app-custom-select
    [selectedValue]="selectedSide!"
    (cchange)="setSide($event.value)"
    *ngIf="isKlapptablarSelected && isDoubleDoor"
    class="col-span-2 md:col-span-1"
    [label]="'Configuration.Fields.OpenField.Side' | translate"
    [placeholder]="false"
    [options]="sideOptions"
  >
  </app-custom-select>
</div>
