<app-modal class="absolute" submitLabel="OK" *ngIf="showModal" (onClose)="showModal = false" (onSubmit)="showModal = false">
  <div class="mt-2">
    Die Option "Boden offen" wurde automatisch für sie ausgewählt
  </div>
</app-modal>
<app-checkbox 
  *ngIf="socketProduct$ | async as socketProduct"
  class="mb-2"
  [label]="'Configuration.AL.Socket.None' | translate"
  [checked]="withoutSocket"
  (checkedChange)="updateWithoutSocket($event,socketProduct)"
></app-checkbox>
<div class="flex items-start flex-wrap sm:flex-nowrap justify-start col-span-6 gap-4 md:col-span-3"*ngIf="!withoutSocket && selectedSocket">
  <app-checkbox 
    [matTooltip]="'Configuration.Fields.OpenField.ManualOverride'|translate"
    matTooltipPosition="above"
    [highlight]="highlightOverride"
    [label]="'Configuration.Fields.OpenField.ManualOverride'|translate"
    [isMobileLabel]="true"
    class="relative self-end"
    style="bottom: 10px;"
    [(checked)]="item.override"
    (checkedChange)="updateOverride($event)"
    (mouseenter)="highlightLocks = true"
    (mouseleave)="highlightLocks = false"
  ></app-checkbox>
  <app-input
    class="w-full"
    [isReadonly]="!item.override"
    [label]="'Configuration.Fields.OpenField.Width' | translate"
    [type]="'number'"
    [unit]="'mm'"
    [highlightLock]="highlightLocks"
    [(value)]="selectedSocket.width"
    [isLocked]="!item.override"
    [showLock]="true"
    (valueChange)="updateSlotItem(40, { width: +$event})"

    (lockMouseEnter)="highlightOverride = true"
    (lockMouseLeave)="highlightOverride = false"
  ></app-input>
  <app-custom-select
    class="w-full"
    [selectedValue]="selectedHeight"
    [options]="heightOptions"
    [placeholder]="false"
    [label]="'Configuration.Fields.OpenField.Height' | translate"
    unit="mm"
    (cchange)="updateSlotItem(40, { height: +$event.value})"
  >
  </app-custom-select>
  <app-input
    class="w-full"
    [isReadonly]="!item.override"
    [label]="'Configuration.Fields.OpenField.Depth' | translate"
    [type]="'number'"
    [unit]="'mm'"
    [highlightLock]="highlightLocks"
    [(value)]="selectedSocket.depth"
    [isLocked]="!item.override"
    [showLock]="true"
    (valueChange)="updateSlotItem(40, { depth: +$event})"

    (lockMouseEnter)="highlightOverride = true"
    (lockMouseLeave)="highlightOverride = false"
  ></app-input>
</div>
