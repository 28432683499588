<div class="grid grid-cols-6 items-center justify-start col-span-6 gap-4">
  <app-custom-select
    *ngIf="cartridgeOptions$ | async as cartridgeOptions"
    class="col-span-6 md:col-span-3"
    [label]="'Configuration.Fields.OpenField.CartridgeHolder' | translate"
    [options]="cartridgeOptions"
    [selectedValue]="selectedCartridge!"
    (cchange)="addSlotItem(cartridgeSlot, $event)"
  ></app-custom-select>
  <app-input
    *ngIf="selectedCartridge?.value != ''"
    class="col-span-2 md:col-span-1"
    [label]="'Configuration.Fields.OpenField.Amount' | translate"
    unit="×"
    [value]="cartridgeAmount"
    (onChange)="updateSlotItem(cartridgeSlot, { amount: +$event })"
  ></app-input>
  <app-custom-select
    [selectedValue]="selectedSide!"
    (cchange)="setSide($event.value)"
    *ngIf="selectedCartridge?.value != '' && isDoubleDoor"
    class="col-span-4 md:col-span-2" 
    [label]="'Configuration.Fields.OpenField.Side' | translate"
    [placeholder]="false"
    [options]="sideOptions"
  ></app-custom-select>
</div>
