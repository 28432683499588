import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Order, OrderStatusEnum, UpdateOrderDto } from 'src/app/api-client';
import { OrderService } from 'src/app/services/order.service';
import { TemplateService } from '../../services/template.service';

@Component({
  selector: 'app-order-bar',
  templateUrl: './order-bar.component.html',
  styleUrls: ['./order-bar.component.scss']
})
export class OrderBarComponent {
  @Input()
  isOverview: boolean = false;

  @Output()
  createOffer = new EventEmitter();

  OrderStatusEnum = OrderStatusEnum;

  createTemplateModal = false;
  templateName = '';

  creatingOffer = false;

  constructor(public orderService: OrderService, private router: Router, public templateService: TemplateService) {}

  async offer(isOffer: boolean) {
    this.creatingOffer = true;
    await this.orderService.downloadPDF(isOffer);
    this.createOffer.emit();
    this.creatingOffer = false;
  }

  updateOrder(currentOrder: Order, value: string) {
    const payload: UpdateOrderDto = {
      name: value
    };
    this.orderService.updateOrder(currentOrder.id, payload);
  }
}
