import { Component, Input, OnInit } from '@angular/core';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { BaseALSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map } from 'rxjs';
import { AL_CONFIG } from '../../config/al.config';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';

@Component({
  selector: 'app-al-door',
  templateUrl: './door.component.html',
  styleUrls: ['./door.component.scss']
})
export class DoorComponent extends BaseALSlotComponent {
  @Input()
  item!: ProductLineItem;

  doorSlot = 5;
  doorProducts$: Observable<Product[]> | undefined;
  doorOptions$: Observable<Option[]> | undefined;
  selectedDoor: Option | undefined = undefined;

  lockSlot = 6;
  lockProducts$: Observable<Product[]> | undefined;
  lockOptions$: Observable<Option[]> | undefined;
  selectedLock: Option | undefined = undefined;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  /**
   * Doorypes
   * 0 = Single door
   * 1 = Single or double door
   * 2 = Double door
   */
  getDoorType(): 0 | 1 | 2 {
    if (this.item.width && this.item.width <= 1000) {
      return 1;
    } else if (this.item.width && this.item.width > 1000) {
      return 2;
    } else {
      return 0;
    }
  }

  override initItem(): void {}

  override updateItem(firstChange?: boolean | undefined): void {
    // Gets the door products from api
    this.doorProducts$ = this.productService.getSlot(this.doorSlot, ProductTypeEnum.Al);
    this.doorOptions$ = this.doorProducts$!.pipe(
      map((prods) => {
        return prods.filter((prodOpt) => {
          if (this.getDoorType() === 0) {
            return this.singleDoorProductIds.includes(prodOpt.productNumber);
          } else if (this.getDoorType() === 1) {
            return (
              this.singleDoorProductIds.includes(prodOpt.productNumber) ||
              this.doubleDoorProductIds.includes(prodOpt.productNumber)
            );
          } else if (this.getDoorType() === 2) {
            return this.doubleDoorProductIds.includes(prodOpt.productNumber);
          } else {
            return false;
          }
        });
      }),
      this.productToOptionsPipe(this.doorSlot, this.translate.instant('Configuration.AVK.Dropdowns.None')),
      map((res) => {
        this.selectedDoor = res.selected;
        return res.res;
      })
    );

    this.lockProducts$ = this.productService.getSlot(this.lockSlot, ProductTypeEnum.Al);
    this.lockOptions$ = this.lockProducts$.pipe(
      map((prods) =>
        prods.map((prod) => {
          if (this.item.width! < 850) {
            prod.description = prod.description.replace('Stangen', 'Reiber');
            prod.descriptionFr = prod.descriptionFr.replace('crémone', 'pêne');
          } else {
            prod.description = prod.description.replace('Reiber', 'Stangen');
            prod.descriptionFr = prod.descriptionFr.replace('pêne', 'crémone');
          }
          return prod;
        })
      ),
      this.productToOptionsPipe(this.lockSlot, this.translate.instant('Configuration.AVK.Dropdowns.None')),
      map((res) => {
        this.selectedLock = res.selected;
        return res.res;
      })
    );
  }
}
