<div class="flex min-h-screen">
  <div class="flex flex-col justify-center flex-1 w-full max-w-xl px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="w-full">
          <img class="w-auto h-12 mx-auto" src="assets/svg/logo.svg" alt="Logo ASS">
      </div>
    <div class="w-full mx-auto my-auto">
      <div>
        <h2 class="mt-6 text-3xl font-extrabold text-gray-800">{{'Auth.Reset.Header'|translate}}</h2>
        <p class="mt-2 text-sm text-gray-500">
          {{'Auth.Reset.SubHeader'|translate}}
        </p>
      </div>


      <div class="mt-8">
          <div class="space-y-6">

              <app-input 
                [label]="'Auth.Reset.SubHeader'|translate" 
                [type]="'email'" 
                [placeholder]="'mail@ass-ag.ch'" 
                [value]="this.email" 
                [isReadonly]="true">
              </app-input>

              <app-input 
                [label]="'Auth.Reset.Password'|translate" 
                [type]="'password'" 
                (onChange)="this.password = $event">
              </app-input>
              <app-input 
                [label]="'Auth.Reset.PasswordConfirm'|translate" 
                [type]="'password'" 
                (onChange)="this.passwordConfirmation = $event">
              </app-input>

              <div *ngIf="auth.errorMessage" class="flex items-center justify-center gap-2 text-monza-500">
                  <app-icon class="flex-none w-5 h-5" 
                    [icon]="'error'">
                  </app-icon>
                  <span class="text-sm font-medium">{{ auth.errorMessage }}</span>
              </div>

              <app-button 
                [label]="'Auth.Reset.CTA'|translate" 
                [size]="'xl'" 
                [fullWidth]="true" 
                (onClick)="reset()">
              </app-button>
          </div>
      </div>
    </div>
  </div>
  <!-- preview -->
  <app-preview class="relative flex-1 hidden w-0 lg:block">

  </app-preview>
</div>