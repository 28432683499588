import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {
  @Input()
  inputId = uuidv4();

  @Input()
  name!: string;

  @Input()
  checked: boolean | undefined = false;

  @Output()
  checkedChange: EventEmitter<any> = new EventEmitter();

  @Input()
  label: string = '';

  @Input()
  isReadonly = false;

  @Input()
  description: string = '';

  @Input()
  tooltip: string = '';

  @Input()
  highlight: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
