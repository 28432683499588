let host = '';
if (location.hostname.includes('localhost')) {
  host = 'http://localhost';
} else if (location.hostname.includes('ass-stage')) {
  host = 'https://stage-api.ass.hypetrain.cloud'.replace(/\/+$/, '');
} else {
  host = 'https://api.ass.hypetrain.cloud'.replace(/\/+$/, '');
}

export const ASS_SERVICE_CONF = {
  BASE_URL: host
};
