<div class="flex items-center justify-start col-span-6 gap-4">
  <app-custom-select
    *ngIf="floorOptions$ | async as floorOptions; else mdLoading"
    class="w-full"
    [placeholder]="false"
    [options]="floorOptions"
    [selectedValue]="selectedFloorOption!"
    (cchange)="changeFloor($event.value); selectedFloorOption = $event"
  ></app-custom-select>
</div>


<ng-template #lgLoading>
  <app-skeleton size="lg"></app-skeleton>
</ng-template>
<ng-template #mdLoading>
  <app-skeleton size="md" class="col-span-3"></app-skeleton>
</ng-template>
<ng-template #xsLoading>
  <app-skeleton size="xs"></app-skeleton>
</ng-template>
