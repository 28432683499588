import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { IframeService } from '../../services/3dview.service';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: '0' })),
      state('*', style({ opacity: '1' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class IframeComponent implements OnInit {
  controls = false;
  constructor(private iframeService: IframeService) {}

  ngOnInit(): void {
    this.iframeService.initIframe();
  }
}
