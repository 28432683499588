import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent {

    @Input()
    steps: string[] = [];

    @Input()
    active: number = 0;

    @Output()
    onStepEvent: EventEmitter<number> = new EventEmitter();

    counter(i: number) {
        return new Array(i);
    }

    stepEvent(index: number) {
        this.onStepEvent.emit(index);
    }

}
