import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription, map, tap } from 'rxjs';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { OrderService } from '../../../../../services/order.service';
import { ProductService } from '../../../../../services/product.service';
import { BaseAVKSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-avk-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent extends BaseAVKSlotComponent {
  @Input()
  item!: ProductLineItem;

  @Input()
  slot!: number;

  airFilterOptionsTop$!: Observable<Option[]>;
  selectedFilterTop!: Option;
  airFilterOptionsBottom$!: Observable<Option[]>;
  selectedFilterBottom!: Option;
  filterEnabled = false;

  constructor(private readonly productService: ProductService, private readonly translate: TranslateService) {
    super(translate);
  }

  override initItem(): void {}

  updateItem() {
    this.filterEnabled = this.item.set.some((item) => item.slot == this.slot || item.slot == this.slot + 1);
    this.airFilterOptionsTop$ = this.productService.getSlot(this.slot, ProductTypeEnum.Avk).pipe(
      map((res) => res.filter((prod) => !prod.productNumber.includes('ral'))),
      this.productToOptionsPipe(this.slot, this.translate.instant('Configuration.AVK.Dropdowns.None')),
      tap((res) => (this.selectedFilterTop = res.selected)),
      map((res) => res.res)
    );
    this.airFilterOptionsBottom$ = this.productService.getSlot(this.slot + 1, ProductTypeEnum.Avk).pipe(
      map((res) => res.filter((prod) => !prod.productNumber.includes('ral'))),
      this.productToOptionsPipe(this.slot + 1, this.translate.instant('Configuration.AVK.Dropdowns.None')),
      tap((res) => (this.selectedFilterBottom = res.selected)),
      map((res) => res.res)
    );
  }

  toggle(checked: boolean) {
    if (!checked) {
      if (this.item.set.some((s) => s.slot == this.slot)) {
        this.removeSlot.emit(this.slot);
      }
      if (this.item.set.some((s) => s.slot == this.slot + 1)) {
        this.removeSlot.emit(this.slot + 1);
      }
    }
  }

  selectFilter(opt: Option, slot: number) {
    if (opt.value) {
      this.addSlot.emit({
        create: { productId: opt.value },
        slot
      });
    } else {
      this.removeSlot.emit(slot);
    }
  }
}
