import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CreateProductLineItemDto, ProductLineItem, UpdateProductLineItemDto } from '../../../api-client';
import { OrderService } from '../../../services/order.service';
import { TemplateService } from '../../../services/template.service';
import { ToastService } from '../../../services/toast.service';
import { Observable, map } from 'rxjs';

export interface AddSWGSlotDTO {
  slot: number;
  productNumber?: string;
  create?: CreateProductLineItemDto;
}

export interface UpdateSWGSlotDTO {
  slot: number;
  update: UpdateProductLineItemDto;
}

@Component({
  selector: 'app-swg',
  templateUrl: './swg.component.html',
  styleUrls: ['./swg.component.scss']
})
export class SwgComponent implements OnInit {
  @Input()
  item!: ProductLineItem;

  @Input()
  index!: number;

  isExpanded = false;

  addModalOpen = false;

  templateName = '';
  createTemplateModal = false;

  fieldAmount$!: Observable<number | undefined>;

  labelProduct?: ProductLineItem;
  font: string = 'Arial';
  color: string = 'Black';

  constructor(
    public readonly orderService: OrderService,
    public readonly translate: TranslateService,
    public readonly templateService: TemplateService,
    private readonly toastService: ToastService
  ) {
    this.fieldAmount$ = this.orderService.order$.pipe(
      map((o) => o?.lineItems.reduce((sum, curr) => (sum += curr.isField ? 1 : 0), 0))
    );
    this.ngOnChanges();
  }

  ngOnInit(): void {}

  ngOnChanges(changes?: SimpleChanges) {
    this.labelProduct = this.orderService.order$.value?.lineItems?.find(
      (l) => l.addedFrom && l.addedFrom.includes(this.item?.id) && l.isLabel
    );
  }

  removeField() {
    this.orderService.removeLineItem(this.item.id);
  }

  toggleIsExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  addAddon(dto: AddSWGSlotDTO) {
    if (dto.productNumber) {
      this.orderService.addAddonProductByName(this.item.id, dto.productNumber);
    } else if (dto.create) {
      this.orderService.addAddonProduct(this.item.id, dto.create);
    }
  }

  removeAddon(slot: number) {
    this.orderService.removeAddonProduct(this.item.id, slot);
  }

  addSlot(dto: AddSWGSlotDTO) {
    if (this.item.set.some((prod) => prod.id == dto.create?.productId)) {
      this.toastService.displayToast({
        message: this.translate.instant('Configuration.AVK.Errors.AlreadyAdded'),
        time: 5000,
        type: 'danger'
      });
      return;
    }
    this.orderService.addLineItemSlot(this.item.id, dto.slot.toString(), dto.create as CreateProductLineItemDto);
  }

  updateSlot(dto: UpdateSWGSlotDTO) {
    this.orderService.updateLineItemSlot(this.item.id, dto.slot.toString(), dto.update);
  }

  removeSlot(slot: number) {
    if (!this.item.set.some((prod) => prod.slot == slot)) {
      this.toastService.displayToast({
        message: this.translate.instant('Configuration.AVK.Errors.AlreadyRemoved'),
        time: 5000,
        type: 'danger'
      });
      return;
    }
    this.orderService.removeLineItemSlot(this.item.id, slot.toString());
  }
}
