<div class="grid grid-cols-6 items-center justify-start col-span-6 gap-4">
  <div class="col-span-6 md:col-span-3">
    <app-custom-select
      *ngIf="doorOptions$ | async as doorOptions"
      class="w-full"
      [label]="'Configuration.Fields.OpenField.Door' | translate"
      [options]="doorOptions"
      [selectedValue]="selectedDoor!"
      (cchange)="addSlotItem(doorSlot, $event)"
    >
    </app-custom-select>
  </div>
  <div class="col-span-6 md:col-span-3">
    <app-custom-select
      *ngIf="lockOptions$ | async as lockOptions"
      class="w-full"
      [label]="'Configuration.Fields.OpenField.Lock' | translate"
      [options]="lockOptions"
      [selectedValue]="selectedLock!"
      (cchange)="addSlotItem(lockSlot, $event)"
    >
    </app-custom-select>
  </div>
</div>
