<app-modal *ngIf="isInviteEmployeeModalOpen" (onClose)="toggleInviteEmployeeModal()">
  <app-input
    [label]="'Profile.Company.InviteEmailLabel' | translate"
    [type]="'email'"
    [description]="'Profile.Company.InviteEmailDescription' | translate"
    [id]="'employeemail'"
    (onChange)="this.inviteEmail = $event"
    [value]="inviteEmail"
  ></app-input>
  <app-button
    [label]="'Profile.Company.InviteSubmit' | translate"
    [type]="'secondary'"
    [fullWidth]="true"
    (onClick)="inviteEmpoyee()"
  ></app-button>
</app-modal>
<app-address-modal
  [address]="addressToEdit"
  *ngIf="isAddressModalOpen"
  (onClose)="this.isAddressModalOpen = false"
></app-address-modal>
<ng-container *ngIf="this.companyService.company$ | async as company">
  <ng-container *ngIf="company.discounts && company.discounts.length > 0">
    <div class="flex items-center justify-between mb-6">
      <span class="block text-lg font-medium uppercase">{{ 'Profile.Discount.Header' | translate }}</span>
    </div>
    <div class="w-full p-8 pb-6 bg-white mb-12">
      <div class="flex items-center justify-center my-8 divide-x divide-gray-200">
        <div
          class="flex flex-col items-center w-full justify-center px-8"
          *ngIf="discountCountFix$ | async as discountCountFix"
        >
          <span class="text-sm font-medium text-gray-500">{{ 'Profile.Discount.FixLabel' | translate }}</span>
          <ng-container *ngFor="let discount of company.discounts">
            <span *ngIf="discount.type === 'Flat'" class="text-base text-green-500">
              <span *ngIf="discountCountFix > 1" class="text-gray-400 font-bold">{{
                'Profile.Company.DiscountProducts.' + discount.productType | translate
              }}</span>
              - {{ discount.flat }} CHF
            </span>
          </ng-container>
        </div>
        <div
          class="flex flex-col items-center w-full justify-center px-8"
          *ngIf="discountCountPercent$ | async as discountCountPercent"
        >
          <span class="text-sm font-medium text-gray-500">{{ 'Profile.Discount.PercentLabel' | translate }}</span>
          <ng-container *ngFor="let discount of company.discounts">
            <span *ngIf="discount.type === 'Percent'" class="text-base flex gap-2 items-center text-green-500">
              <span *ngIf="discountCountPercent > 1" class="text-gray-400 font-bold">{{
                'Profile.Company.DiscountProducts.' + discount.productType | translate
              }}</span>
              - {{ discount.percent }}%
            </span>
          </ng-container>
        </div>
        <div
          class="flex flex-col items-center w-full justify-center px-8"
          *ngIf="discountCountSeries$ | async as discountCountSeries"
        >
          <span class="text-sm font-medium text-gray-500">{{ 'Profile.Discount.SeriesLabel' | translate }}</span>
          <ng-container *ngFor="let discount of company.discounts">
            <ng-container *ngIf="discount.type === 'Series'">
              <span *ngFor="let series of discount.series" class="text-base flex gap-2 items-center text-green-500">
                <span *ngIf="discountCountSeries > 1" class="text-gray-400 font-bold">{{
                  'Profile.Company.DiscountProducts.' + discount.productType | translate
                }}</span>
                {{ 'Profile.Discount.SeriesFrom' | translate : series }} - {{ series.discountAmount }}%
              </span>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="this.companyService.company$ | async as company">
  <div class="flex items-center justify-between mb-6">
    <span class="block text-lg font-medium uppercase">{{ 'Profile.Defaults.Header' | translate }}</span>
  </div>
  <div *ngVar="isAdmin$ | async as isAdmin" class="w-full p-8 pb-6 bg-white mb-12">
    <div class="setting-wrapper">
      <div class="flex flex-col items-center justify-center" *ngIf="company.availableProductTypes.length > 0">
        <span class="text-xl text-gray-500 font-semibold">ARS Aluminium-Reihenschrank</span>
      </div>
      <div class="lg:flex justify-center my-8 sm:divide-x divide-gray-200">
        <div class="flex flex-col w-full px-8">
          <span class="mb-2 text-sm font-medium text-gray-500 w-full lg:text-center">{{
            'Profile.Defaults.Gewindebolzen.Header' | translate
          }}</span>
          <app-radio
            name="gewinde"
            class="my-1"
            [label]="'Profile.Defaults.Gewindebolzen.None' | translate"
            [checked]="company.defaultSlot13 == 0"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              updateCompanyDefaults(company.id, {
                defaultSlot13: 0
              })
            "
          ></app-radio>
          <app-radio
            name="gewinde"
            class="my-1"
            [label]="'Profile.Defaults.Gewindebolzen.2' | translate"
            [checked]="company.defaultSlot13 == 2"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              updateCompanyDefaults(company.id, {
                defaultSlot13: 2
              })
            "
          ></app-radio>
          <app-radio
            name="gewinde"
            class="my-1"
            [label]="'Profile.Defaults.Gewindebolzen.4' | translate"
            [checked]="company.defaultSlot13 == 4"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              updateCompanyDefaults(company.id, {
                defaultSlot13: 4
              })
            "
          ></app-radio>
        </div>
        <div class="flex flex-col w-full px-8">
          <span class="mb-2 mt-4 lg:mt-0 text-sm font-medium text-gray-500 w-full lg:text-center">{{
            'Profile.Defaults.Wandbefestigungswinkel.Header' | translate
          }}</span>
          <app-radio
            name="wandbef"
            class="my-1"
            [label]="'Profile.Defaults.Wandbefestigungswinkel.None' | translate"
            [checked]="company.defaultAddonWall == 0"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              updateCompanyDefaults(company.id, {
                defaultAddonWall: 0
              })
            "
          ></app-radio>
          <app-radio
            name="wandbef"
            class="my-1"
            [label]="'Profile.Defaults.Wandbefestigungswinkel.1' | translate"
            [checked]="company.defaultAddonWall == 2"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              updateCompanyDefaults(company.id, {
                defaultAddonWall: 2
              })
            "
          ></app-radio>
          <app-radio
            name="wandbef"
            class="my-1"
            [label]="'Profile.Defaults.Wandbefestigungswinkel.2' | translate"
            [checked]="company.defaultAddonWall == 1"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              updateCompanyDefaults(company.id, {
                defaultAddonWall: 1
              })
            "
          ></app-radio>
        </div>
        <div class="flex flex-col w-full px-8">
          <span class="mb-2 mt-4 lg:mt-0  text-sm font-medium text-gray-500 w-full lg:text-center">{{
            'Profile.Defaults.Schemafach.Header' | translate
          }}</span>
          <app-radio
            name="schema"
            class="my-1"
            [label]="'Profile.Defaults.Schemafach.None' | translate"
            [checked]="company.defaultSlot12 == null"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              updateCompanyDefaults(company.id, {
                defaultSlot12: null,
                defaultSlot12PerField: false
              })
            "
          ></app-radio>
          <app-radio
            name="schema"
            class="my-1"
            [label]="'Profile.Defaults.Schemafach.PerField' | translate"
            [checked]="company.defaultSlot12PerField && company.defaultSlot12 != null"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              updateCompanyDefaults(company.id, {
                defaultSlot12: selectedSchemaSize.value,
                defaultSlot12PerField: true
              })
            "
          ></app-radio>
          <app-radio
            name="schema"
            class="my-1"
            [label]="'Profile.Defaults.Schemafach.PerConfiguration' | translate"
            [checked]="!company.defaultSlot12PerField && company.defaultSlot12 != null"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              updateCompanyDefaults(company.id, {
                defaultSlot12: selectedSchemaSize.value,
                defaultSlot12PerField: false
              })
            "
          ></app-radio>
          <ng-container *ngIf="schemaSizes$ | async as sizes">
            <app-custom-select
              *ngIf="company.defaultSlot12 != null"
              [placeholder]="false"
              [options]="sizes"
              [selectedValue]="selectedSchemaSize"
              [isReadonly]="!isAdmin"
              (cchange)="
                selectedSchemaSize = $event;
                updateCompanyDefaults(company.id, {
                  defaultSlot12: selectedSchemaSize.value
                })
              "
              [label]="'Profile.Defaults.Schemafach.Size' | translate"
              class="mt-2"
            ></app-custom-select>
          </ng-container>
        </div>
        <div class="flex flex-col w-full px-8">
          <span class="mb-2 mt-4 lg:mt-0  text-sm font-medium text-gray-500 w-full lg:text-center">{{
            'Profile.Defaults.Rostbefestigung.Header' | translate
          }}</span>
          <app-radio
            name="rostbef"
            class="my-1"
            [label]="'Profile.Defaults.Rostbefestigung.None' | translate"
            [checked]="company.defaultSlot22 == 0"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              updateCompanyDefaults(company.id, {
                defaultSlot22: 0
              })
            "
          ></app-radio>
          <app-radio
            name="rostbef"
            class="my-1"
            [label]="'Profile.Defaults.Rostbefestigung.2' | translate"
            [checked]="company.defaultSlot22 == 2"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              updateCompanyDefaults(company.id, {
                defaultSlot22: 2
              })
            "
          ></app-radio>
        </div>
      </div>
    </div>    
    <hr class="mb-6" *ngIf="company.availableProductTypes.indexOf('AL') != -1" />
    <div class="setting-wrapper" *ngIf="company.availableProductTypes.indexOf('AL') != -1">
      <div class="flex flex-col items-center justify-center">
        <span class="text-xl text-gray-500 font-semibold">AL Aluminium-Wandgehäuse</span>
      </div>
      <div class="sm:flex justify-center my-8 sm:divide-x divide-gray-200">
        <div class="flex flex-col w-full px-8">
          <span class="mb-2 text-sm font-medium text-gray-500 w-full sm:text-center">{{
            'Profile.Defaults.AL.Wandbefestigungslaschen.Header' | translate
          }}</span>
          <app-radio
            name="al-wandbef"
            class="my-1"
            [label]="'Profile.Defaults.AL.Wandbefestigungslaschen.None' | translate"
            [checked]="((company | companyHasDefault : 'wblal200-400mrw') && (company | companyHasDefault : 'wblal200-400v')) == undefined"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              deleteDefaultALAddonWall(company);
            "
          ></app-radio>
          <app-radio
            name="al-wandbef"
            class="my-1"
            [label]="'Profile.Defaults.AL.Wandbefestigungslaschen.1' | translate"
            [checked]="company | companyHasDefault : 'wblal200-400mrw'"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              setDefaultProductALAddonWall(company.id, 'wblal200-400mrw', 4)
            "
          ></app-radio>
          <app-radio
            name="al-wandbef"
            class="my-1"
            [label]="'Profile.Defaults.AL.Wandbefestigungslaschen.2' | translate"
            [checked]="company | companyHasDefault : 'wblal200-400v'"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              setDefaultProductALAddonWall(company.id, 'wblal200-400v', 4)
            "
          ></app-radio>
        </div>
        <div class="flex flex-col w-full px-8">
          <span class="mb-2 mt-4 sm:mt-0 text-sm font-medium text-gray-500 w-full sm:text-center">{{
            'Profile.Defaults.AL.Schema.Header' | translate
          }}</span>
          <app-radio
            name="al-schema"
            class="my-1"
            [label]="'Profile.Defaults.AL.Schema.None' | translate"
            [checked]="company.AL_defaultSlot12 == null"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              updateCompanyDefaults(company.id, {
                AL_defaultSlot12: null,
                AL_defaultSlot12PerField: false
              })
            "
          ></app-radio>
          <app-radio
            name="al-schema"
            class="my-1"
            [label]="'Profile.Defaults.AL.Schema.PerField' | translate"
            [checked]="company.AL_defaultSlot12PerField && company.AL_defaultSlot12 != null"
            [isReadonly]="!isAdmin"
            (checkedChange)="
              updateCompanyDefaults(company.id, {
                AL_defaultSlot12: selectedSchemaSize.value,
                AL_defaultSlot12PerField: true
              })
            "
          ></app-radio>
          <ng-container *ngIf="schemaSizes$ | async as sizes">
            <app-custom-select
              *ngIf="company.AL_defaultSlot12 != null"
              [placeholder]="false"
              [options]="sizes"
              [selectedValue]="selectedSchemaSize"
              [isReadonly]="!isAdmin"
              (cchange)="
                selectedSchemaSize = $event;
                updateCompanyDefaults(company.id, {
                  AL_defaultSlot12: selectedSchemaSize.value
                })
              "
              [label]="'Profile.Defaults.AL.Schema.Size' | translate"
              class="mt-2"
            ></app-custom-select>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div class="flex items-center justify-between mb-6">
  <span class="block text-lg font-medium uppercase">{{ 'Profile.Company.Header' | translate }}</span>
</div>
<ng-container *ngVar="isAdmin$ | async as isAdmin">
  <div class="relative sm:pt-0 w-full p-8 pb-6 bg-white" *ngIf="this.companyService.company$ | async as company" [ngClass]="!isAdmin ? 'p-4' : 'p-24 sm:p-0'">
    <!-- profile -->
    <div>
      <div class="flex flex-col items-center justify-center">
        <span class="text-xl font-semibold">{{ company.name }}</span>
        <span class="text-xs text-gray-500">{{ company.id }}</span>
      </div>
      <app-modal
        *ngIf="confirmDeleteModal"
        [submitLabel]="'Profile.Company.DeleteConfirm' | translate"
        [cancelLabel]="'Profile.Company.DeleteDecline' | translate"
        (onClose)="confirmDeleteModal = false"
        (onSubmit)="companyService.requestDelete(); confirmDeleteModal = false"
      >
        {{ 'Profile.Company.DeleteConfirmation' | translate }}
      </app-modal>
      <app-button
        *ngIf="isAdmin"
        [isDisabled]="company.deleteRequest"
        [label]="
          company.deleteRequest
            ? ('Profile.Company.CompanyDeleteRequested' | translate)
            : ('Profile.Company.RequestDelete' | translate)
        "
        [icon]="'trash'"
        [type]="'primary'"
        class="absolute right-8 top-6"
        (onClick)="confirmDeleteModal = true"
      ></app-button>
      <div class="flex items-center justify-center my-8 divide-x divide-gray-200">
        <div class="flex flex-col items-center justify-center px-8">
          <span class="text-sm font-medium text-gray-500">{{ 'Profile.Company.Employees' | translate }}</span>
          <span class="text-lg font-semibold">{{ company.users.length }}</span>
        </div>
        <div class="flex flex-col items-center justify-center px-8">
          <span class="text-sm font-medium text-gray-500">{{ 'Profile.Company.OpenInvites' | translate }}</span>
          <span class="text-lg font-semibold">{{ company.invites.length }}</span>
        </div>
        <div class="flex flex-col items-center justify-center px-8">
          <span class="text-sm font-medium text-gray-500">{{ 'Profile.Company.AddressCountLabel' | translate }}</span>
          <span class="text-lg font-semibold">{{ company.addresses.length }}</span>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <span class="font-medium" [ngClass]="!isAdmin ? 'relative right-4' : 'relative right-20 sm:left-3.5'">{{ 'Profile.Company.Employees' | translate }}</span>
        <app-button
          *ngIf="isAdmin"
          [label]="'Profile.Company.InviteLabel' | translate"
          [icon]="'person-add'"
          [type]="'secondary'"
          (onClick)="toggleInviteEmployeeModal()"
          class="mr-8"
        ></app-button>
      </div>
    </div>
  </div>
  <!-- <app-table
    class="bg-white"
    *ngIf="this.companyService.company$ | async as company"
    [columns]="this.columns"
    [rows]="this.rows"
    [selectionField]="false"
    [entryCount]="company.users.length"
    [limit]="10"
    [pagination]="company.users.length > 10 ? true : false"
    [name]="'employees'"
    [insideCard]="true"
  ></app-table> -->

  <app-user-modal
    *ngIf="userEditModalOpen && selectedUser"
    [user]="selectedUser"
    (onClose)="userEditModalOpen = false"
    (onSubmitUser)="updateUser($event)"
  ></app-user-modal>

  <app-modal
    *ngIf="confirmDeleteUserModal && selectedDeleteUser"
    [submitLabel]="'Profile.Company.DeleteConfirm' | translate"
    [cancelLabel]="'Profile.Company.DeleteDecline' | translate"
    (onClose)="confirmDeleteUserModal = false"
    (onSubmit)="confirmDeleteUserModal = false; deleteUser(selectedDeleteUser)"
  >
    {{
      'Profile.Company.DeleteUserConfirm'
        | translate : { firstname: selectedDeleteUser.firstName, lastname: selectedDeleteUser.lastName }
    }}
  </app-modal>

  <div class="overflow-x-auto">
    <table
      class="ass-table"
      *ngIf="this.companyService.company$ | async as company"
      mat-table
      [dataSource]="this.companyService.usersPaginated$"
      matSort
      (matSortChange)="sortEmployees($event)"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name">
          {{ 'Profile.Company.Table.Columns.Name' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="flex">
            <app-username-renderer
              [data]="{
                value: row.firstName + ' ' + row.lastName,
                meta: { isAdmin: company.admin.id === row.id ? true : false }
              }"
            ></app-username-renderer>
            <app-badge
              type="success"
              class="ml-2"
              *ngIf="authService.currentUser !== undefined && row.id === authService.currentUser.id"
              label="Du"
            ></app-badge>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="email">
          {{ 'Profile.Company.Table.Columns.Email' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="created">
          {{ 'Profile.Company.Table.Columns.Created' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <app-date-renderer
            [data]="{
              value: row.created
            }"
          ></app-date-renderer>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'Profile.Company.Table.Columns.Actions' | translate }}</th>
        <td mat-cell *matCellDef="let row">
          <div class="flex gap-x-4">
            <app-button
              *ngIf="isAdmin && authService.currentUser !== undefined && row.id !== authService.currentUser.id"
              style="width: fit-content"
              icon="edit"
              [onlyIcon]="true"
              (onClick)="userEditModalOpen = true; selectedUser = row"
            ></app-button>
            <app-button
              *ngIf="isAdmin && authService.currentUser !== undefined && row.id !== authService.currentUser.id"
              style="width: fit-content"
              icon="trash"
              [onlyIcon]="true"
              (onClick)="confirmDeleteUserModal = true; selectedDeleteUser = row"
            ></app-button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"></tr>
    </table>
  </div>

  <mat-paginator
    *ngIf="this.companyService.usersPaginatedCount$ | async as length"
    [pageSize]="limit"
    [length]="length"
    (page)="paginateUsers($event)"
    aria-label="Select page of users"
  ></mat-paginator>

  <div class="w-full p-8 pt-6 bg-white" *ngIf="this.companyService.company$ | async as company">
    <hr class="mb-6" />
    <div class="flex items-center justify-between">
      <span class="font-medium">{{ 'Profile.Company.Addresses.Header' | translate }}</span>
      <app-button
        *ngIf="isAdmin"
        [label]="'Profile.Company.Addresses.AddButton' | translate"
        [icon]="'add-location'"
        [type]="'secondary'"
        (onClick)="createAddress()"
      ></app-button>
    </div>
    <div class="grid grid-cols-1 gap-8 mt-6 lg:grid-cols-3" *ngIf="company.addresses.length > 0">
      <div class="relative col-span-1" *ngFor="let address of company.addresses">
        <div
          class="absolute flex items-center justify-center h-16 px-2 text-white -top-1 bg-emerald-500 right-4"
          [matTooltip]="'Profile.Company.Addresses.DefaultAddressTooltip' | translate"
          matTooltipPosition="above"
          matTooltipShowDelay="800"
          *ngIf="address.isActive"
        >
          <app-icon [icon]="'done'" class="w-5 h-5"></app-icon>
        </div>
        <button
          class="absolute flex items-center justify-center h-16 px-2 text-white bg-emerald-500 focus:ring-emerald-500 -top-1 right-14"
          [matTooltip]="'Profile.Company.Addresses.CompanyAddressTooltip' | translate"
          matTooltipPosition="above"
          matTooltipShowDelay="800"
          *ngIf="address.mainAddress"
        >
          <app-icon [icon]="'add-location'" class="w-5 h-5"></app-icon>
        </button>
        <button
          (click)="setAddressActive(address)"
          class="absolute flex items-center justify-center h-16 px-2 text-gray-400 bg-transparent bg-gray-200 -top-1 right-4 hover:text-emerald-500"
          [matTooltip]="'Profile.Company.Addresses.ChangeDefaultAddressTooltip' | translate"
          matTooltipPosition="above"
          matTooltipShowDelay="800"
          *ngIf="!address.isActive"
        >
          <app-icon [icon]="'done'" class="w-5 h-5"></app-icon>
        </button>
        <div class="flex flex-col items-start p-4 bg-gray-100">
          <span class="block mb-2 font-medium">{{ address.companyName ? address.companyName : company.name }}</span>
          <span>{{ address.firstname }} {{ address.lastname }}</span>
          <span class="block mb-4">{{ address.phone }}</span>
          <span>{{ address.street }}</span>
          <span>{{ address.postal }} {{ address.city }}</span>
          <span>{{ address.country }}</span>
        </div>
        <app-button
          *ngIf="!address.mainAddress"
          [label]="'Profile.Company.Addresses.EditCTA' | translate"
          [fullWidth]="true"
          [icon]="'edit'"
          (onClick)="editAddress(address)"
        ></app-button>
        <app-button
          *ngIf="address.mainAddress"
          [isDisabled]="true"
          [label]="'Profile.Company.Addresses.EditCTA' | translate"
          [fullWidth]="true"
          [icon]="'edit'"
          [matTooltip]="'Profile.Company.Addresses.ChangeCompanyAddressTooltip' | translate"
          matTooltipPosition="above"
        ></app-button>
      </div>
      <div class="col-span-1" *ngIf="isAdmin">
        <button
          class="flex items-center justify-center w-full h-full bg-white border-2 text-emerald-500 border-emerald-500 hover:text-monza-500 hover:border-monza-500"
          (click)="createAddress()"
        >
          <app-icon [icon]="'add-location'" class="w-5 h-5 mr-2"></app-icon>
          {{ 'Profile.Company.Addresses.AddCard' | translate }}
        </button>
      </div>
    </div>
    <div class="w-full mt-8 text-center" *ngIf="company.addresses.length === 0">
      <span class="font-medium text-gray-500">Noch keine Adressen hinterlegt</span>
    </div>
  </div>
</ng-container>
