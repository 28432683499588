<div class="flex min-h-screen">
    <div class="flex flex-col justify-center flex-1 w-full max-w-xl px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="w-full">
            <img class="w-auto h-12 mx-auto" src="assets/svg/logo.svg" alt="Logo ASS">
        </div>
      <div class="w-full mx-auto my-auto">
        <div>
          <h2 class="mt-6 text-3xl font-extrabold text-gray-800">{{'Auth.Login.Login'|translate}}</h2>
          <p class="mt-2 text-sm text-gray-500">
            {{'Auth.Login.Or'|translate}}
            <a 
            [routerLink]="['/branch']" 
            class="font-medium text-monza-500 hover:text-monza-600">{{'Auth.Login.Register'|translate}}</a>
          </p>
        </div>
  
  
        <div class="mt-8">
            <form (submit)="login()" class="space-y-6">

              <app-input 
                [label]="'Auth.Login.Email'|translate" 
                [type]="'email'" 
                [placeholder]="'mail@ass-ag.ch'" 
                (onChange)="this.email = $event">
              </app-input>

              <app-input 
                [label]="'Auth.Login.Password'|translate" 
                [type]="'password'" 
                (onChange)="this.password = $event">
              </app-input>

              <div class="flex items-center justify-end">
                  <div class="text-sm">
                      <a 
                        [routerLink]="['/forgot']" 
                        class="font-medium text-monza-500 hover:text-monza-600"
                      > {{'Auth.Login.Forgot'|translate}} </a>
                  </div>
              </div>

              <div *ngIf="auth.errorMessage" class="flex items-center justify-center gap-2 text-monza-500">
                  <app-icon class="flex-none w-5 h-5" [icon]="'error'"></app-icon>
                  <span class="text-sm font-medium" [innerHTML]="auth.errorMessage"></span>
              </div>

              <app-button 
                [label]="'Auth.Login.LoginCTA'|translate" 
                [size]="'xl'" 
                [fullWidth]="true" 
                btnType="submit"
              >
              </app-button>
              
            </form>
        </div>
      </div>
    </div>
    <!-- preview -->
    <app-preview class="relative flex-1 hidden w-0 lg:block"></app-preview>
  </div>