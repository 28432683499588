<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-start justify-start col-span-6 gap-4" *ngIf="products$ | async as products; else xsLoading">
  <label for="ceiling-default" class="flex flex-col gap-4 cursor-pointer">
    <div class="place-self-center cursor-pointer">
      <svg xmlns="http://www.w3.org/2000/svg" width="155" height="20" viewBox="0 0 155 20">
        <rect id="Rechteck_9" data-name="Rechteck 9" width="155" height="20" fill="#d4d4d4" />
      </svg>
    </div>
    <app-radio
      inputId="ceiling-default"
      class="place-self-center"
      name="ceiling-type"
      [label]="'Configuration.AVK.CeilingTypes.Default' | translate"
      [checked]="selectedProduct && selectedProduct.productNumber == CeilingMap['Default']"
      (checkedChange)="selectCeilingType(products, CeilingMap['Default'])"
    ></app-radio>
  </label>
  <label for="ceiling-design" class="flex flex-col gap-4 cursor-pointer">
    <div class="place-self-center cursor-pointer">
      <svg xmlns="http://www.w3.org/2000/svg" width="155" height="20" viewBox="0 0 155 20">
        <path id="Vereinigungsmenge_1" data-name="Vereinigungsmenge 1" d="M0,20V10L10,0H145l10,10V20Z" fill="#d4d4d4" />
      </svg>
    </div>
    <app-radio
      inputId="ceiling-design"
      class="place-self-center"
      name="ceiling-type"
      [label]="'Configuration.AVK.CeilingTypes.Design' | translate"
      [checked]="selectedProduct && selectedProduct.productNumber == CeilingMap['Design']"
      (checkedChange)="selectCeilingType(products, CeilingMap['Design'])"
    ></app-radio>
  </label>
  <label for="ceiling-giebel" class="flex flex-col gap-4 cursor-pointer">
    <div class="place-self-center cursor-pointer">
      <svg xmlns="http://www.w3.org/2000/svg" width="155" height="20" viewBox="0 0 155 20">
        <path
          id="Vereinigungsmenge_2"
          data-name="Vereinigungsmenge 2"
          d="M0,20V10L79.215,0,155,10V20Z"
          fill="#d4d4d4"
        />
      </svg>
    </div>
    <app-radio
      inputId="ceiling-giebel"
      class="place-self-center"
      name="ceiling-type"
      [label]="'Configuration.AVK.CeilingTypes.Gabled' | translate"
      [checked]="selectedProduct && selectedProduct.productNumber == CeilingMap['Gabled']"
      (checkedChange)="selectCeilingType(products, CeilingMap['Gabled'])"
    ></app-radio>
  </label>
  <label for="ceiling-pult" class="flex flex-col gap-4 cursor-pointer">
    <div class="place-self-center cursor-pointer">
      <svg xmlns="http://www.w3.org/2000/svg" width="155" height="20" viewBox="0 0 155 20">
        <path id="Vereinigungsmenge_3" data-name="Vereinigungsmenge 3" d="M0,20V10L155,0V20Z" fill="#d4d4d4" />
      </svg>
    </div>
    <app-radio
      inputId="ceiling-pult"
      class="place-self-center"
      name="ceiling-type"
      [label]="'Configuration.AVK.CeilingTypes.Pent' | translate"
      [checked]="selectedProduct && selectedProduct.productNumber == CeilingMap['Pent']"
      (checkedChange)="selectCeilingType(products, CeilingMap['Pent'])"
    ></app-radio>
  </label>
</div>

<ng-template #lgLoading>
  <app-skeleton size="lg" ></app-skeleton>
</ng-template>
<ng-template #mdLoading>
  <app-skeleton size="md" ></app-skeleton>
</ng-template>
<ng-template #xsLoading>
  <app-skeleton size="xs" ></app-skeleton>
</ng-template>