<div class="grid grid-cols-4 items-center justify-start col-span-6 gap-4">
  <app-checkbox
    [label]="'Configuration.SWG.Options.AssemblyPlate' | translate"
    *ngIf="assemblyPlateProduct$ | async as assemblyPlateProduct"
    [(checked)]="selectedAssemblyPlate"
    (checkedChange)="toggleAssemblyPlate(assemblyPlateProduct.id)"
    [isReadonly]="order.status == OrderStatusEnum.Ordered"
    class="col-span-4 md:col-span-2 xl:col-span-1"
  ></app-checkbox>
  <app-checkbox
    [label]="'Configuration.SWG.Options.Moosgummi' | translate"
    *ngIf="entryProduct$ | async as entryProduct"
    [(checked)]="selectedEntry"
    (checkedChange)="toggleEntry(entryProduct.id)"
    [isReadonly]="!isEntryValid || order.status == OrderStatusEnum.Ordered"
    class="col-span-4 md:col-span-2 xl:col-span-1"
  ></app-checkbox>
  <app-checkbox
    [label]="'Configuration.SWG.Options.Wandbef' | translate"
    *ngIf="wallAttachProduct$ | async as wallAttachProduct"
    [(checked)]="selectedWallAttach"
    (checkedChange)="toggleWallAttach(wallAttachProduct.id)"
    [isReadonly]="!isWallAttachValid || order.status == OrderStatusEnum.Ordered"
    class="col-span-4 md:col-span-2 xl:col-span-1"
  ></app-checkbox>
  <app-checkbox
    [label]="'Configuration.SWG.Options.Rostbef' | translate"
    *ngIf="grateFasteningProduct$ | async as grateFasteningProduct"
    [(checked)]="selectedGrateFastening"
    (checkedChange)="toggleGrateFastening(grateFasteningProduct.id)"
    [isReadonly]="!isGrateFasteningValid || order.status == OrderStatusEnum.Ordered"
    class="col-span-4 md:col-span-2 xl:col-span-1"
  ></app-checkbox>
</div>
