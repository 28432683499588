<div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" >
    <div class="absolute w-full h-full backdrop-click" (click)="onClose.emit()"></div>
    <div class="relative flex flex-col max-h-screen gap-4 p-8 bg-white" [ngClass]="{'overflow-y-auto': overflow}">
        <app-button [label]="'Schließen'" [icon]="'close'" [onlyIcon]="true" [type]="'ghost'" [size]="'xs'" (onClick)="onClose.emit()" class="absolute top-1 right-1"></app-button>
        <ng-content></ng-content>

        <div class="flex justify-between" *ngIf="cancelLabel !== '' || submitLabel !== ''">
            <app-button *ngIf="cancelLabel !== ''" [label]="cancelLabel" (onClick)="onClose.emit()"></app-button>
            <app-button class="ml-4" *ngIf="submitLabel !== ''" [label]="submitLabel" (onClick)="onSubmit.emit()"></app-button>
        </div>

    </div>
</div>