<div class="flex items-center justify-start col-span-6 gap-4">
  <div class="col-span-6 flex items-start justify-start gap-4">
    <ng-container *ngIf="backWallProduct$ | async as backWallProduct; else mdLoading">
      <ng-container *ngIf="backWallDoor$ | async as backWallDoor; else mdLoading">
        <app-radio
          class="col-span-3"
          name="backwall-as-door"
          [label]="'Configuration.AVK.Backwall.Screwed' | translate"
          [checked]="!asDoor"
          (checkedChange)="toggleDoor(backWallProduct, backWallDoor, false)"
        ></app-radio>
        <app-radio
          *ngIf="backWallDoor$ | async as backWallDoor"
          class="col-span-3"
          name="backwall-as-door"
          [label]="'Configuration.AVK.Backwall.AsDoor' | translate"
          [checked]="asDoor"
          (checkedChange)="toggleDoor(backWallProduct, backWallDoor, true)"
        ></app-radio
      ></ng-container>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="!asDoor">
  <app-custom-select
    *ngIf="openingOptions$ | async as openingOptions; else mdLoading"
    class="col-span-2"
    [placeholder]="true"
    [label]="'Configuration.AVK.Door.Opening' | translate"
    [options]="openingOptions"
    [selectedValue]="selectedOpening!"
    (cchange)="selectedOpening = $event; setOpening($event.value)"
  ></app-custom-select>
</ng-container>

<app-avk-door
  [item]="item"
  doorInstallationArea="back"
  (addSlot)="addSlot.emit($event)"
  (updateSlot)="updateSlot.emit($event)"
  (removeSlot)="removeSlot.emit($event)"
  [show]="asDoor"
></app-avk-door>


<ng-template #lgLoading>
  <app-skeleton size="lg"></app-skeleton>
</ng-template>
<ng-template #mdLoading>
  <app-skeleton size="md" class="col-span-3"></app-skeleton>
</ng-template>
<ng-template #xsLoading>
  <app-skeleton size="xs"></app-skeleton>
</ng-template>
