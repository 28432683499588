import { Pipe, PipeTransform } from '@angular/core';
import { ProductLineItem } from '../api-client';

@Pipe({
  name: 'translatedName'
})
export class TranslatedNamePipe implements PipeTransform {
  transform(item: ProductLineItem, langKey: string): string {
    if (langKey === undefined || langKey === 'de') {
      return item.name;
    } else {
      return item.nameFr !== '' ? item.nameFr : item.name;
    }
  }
}
