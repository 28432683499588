import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { colors } from '../../../colors';
import { BaseAVKSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { ProductService } from '../../../../../services/product.service';

@Component({
  selector: 'app-avk-surface',
  templateUrl: './surface.component.html',
  styleUrls: ['./surface.component.scss']
})
export class SurfaceComponent extends BaseAVKSlotComponent {
  @Input()
  item!: ProductLineItem;

  eloxProduct$ = this.productService.getSlot(211, ProductTypeEnum.Avk).pipe(map((p) => p[0]));

  colorItem?: ProductLineItem;
  colorProductId!: string;
  hasElox = false;
  hasGraffiti = false;

  colorProducts$ = this.productService.getSlot(210, ProductTypeEnum.Avk);

  colorRalProduct$ = this.colorProducts$.pipe(map((p) => p.find((p) => p.productNumber == 'avral')));
  colorProduct$ = this.colorProducts$.pipe(map((p) => p.find((p) => p.productNumber == 'av7035')));
  colorGraffitiProduct$ = this.colorProducts$.pipe(map((p) => p.find((p) => p.productNumber == 'avanti')));

  colorPickerOpen = false;
  readonly RAL_COLORS = colors;
  ralError: string = '';

  ralColor!: Observable<Option>;
  ral7035!: Observable<Option>;

  selectedColor: {
    code: string;
    rgb_approx: string;
    rgb_hex: string;
    name: string;
  } = this.RAL_COLORS.find((color) => color.code == '7035')!;

  @ViewChild('elem') elem!: ElementRef;
  @ViewChild('elem2') elem2!: ElementRef;
  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly productService: ProductService
  ) {
    super(translate);
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.elem || !this.elem2) return;
      if (!this.elem.nativeElement.contains(e.target) && !this.elem2.nativeElement.contains(e.target)) {
        this.colorPickerOpen = false;
        this.cdr.detectChanges();
      }
    });
  }

  override initItem(): void {}
  override updateItem(firstChange?: boolean | undefined): void {
    this.hasElox = this.item.set.some((p) => p.slot == 211);
    this.colorItem = this.item.set.find((s) => s.slot == 210 && s.custom);
    if (this.colorItem) {
      this.selectedColor = this.RAL_COLORS.find((color) => color.code == this.colorItem?.custom) ?? this.selectedColor;

      if (this.colorItem.productNumber == 'avanti') {
        this.hasGraffiti = true;
      }
    }
  }

  setColor(code: string, colorProductId: string) {
    const color = this.RAL_COLORS.find((c) => c.code == code);

    if (color) {
      this.selectedColor = color;
      if (!this.item.set.some((i) => i.productId == colorProductId)) {
        this.addSlot.emit({ slot: 210, create: { productId: colorProductId, custom: this.selectedColor.code } });
      } else {
        this.updateSlot.emit({ slot: 210, update: { custom: this.selectedColor.code } });
      }
    } else {
      this.ralError = `RAL-${code} konnte nicht gefunden werden`;
    }
  }

  toggleGraffiti(antiGraffiti: Product, fallback: Product, checked: boolean) {
    if (checked) {
      this.setColor(this.selectedColor.code, antiGraffiti.id);
    } else {
      this.setColor(this.selectedColor.code, fallback.id);
    }
    this.hasGraffiti = checked;
  }

  toggleElox(product: Product, checked: boolean) {
    if (checked) {
      this.addSlot.emit({
        create: { productId: product.id },
        slot: 211
      });
    } else {
      this.removeSlot.emit(211);
    }
    this.hasElox = checked;
  }
}
