import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '../../api-client';

@Pipe({
  name: 'companyHasDefault'
})
export class CompanyHasDefault implements PipeTransform {
  transform(company: Company, productNumber: string): boolean {
    if (!company || !productNumber) return false;

    return company.defaultProducts?.some((p) => p.productNumber === productNumber);
  }
}
