<ng-container *ngIf="lineItemAggregations$ | async as lineItemAggregations">
  <div class="relative flex flex-col p-8 bg-white border-l-4 border-blue-500" *ngIf="lineItemAggregations.length > 0">
    <div class="flex flex-col gap-4">
      <div>
        <h3 class="flex items-center font-medium z-10">{{'Configuration.SWG.AggregatedAddons' | translate}}</h3>
      </div>

      <div class="grid grid-cols-[auto_1fr] gap-x-2">
        <ng-container *ngFor="let aggregatedItem of lineItemAggregations">
          <div class="text-blue-500 font-bold">
            {{ aggregatedItem.amount }} ×
          </div>
          <div>
            {{ aggregatedItem.item | concatDescription : translate.currentLang }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
