<div class="grid grid-cols-6 items-center justify-start col-span-6 gap-4">
  <ng-container *ngIf="order.file">
    <ng-container *ngIf="doorHoleProduct$ | async as doorHoleProduct">
      <div class="col-span-6 md:col-span-2">
        <app-input
          [isReadonly]="order.status == OrderStatusEnum.Ordered"
          label="< Ø100 mm / 100 x 100 mm"
          unit="×"
          [value]="doorHoleSelected?.amount"
          (onChange)="
            addSlotItem(slot, doorHoleProduct, {
              amount: +$event,
              amount2: doorHoleSelected?.amount2 ?? 0,
              amount3: doorHoleSelected?.amount3 ?? 0
            })
          "
        ></app-input>
      </div>
      <div class="col-span-6 md:col-span-2">
        <app-input
          [isReadonly]="order.status == OrderStatusEnum.Ordered"
          label="> Ø101 mm / 101 x 101"
          unit="×"
          [value]="doorHoleSelected?.amount2"
          (onChange)="
            addSlotItem(slot, doorHoleProduct, {
              amount: doorHoleSelected?.amount ?? 0,
              amount2: +$event,
              amount3: doorHoleSelected?.amount3 ?? 0
            })
          "
        ></app-input>
      </div>
      <div class="col-span-6 md:col-span-2">
        <app-input
          [isReadonly]="order.status == OrderStatusEnum.Ordered"
          label="> Ø301 mm / 301 x 301"
          unit="×"
          [value]="doorHoleSelected?.amount3"
          (onChange)="
            addSlotItem(slot, doorHoleProduct, {
              amount: doorHoleSelected?.amount ?? 0,
              amount2: doorHoleSelected?.amount2 ?? 0,
              amount3: +$event
            })
          "
        ></app-input></div
    ></ng-container>
  </ng-container>

  <ng-container *ngIf="!order.file">
    <div class="col-span-6 text-xs text-gray-500">
      {{ 'Configuration.Fields.OpenField.DoorHolesMessage' | translate }}
    </div>
  </ng-container>
</div>
