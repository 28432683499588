<div class="container fixed top-0 z-50 flex items-center justify-end h-16 px-4 transition-all duration-150 ease-in-out transform -translate-x-1/2 pointer-events-none left-1/2 lg:px-8" [ngClass]="isActive ? 'opacity-100' : 'opacity-0'">
    <div 
        class="relative flex items-center px-3 py-2 -top-px text-sm font-medium border border-white"
        [ngClass]="toast?.type === 'danger' ? 'bg-monza-500 text-white' : 'bg-emerald-500 text-white'"
    >
        <app-icon *ngIf="toast?.type === 'success'" [icon]="'done'" class="w-5 h-5 mr-2"></app-icon>
        <app-icon *ngIf="toast?.type === 'danger'" [icon]="'warning'" class="w-5 h-5 mr-2"></app-icon>
        {{ toast?.message }}
        <div class="w-full h-0.5 bg-gray-200 absolute left-0 bottom-0" [ngClass]="isAnimation ? 'line-decline' : ''" [ngStyle]="{'animation-duration.ms': toast?.time}"></div>
    </div>
</div>
