<app-modal
  [submitLabel]="'Configuration.SWG.Modal.Add' | translate"
  [cancelLabel]="'Configuration.SWG.Modal.Cancel' | translate"
  *ngIf="isModalOpen"
  [overflow]="false"
  (onClose)="toggleSwgAddonModal()"
  (onSubmit)="submitSwgAddonModal()"
>
  <div class="w-96">
    <app-custom-select
      [label]="'Configuration.SWG.Modal.ChooseAddon' | translate"
      *ngIf="addonProducts$ | async as addonProducts"
      [selectedValue]="selectedAddon!"
      (cchange)="selectedAddon = $event"
      [options]="addonProducts"
    ></app-custom-select>
    <app-input
      [label]="'Configuration.SWG.Modal.Amount' | translate"
      class="my-4"
      [(value)]="quantity"
    ></app-input>
  </div>
</app-modal>
