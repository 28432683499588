<app-modal (onClose)="onClose.emit()">
    <ng-container *ngIf="showExistingAddresses">
        <app-custom-select  
            [isReadonly]="isReadonly"
            *ngIf="addressOptions$ | async as options"
            [options]="options"
            [selectedValue]="selectedAddress"
            [label]="'AddressModal.ChooseExisting'|translate"
            [multiLine]="true"
            (cchange)="$event.meta != undefined ? (this.address = $event.meta) : this.initEmptyAddress(true)"
        >
        </app-custom-select>
        <hr class="my-2">
    </ng-container>

    <form class="relative flex flex-col gap-4 bg-white" *ngIf="this.address != undefined">
        <span class="text-center block w-full">
            <ng-container *ngIf="this.address.id">{{'AddressModal.Edit'|translate}}</ng-container>
            <ng-container *ngIf="!this.address.id">{{'AddressModal.Create'|translate}}</ng-container>
        </span>
        <app-input 
            [isReadonly]="isReadonly || this.address.mainAddress" 
            [label]="'AddressModal.Firstname'|translate" 
            [type]="'text'" 
            [value]="this.address.firstname" 
            (onChange)="this.address.firstname = $event">
        </app-input>
        <app-input 
            [isReadonly]="isReadonly || this.address.mainAddress" 
            [label]="'AddressModal.Lastname'|translate" 
            [type]="'text'" 
            [value]="this.address.lastname" 
            (onChange)="this.address.lastname = $event">
        </app-input>
        <app-input 
            [isReadonly]="isReadonly || this.address.mainAddress" 
            [label]="'AddressModal.Phone'|translate" 
            [type]="'text'" 
            [placeholder]="'+49 123 456 789'" 
            [value]="this.address.phone" 
            (onChange)="this.address.phone = $event">
        </app-input>
        <div class="grid grid-cols-2 gap-4">
            <app-input 
                [isReadonly]="isReadonly || this.address.mainAddress" 
                [label]="'AddressModal.Street'|translate" 
                [type]="'text'" 
                [placeholder]="'Haupstraße 22'" 
                [value]="this.address.street" 
                (onChange)="this.address.street = $event">
            </app-input>
            <app-input 
                [isReadonly]="isReadonly || this.address.mainAddress" 
                [label]="'AddressModal.Land'|translate" 
                [type]="'text'" 
                [placeholder]="'Schweiz'" 
                [value]="this.address.country" 
                (onChange)="this.address.country = $event">
            </app-input>
        </div>
        <div class="grid grid-cols-2 gap-4">
            <app-input 
                [isReadonly]="isReadonly || this.address.mainAddress" 
                [label]="'AddressModal.Postal'|translate" 
                [type]="'text'" 
                [placeholder]="'8564'" 
                [value]="this.address.postal" 
                (onChange)="this.address.postal = $event">
            </app-input>
            <app-input 
                [isReadonly]="isReadonly || this.address.mainAddress" 
                [label]="'AddressModal.City'|translate" 
                [type]="'text'" 
                [placeholder]="'Wäldi'" 
                [value]="this.address.city" 
                (onChange)="this.address.city = $event">
            </app-input>
        </div>
        <app-button 
            [isDisabled]="isReadonly" 
            (onClick)="onSubmitAddress.emit(address)" class="mt-4" 
            [label]="address ? ('AddressModal.Save'|translate) : ('AddressModal.Add'|translate)" 
            [type]="'secondary'" 
            [icon]="'done'" 
            [fullWidth]="true" 
            (onClick)="save()"></app-button>
        <hr class="my-4" *ngIf="(isDeletable && address && !address.isActive && !address.mainAddress && address.id) || (isRemovable && address)">
        <app-button 
            [isDisabled]="isReadonly" *ngIf="isDeletable && address && !address.isActive && !address.mainAddress && address.id" 
            [label]="'AddressModal.Delete'|translate" 
            [type]="'primary'" 
            [fullWidth]="true" 
            [icon]="'delete'" 
            (onClick)="delete()"></app-button>
        <app-button 
            [isDisabled]="isReadonly" *ngIf="isRemovable && address" 
            [label]="'AddressModal.Remove'|translate" 
            [type]="'primary'" 
            [fullWidth]="true" 
            [icon]="'delete'" 
            (onClick)="onRemove.emit()"></app-button>
    </form>
</app-modal>