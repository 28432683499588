<div class="flex min-h-screen">
    <div class="flex flex-col justify-center flex-1 w-full max-w-xl px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="w-full">
            <img class="w-auto h-12 mx-auto" src="assets/svg/logo.svg" alt="Logo ASS">
        </div>
        <div class="w-full mx-auto my-auto">
            <div>
                <h2 class="mt-6 text-3xl font-extrabold text-gray-800">{{'Auth.Register.Register'|translate}}</h2>
                <p class="mt-2 text-sm text-gray-500">
                    {{'Auth.Register.AlreadyRegistered'|translate}}
                    <a 
                    [routerLink]="
                    ['/login']" class="font-medium text-monza-500 hover:text-monza-600">{{'Auth.Register.Login'|translate}}</a>
                </p>
            </div>
    
            <div class="mt-8">

                <div class="space-y-6">
                    <p class="text-sm">{{'Auth.Register.InviteCodeNotice'|translate}}</p>
                    <app-input 
                    [placeholder]="'mail@ass-ag.ch'" 
                    [label]="'Auth.Register.Email'|translate" 
                    (onChange)="this.email = $event; checkInviteToken()">
                </app-input>
                    <app-input 
                    [placeholder]="'Auth.Register.InviteCodePlaceholder'|translate" 
                    [label]="'Auth.Register.InviteCode'|translate" 
                    (onChange)="this.token = $event; checkInviteToken()">
                </app-input>
                <app-button 
                    [label]="'Auth.Register.Continue'|translate" 
                    [fullWidth]="true" 
                    [isDisabled]="!isTokenValid" 
                    (onClick)="goToInviteRegister()">
                </app-button>
                </div>

                <div class="relative my-4">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                      <div class="w-full border-t border-gray-300">

                      </div>
                    </div>
                    <div class="relative flex justify-center text-sm">
                      <span class="px-2 text-gray-500 bg-white"> {{'Auth.Register.Or'|translate}} </span>
                    </div>
                </div>

                <div class="space-y-4">
                    <p class="text-sm">{{'Auth.Register.RegisterNewCompany'|translate}}</p>
                    <app-button 
                    [label]="'Auth.Register.Register'|translate" 
                    [fullWidth]="true" 
                    (onClick)="goToCompanyRegister()" 
                    [type]="'light'">
                </app-button>
                </div>

            </div>
        </div>
    </div>
    <!-- image -->
    <app-preview class="relative flex-1 hidden w-0 lg:block">

    </app-preview>
  </div>