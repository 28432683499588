<app-order-bar (createOffer)="initOrder()"></app-order-bar>

<div class="container grid grid-cols-6 gap-4 px-4 py-4 md:py-20 mx-auto lg:px-8">
    <div class="col-span-6 md:col-span-4">
        <app-order-fields></app-order-fields>
    </div>
    <div class="col-span-6 md:col-span-2">
        <app-order-calculation class="sticky top-3 h-min"></app-order-calculation>
    </div>
</div>
