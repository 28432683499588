<div>
  <app-modal
    *ngIf="confirmModalOpen"
    (onClose)="confirmModalOpen = false"
    (onSubmit)="deleteOrders(); confirmModalOpen = false"
    class="text-center"
    [cancelLabel]="'OrderTable.ModalCancel' | translate"
    [submitLabel]="'OrderTable.ModalSubmit' | translate"
  >
    <span translate="OrderTable.ConfirmText" [translateParams]="{ count: selectedRowsCount }"></span>
  </app-modal>

  <div class="flex items-center justify-between mb-6">
    <span class="block text-lg font-medium uppercase">{{ 'OrderTable.Header' | translate }}</span>
    <app-button
      [label]="'OrderTable.FilterLabel' | translate"
      [size]="'xs'"
      (onClick)="isConfigurationsFilterOpen = !isConfigurationsFilterOpen"
    ></app-button>
  </div>

  <app-button
    type="primary"
    [label]="'OrderTable.RestoreText' | translate : { count: companyService.deletedOrders.length }"
    (onClick)="restoreOrders()"
    *ngIf="companyService.deletedOrders"
  >
  </app-button>
  <!-- filter -->
  <div
    class="relative flex items-center gap-4 p-4 bg-white border-l-4 border-monza-500"
    *ngIf="isConfigurationsFilterOpen"
  >
    <ng-container *ngIf="companyService.company$ | async as company">
      <app-input
        class="w-full max-w-lg"
        [label]="'OrderTable.SearchLabel' | translate"
        [type]="'text'"
        [isLabelHidden]="true"
        [placeholder]="'OrderTable.SearchLabel' | translate"
        (valueChange)="searchOrders($event)"
      ></app-input>
      <div class="w-px h-8 bg-gray-200"></div>
      <app-button
        [label]="'ARS'"
        [type]="search.productType === 'ARS' ? 'primary' : 'light'"
        (onClick)="search.productType = search.productType === 'ARS' ? undefined : 'ARS'; searchOrdersChange()"
      >
      </app-button>
      <app-button
        *ngIf="authService.currentUser?.isSuperAdmin && company.availableProductTypes.includes('AVK')"
        [label]="'AVK'"
        [isDisabled]="authService.currentUser?.isSuperAdmin === false"
        [type]="search.productType === 'AVK' ? 'primary' : 'light'"
        (onClick)="search.productType = search.productType === 'AVK' ? undefined : 'AVK'; searchOrdersChange()"
      >
      </app-button>
      <app-button
        *ngIf="authService.currentUser?.isSuperAdmin && company.availableProductTypes.includes('AL')"
        [label]="'AL'"
        [isDisabled]="authService.currentUser?.isSuperAdmin === false"
        [type]="search.productType === 'AL' ? 'primary' : 'light'"
        (onClick)="search.productType = search.productType === 'AL' ? undefined : 'AL'; searchOrdersChange()"
      >
      </app-button
    ></ng-container>
    <div class="w-px h-8 bg-gray-200"></div>
    <app-checkbox
      [label]="'OrderTable.OnlyOrderd' | translate"
      [(checked)]="search.onlyOrdered"
      (checkedChange)="searchOrdersChange()"
    ></app-checkbox>
    <div class="w-px h-8 bg-gray-200"></div>
    <app-checkbox
      [label]="'OrderTable.OnlyMe' | translate"
      [(checked)]="search.onlyMe"
      (checkedChange)="searchOrdersChange()"
    ></app-checkbox>
    <div class="absolute right-2.5 top-2.5">
      <app-button
        [label]="'OrderTable.CloseFilter' | translate"
        [size]="'xs'"
        [onlyIcon]="true"
        [icon]="'close'"
        [type]="'ghost'"
        (onClick)="isConfigurationsFilterOpen = !isConfigurationsFilterOpen"
      ></app-button>
    </div>
  </div>
  <div *ngIf="isConfigurationsFilterOpen" class="mb-6">
    <app-badge
      *ngIf="companyService.orderCount$ | async as count"
      [label]="count + ' ' + ('General.Results' | translate)"
      [type]="'danger'"
    ></app-badge>
  </div>
  <div class="overflow-x-auto">
    <table
      class="ass-table"
      mat-table
      [dataSource]="this.companyService.orders$"
      matSort
      (matSortChange)="sortOrders($event)"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <app-checkbox [(checked)]="selectAll" (onToggle)="selectAll = !selectAll"></app-checkbox>
        </th>
        <td mat-cell *matCellDef="let row; let i = index" class="w-12">
          <app-checkbox
            *ngIf="row.status !== 'Ordered'"
            [(checked)]="rowsSelected[i].selected"
            (onToggle)="toggleSelected(i)"
          ></app-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="'name'" [disabled]="somethingSelected">
          <app-button
            *ngIf="somethingSelected"
            [label]="'OrderTable.Delete' | translate"
            [size]="'xs'"
            (onClick)="confirmModalOpen = true"
          ></app-button>
          <span *ngIf="!somethingSelected"> {{ 'OrderTable.Columns.Name' | translate }} </span>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.name | shorten }}
        </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="nettoPrice">
          {{ 'OrderTable.Columns.Price' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="flex">
            <div badge class="w-full whitespace-nowrap" type="price" [hasPromo]="row.activePromotion != undefined" [label]="row.nettoPrice"></div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="user">
          {{ 'OrderTable.Columns.Username' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{ row.user.firstName }} {{ row.user.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="created">
          {{ 'OrderTable.Columns.Created' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <app-date-renderer [data]="{ value: row.created }"></app-date-renderer>
        </td>
      </ng-container>

      <ng-container matColumnDef="updated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="updated">
          {{ 'OrderTable.Columns.Updated' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <app-date-renderer [data]="{ value: row.updated }"></app-date-renderer>
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="productType">
          {{ 'OrderTable.Columns.Typ' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <div badge class="w-full whitespace-nowrap" type="info" [label]="row.productType"></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="status">
          {{ 'OrderTable.Columns.Status' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <div
            badge
            class="w-full whitespace-nowrap"
            *ngIf="row.status === 'Ordered'"
            [label]="'OrderTable.StatusORDERED' | translate"
          ></div>
          <div
            badge
            class="w-full whitespace-nowrap"
            *ngIf="row.status === 'Created'"
            [label]="'OrderTable.StatusCREATED' | translate"
          ></div>
          <div
            badge
            class="w-full whitespace-nowrap"
            *ngIf="row.status === 'Offer'"
            [label]="'OrderTable.StatusOFFERED' | translate"
          ></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'OrderTable.Columns.Actions' | translate }}</th>
        <td mat-cell *matCellDef="let row">
          <div class="flex items-center justify-start gap-2">
            <app-button
              [label]="'OrderTable.CTAToConfigurator' | translate"
              [size]="'xs'"
              [fullWidth]="true"
              (onClick)="goToConfigurator(row)"
            ></app-button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">{{ 'OrderTable.NoOrdersText' | translate }}</td>
      </tr>
    </table>

    <mat-paginator
      *ngIf="this.companyService.orderCount$ | async as length"
      [pageSize]="limit"
      [length]="length"
      (page)="paginateOrders($event)"
      aria-label="Select page of users"
    ></mat-paginator>
  </div>
</div>
