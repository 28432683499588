import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AddAVKSlotDTO, UpdateAVKSlotDTO } from '../../avk.component';
import { Product, ProductLineItem } from '../../../../../api-client';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({ template: '' })
export abstract class BaseAVKSlotComponent implements OnChanges, OnInit {
  abstract item: ProductLineItem;

  @Output()
  addSlot = new EventEmitter<AddAVKSlotDTO>();

  @Output()
  updateSlot = new EventEmitter<UpdateAVKSlotDTO>();

  @Output()
  removeSlot = new EventEmitter<number>();

  constructor(protected readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.initItem();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['item']) {
      this.updateItem(changes['item'].firstChange);
    }
  }

  abstract initItem(): void;

  abstract updateItem(firstChange?: boolean): void;

  productToOptionsPipe(slotNr: number, startingElem: string | null = '-') {
    let selected: Option | undefined = undefined;
    return map((s: Product[]) => {
      s.sort((a, b) => a.sorting - b.sorting);
      const res = s.map((slot) => {
        const opt = this.productSlotToOption(slot);
        if (slot.id == this.item.set.find((s) => s.slot == slotNr)?.productId) selected = opt;
        return opt;
      });
      if (startingElem != null)
        res.unshift({
          value: '',
          description: '',
          label: startingElem
        });
      if (!selected) selected = res[0];
      return { selected, res };
    });
  }

  productSlotToOption(prod: Product): Option {
    const description =
      this.translateService.currentLang === undefined || this.translateService.currentLang !== 'fr'
        ? prod.description
        : prod.descriptionFr;
    const additionalDescription =
      this.translateService.currentLang === undefined || this.translateService.currentLang !== 'fr'
        ? prod.additionalDescription
        : prod.additionalDescriptionFr;

    let description1 = description.trim().replace(/-$/g, '');
    const description2 = additionalDescription;

    if (
      !description.endsWith(' ') &&
      !description.endsWith('-') &&
      !description.endsWith('- ') &&
      !description2.startsWith(' ')
    ) {
      description1 = description1 + ' ';
    }

    const translated = this.translateService.getStreamOnTranslationChange('none').pipe(
      map((v) => {
        const description =
          this.translateService.currentLang === undefined || this.translateService.currentLang !== 'fr'
            ? prod.description
            : prod.descriptionFr;
        const additionalDescription =
          this.translateService.currentLang === undefined || this.translateService.currentLang !== 'fr'
            ? prod.additionalDescription
            : prod.additionalDescriptionFr;

        let description1 = description.trim().replace(/-$/g, '');
        const description2 = additionalDescription;

        if (
          !description.endsWith(' ') &&
          !description.endsWith('-') &&
          !description.endsWith('- ') &&
          !description2.startsWith(' ')
        ) {
          description1 = description1 + ' ';
        }

        return `${description1}${description2}`;
      })
    );

    return {
      value: prod.id,
      description: '',
      label: `${description1}${description2}`,
      translated,
      meta: prod.productNumber
    };
  }
}
