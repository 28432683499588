import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, Observable, ReplaySubject, Subject, tap, zip } from 'rxjs';
import { Template } from '../../api-client';
import { AuthService } from '../../services/auth.service';
import { OrderService } from '../../services/order.service';
import { TemplateService } from '../../services/template.service';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'app-draft-select',
  templateUrl: './draft-select.component.html',
  styleUrls: ['./draft-select.component.scss']
})
export class DraftSelectComponent implements OnInit {
  @Input()
  type: 'ORDER' | 'FIELD' = 'ORDER';

  @Input()
  grid = 6;

  @Input()
  sorting = 0;

  @Output()
  selected = new EventEmitter();

  templates$!: ReplaySubject<Template[]>;
  templatesCount$!: ReplaySubject<number>;

  searchDto: any = {};

  showNext$!: Observable<Boolean>;
  showPrev$!: Observable<Boolean>;

  draftFilter: boolean = false;
  constructor(
    public templateService: TemplateService,
    public authService: AuthService,
    public companyService: CompanyService,
    public orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.templates$ =
      this.type === 'ORDER' ? this.templateService.orderTemplates$ : this.templateService.lineItemTemplates$;
    this.templatesCount$ =
      this.type === 'ORDER' ? this.templateService.orderTemplatesCount$ : this.templateService.lineItemTemplatesCount$;

    this.showNext$ = this.templatesCount$.pipe(
      map((count) => {
        return (
          count >
            (this.type === 'ORDER'
              ? this.templateService.orderTemplateOffset + 1
              : this.templateService.lineItemTemplateOffset + 1) *
              this.limit && count > this.limit
        );
      })
    );
    this.showPrev$ = this.templatesCount$.pipe(
      map((count) => {
        return (
          (this.type === 'ORDER'
            ? this.templateService.orderTemplateOffset
            : this.templateService.lineItemTemplateOffset) > 0 && count > this.limit
        );
      })
    );
  }

  get limit() {
    return this.type === 'ORDER' ? this.templateService.orderTemplateLimit : this.templateService.lineItemTemplateLimit;
  }

  search() {
    if (this.type === 'ORDER') {
      this.templateService.orderTemplateOffset = 0;
      this.templateService.findAllOrderTemplates(this.searchDto);
    } else {
      this.templateService.lineItemTemplateOffset = 0;
      this.templateService.findAllLineItemTemplates(this.searchDto);
    }
  }

  create(id: string) {
    if (this.type === 'ORDER') {
      this.orderService.createOrderByTemplate(id);
    } else {
      this.orderService.addTemplateLineItem(id, this.sorting);
    }
  }

  prev() {
    if (this.type === 'ORDER') {
      this.templateService.orderTemplateOffset -= this.limit;
      this.templateService.findAllOrderTemplates(this.templateService.orderTemplateSearchDto);
    } else {
      this.templateService.lineItemTemplateOffset -= this.limit;
      this.templateService.findAllLineItemTemplates(this.templateService.lineItemTemplateSearchDto);
    }
  }

  next() {
    if (this.type === 'ORDER') {
      this.templateService.orderTemplateOffset += this.limit;
      this.templateService.findAllOrderTemplates(this.templateService.orderTemplateSearchDto);
    } else {
      this.templateService.lineItemTemplateOffset += this.limit;
      this.templateService.findAllLineItemTemplates(this.templateService.lineItemTemplateSearchDto);
    }
  }
}
