<div class="h-full w-full relative">
  <div [@fade] class="flex items-start gap-y-2 flex-col absolute pointer-events-none top-4 left-4" *ngIf="controls">
    <div class="flex items-center justify-center">
      <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 0H160c-88.38 0-160 71.63-160 160v192c0 88.38 71.63 160 160 160h64c88.38 0 160-71.63 160-160V160C384 71.62 312.4 0 224 0zM352 352c-.125 70.63-57.38 127.9-128 128H160c-70.63-.125-127.9-57.38-128-128V160c.125-70.63 57.38-127.9 128-128h64c70.63 .125 127.9 57.38 128 128V352zM192 95.1c-8.844 0-16 7.156-16 16v64C176 184.8 183.2 192 192 192s16-7.156 16-16v-64C208 103.2 200.8 95.1 192 95.1z"/></svg>
      Zoom 
    </div>
    <div class="flex items-center justify-center">
      <svg class="w-5 h-5 mr-2" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 384 512" style="enable-background:new 0 0 384 512;" xml:space="preserve">
      <path d="M224,0h-64C71.6,0,0,71.6,0,160v192c0,88.4,71.6,160,160,160h64c88.4,0,160-71.6,160-160V160C384,71.6,312.4,0,224,0z
          M32,160C32.1,89.4,89.4,32.1,160,32h16v160H32V160z M352,352c-0.1,70.6-57.4,127.9-128,128h-64c-70.6-0.1-127.9-57.4-128-128V224
        h320V352z"/>
      </svg>
      Pan 
    </div>
    <div class="flex items-center justify-center">
      <svg class="w-5 h-5 mr-2" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 384 512" style="enable-background:new 0 0 384 512;" xml:space="preserve">
        <path d="M224,0h-64C71.6,0,0,71.6,0,160v192c0,88.4,71.6,160,160,160h64c88.4,0,160-71.6,160-160V160C384,71.6,312.4,0,224,0z
          M352,352c-0.1,70.6-57.4,127.9-128,128h-64c-70.6-0.1-127.9-57.4-128-128V224h320V352z M352,192H208V32h16
        c70.6,0.1,127.9,57.4,128,128V192z"/>
      </svg>      
      Rotate 
    </div>
  </div>
  <iframe 
    id="iframe-view" 
    class="h-full w-full" 
    src="/assets/3d/index.html" 
    (mouseenter)="controls = true" 
    (mouseleave)="controls = false"
  ></iframe>
</div>