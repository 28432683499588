<div class="flex items-start justify-start col-span-6 gap-4" *ngIf="order$ | async as order" >
    <app-input
        class="w-full"
        *ngIf="!item.isDividerWall && !item.isWall"
        [label]="'Configuration.Fields.OpenField.Width'|translate"
        [type]="'number'"
        [unit]="'mm'"
        [(value)]="item.width"
        [isReadonly]="order.status == OrderStatusEnum.Ordered"
        (onChange)="updateWidth($event)"
    ></app-input>
    <app-input
        class="w-full"
        *ngIf="!item.isSocket"
        [label]="'Configuration.Fields.OpenField.Height'|translate"
        [type]="'number'"
        [unit]="'mm'"
        [(value)]="item.height"
        [isReadonly]="order.status == OrderStatusEnum.Ordered"
        (onChange)="updateHeight($event)"
    ></app-input>
    <app-input
        class="w-full"
        [label]="'Configuration.Fields.OpenField.Depth'|translate"
        [type]="'number'"
        [unit]="'mm'"
        [(value)]="item.depth"
        (onChange)="updateDepth($event)"
        [isReadonly]="order.status == OrderStatusEnum.Ordered"
    ></app-input>
</div>