import { Component, Input } from '@angular/core';
import { Product, ProductLineItem, ProductLineItemSideEnum, ProductTypeEnum } from '../../../../../api-client';
import { BaseALSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map } from 'rxjs';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';

@Component({
  selector: 'app-al-klapptablar',
  templateUrl: './klapptablar.component.html',
  styleUrls: ['./klapptablar.component.scss']
})
export class KlapptablarComponent extends BaseALSlotComponent {
  @Input()
  item!: ProductLineItem;

  readonly klapptablarSlot = 10;
  kapptablarItem$: Observable<Product> = this.productService
    .getSlot(this.klapptablarSlot, ProductTypeEnum.Al)
    .pipe(map((res) => res[0]));
  isKlapptablarSelected = false;
  selectedSide: Option<ProductLineItemSideEnum> | undefined = undefined;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  override initItem(): void {}
  override updateItem(firstChange?: boolean | undefined): void {
    this.isKlapptablarSelected = this.item.set.some((set) => set.slot === this.klapptablarSlot);
    const side = this.sideOptions.find(
      (side) => side.value == this.item.set.find((s) => s.slot == this.klapptablarSlot)?.side
    );
    if (side) this.selectedSide = side;
  }

  setSide(side: ProductLineItemSideEnum) {
    this.updateSlotItem(this.klapptablarSlot, { side });
  }
}
