import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { Order, OrderStatusEnum, ProductLineItem } from 'src/app/api-client';
import { OrderService } from 'src/app/services/order.service';
import { AVK_CONFIG } from '../order-field/avk/config/avk.config';
import { colors } from '../order-field/colors';
import { ActivatedRoute } from '@angular/router';
import { IframeService } from '../../services/3dview.service';

async function blobToBase64(blob: Blob) {
  return new Promise<any>((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve((reader.result as string).split('base64,')[1]);
    reader.readAsDataURL(blob);
  });
}

@Component({
  selector: 'app-order-calculation',
  templateUrl: './order-calculation.component.html',
  styleUrls: ['./order-calculation.component.scss']
})
export class OrderCalculationComponent {
  OrderStatusEnum = OrderStatusEnum;

  fileName: string = '';
  fileLoading = false;

  lineItems$ = this.orderService.order$.pipe(
    map((order) => {
      if (!order) return [];
      if (order.productType === 'SWG') {
        const items: ProductLineItem[] = [];
        for (const item of order.lineItems) {
          if (item.isAddon) {
            if (items.some((l) => l.productNumber === item.productNumber)) {
              const found = items.find((l) => l.productNumber === item.productNumber);
              found!.amount += item.amount;
              found!.price += item.price;
            } else {
              items.push({ ...item });
            }
          } else {
            items.push({ ...item });
          }
        }
        return items;
      }

      return order.lineItems;
    })
  );

  avkSettings$ = this.orderService.order$.pipe(
    map((order) => {
      const parent = order?.lineItems[0];
      const items = parent?.set;
      if (!items) return undefined;

      const compartmentMeta = items.find(
        (item) =>
          (item.slot === AVK_CONFIG.DOOR.META.RIGHT || item.slot === AVK_CONFIG.DOOR.META.LEFT) &&
          item.custom == 'additional'
      );

      const frontHorizontalDoor = items.find(
        (item) =>
          (item.slot == AVK_CONFIG.FRONT_H.COMPARTMENT_SLOT_LEFT &&
            (items.some((item) => item.slot === AVK_CONFIG.DOOR.META.RIGHT && item.custom == 'additional') ||
              !items.some((item) => item.custom == 'additional'))) ||
          (item.slot == AVK_CONFIG.FRONT_H.COMPARTMENT_SLOT_RIGHT &&
            (items.some((item) => item.slot === AVK_CONFIG.DOOR.META.LEFT && item.custom == 'additional') ||
              !items.some((item) => item.custom == 'additional')))
      );

      const frontHorizontalCompartment = items.find(
        (item) =>
          (item.slot == AVK_CONFIG.FRONT_H.COMPARTMENT_SLOT_RIGHT &&
            items.some((item) => item.slot === AVK_CONFIG.DOOR.META.RIGHT && item.custom == 'additional')) ||
          (item.slot == AVK_CONFIG.FRONT_H.COMPARTMENT_SLOT_LEFT &&
            items.some((item) => item.slot === AVK_CONFIG.DOOR.META.LEFT && item.custom == 'additional'))
      );

      const frontIsLeft = items.some((item) => item.slot === AVK_CONFIG.DOOR.META.LEFT && item.custom == 'additional');

      let frontHorizontalDoorRatio = '20%';
      if (frontHorizontalDoor && frontHorizontalDoor.height && parent.height) {
        frontHorizontalDoorRatio = `${(frontHorizontalDoor.height / parent.height) * 100}%`;
      }

      let frontHorizontalCompartmentRatio = '20%';
      if (frontHorizontalCompartment && frontHorizontalCompartment.height && parent.height) {
        frontHorizontalCompartmentRatio = `${(frontHorizontalCompartment.height / parent.height) * 100}%`;
      }

      let compartmentRatio = '20%';
      if (compartmentMeta && parent.width) {
        const compartment = items.find((item) => item.slot === compartmentMeta?.slot + 1);
        if (compartment && compartment.width) {
          compartmentRatio = `${(compartment.width / parent.width) * 100}%`;
        }
      }

      return {
        hasCompartment: compartmentMeta != undefined,
        compartmentRatio,
        hasFrontHorizontalDoor: frontHorizontalDoor != undefined,
        frontHorizontalDoorRatio,
        hasFrontHorizontalCompartment: frontHorizontalCompartment != undefined,
        frontHorizontalCompartmentRatio,
        frontIsLeft
      };
    })
  );

  @ViewChild('iframe') view3D!: ElementRef;

  constructor(
    public orderService: OrderService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private iframeService: IframeService
  ) {}

  refresh() {
    this.orderService.sortOrder(this.route.snapshot.params['id']);
  }

  targetFps(fps: number) {
    this.iframeService.targetFps(fps);
  }

  ngOnInit() {
    this.orderService.order$.subscribe((order) => {
      if (order == undefined) return;
      if (order.file != undefined && order.file != null) {
        if (order.file.name.includes('/')) {
          this.fileName = order.file.name.split('/')[1];
        } else {
          this.fileName = order.file.name;
        }
      } else {
        this.fileName = '';
      }
    });
  }

  trackById(index: number, ob: any) {
    return ob.id;
  }

  async uploadFile(event: any) {
    const file = event.target.files?.item(0);
    if (file == undefined) {
      return;
    }

    this.fileName = file.name;

    this.fileLoading = true;
    try {
      await this.orderService.addFileToOrder({
        contentType: file.type,
        data: await blobToBase64(file),
        key: `${this.orderService.orderID}/${file.name}`,
        level: 'private',
        size: file.size
      });
      this.fileLoading = false;
    } catch (e) {
      this.fileLoading = false;
      this.fileName = '';
    }
  }
}
