<div class="w-full bg-white">
  <ng-container *ngIf="orderService.order$ | async as order">
    <app-modal
      *ngIf="createTemplateModal"
      (onClose)="createTemplateModal = false"
      (onSubmit)="
        templateService.createOrderemplate(order.id, { name: templateName });
        createTemplateModal = false;
        templateName = ''
      "
      class="text-center"
      [cancelLabel]="'DraftModal.Cancel' | translate"
      [submitLabel]="'DraftModal.Submit' | translate"
    >
      <app-input [placeholder]="'DraftModal.Placeholder' | translate" [(value)]="templateName"></app-input>
    </app-modal>
  </ng-container>
  <div
    class="container relative flex flex-col items-center justify-between gap-4 px-4 py-4 mx-auto border-t border-transparent md:h-16 md:py-0 md:gap-0 md:flex-row lg:px-8"
  >
    <div class="absolute top-0 h-px bg-gray-200 left-4 lg:left-8 right-4 lg:right-8"></div>
    <div class="flex items-center w-full lg:w-1/4 gap-4">
      <app-button
        *ngIf="orderService.order$ | async as order"
        [label]="'Configuration.Bar.Back' | translate"
        [icon]="'keyboard-arrow-left'"
        [type]="'light'"
        [routerLink]="isOverview ? '/c/' + order.id : '/'"
      >
      </app-button>
      <app-input
        *ngIf="orderService.order$ | async as order"
        class="w-full"
        [isReadonly]="order.status == OrderStatusEnum.Ordered"
        [label]="'Configuration.Bar.NamePlaceholder' | translate"
        [isLabelHidden]="true"
        [type]="'text'"
        [placeholder]="'Configuration.Bar.NamePlaceholder' | translate"
        [value]="order.name"
        [isGhost]="true"
        (onChange)="updateOrder(order, $event)"
      >
      </app-input>
      <div
        *ngIf="!(orderService.order$ | async)"
        class="w-48 bg-gray-200 border-gray-200 h-9 border-x animate-pulse"
      ></div>
      <div
        *ngIf="!(orderService.order$ | async)"
        class="w-48 bg-gray-200 border-gray-200 h-9 border-x animate-pulse"
      ></div>
    </div>
    <div class="flex w-full md:w-auto gap-4 md:flex-row items-end md:items-center flex-col">
      <div
        badge
        class="w-full"
        *ngIf="(orderService.order$ | async)?.status === 'Offer'"
        [label]="'OrderTable.StatusOFFERED' | translate"
      ></div>
      <app-button
        *ngIf="isOverview && (orderService.order$ | async) as order"
        [label]="
          order.status === 'Ordered'
            ? ('Configuration.Bar.DownloadOrderOverview' | translate)
            : ('Configuration.Bar.DownloadOffer' | translate)
        "
        [icon]="'print'"
        [type]="'light'"
        [loading]="creatingOffer"
        (onClick)="offer(order.status !== 'Ordered')"
      ></app-button>
      <ng-container *ngIf="orderService.order$ | async as order">
        <app-button
          *ngIf="order.productType !== 'SWG'"
          (onClick)="createTemplateModal = true"
          [label]="'Configuration.Bar.SaveAsDraft' | translate"
          [icon]="'bookmark-add'"
        ></app-button
      ></ng-container>
      <ng-container *ngIf="!isOverview">
        <app-button
          *ngIf="orderService.order$ | async as order"
          [routerLink]="'/overview/' + order.id"
          [label]="'Configuration.Bar.GoToOverview' | translate"
          [icon]="'keyboard-arrow-right'"
          [type]="'secondary'"
        ></app-button>
      </ng-container>
      <div
        *ngIf="!(orderService.order$ | async)"
        class="w-48 bg-gray-200 border-gray-200 h-9 border-x animate-pulse"
      ></div>
    </div>
  </div>
</div>
