import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import axios from 'axios';
import { BehaviorSubject, firstValueFrom, from, lastValueFrom, ReplaySubject } from 'rxjs';
import {
  Company,
  CompanyApi,
  CompanyByTokenDTO,
  Configuration,
  ConfigurationParameters,
  CreateAddressDto,
  InviteCompanyDto,
  Order,
  OrderByOrder,
  OrderByUser,
  SearchOrder,
  SearchUser,
  UpdateAddressDto,
  UpdateCompanyDto,
  User
} from '../api-client';
import { AuthService } from './auth.service';
import { ASS_SERVICE_CONF } from './conf';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  api: CompanyApi;

  company$: ReplaySubject<Company> = new ReplaySubject();

  usersPaginated$: ReplaySubject<User[]> = new ReplaySubject();
  usersPaginatedCount$: ReplaySubject<number> = new ReplaySubject();

  orders$: ReplaySubject<Order[]> = new ReplaySubject();
  orderCount$: ReplaySubject<number> = new ReplaySubject();

  deletedOrders?: string[] = undefined;

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {
    this.api = new CompanyApi(this.authService.apiConfig, ASS_SERVICE_CONF.BASE_URL);
    this.authService.apiConfig$.subscribe((apiConfig) => {
      this.api = new CompanyApi(apiConfig, ASS_SERVICE_CONF.BASE_URL);
    });
  }

  async setDefaultProduct(companyId: string, productNumber: string, quantity: number) {
    return this.api
      .companyControllerSetDefaultProduct(companyId, { productNumber, quantity })
      .then((res) => {
        this.company$.next(res.data);
      })
      .catch(this.catch.bind(this));
  }

  async deleteDefaultProduct(companyId: string, productNumber: string) {
    return this.api
      .companyControllerDeleteDefaultProduct(productNumber, companyId)
      .then((res) => {
        this.company$.next(res.data);
      })
      .catch(this.catch.bind(this));
  }

  loadOrders(search: SearchOrder, sort: OrderByOrder, limit: number, offset: number) {
    this.api
      .companyControllerFindAllOrders(limit.toString(), offset.toString(), { search, sort })
      .then((res) => {
        this.orders$.next(res.data.orders ? res.data.orders : []);
        this.orderCount$.next(res.data.count);
      })
      .catch(this.catch.bind(this));
  }

  loadUsers(search: SearchUser, sort: OrderByUser, limit: number, offset: number) {
    this.api
      .companyControllerMyCompanyUsers(limit.toString(), offset.toString(), { search, sort })
      .then((res) => {
        this.usersPaginated$.next(res.data.users ? res.data.users : []);
        this.usersPaginatedCount$.next(res.data.count);
      })
      .catch(this.catch.bind(this));
  }

  requestDelete() {
    this.api
      .companyControllerAdminRequestDelete()
      .then((res) => {
        this.company$.next(res.data);
      })
      .catch(this.catch.bind(this));
  }

  async deleteOrders(ids: string[]) {
    return this.api
      .companyControllerRemoveMultipleOrders(ids)
      .then((res) => {
        this.deletedOrders = ids;
      })
      .catch(this.catch.bind(this));
  }

  async restoreOrders(ids: string[]) {
    return this.api
      .companyControllerRestoreMultipleOrders(ids)
      .then((res) => {})
      .catch(this.catch.bind(this));
  }

  loadCompany() {
    this.api
      .companyControllerMyCompany()
      .then((res) => {
        this.company$.next(res.data);
      })
      .catch(this.catch.bind(this));
  }

  async checkInviteToken(mail: string, token: string): Promise<boolean> {
    const payload: CompanyByTokenDTO = {
      mail: mail,
      token: token
    };
    try {
      await this.api.companyControllerGetCompanyNameByToken(payload);
      return true;
    } catch (error: any) {
      return false;
    }
  }

  async getNameByToken(mail: string, token: string): Promise<string | undefined> {
    const payload: CompanyByTokenDTO = {
      mail: mail,
      token: token
    };
    try {
      return (await this.api.companyControllerGetCompanyNameByToken(payload)).data;
    } catch (error: any) {
      console.error(error);
      return;
    }
  }

  inviteEmployee(email: string): void {
    const payload: InviteCompanyDto = {
      email: email
    };
    this.api
      .companyControllerInvite(payload)
      .then((res) => {
        this.company$.next(res.data);
        this.toastService.displayToast({
          message: 'Einladung wurde versendet.',
          type: 'success',
          time: 4000
        });
      })
      .catch(this.catch.bind(this));
  }

  async createAddress(payload: CreateAddressDto) {
    return new Promise((resolve, reject) => {
      this.api
        .companyControllerCreateCompanyAddress(payload)
        .then((res) => {
          this.company$.next(res.data);
          resolve(res.data);
        })
        .catch((reason: any) => {
          this.catch(reason);
          reject(reason);
        });
    });
  }

  deleteAddress(id: string) {
    this.api
      .companyControllerDeleteCompanyAddress(id)
      .then((res) => {
        this.company$.next(res.data);
        this.toastService.displayToast({
          message: 'Adresse wurde gelöscht',
          type: 'success',
          time: 4000
        });
      })
      .catch(this.catch.bind(this));
  }

  async updateAddress(id: string, payload: UpdateAddressDto) {
    return new Promise((resolve, reject) => {
      this.api
        .companyControllerUpdateCompanyAddress(id, payload)
        .then((res) => {
          this.company$.next(res.data);
          this.toastService.displayToast({
            message: 'Adresse wurde gespeichert',
            type: 'success',
            time: 4000
          });
          resolve(res.data);
        })
        .catch((reason: any) => {
          this.catch(reason);
          reject(reason);
        });
    });
  }

  udpateCompany(id: string, dto: UpdateCompanyDto) {
    this.api
      .companyControllerUpdate(id, dto)
      .then((res) => {
        this.company$.next(res.data);
      })
      .catch(this.catch.bind(this));
  }

  setActiveAddress(id: string) {
    this.api
      .companyControllerSetAddressActive(id)
      .then((res) => {
        this.company$.next(res.data);
      })
      .catch(this.catch.bind(this));
  }

  async catch(error: any) {
    let message = '';
    if ((error as any).response?.data) {
      message = (error as any).response?.data.message;
    } else {
      message = error.message;
    }

    const m = await firstValueFrom(this.translate.get(error?.response?.data?.message));
    let translatedMessage;
    if (typeof message === 'object') {
      translatedMessage = Object.values(m)[0] as string;
    } else {
      translatedMessage = m;
    }

    console.error(error);
    this.toastService.displayToast({
      type: 'danger',
      message: translatedMessage,
      time: 4000
    });
  }
}
