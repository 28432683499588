import { Component, Input, OnInit, Output } from '@angular/core';
import { OrderStatusEnum, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { OrderService } from '../../../../../services/order.service';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { BaseALSlotComponent } from './base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';
import { ProductService } from '../../../../../services/product.service';
import { AuthService } from '../../../../../services/auth.service';

@Component({
  selector: 'app-al-base, [alBase]',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent extends BaseALSlotComponent implements OnInit {
  order$ = this.orderService.order$;

  @Input()
  item!: ProductLineItem;

  OrderStatusEnum = OrderStatusEnum;

  selectedProtection!: Option;
  protectionLevelOptions: Option[] = [
    {
      value: '00',
      description: '',
      meta: '',
      label: 'IP00'
    },
    {
      value: '20',
      description: '',
      meta: '',
      label: 'IP20'
    },
    {
      value: '40',
      description: '',
      meta: '',
      label: 'IP40'
    },
    {
      value: '41',
      description: '',
      meta: '',
      label: 'IP41'
    },
    {
      value: '54',
      description: '',
      meta: '',
      label: 'IP54'
    }
  ];

  selectedDepth!: Option;
  depthOptions: Option[] = [150, 200, 250, 300, 350, 400].map((d) => ({
    value: d.toString(),
    description: '',
    label: d.toString()
  }));

  withoutSlot = 4;
  selectedWithout!: Option;
  withoutOptions$: Observable<Option[]> = this.productService.getSlot(this.withoutSlot, ProductTypeEnum.Al).pipe(
    this.productToOptionsPipe(this.withoutSlot, '-'),
    map((res) => {
      this.selectedWithout = res.selected;
      return res.res;
    })
  );

  highlightOverride = false;
  highlightLocks = false;

  constructor(
    private readonly orderService: OrderService,
    private readonly translate: TranslateService,
    private readonly productService: ProductService,
    private readonly auth: AuthService
  ) {
    super(translate);
  }

  override initItem(): void {
    this.selectedDepth = this.depthOptions.find((o) => o.value == this.item.depth?.toString())!;
    this.selectedProtection = this.protectionLevelOptions.find((o) => o.value == this.item.ipProtectionType)!;
  }

  override updateItem(firstChange?: boolean | undefined): void {}

  updateOverride(event: boolean) {
    this.orderService.updateLineItem(this.item.id, { override: event });
  }

  updateWidth(event: string) {
    this.orderService.updateLineItem(this.item.id, { width: +event });
  }

  updateHeight(event: string) {
    this.orderService.updateLineItem(this.item.id, { height: +event });
  }

  updateDepth(event: string) {
    this.orderService.updateLineItem(this.item.id, { depth: +event });
  }

  async updateProtectionLevel(event: string) {
    this.orderService.updateLineItem(this.item.id, { ipProtectionType: event });
  }
}
