import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Order } from '../../api-client';
import { OrderService } from '../../services/order.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent {
  @Input()
  order!: Order;

  promoCode = '';
  error = '';

  constructor(private orderService: OrderService, private translate: TranslateService) {}

  async addPromo() {
    try {
      await this.orderService.addPromoToOrder(this.order.id, this.promoCode);
      this.error = '';
    } catch (error) {
      let message = '';
      if ((error as any).response?.data) {
        message = (error as any).response?.data.message;
      } else if ((error as any).message) {
        message = (error as any).message;
      } else {
        message = 'An error occurred';
      }

      this.error = this.translate.instant(message);
    }
  }

  async removePromo() {
    this.orderService.removePromotion(this.order.id);
  }
}
