import { Component, Input } from '@angular/core';
import {
  Order,
  OrderStatusEnum,
  Product,
  ProductLineItem,
  ProductLineItemSideEnum,
  ProductTypeEnum
} from '../../../../../api-client';
import { BaseALSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-al-cartridge',
  templateUrl: './cartridge.component.html',
  styleUrls: ['./cartridge.component.scss']
})
export class CartridgeComponent extends BaseALSlotComponent {
  @Input()
  item!: ProductLineItem;

  cartridgeSlot = 11;
  cartridgeProducts$: Observable<Product[]> | undefined;
  cartridgeOptions$: Observable<Option[]> | undefined;
  selectedCartridge: Option | undefined = undefined;
  selectedSide: Option<ProductLineItemSideEnum> | undefined = undefined;

  cartridgeAmount = 1;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  override initItem(): void {}
  override updateItem(firstChange?: boolean | undefined): void {
    this.cartridgeProducts$ = this.productService.getSlot(this.cartridgeSlot, ProductTypeEnum.Al);
    this.cartridgeOptions$ = this.cartridgeProducts$!.pipe(
      this.productToOptionsPipe(this.cartridgeSlot, this.translate.instant('Configuration.AVK.Dropdowns.None')),
      map((res) => {
        this.selectedCartridge = res.selected;
        return res.res;
      })
    );
    this.cartridgeAmount = this.item.set.find((s) => s.slot == this.cartridgeSlot)?.amount || 1;
    const side = this.sideOptions.find(
      (side) => side.value == this.item.set.find((s) => s.slot == this.cartridgeSlot)?.side
    );
    if (side) this.selectedSide = side;
  }

  setSide(side: ProductLineItemSideEnum) {
    this.updateSlotItem(11, { side });
  }
}
