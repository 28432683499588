<div class="grid grid-cols-1 lg:grid-cols-2 items-start justify-start col-span-6 gap-y-8 lg:gap-8">
  <div class="flex items-start justify-start gap-4" *ngIf="compartmentProduct$ | async as compartmentProduct; else mdLoading">
    <app-checkbox
      class="relative self-end"
      [checked]="compartmentLineItem != undefined"
      [ngStyle]="{ 'bottom': compartmentLineItem?'10px':'0px' }"
      (checkedChange)="toggleCompartment(compartmentProduct, $event)"
      [label]="!compartmentLineItem ? ('Configuration.AVK.FrontHorizontal.NoFront' | translate) : ''"
    ></app-checkbox>

    <app-custom-select
      *ngIf="compartmentLineItem"
      class="w-full"
      [label]="'Configuration.AVK.FrontHorizontal.Type' | translate"
      [options]="constructionTypes"
      [selectedValue]="selectedConstructionType"
      (cchange)="selectedConstructionType = $event; setType($any($event.value))"
    >
    </app-custom-select>

    <app-input
      *ngIf="compartmentLineItem"
      [label]="'Configuration.Fields.OpenField.Height' | translate"
      [type]="'number'"
      [unit]="'mm'"
      (onChange)="updateHeight(+$event)"
      [value]="compartmentLineItem.height"
      placeholder="200"
    ></app-input>
  </div>

  <div class="col-span-2" *ngIf="selectedConstructionType.value == 'door' && compartmentLineItem != undefined">
    <app-avk-door
      [additionalCompartment]="additionalCompartment"
      [item]="item"
      [show]="selectedConstructionType.value == 'door' && compartmentLineItem != undefined"
      [doorInstallationArea]="$any('front-horizontal-' + side)"
      (addSlot)="addSlot.emit($event)"
      (removeSlot)="removeSlot.emit($event)"
    ></app-avk-door>
  </div>

  <div class="col-span-2" *ngIf="compartmentLineItem">
    <app-avk-filter
      [item]="item"
      [slot]="this.currentDoorSlot + 6"
      (addSlot)="addSlot.emit($event)"
      (updateSlot)="updateSlot.emit($event)"
      (removeSlot)="removeSlot.emit($event)"
    ></app-avk-filter>
  </div>
</div>



<ng-template #lgLoading>
  <app-skeleton size="lg"></app-skeleton>
</ng-template>
<ng-template #mdLoading>
  <app-skeleton size="md" class="col-span-3"></app-skeleton>
</ng-template>
<ng-template #xsLoading>
  <app-skeleton size="xs"></app-skeleton>
</ng-template>