<div class="flex justify-start gap-4 flex-col">
  <app-checkbox [label]="'Configuration.AVK.Door.Filter'|translate" [(checked)]="filterEnabled" (checkedChange)="toggle($event)"></app-checkbox>
  <div class="grid grid-cols-2 gap-4 flex-grow" *ngIf="filterEnabled">
    <div class="flex gap-4 items-center justify-start">
      <label class="block text-sm font-medium text-gray-600">
        {{ 'Configuration.AVK.Sidewall.Top' | translate }}:
      </label>
      <app-custom-select
        class="flex-grow min-w-0"
        *ngIf="airFilterOptionsTop$ | async as airFilterOptionsTop; else mdLoading"
        [options]="airFilterOptionsTop"
        [selectedValue]="selectedFilterTop"
        (cchange)="selectFilter($event, this.slot)"
      >
      </app-custom-select>
    </div>
    <div class="flex gap-4 items-center justify-start">
      <label class="block text-sm font-medium text-gray-600">
        {{ 'Configuration.AVK.Sidewall.Bottom' | translate }}:
      </label>
      <app-custom-select
        class="flex-grow min-w-0"
        *ngIf="airFilterOptionsBottom$ | async as airFilterOptionsBottom; else mdLoading"
        [options]="airFilterOptionsBottom"
        [selectedValue]="selectedFilterBottom"
        (cchange)="selectFilter($event, this.slot + 1)"
      >
      </app-custom-select>
    </div>
  </div>
</div>

<ng-template #lgLoading>
  <app-skeleton size="lg"></app-skeleton>
</ng-template>
<ng-template #mdLoading>
  <app-skeleton size="md" class="col-span-3"></app-skeleton>
</ng-template>
<ng-template #xsLoading>
  <app-skeleton size="xs"></app-skeleton>
</ng-template>
