<div class="relative flex items-start">
    <div class="flex items-center h-5">
        <input 
            [disabled]="isReadonly" 
            (change)="checkedChange.emit($event.returnValue)" 
            [id]="inputId" 
            [attr.aria-describedby]="name + '-description'" 
            [name]="name"
            [checked]="checked" 
            type="radio" 
            class="w-4 h-4 border-gray-300 text-monza-500 focus:ring-monza-400 transition-all duration-200" 
            [ngClass]="{'ring-monza-400 ring-2 ring-offset-2': highlight, 'cursor-default': isReadonly, 'cursor-pointer': !isReadonly}"
        >
    </div>
    <div class="ml-3 text-sm flex-shrink-0" *ngIf="label">
        <label [for]="inputId" class="font-medium text-gray-600" [ngClass]="{'cursor-default': isReadonly, 'cursor-pointer': !isReadonly}"><span [innerHTML]="label"></span></label>
        <p *ngIf="description" [id]="name+'-description'" class="text-gray-400">{{description}}</p>
    </div>
    <app-helper *ngIf="tooltip" matTooltipPosition="above" [matTooltip]="tooltip"></app-helper>
</div>