import { Product, ProductLineItem } from '../../api-client';

export function ConcatDescription(item: ProductLineItem | Product, langKey: string) {
  const description = langKey === undefined || langKey !== 'fr' ? item.description : item.descriptionFr;
  const additionalDescription =
    langKey === undefined || langKey !== 'fr' ? item.additionalDescription : item.additionalDescriptionFr;

  const description1 = description.trim().replace(/-$/g, '');
  const description2 = additionalDescription;
  return description1 + description2;
}
