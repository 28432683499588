import { Pipe, PipeTransform } from '@angular/core';
import { ProductLineItem } from '../api-client';

@Pipe({
  name: 'containsProduct'
})
export class ContainsProductPipe implements PipeTransform {
  transform(lineItem: ProductLineItem, productNumber: string): boolean {
    return (
      lineItem.set != undefined &&
      lineItem.set.length > 0 &&
      lineItem.set.some((prod) => prod.productNumber === productNumber)
    );
  }
}
