import { Component, Input, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Order, Product, ProductLineItem, ProductTypeEnum, SetAddonProductsDTO } from '../../../../../api-client';
import { firstValueFrom, from, lastValueFrom, map, switchMap } from 'rxjs';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { OrderService } from '../../../../../services/order.service';

@Component({
  selector: 'app-avk-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent {
  @Input()
  order!: Order;

  modalOpen = false;

  amounts: { [key: string]: number } = {};

  attachmentProducts$ = from(
    this.productService.api.productControllerFindAllAddonProductsForProduct(ProductTypeEnum.Avk)
  ).pipe(map((res) => res.data));

  heatingOptions$ = this.attachmentProducts$.pipe(map((prods) => prods.filter((p) => p.slot == 10)));
  heatingAmounts$ = this.heatingOptions$.pipe(
    switchMap((prods) =>
      this.orderService.order$.pipe(
        map((order) =>
          prods.map((prod) => ({
            amount: order?.lineItems.find((item) => item.productId == prod.id)?.amount ?? 0,
            id: prod.id
          }))
        )
      )
    )
  );
  isHeatingExpanded = false;

  thermostatOptions$ = this.attachmentProducts$.pipe(map((prods) => prods.filter((p) => p.slot == 20)));
  thermostatAmounts$ = this.thermostatOptions$.pipe(
    switchMap((prods) =>
      this.orderService.order$.pipe(
        map((order) =>
          prods.map((prod) => ({
            amount: order?.lineItems.find((item) => item.productId == prod.id)?.amount ?? 0,
            id: prod.id
          }))
        )
      )
    )
  );
  isThermostatExpanded = false;

  hygrostat$ = this.attachmentProducts$.pipe(map((prods) => prods.find((p) => p.slot == 30)));
  hygrostatAmount$ = this.hygrostat$.pipe(
    switchMap((prod) =>
      this.orderService.order$.pipe(
        map((order) => ({
          amount: order?.lineItems.find((item) => item.productId == prod?.id)?.amount ?? 0,
          id: prod?.id
        }))
      )
    )
  );

  floorClamp$ = this.attachmentProducts$.pipe(map((prods) => prods.find((p) => p.slot == 40)));
  floorClampAmount$ = this.floorClamp$.pipe(
    switchMap((prod) =>
      this.orderService.order$.pipe(
        map((order) => ({
          amount: order?.lineItems.find((item) => item.productId == prod?.id)?.amount ?? 0,
          id: prod?.id
        }))
      )
    )
  );

  constructor(
    public readonly translate: TranslateService,
    private readonly productService: ProductService,
    private readonly orderService: OrderService
  ) {}

  ngOnInit() {
    this.init();
  }

  async init() {
    const values = await Promise.all([
      firstValueFrom(this.heatingAmounts$),
      firstValueFrom(this.thermostatAmounts$),
      [await firstValueFrom(this.hygrostatAmount$)],
      [await firstValueFrom(this.floorClampAmount$)]
    ]);

    for (const value of values) {
      for (const amount of value) {
        if (amount.id) {
          this.amounts[amount.id] = amount.amount;
        }
      }
    }
  }

  toggleAttachmentModal() {
    this.modalOpen = !this.modalOpen;
  }

  async submitAttachmentModal() {
    this.modalOpen = false;

    const dto: SetAddonProductsDTO = {
      addons: Object.entries(this.amounts)
        .filter((obj) => obj[1] > 0)
        .map((obj) => ({ amount: obj[1], id: obj[0] }))
    };

    this.orderService.setAddonItems(dto);
  }
}
