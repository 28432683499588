<div
  class="grid grid-cols-4 col-span-6 gap-y-2 lg:gap-y-8 lg:gap-8 transition-all duration-300"
  [ngClass]="{ 'opacity-100': show, 'opacity-0 max-h-0 pointer-events-none -mt-4': !show }"
>
  <div class="col-span-4 grid grid-cols-1 md:grid-cols-3 items-center justify-start gap-8">
    <ng-container *ngIf="doorProducts$ | async as doorProducts; else mdLoading">
      <ng-container *ngIf="widthForInstallationArea$ | async as availableWidth; else mdLoading">
        <label for="{{ groupIdentifier }}-door-single" class="flex flex-col gap-4 cursor-pointer"[ngClass]="{'cursor-default': availableWidth > 1000, 'cursor-pointer': availableWidth <= 1000}">
          <div class="place-self-center" >
            <svg
              [ngClass]="{ 'opacity-50': availableWidth > 1000, 'opacity-100': availableWidth <= 1000 }"
              xmlns="http://www.w3.org/2000/svg"
              width="155"
              height="82"
              viewBox="0 0 155 82"
            >
              <g id="Gruppe_31" data-name="Gruppe 31" transform="translate(-220 -950)">
                <rect
                  id="Rechteck_53"
                  data-name="Rechteck 53"
                  width="77"
                  height="82"
                  transform="translate(264 950)"
                  fill="#d4d4d4"
                />
              </g>
            </svg>
          </div>
          <app-radio
            class="place-self-center"
            inputId="{{ groupIdentifier }}-door-single"
            name="{{ groupIdentifier }}-door"
            [label]="'Configuration.AVK.Door.Single' | translate"
            [isReadonly]="availableWidth > 1000"
            [checked]="selectedType == 'single'"
            (checkedChange)="selectedType = 'single'; setDoorType(doorProducts, selectedDoorSide, selectedType)"
          >
          </app-radio>
        </label>
        <label for="{{ groupIdentifier }}-door-double" class="flex flex-col gap-4 cursor-pointer"  [ngClass]="{'cursor-default': availableWidth <= 600, 'cursor-pointer': availableWidth > 600}">
          <div class="place-self-center">
            <svg
              [ngClass]="{ 'opacity-50': availableWidth < 600, 'opacity-100': availableWidth >= 600 }"
              xmlns="http://www.w3.org/2000/svg"
              width="155"
              height="82"
              viewBox="0 0 155 82"
            >
              <path
                id="Vereinigungsmenge_4"
                data-name="Vereinigungsmenge 4"
                d="M78,64V-18h77V64ZM0,64V-18H77V64Z"
                transform="translate(0 18)"
                fill="#d4d4d4"
              />
            </svg>
          </div>

          <app-radio
            class="place-self-center"
            inputId="{{ groupIdentifier }}-door-double"
            name="{{ groupIdentifier }}-door"
            [label]="'Configuration.AVK.Door.Double' | translate"
            [isReadonly]="availableWidth < 600"
            [checked]="selectedType == 'double'"
            (checkedChange)="selectedType = 'double'; setDoorType(doorProducts, selectedDoorSide, selectedType)"
          >
          </app-radio>
        </label>
        <label for="{{ groupIdentifier }}-door-middle" class="flex flex-col gap-4 cursor-pointer" [ngClass]="{'cursor-default': availableWidth <= 600, 'cursor-pointer': availableWidth > 600}">
          <div class="place-self-center">
            <svg
              [ngClass]="{ 'opacity-50': availableWidth < 600, 'opacity-100': availableWidth >= 600 }"
              xmlns="http://www.w3.org/2000/svg"
              width="155"
              height="82"
              viewBox="0 0 155 82"
            >
              <path
                id="Vereinigungsmenge_6"
                data-name="Vereinigungsmenge 6"
                d="M78,82V0h77V82ZM0,82V0H77V82Z"
                fill="#d4d4d4"
              />
            </svg>
          </div>

          <app-radio
            class="place-self-center"
            inputId="{{ groupIdentifier }}-door-middle"
            name="{{ groupIdentifier }}-door"
            [label]="'Configuration.AVK.Door.DoubleMiddle' | translate"
            [isReadonly]="availableWidth < 600"
            [checked]="selectedType == 'middle'"
            (checkedChange)="selectedType = 'middle'; setDoorType(doorProducts, selectedDoorSide, selectedType)"
            tooltip="Mittelpfosten versenkt"
          >
          </app-radio>
        </label>
      </ng-container>
    </ng-container>
  </div>
  <div class="col-span-4 lg:col-span-2 flex flex-col gap-4">
    <div class="grid grid-cols-2 gap-4" *ngIf="openingOptions$ | async as openingOptions; else mdLoading">
      <app-custom-select
        [placeholder]="true"
        [ngClass]="{ 'col-span-2': selectedHandle.value == '0' }"
        [label]="'Configuration.AVK.Door.Handle' | translate"
        [options]="handleOptions"
        [selectedValue]="selectedHandle"
        (cchange)="setHandle($event)"
      ></app-custom-select>

      <app-custom-select
        *ngIf="selectedHandle?.value != '0'"
        [placeholder]="true"
        [label]="'Configuration.AVK.Door.Cylinder' | translate"
        [options]="cylinderOptions"
        [selectedValue]="selectedCylinder"
        (cchange)="setCylinder($event)"
      ></app-custom-select>
    </div>
    <app-custom-select
      *ngIf="openingOptions$ | async as openingOptions; else mdLoading"
      class="col-span-2"
      [placeholder]="true"
      [label]="'Configuration.AVK.Door.Opening' | translate"
      [options]="openingOptions"
      [selectedValue]="selectedOpening!"
      (cchange)="selectedOpening = $event; setSlot($event.value, this.openingSlots.get(this.doorInstallationArea)!)"
    ></app-custom-select>

    <div class="flex gap-4 justify-between">
      <app-checkbox
        *ngIf="groundWireProduct$ | async as groundWireProduct; else mdLoading"
        [label]="'Configuration.AVK.Door.GroundWire' | translate"
        [checked]="hasGroundWire"
        (onToggle)="toggleGroundWire(groundWireProduct, $event)"
      ></app-checkbox>
      <app-checkbox
        *ngIf="lockableProduct$ | async as lockableProduct; else mdLoading"
        [label]="'Configuration.AVK.Door.Lockable' | translate"
        [checked]="hasLockable"
        (onToggle)="toggleLockable(lockableProduct, $event)"
      ></app-checkbox>
    </div>
  </div>

  <div class="col-span-4 lg:col-span-2 flex flex-col gap-4">
    <ng-container *ngIf="schemaProducts$ | async as schemaProducts; else mdLoading">
      <div class="flex items-center justify-start gap-4">
        <app-custom-select
          *ngIf="schemaOptions$ | async as schemaOptions; else mdLoading"
          class="flex-grow"
          style="max-width: 50%"
          [label]="'Configuration.AVK.Door.SchemaCompartment' | translate"
          [options]="schemaOptions"
          [selectedValue]="selectedSchema!"
          (cchange)="
            selectedSchema = $event; setSchema(schemaProducts, $any(selectedSchemaSideOption.value), $event.value)
          "
        ></app-custom-select>
        <div
          class="flex-auto flex items-center justify-start gap-4 transition-all duration-300"
          [ngClass]="{
            'max-w-80 opacity-100': selectedSchema?.value != '' && selectedType !== 'single',
            'max-w-0 opacity-0 pointer-events-none': selectedSchema?.value == '' || selectedType === 'single'
          }"
        >
          <svg
            class="mt-5 h-5 w-5 fill-monza-500 text-monza-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
              d="M0 256C0 167.6 71.6 96 160 96h64 32v64H224 160c-53 0-96 43-96 96s43 96 96 96h64 32v64H224 160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H352 320V352h32 64c53 0 96-43 96-96s-43-96-96-96H352 320V96h32 64c88.4 0 160 71.6 160 160zM192 224H384h32v64H384 192 160V224h32z"
            />
          </svg>
          <app-custom-select
            [placeholder]="false"
            class="mt-5 flex-auto"
            [options]="sideOptions"
            [selectedValue]="selectedSchemaSideOption"
            (cchange)="
              selectedSchemaSideOption = $event; setSchema(schemaProducts, $any($event.value), selectedSchema!.value)
            "
          ></app-custom-select>
        </div>
      </div>
    </ng-container>

    <div class="flex items-center justify-start gap-4">
      <app-checkbox
        *ngIf="klapptablarProduct$ | async as klapptablarProduct; else mdLoading"
        [(checked)]="hasKlapptablar"
        [label]="'Configuration.AVK.Door.Klapptablar' | translate"
        (onToggle)="toggleKlapptablar(klapptablarProduct, $event)"
      ></app-checkbox>
      <div
        class="flex-auto flex items-center justify-start gap-4 transition-all duration-300"
        [ngClass]="{
          'max-w-80 opacity-100': hasKlapptablar && selectedType !== 'single',
          'max-w-0 opacity-0 pointer-events-none': !hasKlapptablar || selectedType === 'single'
        }"
      >
        <svg class="h-5 w-5 fill-monza-500 text-monza-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
          <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M0 256C0 167.6 71.6 96 160 96h64 32v64H224 160c-53 0-96 43-96 96s43 96 96 96h64 32v64H224 160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H352 320V352h32 64c53 0 96-43 96-96s-43-96-96-96H352 320V96h32 64c88.4 0 160 71.6 160 160zM192 224H384h32v64H384 192 160V224h32z"
          />
        </svg>
        <ng-container *ngIf="klapptablarProductLeft$ | async as klapptablarProductLeft; else mdLoading">
          <ng-container *ngIf="klapptablarProductRight$ | async as klapptablarProductRight; else mdLoading">
            <app-custom-select
              class="flex-auto"
              [placeholder]="false"
              [options]="sideOptions"
              [selectedValue]="selectedSideOption"
              (cchange)="
                setKlapptablarSide($event.value == 'left' ? klapptablarProductLeft : klapptablarProductRight, $event)
              "
            ></app-custom-select>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div
      class="flex items-center justify-start gap-4 mt-2 transition-all duration-300"
      [ngClass]="{
        'max-h-8 opacity-100': selectedType === 'single',
        'max-h-0 opacity-0 pointer-events-none': selectedType !== 'single'
      }"
    >
      <ng-container *ngIf="doorProducts$ | async as doorProducts; else mdLoading">
        <app-radio
          class="col-span-3"
          [label]="'Configuration.AVK.Door.HingeLeft' | translate"
          name="lock-side-{{ groupIdentifier }}"
          [checked]="selectedDoorSide === 'left'"
          (checkedChange)="selectedDoorSide = 'left'; setDoorType(doorProducts, selectedDoorSide, selectedType)"
        ></app-radio>
        <app-radio
          class="col-span-3"
          [label]="'Configuration.AVK.Door.HingeRight' | translate"
          name="lock-side-{{ groupIdentifier }}"
          [checked]="selectedDoorSide === 'right'"
          (checkedChange)="selectedDoorSide = 'right'; setDoorType(doorProducts, selectedDoorSide, selectedType)"
        ></app-radio>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #lgLoading>
  <app-skeleton size="lg"></app-skeleton>
</ng-template>
<ng-template #mdLoading>
  <app-skeleton size="md" class="col-span-3"></app-skeleton>
</ng-template>
<ng-template #xsLoading>
  <app-skeleton size="xs"></app-skeleton>
</ng-template>
