import { Component, Input } from '@angular/core';
import { ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { BaseALSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-al-roof',
  templateUrl: './roof.component.html',
  styleUrls: ['./roof.component.scss']
})
export class RoofComponent extends BaseALSlotComponent {
  @Input()
  item!: ProductLineItem;

  products$ = this.productService.getSlot(32, ProductTypeEnum.Al);
  roof1$ = this.products$.pipe(
    map((prods) => prods.filter((product) => product.productNumber === 'aprp')),
    this.productToOptionsPipe(32, null),
    map((res) => res.res[0])
  );
  selected1 = false;
  roof2$ = this.products$.pipe(
    map((prods) => prods.filter((product) => product.productNumber === 'rda')),
    this.productToOptionsPipe(32, null),
    map((res) => res.res[0])
  );
  selected2 = false;
  empty = true;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  override initItem(): void {}
  override updateItem(firstChange?: boolean | undefined): void {
    const slotItem = this.item.set.find((set) => set.slot === 32);

    if (slotItem) {
      this.selected1 = slotItem.productNumber === 'aprp';
      this.selected2 = slotItem.productNumber === 'rda';
      this.empty = false;
    } else {
      this.empty = true;
    }
  }
}
