<div class="flex flex-row items-center flex-wrap lg:flex-nowrap justify-start col-span-6 gap-4">
  <ng-container *ngIf="ral7035$ | async as ral7035">
    <ng-container *ngIf="withBackProduct$ | async as withBackProduct">
      <div class="relative flex flex-row items-center">
        <div
          #elem
          class="w-12 h-12 mr-8 transition duration-100 ease-in-out rounded-full shadow-xl ring-2 ring-offset-2 ring-offset-white ring-gray-200 hover:ring-gray-400"
          [ngStyle]="{
            background: selectedColor.rgb_hex,
            'cursor-default': order.status == 'Ordered',
            'cursor-pointer': order.status != 'Ordered'
          }"
          (click)="order.status == 'Ordered' ? undefined : (colorPickerOpen = !colorPickerOpen)"
        ></div>
        <app-input
          [isReadonly]="order.status == OrderStatusEnum.Ordered"
          class="w-32"
          [label]="'Configuration.Fields.OpenField.RALColor' | translate"
          [error]="ralError"
          [value]="selectedColor.code"
          (valueChange)="updateColor($event)"
        ></app-input>
        <div
          #elem2
          class="absolute w-96 max-w-xs transform -translate-y-4 bg-white shadow-xl bottom-full ring-1 ring-gray-100"
          *ngIf="colorPickerOpen"
        >
          <app-button
            [label]="'Configuration.Fields.OpenField.Close' | translate"
            [icon]="'close'"
            [onlyIcon]="true"
            [type]="'ghost'"
            [size]="'xs'"
            class="absolute top-0 transform translate-x-2 shadow-xl left-full ring-1 ring-gray-100"
            (onClick)="colorPickerOpen = false"
          ></app-button>
          <div class="grid w-full h-full grid-cols-5 gap-4 p-4 overflow-y-scroll max-h-64 scrollbar">
            <div
              class="flex w-full transition duration-100 ease-in-out rounded-full shadow-xl cursor-pointer aspect-square ring-2 ring-offset-2 ring-offset-white ring-gray-200 hover:ring-monza-500"
              [ngStyle]="{ background: color.rgb_hex }"
              *ngFor="let color of RAL_COLORS"
              (click)="selectedColor = color; colorPickerOpen = false; updateColor(color.code)"
            >
              <span
                class="relative self-center w-full font-mono text-xs text-center text-white top-px"
                [ngStyle]="{ color: color.rgb_hex | textColor }"
                >{{ color.code }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <app-checkbox
        (checkedChange)="toggleWithBack($event, withBackProduct, ral7035)"
        class="ml-4 mt-6"
        [checked]="withBack"
        [label]="'Configuration.AL.BackWall.Painted' | translate"
      ></app-checkbox>
    </ng-container>
  </ng-container>
</div>
