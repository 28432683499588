<div class="container px-4 mx-auto text-center lg:px-8">
    <hr />
    <div class="flex items-center flex-wrap sm:flex-nowrap justify-center">
        <span 
            class="inline-block py-4 text-sm text-gray-500"
            translate="Footer.CopyRight"
            [translateParams]="{year}"
            >
        </span>
        <div class="inline-block ml-0 sm:ml-6 h-full pt-1 pb-4 sm:pb-0">
            <button 
                class="h-6 px-3 text-sm border-gray-300 border"  
                [ngClass]="{'bg-monza-500 text-white': translate.currentLang === 'de','bg-white text-gray-500': translate.currentLang !== 'de'}" 
                (click)="translate.use('de')">DE</button>
            <button 
                class="h-6 px-3 text-sm ml-3 border-gray-300 border" 
                [ngClass]="{'bg-monza-500 text-white': translate.currentLang === 'fr','bg-white text-gray-500': translate.currentLang !== 'fr'}"
                (click)="translate.use('fr')"
            >FR</button>
        </div>
    </div>
</div>