import { ChangeDetectorRef, Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableEvent } from '../../models/table-event.model';
import { TableCell } from '../../models/table-row.model';

@Directive()
export abstract class CustomCellRenderer implements OnInit {
  @Output() onRowEvent: EventEmitter<null> = new EventEmitter();

  @Input()
  public data!: TableCell;

  @Input()
  public event!: TableEvent;

  constructor(protected detector: ChangeDetectorRef) {}

  public ngOnInit() {
    this.detector.detectChanges();
  }

  public emitEvent() {
    this.onRowEvent.emit();
  }
}
