import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-label',
  templateUrl: './order-label.component.html',
  styleUrls: ['./order-label.component.scss']
})
export class OrderLabelComponent implements OnInit {
  @Input()
  label!: string;

  @Input()
  subHeader = false;

  constructor() {}

  ngOnInit(): void {}
}
