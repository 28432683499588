import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order, ProductLineItem, Template } from '../../api-client';

@Component({
  selector: 'app-draft-box',
  templateUrl: './draft-box.component.html',
  styleUrls: ['./draft-box.component.scss']
})
export class DraftBoxComponent implements OnInit {
  @Input()
  template?: Template;

  @Output()
  selectTemplate = new EventEmitter();

  @Output()
  deleteTemplate = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
