import { Component } from '@angular/core';
import { TableEvent } from 'src/app/components/table/models/table-event.model';
import { TableColumn } from 'src/app/components/table/models/table-column.model';
import { TableRow } from 'src/app/components/table/models/table-row.model';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { ToastPayload, ToastService } from 'src/app/services/toast.service';
import { Company } from 'src/app/api-client';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  oldPassword = '';
  password = '';
  passwordConfirm = '';

  constructor(
    public auth: AuthService,
    public userService: UserService,
    public companyService: CompanyService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.companyService.loadCompany();
  }

  saveToast() {
    this.userService.updateUser({
      password: this.oldPassword,
      newPassword: this.password,
      newPasswordConfirmation: this.passwordConfirm,
      email: this.auth.currentUser?.email,
      firstName: this.auth.currentUser?.firstName,
      lastName: this.auth.currentUser?.lastName
    });
  }
}
