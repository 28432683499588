import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { User } from 'src/app/api-client';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnChanges {
  constructor(private companyService: CompanyService) {}

  ngOnInit() {}

  ngOnChanges(): void {}

  @Input()
  isReadonly = false;

  @Input()
  user!: User | null | undefined;

  @Input()
  showExistingUseres = false;

  @Input()
  isDeletable = true;

  @Input()
  isRemovable = false;

  @Output()
  onClose: EventEmitter<void> = new EventEmitter();

  @Output()
  onRemove: EventEmitter<void> = new EventEmitter();

  @Output()
  onSubmitUser: EventEmitter<User> = new EventEmitter();

  async save() {
    if (!this.user) return;

    // if (!this.user.isActive && !this.user.mainUser) {
    //   if (this.user.id) {
    //     const payload: UpdateUserDto = {
    //       firstname: this.user.firstname,
    //       lastname: this.user.lastname,
    //       phone: this.user.phone,
    //       street: this.user.street,
    //       postal: this.user.postal,
    //       city: this.user.city,
    //       country: this.user.country
    //     };
    //     await this.companyService.updateUser(this.user.id, payload);
    //   } else {
    //     const payload: CreateUserDto = {
    //       firstname: this.user.firstname,
    //       lastname: this.user.lastname,
    //       phone: this.user.phone,
    //       street: this.user.street,
    //       postal: this.user.postal,
    //       city: this.user.city,
    //       country: this.user.country
    //     };
    //     await this.companyService.createUser(payload);
    //   }
    // }
    this.onClose.emit();
  }
}
