<div class="grid grid-cols-6 items-center justify-start col-span-6 gap-4">
  <div class="col-span-6 md:col-span-3">
    <app-custom-select
      *ngIf="lockOptions$ | async as lockOptions"
      class="w-full"
      [label]="'Configuration.Fields.OpenField.Lock' | translate"
      [options]="lockOptions"
      [selectedValue]="selectedLock!"
      [isReadonly]="order.status == OrderStatusEnum.Ordered"
      (cchange)="updateLock($event)"
      [placeholder]="false"
    >
    </app-custom-select>
  </div>
</div>
