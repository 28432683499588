<div class="relative flex flex-row items-center col-span-6">
  <ng-container *ngIf="colorProduct$ | async as colorProduct; else mdLoading">
    <ng-container *ngIf="colorRalProduct$ | async as colorRalProduct; else mdLoading">
      <ng-container *ngIf="colorGraffitiProduct$ | async as colorGraffitiProduct; else mdLoading">
        <div
          #elem
          class="w-12 h-12 mr-8 transition duration-100 ease-in-out rounded-full shadow-xl ring-2 ring-offset-2 ring-offset-white ring-gray-200 hover:ring-gray-400"
          [ngStyle]="{ background: selectedColor.rgb_hex }"
          (click)="colorPickerOpen = !colorPickerOpen"
        ></div>
        <app-input
          class="w-32"
          [label]="'Configuration.Fields.OpenField.RALColor' | translate"
          [error]="ralError"
          [value]="selectedColor.code"
          (valueChange)="
            setColor(
              $event,
              hasGraffiti ? colorGraffitiProduct.id : $event == '7035' ? colorProduct.id : colorRalProduct.id
            )
          "
        ></app-input>
        <div
          #elem2
          class="w-1/2 max-w-xs transform -translate-y-4 bg-white shadow-xl bottom-full ring-1 ring-gray-100"
          [ngClass]="{ absolute: colorPickerOpen, hidden: !colorPickerOpen }"
        >
          <app-button
            [label]="'Configuration.Fields.OpenField.Close' | translate"
            [icon]="'close'"
            [onlyIcon]="true"
            [type]="'ghost'"
            [size]="'xs'"
            class="absolute top-0 transform translate-x-2 shadow-xl left-full ring-1 ring-gray-100"
            (onClick)="colorPickerOpen = false"
          ></app-button>
          <div class="grid w-full h-full grid-cols-5 gap-4 p-4 overflow-y-scroll max-h-64 scrollbar">
            <div
              class="flex w-full transition duration-100 ease-in-out rounded-full shadow-xl cursor-pointer aspect-square ring-2 ring-offset-2 ring-offset-white ring-gray-200 hover:ring-monza-500"
              [ngStyle]="{ background: color.rgb_hex }"
              *ngFor="let color of RAL_COLORS"
              (click)="
                setColor(
                  color.code,
                  hasGraffiti ? colorGraffitiProduct.id : color.code == '7035' ? colorProduct.id : colorRalProduct.id
                )
              "
            >
              <span
                class="relative self-center w-full font-mono text-xs text-center text-white top-px"
                [ngStyle]="{ color: color.rgb_hex | textColor }"
                >{{ color.code }}</span
              >
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="flex flex-col ml-4 mt-2 gap-4">
    <app-checkbox
      *ngIf="eloxProduct$ | async as eloxProduct; else mdLoading"
      [label]="'Configuration.AVK.Color.Elox' | translate"
      [checked]="hasElox"
      (onToggle)="toggleElox(eloxProduct, $event)"
    ></app-checkbox>
    <ng-container *ngIf="colorProduct$ | async as colorProduct; else mdLoading">
      <ng-container *ngIf="colorRalProduct$ | async as colorRalProduct; else mdLoading">
        <app-checkbox
          *ngIf="colorGraffitiProduct$ | async as colorGraffitiProduct; else mdLoading"
          [label]="'Configuration.AVK.Color.Graffiti' | translate"
          [checked]="hasGraffiti"
          (onToggle)="
            toggleGraffiti(colorGraffitiProduct, selectedColor.code == '7035' ? colorProduct : colorRalProduct, $event)
          "
        ></app-checkbox>
      </ng-container>
    </ng-container>
  </div>
</div>


<ng-template #lgLoading>
  <app-skeleton size="lg"></app-skeleton>
</ng-template>
<ng-template #mdLoading>
  <app-skeleton size="md" class="col-span-3"></app-skeleton>
</ng-template>
<ng-template #xsLoading>
  <app-skeleton size="xs"></app-skeleton>
</ng-template>