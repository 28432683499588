<ng-container *ngIf="orderService.order$ | async as order">
  <div class="flex justify-between w-full font-medium text-emerald-500" *ngIf="order.activeDiscount">
    <span *ngIf="order.activeDiscount?.type == 'Percent'">
      <ng-container *ngIf="discountPercent$ | async as percent">
        {{'Discount.Percent'|translate:({value:percent})}}
      </ng-container>
    </span>
    <span *ngIf="order.activeDiscount?.type == 'Flat'">{{'Discount.Flat'|translate}}</span>
    
    <span *ngIf="order.activeDiscount?.type == 'Series'">
      <ng-container *ngIf="discountSeries$ | async as series">
      {{'Discount.Series'|translate:({amount: series.discountAmount, min:series.minFields})}}
      </ng-container>
    </span>

    <span class="font-mono">CHF -{{ discount$ | async   | currency: ' ':'symbol':'1.2-2' }}</span>
  </div>

  
  <div class="flex justify-between w-full font-medium text-emerald-500" *ngIf="promotion$ | async as promotion">
    <span>
        {{'Promotion.Promotion' | translate}} {{promotion.percent}}%
    </span>

    <span class="font-mono">CHF -{{ promotion.value | currency: ' ':'symbol':'1.2-2' }}</span>
  </div>
</ng-container>
