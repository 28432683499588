import { Component, Input, OnInit, Output } from '@angular/core';
import { Order, OrderStatusEnum, ProductLineItem } from '../../../../../api-client';
import { OrderService } from '../../../../../services/order.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-avk-base, [avkBase]',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  order$ = this.orderService.order$;

  @Input()
  item!: ProductLineItem;

  OrderStatusEnum = OrderStatusEnum;

  highlightOverride = false;
  highlightLocks = false;

  constructor(private readonly orderService: OrderService) {}

  ngOnInit(): void {}

  updateOverride(event: boolean) {
    this.orderService.updateLineItem(this.item.id, { override: event });
  }

  updateWidth(event: string) {
    this.orderService.updateLineItem(this.item.id, { width: +event });
  }

  updateHeight(event: string) {
    this.orderService.updateLineItem(this.item.id, { height: +event });
  }

  updateDepth(event: string) {
    this.orderService.updateLineItem(this.item.id, { depth: +event });
  }
}
