<div class="relative">
  <input 
    type="text" 
    [ngClass]="{'bg-gray-100': isReadonly}"
    [matDatepickerFilter]="filter" 
    [min]="minDate ? minDate : null" 
    [formControl]="dateForm" 
    [matDatepicker]="picker" 
    [placeholder]="placeholder" 
    (dateChange)="onChange.emit($event)"
  >
  <mat-datepicker-toggle [disabled]="isReadonly" [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker [disabled]="isReadonly">
  </mat-datepicker>
</div>