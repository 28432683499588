import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription, map, tap } from 'rxjs';
import { Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { OrderService } from '../../../../../services/order.service';
import { ProductService } from '../../../../../services/product.service';
import { BaseAVKSlotComponent } from '../base/base-slot.component';
import { TranslateService } from '@ngx-translate/core';
import { AVK_CONFIG } from '../../config/avk.config';

@Component({
  selector: 'app-avk-sidewall',
  templateUrl: './sidewall.component.html',
  styleUrls: ['./sidewall.component.scss']
})
export class SidewallComponent extends BaseAVKSlotComponent {
  @Input()
  item!: ProductLineItem;

  @Input()
  side: 'Left' | 'Right' = 'Left';

  @Output()
  sideWallAsDoorChange = new EventEmitter<boolean>();
  sideWallAsDoor = false;

  openingSlots: Map<'Left' | 'Right', number> = new Map([
    ['Left', AVK_CONFIG.DOOR.SIDE_LEFT.SLOT_OPENING],
    ['Right', AVK_CONFIG.DOOR.SIDE_RIGHT.SLOT_OPENING]
  ]);
  openingProducts$: Observable<Product[]> | undefined;
  selectedOpening: Option | undefined = undefined;
  openingOptions$: Observable<Option[]> | undefined;

  sideWallProduct$!: Observable<Product>;
  doorProduct$!: Observable<Product | undefined>;

  constructor(
    private readonly productService: ProductService,
    private readonly orderService: OrderService,
    private readonly translate: TranslateService
  ) {
    super(translate);
  }

  override initItem(): void {
    this.sideWallProduct$ = this.productService.getSlot(this.currentSlot, ProductTypeEnum.Avk).pipe(map((p) => p[0]));
    this.doorProduct$ = this.productService
      .getSlot(this.currentSlot + 1, ProductTypeEnum.Avk)
      .pipe(map((p) => p.find((p) => p.productNumber == 'avtl')));
  }

  setOpening(productId: string, slot: number) {
    if (productId == '') {
      if (this.item.set.some((s) => s.slot == slot)) {
        this.removeSlot.emit(slot);
      }
    } else {
      this.addSlot.emit({
        create: { productId },
        slot
      });
    }
  }

  updateItem() {
    this.sideWallAsDoor = this.item.set.some((s) => s.slot === this.currentSlot + 1);
    this.sideWallAsDoorChange.emit(this.sideWallAsDoor);
    this.openingProducts$ = this.productService.getSlot(this.openingSlots.get(this.side)!, ProductTypeEnum.Avk);
    this.openingOptions$ = this.openingProducts$.pipe(
      this.productToOptionsPipe(
        this.openingSlots.get(this.side)!,
        this.translate.instant('Configuration.AVK.Dropdowns.None')
      ),
      map((res) => {
        this.selectedOpening = res.selected;
        return res.res;
      })
    );
  }

  get currentSlot() {
    return this.side === 'Left' ? 99 : 119;
  }

  toggleDoor(sideWall: Product, door: Product, checked: boolean) {
    this.sideWallAsDoor = checked;
    this.sideWallAsDoorChange.emit(checked);
    if (!checked) {
      this.addSlot.emit({ create: { productId: sideWall.id }, slot: this.currentSlot });
    } else {
      this.addSlot.emit({ create: { productId: door.id }, slot: this.currentSlot + 1 });
    }
  }
}
